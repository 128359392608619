<template>
    <div id="app">
        <start-vy v-if="!currentProfile" />
        <kliniken-frame v-else />
        <confirm-dialog />
        <popup />
        <svg xmlns:svg="<http://www.w3.org/2000/svg>" version="1.1" height="0" width="0" style="display: none">
            <defs>
                <filter id="global-B0B0B1">
                    <feColorMatrix
                        color-interpolation-filters="sRGB"
                        type="matrix"
                        values="0.69 0   0   0   0
                        0   0.69  0   0   0
                        0   0   0.69  0   0
                        0   0   0   1   0 "
                    />
                </filter>
            </defs>
        </svg>
    </div>
</template>

<script>
import StartVy from "@/components/StartVy.vue";
import KlinikenFrame from "@/components/KlinikenFrame.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import Popup from "@/components/Popup.vue";
import { mapGetters } from "vuex";

export default {
    name: "app",

    components: {
        StartVy,
        KlinikenFrame,
        ConfirmDialog,
        Popup,
    },

    computed: {
        ...mapGetters("userData", ["currentProfile"]),
    },
};
</script>

<style lang="scss">
@import "normalize.css";
@import "@/style/app";
@import "@/style/tooltips/klinikentooltip";
</style>
