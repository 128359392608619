import { mapActions, mapGetters, mapMutations } from "vuex";
import dialogEvents from "@/dialogEvents";
import Samtycke from "@/tabs/Samtycke/Samtycke.vue";
import { openDialog } from "@/utils";
import getUrlParams from "@/utils/getUrlParams";
import tabComponents from "@/tabs";

export default {
    computed: {
        ...mapGetters("samtycke", ["typerAvSamtycke", "patientsamtycken"]),
        ...mapGetters("patientData", ["currentId"]),
    },

    methods: {
        ...mapActions("tabs", ["openTab"]),
        ...mapActions("patientData", { loadPatient: "_loadFromBackend" }),
        ...mapMutations("prescriptionSet", ["setExternalParameters"]),
        ...mapActions("samtycke", ["loadPatientsamtycken", "fetchTyperAvSamtycke"]),
        async externalCall() {
            try {
                const urlParams = getUrlParams();

                // Tömer location.search
                history.pushState({}, document.title, window.location.pathname);

                if (urlParams.patient) {
                    await this.loadPatient({ id: urlParams.patient });
                    delete urlParams.patient;
                }

                if (urlParams.modul) {
                    let tab = urlParams.modul;
                    delete urlParams.modul;

                    // Recept ligger både som standalone-app och tabb. Den förra har inte tillgång till parameters via
                    // tabs. Man behöver därför sätta parametrar i store istället.
                    if (tab.toLowerCase() === "prescriptionset") this.setExternalParameters(urlParams);

                    // Fulhack för att akut hotfixa att uthoppsadressen från Skreg inte refaktoriserats samtidigt som
                    // övriga journalmodulen utan fortfarande pekar på "&modul=note".
                    if (tab.toLowerCase() === "note") tab = "journalanteckning";

                    if (tab.toLowerCase() === "journalanteckning") {
                        let samtyckeExists = await this.checkSamtycke();
                        if (!samtyckeExists) {
                            dialogEvents.$emit("open", {
                                type: "warning",
                                description:
                                    "Den här patienten har inte registrerat samtycke. Vill du registrera ett nu?",
                                buttons: [
                                    {
                                        title: "Nej",
                                        type: "secondary",
                                    },
                                    {
                                        title: "Ja",
                                        type: "primary",
                                        action: () => {
                                            this.openTab({ component: Samtycke });
                                        },
                                    },
                                ],
                            });
                        }
                    }

                    const component = this.getComponent(tab);

                    return this.openTab({ component, parameters: urlParams });
                }
            } catch (e) {
                openDialog("Något gick fel vid inhoppet, kontakta support.", "error");
            }
        },
        async checkSamtycke() {
            let patient = this.currentId;
            await this.loadPatientsamtycken(patient);
            await this.fetchTyperAvSamtycke();
            let samtyckesTyper = this.typerAvSamtycke;
            let samtyckesTypExists = false;
            for (let i = 0; i < samtyckesTyper.length; i++) {
                if (samtyckesTyper[i].kod.indexOf("utlamning_skaderegistret") !== -1) samtyckesTypExists = true;
            }
            if (!samtyckesTypExists) return true;
            let samtycken = this.patientsamtycken;
            for (let i = 0; i < samtycken.length; i++) {
                let samtyckesTyp = samtycken[i].typavsamtycke_display.kod.toLowerCase();
                let valid = samtycken[i].is_current;
                if (samtyckesTyp.indexOf("utlamning_skaderegistret") !== -1 && valid) return true;
            }
            return false;
        },
        getComponent(name) {
            let component;
            const entries = Object.entries(tabComponents);
            entries.forEach((entry) => {
                if (name.toLowerCase() === entry[0].toLowerCase()) component = entry[1];
            });
            return component;
        },
    },
};
