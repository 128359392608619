<template>
    <div class="operationsjournal">
        <modal-basic ref="dialog" />
        <h1>Operationsjournal</h1>
        <collapsible-section number="1" heading="Operationsuppgifter" class="operationsuppgifter">
            <FormulateForm
                @submit="submitOperationsuppgifter"
                v-model="operationsuppgifterFormData"
                :errors="formError"
            >
                <div class="typoperationer">
                    <FormulateInput
                        name="typoperation"
                        :options="typoperationer"
                        type="vueselect"
                        isDropdown="true"
                        placeholder="Välj typoperation"
                        label="Typoperation"
                        v-on:input="typoperationChanged()"
                    />
                </div>

                <FormulateInput
                    id="huvuddiagnos"
                    name="huvuddiagnos"
                    label="Huvuddiagnoskod *"
                    type="vueselect"
                    :options="huvuddiagnoser"
                    @search="searchHuvuddiagnos"
                    v-on:input="huvuddiagnosChanged()"
                    class="autocomplete"
                />

                <FormulateInput
                    type="group"
                    name="bidiagnoser"
                    :repeatable="true"
                    add-label="Lägg till bidiagnoser"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <FormulateInput
                            name="bidiagnos"
                            label="Bidiagnoskod"
                            type="vueselect"
                            isDropdown="true"
                            :options="bidiagnoser[index]"
                            @search="(search, loading) => searchBidiagnoser(index, search, loading)"
                            v-on:input="bidiagnosChanged(index)"
                            class="autocomplete"
                        />
                    </template>

                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeBidiagnoser(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>

                    <template #addMore="{ addMore }">
                        <div class="formulate-input-group-add-more">
                            <button type="button" data-minor="" data-ghost="" @click="addMoreBidiagnoser(addMore)">
                                <span class="formulate-input-element--button--label">Lägg till fler bidiagnoser</span>
                            </button>
                        </div>
                    </template>
                </FormulateInput>

                <FormulateInput
                    id="huvudoperationskod"
                    name="huvudoperationskod"
                    label="Huvudoperationskod *"
                    type="vueselect"
                    isDropdown="true"
                    :options="huvudoperationskoder"
                    @search="searchHuvudoperationskod"
                    v-on:input="huvudoperationskodChanged()"
                    class="autocomplete"
                />

                <FormulateInput
                    type="group"
                    name="bioperationskoder"
                    :repeatable="true"
                    add-label="Lägg till bioperationskod"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <FormulateInput
                            name="bioperationskod"
                            label="Bioperationskod"
                            type="vueselect"
                            :options="bioperationskoder[index]"
                            @search="(search, loading) => searchBioperationskoder(index, search, loading)"
                            v-on:input="bioperationskodChanged(index)"
                            class="autocomplete"
                        />
                    </template>
                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeBioperationskoder(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>

                    <template #addMore="{ addMore }">
                        <div class="formulate-input-group-add-more">
                            <button
                                type="button"
                                data-minor=""
                                data-ghost=""
                                @click="addMoreBioperationskoder(addMore)"
                            >
                                <span class="formulate-input-element--button--label"
                                    >Lägg till fler bioperationskoder</span
                                >
                            </button>
                        </div>
                    </template>
                </FormulateInput>

                <div class="row">
                    <FormulateInput
                        type="date"
                        name="operationsdatum"
                        label="Operationsdatum *"
                        @input="removeError('operationsdatum')"
                    />

                    <FormulateInput
                        name="sal"
                        label="Opsalar"
                        type="vueselect"
                        :options="salar"
                        placeholder="Välj Opsal"
                    />

                    <FormulateInput type="checkbox" name="reop" label="Reoperation" />

                    <FormulateInput type="checkbox" name="garantiop" label="Garantioperation" />
                </div>

                <div class="row">
                    <FormulateInput name="lateralitet" label="Lateralitet" type="vueselect" :options="lateraliteter" />

                    <FormulateInput name="avtal" label="Avtal" type="vueselect" :options="avtal" />

                    <FormulateInput class="anmarkning" name="anmarkning" type="textarea" label="Anmärkning" />
                </div>

                <div class="grey-div">
                    <FormulateInput type="vueselect" label="Signeringsansvarig"></FormulateInput>
                    <ButtonGroup class="buttonGroup">
                        <Button size="small" class="spara" type="submit">
                            <img v-if="loading" class="rotate" src="@/assets/loader.svg" alt="" />
                            <span v-else>Spara</span>
                        </Button>
                    </ButtonGroup>
                </div>
            </FormulateForm>
        </collapsible-section>

        <collapsible-section number="2" heading="Patientdata" class="patientdata">
            <FormulateForm @submit="submitPatientdata" v-model="patientdataFormData" :errors="formError">
                <FormulateInput
                    type="group"
                    name="patientdata"
                    class="patientdata-group"
                    #default="{ index: groupIndex }"
                >
                    <FormulateInput type="group" name="kontroll" class="kontroll patientdata-groups">
                        <template #label="{ id }">
                            <h4 :for="id">Kontroll</h4>
                        </template>
                        <FormulateInput
                            name="id_kontroll"
                            label="ID-kontroll"
                            type="vueselect"
                            :options="id_kontroll"
                        />

                        <FormulateInput name="opmarkering" label="Opmarkering" type="text" />
                        <FormulateInput
                            name="orenop"
                            label="Oren op"
                            type="vueselect"
                            :options="[
                                { value: 'ja', label: 'Ja' },
                                { value: 'nej', label: 'Nej' },
                            ]"
                        />

                        <FormulateInput
                            name="blodsmitta"
                            label="Blodsmitta"
                            type="vueselect"
                            :options="[
                                { value: 'ja', label: 'Ja' },
                                { value: 'nej', label: 'Nej' },
                            ]"
                        />
                    </FormulateInput>

                    <FormulateInput type="group" name="hud" class="hud patientdata-groups">
                        <template #label="{ id }">
                            <h4 :for="id">Hud</h4>
                        </template>

                        <template #default="{ index: hudIndex }">
                            <FormulateInput
                                name="hudstatus"
                                label="Hudstatus"
                                type="vueselect"
                                :options="[
                                    { value: 'ua', label: 'Ua' },
                                    { value: 'anmarkning', label: 'Anmärkning' },
                                ]"
                                @input="hudstatusChanged(hudIndex)"
                            />
                            <FormulateInput
                                name="anmarkninghud"
                                label="Anmärkning hud"
                                type="text"
                                :disabled="
                                    !patientdataFormData.patientdata ||
                                    (patientdataFormData.patientdata[groupIndex] &&
                                        patientdataFormData.patientdata[groupIndex]?.hud &&
                                        patientdataFormData.patientdata[groupIndex]?.hud[hudIndex]?.hudstatus !==
                                            'anmarkning')
                                "
                            />
                            <FormulateInput
                                name="haravkortning"
                                label="Håravkortning"
                                type="vueselect"
                                :options="[
                                    { value: 'ja', label: 'Ja' },
                                    { value: 'nej', label: 'Nej' },
                                ]"
                            />

                            <FormulateInput
                                name="optvatt"
                                label="Optvätt"
                                type="vueselect"
                                :options="[
                                    { value: 'descutan', label: 'Descutan' },
                                    { value: 'klorhexidin', label: 'Klorhexidin' },
                                    { value: 'annat', label: 'Annat' },
                                ]"
                            />

                            <FormulateInput name="diatermi" label="Diatermi" type="text" />

                            <FormulateInput
                                name="diatermi_placering"
                                label="Diatermi placering"
                                type="vueselect"
                                :options="[
                                    { value: 'ben', label: 'Ben' },
                                    { value: 'rygg', label: 'Rygg' },
                                    { value: 'mage', label: 'Mage' },
                                    { value: 'annat', label: 'Annat' },
                                ]"
                            />

                            <FormulateInput type="text" name="diatermi_installningar" label="Diatermi inställningar" />

                            <FormulateInput
                                name="blodtomt_tryck_tid"
                                label="Blodtomt tryck tid (minuter)"
                                type="knumber"
                            />
                            <FormulateInput
                                name="hudsutur"
                                label="Hudsutur"
                                type="vueselect"
                                :options="[
                                    { value: 'resorberbar', label: 'Resorberbar' },
                                    { value: 'ejresorberbar', label: 'Ej resorberbar' },
                                    { value: 'stapler', label: 'Stapler' },
                                    { value: 'annan', label: 'Annan' },
                                ]"
                            />

                            <FormulateInput
                                name="forband"
                                label="Förband"
                                type="vueselect"
                                :options="[
                                    { value: 'tegaderm', label: 'Tegaderm' },
                                    { value: 'tejp', label: 'Tejp' },
                                    { value: 'annat', label: 'Annat' },
                                ]"
                            />
                        </template>
                    </FormulateInput>

                    <FormulateInput type="group" name="atgard" class="atgard patientdata-groups">
                        <template #label="{ id }">
                            <h4 :for="id">Åtgärd</h4>
                        </template>

                        <template #default="{ index: atgardIndex }">
                            <FormulateInput
                                name="skoljvatska"
                                label="Sköljvätska"
                                type="vueselect"
                                :options="[
                                    { value: 'natriumklorid9mg/ml', label: 'Natriumklorid 9mg/ml' },
                                    { value: 'annat', label: 'Annat' },
                                ]"
                            />

                            <FormulateInput name="antalpad" label="Antal PAD" type="knumber" />
                            <FormulateInput
                                name="odling_tagen"
                                label="Odling tagen"
                                type="vueselect"
                                :options="[
                                    { value: 'ja', label: 'Ja' },
                                    { value: 'nej', label: 'Nej' },
                                ]"
                            />

                            <div class="flexbreak"></div>
                            <FormulateInput
                                name="dranage"
                                label="Dränage"
                                type="vueselect"
                                :options="[
                                    { value: 'ja', label: 'Ja' },
                                    { value: 'nej', label: 'Nej' },
                                ]"
                            />

                            <FormulateInput
                                name="klippt"
                                label="Klippt?"
                                type="vueselect"
                                :options="[
                                    { value: 'ja', label: 'Ja' },
                                    { value: 'nej', label: 'Nej' },
                                ]"
                                :disabled="
                                    !patientdataFormData.patientdata ||
                                    (patientdataFormData.patientdata[groupIndex] &&
                                        patientdataFormData.patientdata[groupIndex]?.atgard &&
                                        patientdataFormData.patientdata[groupIndex]?.atgard[atgardIndex]?.dranage !==
                                            'ja')
                                "
                            />
                        </template>
                    </FormulateInput>
                </FormulateInput>
                <div class="grey-div">
                    <FormulateInput type="vueselect" label="Signeringsansvarig"></FormulateInput>
                    <ButtonGroup class="buttonGroup">
                        <Button size="small" class="spara" type="submit">
                            <img v-if="loading" class="rotate" src="@/assets/loader.svg" alt="" />
                            <span v-else>Spara</span>
                        </Button>
                    </ButtonGroup>
                </div>
            </FormulateForm>
        </collapsible-section>

        <collapsible-section number="3" heading="Personal" class="personal">
            <FormulateForm @submit="submitPersonal" v-model="personalFormData" :errors="formError">
                <div class="wrapper-3-1">
                    <FormulateInput
                        label="Huvudoperatör *"
                        name="huvudoperator"
                        type="vueselect"
                        :options="operatorer"
                        @search="(search, loading) => searchOperatorer(search, loading)"
                        @input="removeError('huvudoperator')"
                    />
                    <FormulateInput label="Tid in" type="timepicker" useNow name="huvudoperator_intid" />

                    <FormulateInput label="Tid ut" type="timepicker" useNow name="huvudoperator_uttid" />
                </div>
                <FormulateInput
                    type="group"
                    name="ass_operatorer"
                    :repeatable="true"
                    add-label="Lägg till fler opassistenter"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <FormulateInput
                            label="Operationsassistent"
                            type="vueselect"
                            name="profile"
                            :options="ass_operatorer[index]"
                            @search="(search, loading) => searchOpassistenter(index, search, loading)"
                            @input="personalChanged(index, 'ass_operatorer')"
                        />
                        <FormulateInput label="Tid in" type="timepicker" useNow name="intid" />
                        <FormulateInput label="Tid ut" type="timepicker" useNow name="uttid" />
                    </template>
                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeOpassistenter(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>

                    <template #addMore="{ addMore }">
                        <div class="formulate-input-group-add-more">
                            <button type="button" data-minor="" data-ghost="" @click="addMoreAssOperatorer(addMore)">
                                <span class="formulate-input-element--button--label">Lägg till fler opassistenter</span>
                            </button>
                        </div>
                    </template>
                </FormulateInput>
                <FormulateInput
                    type="group"
                    name="operationssjukskoterskor"
                    :repeatable="true"
                    add-label="Lägg till fler opjuksköterskor"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <FormulateInput
                            v-if="index === 0"
                            label="Operationssjuksköterska *"
                            type="vueselect"
                            :options="operationssjukskoterskor[index]"
                            @search="(search, loading) => searchOperationssjukskoterska(index, search, loading)"
                            name="profile"
                            @input="removeError('operationssjukskoterskor')"
                        />
                        <FormulateInput
                            v-else
                            label="Operationssjuksköterska"
                            type="vueselect"
                            :options="operationssjukskoterskor[index]"
                            @search="(search, loading) => searchOperationssjukskoterska(index, search, loading)"
                            name="profile"
                            @input="removeError('operationssjukskoterskor')"
                        />
                        <FormulateInput label="Tid in" type="timepicker" useNow name="intid" />
                        <FormulateInput label="Tid ut" type="timepicker" useNow name="uttid" />
                    </template>
                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeOperationssjukskoterska(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>

                    <template #addMore="{ addMore }">
                        <div class="formulate-input-group-add-more">
                            <button
                                type="button"
                                data-minor=""
                                data-ghost=""
                                @click="addMoreOperationssjukskoterska(addMore)"
                            >
                                <span class="formulate-input-element--button--label">
                                    Lägg till fler opsjuksköterskor
                                </span>
                            </button>
                        </div>
                    </template>
                </FormulateInput>
                <FormulateInput
                    type="group"
                    name="operationsunderskoterskor"
                    :repeatable="true"
                    add-label="Lägg till fler undersköterskor"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <FormulateInput
                            name="profile"
                            label="Undersköterska"
                            type="vueselect"
                            @input="personalChanged(index, 'operationsunderskoterskor')"
                            :options="operationsunderskoterskor[index]"
                            @search="(search, loading) => searchOperationsunderskoterska(index, search, loading)"
                        />
                        <FormulateInput label="Tid in" type="timepicker" useNow name="intid" />
                        <FormulateInput label="Tid ut" type="timepicker" useNow name="uttid" />
                    </template>
                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeOperationsunderskoterska(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>

                    <template #addMore="{ addMore }">
                        <div class="formulate-input-group-add-more">
                            <button
                                type="button"
                                data-minor=""
                                data-ghost=""
                                @click="addMoreOperationsunderskoterska(addMore)"
                            >
                                <span class="formulate-input-element--button--label"
                                    >Lägg till fler undersköterskor</span
                                >
                            </button>
                        </div>
                    </template>
                </FormulateInput>
                <div class="grey-div">
                    <FormulateInput type="vueselect" label="Signeringsansvarig"></FormulateInput>
                    <ButtonGroup class="buttonGroup">
                        <Button size="small" class="spara" type="submit">
                            <img v-if="loading" class="rotate" src="@/assets/loader.svg" alt="" />
                            <span v-else>Spara</span>
                        </Button>
                    </ButtonGroup>
                </div>
            </FormulateForm>
        </collapsible-section>

        <ModalContent
            title="Checklista 1 - Förberedelser"
            :showCloseButton="true"
            v-if="showWHOchecklistaForberedelse"
            @close="showWHOchecklistaForberedelse = false"
            class="who-modal"
        >
            <div class="header">
                <DialogAktivPatient :patient="parameters.operationsanmalan.patient_display" />

                <div class="datum">
                    <strong>Datum</strong>
                    {{ getCurrentDate }}
                </div>

                <div class="tid">
                    <strong>Tid</strong>
                    {{ getCurrentHourMinute }}
                </div>
            </div>

            <h3>Före inledning av anestesi</h3>

            <hr />

            <FormulateForm @submit="submitWHOchecklistaForberedelse" v-model="whochecklistaForberedelseFormData">
                <FormulateInput name="patient_bekraftat" type="checkbox">
                    <template #label="{ id }">
                        <div>
                            <label :for="id">Patienten har bekräftad följande:</label>
                            <ul>
                                <li>
                                    <label>identitet</label>
                                </li>
                                <li>
                                    <label>plats för incision</label>
                                </li>
                                <li>
                                    <label>informerad om och samtycker till operation</label>
                                </li>
                            </ul>
                        </div>
                    </template>
                </FormulateInput>

                <hr />

                <FormulateInput
                    name="operationsomrade_markerat"
                    type="checkbox"
                    label="Operationsområde markerat/ej tillämpligt"
                />

                <hr />

                <FormulateInput
                    name="sakerhetskontroll_for_anestesi"
                    type="checkbox"
                    label="Säkerhetskontroll för anestesi genomförd"
                />

                <hr />

                <FormulateInput
                    name="fungerande_pulsoximeter"
                    type="checkbox"
                    label="Fungerande pulsoximeter kopplad"
                />

                <hr />

                <p>Har patienten något av följande:</p>

                <FormulateInput
                    name="kand_allergi"
                    label="Känd allergi?"
                    :options="{ false: 'Nej', true: 'Ja' }"
                    type="radio"
                />

                <FormulateInput
                    name="risk_for_aspiration"
                    label="Risk för aspiration/svår intubation?"
                    :options="{
                        false: 'Nej',
                        true: 'Ja och utrustning/assistans är tillgänglig',
                    }"
                    type="radio"
                />

                <FormulateInput
                    name="risk_for_blodforlust"
                    label="Risk för >500 ml blodförlust (7 ml/kg för barn)?"
                    :options="{
                        false: 'Nej',
                        true: 'Ja, och tillfredsställande intravenösa infarter och blod/vätskor är planerade',
                    }"
                    type="radio"
                />

                <FormulateInput
                    name="risk_for_hypotermi"
                    label="Risk för hypotermi?"
                    :options="{
                        false: 'Nej',
                        true: 'Ja, och åtgärder är planerade/vidtagna',
                    }"
                    type="radio"
                />

                <hr />

                <i>
                    Published by the World Health Organization in 2008 under the title WHO surgical safety checklist 1st
                    edition 2008 (TR/08/216). Producerad av Landstingens Ömsesidiga Försäkringsbolag mars 2009.
                </i>
                <hr />

                <ButtonGroup>
                    <Button type="submit" size="primary">Spara</Button>
                    <Button
                        type="button"
                        size="secondary"
                        variant="contour"
                        @click.prevent="showWHOchecklistaForberedelse = false"
                    >
                        Stäng
                    </Button>
                </ButtonGroup>
            </FormulateForm>
        </ModalContent>

        <ModalContent
            title="Checklista 2 - Timeout"
            :showCloseButton="true"
            v-if="showWHOchecklistaTimeOut"
            @close="showWHOchecklistaTimeOut = false"
            class="who-modal"
        >
            <div class="header">
                <DialogAktivPatient :patient="parameters.operationsanmalan.patient_display" />

                <div class="datum">
                    <strong>Datum</strong>
                    {{ getCurrentDate }}
                </div>

                <div class="tid">
                    <strong>Tid</strong>
                    {{ getCurrentHourMinute }}
                </div>
            </div>

            <h3>Före incision</h3>

            <hr />

            <FormulateForm v-model="whochecklistaTimeoutFormData" @submit="submitWHOchecklistaTimeOut">
                <FormulateInput
                    name="medlemmar_presenterat_sig"
                    type="checkbox"
                    label="Bekräfta att alla medlemmar i laget presenterat sig med namn och roll"
                />

                <hr />

                <FormulateInput type="checkbox" name="bekraftar_muntligt">
                    <template #label="{ id }">
                        <div>
                            <label :for="id">
                                Anestesiolog/anestesisjuksköterska och operationssjuksköterska, operatör bekräftar
                                muntligt:
                            </label>
                            <ul>
                                <li>
                                    <label>patient ID</label>
                                </li>
                                <li>
                                    <label>plats för incision</label>
                                </li>
                                <li>
                                    <label>planerad operation</label>
                                </li>
                            </ul>
                        </div>
                    </template>
                </FormulateInput>

                <hr />

                <div class="checkbox-group">
                    <p>Väntade kritiska moment under operationen</p>

                    <FormulateInput type="checkbox" name="bedomning_patientspecifika_problem">
                        <template #label="{ id }">
                            <label :for="id">
                                <strong>Anestesipersonalens bedömning: </strong> finns några patientspecifika eller
                                anestesiologiska problem?
                            </label>
                        </template>
                    </FormulateInput>

                    <FormulateInput type="checkbox" name="bedomning_sterilitet_bekraftat">
                        <template #label="{ id }">
                            <label :for="id">
                                <strong>Operationssjuksköterskans bedömning: </strong> har sterilitet bekräftats?
                            </label>
                        </template>
                    </FormulateInput>

                    <FormulateInput
                        name="bedomning_problem_utrustning"
                        type="checkbox"
                        label="Finns några problem med utrustning eller annat?"
                    />

                    <FormulateInput
                        type="checkbox"
                        name="bedomning_korrekt_upplagd"
                        label="Är patienten korrekt upplagd?"
                    />

                    <FormulateInput type="checkbox" name="bedomning_kritiska_moment">
                        <template #label="{ id }">
                            <label :for="id">
                                <strong>Operatörens bedömning: </strong> vilka kritiska eller oväntade moment finns,
                                operationens längd, förväntad blodförlust?
                            </label>
                        </template>
                    </FormulateInput>
                </div>

                <hr />

                <FormulateInput
                    name="antibiotikaprofylax"
                    label="Har antibiotikaprofylax givits inom de senaste 60 minuterna?"
                    :options="{
                        true: 'Ja',
                        false: 'Ej tillämplig',
                    }"
                    type="radio"
                />

                <hr />

                <FormulateInput
                    name="trombosprofylax"
                    label="Har trombosprofylax givits enligt ordination?"
                    :options="{
                        true: 'Ja',
                        false: 'Ej tillämplig',
                    }"
                    type="radio"
                />

                <hr />

                <FormulateInput
                    name="bildinformation"
                    label="Visas nödvändig bildinformation?"
                    :options="{
                        true: 'Ja',
                        false: 'Ej tillämplig',
                    }"
                    type="radio"
                />
                <hr />

                <i>
                    Published by the World Health Organization in 2008 under the title WHO surgical safety checklist 1st
                    edition 2008 (TR/08/216). Producerad av Landstingens Ömsesidiga Försäkringsbolag mars 2009.
                </i>

                <ButtonGroup>
                    <Button type="submit" size="primary">Spara</Button>
                    <Button
                        type="button"
                        size="secondary"
                        variant="contour"
                        @click.prevent="showWHOchecklistaTimeOut = false"
                    >
                        Stäng
                    </Button>
                </ButtonGroup>
            </FormulateForm>
        </ModalContent>

        <ModalContent
            title="Checklista 3 - Avslutning"
            :showCloseButton="true"
            v-if="showWHOchecklistaAvslutning"
            @close="showWHOchecklistaAvslutning = false"
            class="who-modal"
        >
            <div class="header">
                <DialogAktivPatient :patient="parameters.operationsanmalan.patient_display" />

                <div class="datum">
                    <strong>Datum</strong>
                    {{ getCurrentDate }}
                </div>

                <div class="tid">
                    <strong>Tid</strong>
                    {{ getCurrentHourMinute }}
                </div>
            </div>

            <h3>Innan patienten lämnar operationssalen</h3>

            <hr />

            <FormulateForm v-model="whochecklistaAvslutningFormData" @submit="submitWHOchecklistaAvslutning">
                <div class="checkbox-group">
                    <p>Checklisteansvarig får muntlig bekräftelse av laget:</p>

                    <FormulateInput name="vilket_ingrepp" type="checkbox" label="Vilket ingrepp har utförts" />

                    <FormulateInput
                        name="antal_instrument_torkar_nalar"
                        type="checkbox"
                        label="Att antal instrument, torkar och nålar stämmer (eller ej tillämpligt)"
                    />

                    <FormulateInput
                        name="preparat_markta"
                        type="checkbox"
                        label="Hur preparat är märkta (inklusive patientens namn och personnummer)"
                    />

                    <FormulateInput
                        name="problem_utrustning_uppmarksammas"
                        type="checkbox"
                        label="Finns problem med utrustningen som behöver uppmärksammas?"
                    />
                </div>

                <hr />

                <FormulateInput name="huvudpunkter_postoperativt_omhandertagande" type="checkbox">
                    <template #label="{ id }">
                        <div class="checkbox-texts-wrapper">
                            <label :for="id">
                                Operatör, anestesiläkare/sjuksköterska och operationssjuksköterska går igenom
                                huvudpunkterna för det initiala postoperativa omhändertagandet
                            </label>
                        </div>
                    </template>
                </FormulateInput>

                <hr />

                <FormulateInput
                    name="vad_kan_vi_lara"
                    type="checkbox"
                    label="Vad kan vi lära? Vad kan vi göra bättre nästa gång?"
                />
                <hr />

                <i
                    >Published by the World Health Organization in 2008 under the title WHO surgical safety checklist
                    1st edition 2008 (TR/08/216). Producerad av Landstingens Ömsesidiga Försäkringsbolag mars 2009.
                </i>
                <ButtonGroup>
                    <Button type="submit" size="primary">Spara</Button>
                    <Button
                        type="button"
                        size="secondary"
                        variant="contour"
                        @click.prevent="showWHOchecklistaAvslutning = false"
                    >
                        Stäng
                    </Button>
                </ButtonGroup>
            </FormulateForm>
        </ModalContent>

        <collapsible-section number="4" heading="WHO-checklista">
            <div class="who-checklista">
                <p>Checklista för säkerhet vid operationer</p>

                <ButtonLink type="button" @click.prevent="openWhoForberedelse">
                    Checklista 1 - Förberedelse
                    <small>
                        <span>Före inledning av anestesi</span>
                        <span class="updated" v-if="!isEmpty(whochecklistaForberedelseFormData.updated_by)">
                            Sparad {{ moment(whochecklistaForberedelseFormData.updated_at).format("YYYY-MM-DD") }}
                        </span>
                    </small>
                </ButtonLink>

                <ButtonLink type="button" @click.prevent="openWhoTimeout">
                    Checklista 2 - Timeout
                    <small>
                        <span>Före incision</span>
                        <span class="updated" v-if="!isEmpty(whochecklistaTimeoutFormData.updated_by)">
                            Sparad {{ moment(whochecklistaTimeoutFormData.updated_at).format("YYYY-MM-DD") }}
                        </span>
                    </small>
                </ButtonLink>

                <ButtonLink type="button" @click.prevent="openWhoAvslutning">
                    Checklista 3 - Avslutning
                    <small>
                        <span>Innan patienten lämnar operationssalen</span>
                        <span class="updated" v-if="!isEmpty(whochecklistaAvslutningFormData.updated_by)">
                            Sparad {{ moment(whochecklistaAvslutningFormData.updated_at).format("YYYY-MM-DD") }}
                        </span>
                    </small>
                </ButtonLink>
            </div>
        </collapsible-section>

        <collapsible-section number="5" heading="Material" class="material">
            <FormulateForm @submit="submitMaterial" v-model="materialFormData" :errors="formError">
                <FormulateInput
                    type="group"
                    label=""
                    name="material"
                    :repeatable="true"
                    add-label="Lägg till fler material"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <!-- <FormulateInput
    :help="
        setWarningMessage({
            groupIndex: index,
            formGroup: materialFormData.material,
            formGroupFieldName: 'artikelnummer',
            customWarningMessage:
                'Artikeln används redan. Justera antal i tidigare fält eller ange ny batch här.',
        })
    "
    class="first"
    label="Artikelnummer"
    name="artikelnummer"
    type="vueselect"
    :options="material[index]"
    @search="(search, loading) => searchMaterial(index, search, loading)"
    @option:selected="(selectedOption) => materialSelected(selectedOption, index)"
    ref="artikelnummer"
/> -->

                        <FormulateInput
                            label="Artikelnummer"
                            name="artikelnummer"
                            type="text"
                            ref="artikelnummer"
                            @keydown.enter.prevent="($event) => materialOnEnter($event.target?.value ?? '', index)"
                            :error="errorMessages[index]"
                            @blur="($event) => artikelNummerOnLeave($event.target?.value ?? '', index)"
                        />

                        <FormulateInput class="second" label="Materialnamn" name="materialnamn" type="text" readonly />
                        <FormulateInput class="third" label="Batchnummer" name="batchnummer" type="text" />
                        <FormulateInput
                            class="fourth"
                            label="Antal"
                            name="antal"
                            type="knumber"
                            ref="antal"
                            v-on:keydown.enter.prevent="(e) => antalOnEnter(e)"
                        />
                    </template>
                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeMaterial(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>

                    <template #addMore="{ addMore }">
                        <div class="formulate-input-group-add-more">
                            <button type="button" data-minor="" data-ghost="" @click="addMoreMaterial(addMore)">
                                <span class="formulate-input-element--button--label">Lägg till fler material</span>
                            </button>
                        </div>
                    </template>
                </FormulateInput>

                <div class="grey-div">
                    <FormulateInput type="vueselect" label="Signeringsansvarig"></FormulateInput>
                    <ButtonGroup class="buttonGroup">
                        <Button size="small" class="spara" type="submit">
                            <img v-if="loading" class="rotate" src="@/assets/loader.svg" alt="" />
                            <span v-else>Spara</span>
                        </Button>
                    </ButtonGroup>
                </div>
            </FormulateForm>
        </collapsible-section>

        <collapsible-section number="6" heading="Anestesi" class="anestesi">
            <FormulateForm @submit="submitAnestesi" v-model="anestesiFormData" :errors="formError">
                <div class="wrapper-3-1">
                    <FormulateInput
                        name="anestesilakare"
                        label="Anestesiläkare *"
                        type="vueselect"
                        :options="anestesilakare"
                        @search="searchAnestesilakare"
                        @input="removeError('anestesilakare')"
                    />
                    <FormulateInput
                        name="anestesisjukskoterska"
                        label="Anestesisköterska"
                        type="vueselect"
                        :options="anestesisjukskoterskor"
                        @search="searchAnestesisjukskoterskor"
                    />
                </div>

                <div class="huvudanestesikod">
                    <FormulateInput
                        name="huvudanestesikod"
                        label="Huvudanestesikod *"
                        @input="removeError('huvudanestesikod')"
                        type="vueselect"
                        :options="huvudanestesikoder"
                        @search="searchHuvudanestesikod"
                        class="autocomplete"
                    />
                </div>
                <FormulateInput
                    type="group"
                    name="bianestesikoder"
                    :repeatable="true"
                    add-label="Lägg till bianestesikod"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <FormulateInput
                            name="kod"
                            label="Bianestesikod"
                            type="vueselect"
                            :options="bianestesikoder[index]"
                            @search="(search, loading) => searchBianestesikoder(index, search, loading)"
                            class="autocomplete"
                        />
                    </template>
                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeBianestesikoder(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>

                    <template #addMore="{ addMore }">
                        <div class="formulate-input-group-add-more">
                            <button type="button" data-minor="" data-ghost="" @click="addMoreBianestesikoder(addMore)">
                                <span class="formulate-input-element--button--label"
                                    >Lägg till fler bianestesikoder</span
                                >
                            </button>
                        </div>
                    </template>
                </FormulateInput>

                <ModalContent
                    title="Komplikationsgrad förklaring"
                    show-close-button="show-close-button"
                    v-if="forklaring"
                    @close="forklaring = false"
                    class="komplikationsgrad-forklaring"
                >
                    <dl class="forklaringar">
                        <dt>Grad 0</dt>
                        <dd>Anges enbart vid Normalt förlopp</dd>

                        <dt>Grad 1</dt>
                        <dd>Saknar betydelse för post op omhändertagande</dd>

                        <dt>Grad 2</dt>
                        <dd>Påverkar omhändertagandet post op på UVA men saknar betydelse för fortsatt vård</dd>

                        <dt>Grad 3</dt>
                        <dd>
                            Påverkar omhändertagandet post op på vårdavdelning med förlängt omhändertagande och/eller
                            fortsatt speciell observation
                        </dd>

                        <dt>Grad 4</dt>
                        <dd>Påverkar omhändertagandet så att intensivvård krävs postoperativt</dd>

                        <dt>Grad 5</dt>
                        <dd>Medför sannolikt bestående skada eller död</dd>
                    </dl>

                    <i> Senast uppdaterad 2022-10-26 (alt. SPOR version XYX) </i>

                    <ButtonGroup>
                        <Button type="button" size="primary" @click.prevent="forklaring = false">Stäng</Button>
                    </ButtonGroup>
                </ModalContent>
                <FormulateInput
                    type="group"
                    name="anestesikomplikationer"
                    :repeatable="true"
                    add-label="Lägg till fler komplikationer"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <FormulateInput
                            id="anestesikomplikationer-kategori"
                            name="kategori"
                            label="Anestesikomplikation kategori *"
                            type="vueselect"
                            :options="anestesikomplikationerKategorier"
                            @option:selected="anestesikomplikationerKategorierChanged(index)"
                            @input="removeError('anestesikomplikationer')"
                        />
                        <FormulateInput
                            id="anestesikomplikationer-kod"
                            label="Anestesikomplikation *"
                            name="kod"
                            type="vueselect"
                            :options="anestesikomplikationer[index]"
                            @input="anestesikomplikationerErrorChanged('kod', index)"
                            validation="required"
                            :validation-messages="{
                                required: 'Det här fältet får inte vara blankt.',
                            }"
                        >
                        </FormulateInput>

                        <FormulateInput
                            id="anestesikomplikationer-svarighetsgrad"
                            name="svarighetsgrad"
                            label="Svårighetsgrad *"
                            type="vueselect"
                            :options="anestesikomplikationSvarighetsgrader"
                            @input="anestesikomplikationerErrorChanged('svarighetsgrad', index)"
                            validation="required"
                            :validation-messages="{
                                required: 'Det här fältet får inte vara blankt.',
                            }"
                        >
                            <ButtonLink type="button" class="forklaring" @click.prevent="forklaring = true">
                                Förklaring
                            </ButtonLink>
                        </FormulateInput>

                        <FormulateInput
                            id="anestesikomplikationer-tidpunkt"
                            name="tidpunkt"
                            label="Tidpunkt för komplikation"
                            type="timepicker"
                            useNow
                        />
                    </template>
                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeAnestesikomplikationer(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>
                </FormulateInput>

                <div class="grey-div">
                    <FormulateInput type="vueselect" label="Signeringsansvarig"></FormulateInput>
                    <ButtonGroup class="buttonGroup">
                        <Button size="small" class="spara" type="submit">
                            <img v-if="loading" class="rotate" src="@/assets/loader.svg" alt="" />
                            <span v-else>Spara</span>
                        </Button>
                    </ButtonGroup>
                </div>
            </FormulateForm>
        </collapsible-section>

        <collapsible-section number="7" heading="Tider" class="tider">
            <FormulateForm @submit="submitTider" v-model="tiderFormData" :errors="formError">
                <FormulateInput type="timepicker" useNow name="uppdukning_start" label="Uppdukning start" />
                <FormulateInput type="timepicker" useNow name="uppdukning_slut" label="Uppdukning slut" />
                <FormulateInput
                    type="timepicker"
                    useNow
                    name="antibiotikaprofylax_start"
                    label="Antibiotikaprofylax start"
                />
                <FormulateInput type="timepicker" useNow name="ankomsttid_preopenhet" label="Ankomsttid preopenhet" />
                <FormulateInput
                    type="timepicker"
                    useNow
                    name="preoperativ_anestesi_start"
                    label="Preoperativ anestesi start"
                />
                <FormulateInput type="timepicker" useNow name="patienttid_start" label="Patienttid start" />
                <FormulateInput type="timepicker" useNow name="patient_in_opsal" label="Patient in på opsal" />
                <FormulateInput type="timepicker" useNow name="anestesitid_start" label="Anestesitid start" />
                <FormulateInput type="timepicker" useNow name="anestesitid_klar" label="Anestesi klar" />
                <FormulateInput
                    type="timepicker"
                    useNow
                    name="operationsforberedelse_klar"
                    label="Operationsförberedelse klar"
                />
                <FormulateInput type="timepicker" useNow name="opstart" label="Operation start" />
                <FormulateInput
                    type="timepicker"
                    useNow
                    name="x_minuter_kvar"
                    :label="`${operationXMinuterKvar} minuter kvar`"
                />
                <FormulateInput type="timepicker" useNow name="opslut" label="Operation slut" />
                <FormulateInput type="timepicker" useNow name="anestesi_slut" label="Anestesi slut" />
                <FormulateInput type="timepicker" useNow name="patient_ut_opsal" label="Patient ut från opsal" />
                <FormulateInput type="timepicker" useNow name="patienttid_slut" label="Patienttid slut" />
                <div class="grey-div">
                    <FormulateInput type="vueselect" label="Signeringsansvarig"></FormulateInput>
                    <ButtonGroup class="buttonGroup">
                        <Button size="small" class="spara" type="submit">
                            <img v-if="loading" class="rotate" src="@/assets/loader.svg" alt="" />
                            <span v-else>Spara</span>
                        </Button>
                    </ButtonGroup>
                </div>
            </FormulateForm>
        </collapsible-section>
    </div>
</template>

<script>
import DialogAktivPatient from "@/tabs/Operation/DialogAktivPatient.vue";
import moment from "moment";
import BaseTab from "@/tabs/mixins/BaseTab.js";
import CollapsibleSection from "@/components/generic/CollapsibleSection.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import ButtonLink from "@/components/ButtonLink.vue";
import { mapActions, mapGetters } from "vuex";
import emitter from "tiny-emitter/instance";

import {
    anestesikomplikationerKategorierOptions,
    anestesikomplikationerOptions,
    arrayPropertyExistsNotEmpty,
    avtalOptions,
    changeTimeToDate,
    cvOptions,
    cvOptionsShort,
    extractHourMinuteFromDateTime,
    fetchData,
    getPropertyValue,
    getTimeFromDatetime,
    hasDuplicatedObjectsInArray,
    isEmptyObject,
    isNotEmptyArray,
    isNotEmptyObject,
    isNull,
    materialOptions,
    parseError,
    profileOptions,
    propertyExists,
    propertyExistsNotNull,
    removeEmptyObjectsFromArray,
    salarOptions,
    SetField,
    typoperationerOptions,
} from "./utils";
import tabIcon from "@/assets/bed_icon.svg";
import Button from "@/components/Button.vue";
import { cloneDeep, isEmpty } from "lodash";
import { flashMessage, openDialog } from "@/utils";
import ModalBasic, { BasicModalVariant } from "@/components/generic/ModalBasic.vue";
import { getConfig, klinikenApi } from "@/api";
import ModalContent from "@/components/generic/ModalContent.vue";

export default {
    extends: BaseTab,
    name: "Operationsjournal",
    tabName: "Operationsjournal",
    icon: tabIcon,
    components: {
        ModalContent,
        ModalBasic,
        CollapsibleSection,
        Button,
        ButtonGroup,
        ButtonLink,
        DialogAktivPatient,
    },
    props: {
        parameters: {
            type: Object,
            required: false,
        },

        uid: Number,
        tabId: Number,
        editing: Boolean,
    },

    data() {
        return {
            isEditing: false,
            formError: {},
            validation: {},
            operationsuppgifterFormData: {
                typoperation: null,
                huvuddiagnos: null,
                bidiagnoser: [{}],
                bioperationskoder: [{}],
                operationsdatum: null,
                sal: "",
                reop: false,
                garantiop: false,
                lateralitet: "",
                avtal: null,
                anmarkning: null,
            },
            patientdataFormData: {
                patientdata: [{ atgard: [{ dranage: "nej" }], hud: [{ hudstatus: "ua" }] }],
            },
            personalFormData: {
                huvudoperator: {},
                ass_operatorer: [{}],
                operationssjukskoterskor: [{}],
                operationsunderskoterskor: [{}],
            },
            whochecklistaForberedelseFormData: {
                fungerande_pulsoximeter: null,
                kand_allergi: null,
                operationsomrade_markerat: null,
                patient_bekraftat: null,
                risk_for_aspiration: null,
                risk_for_blodforlust: null,
                risk_for_hypotermi: null,
                sakerhetskontroll_for_anestesi: null,
                updated_at: null,
                updated_by: null,
            },
            whochecklistaTimeoutFormData: {
                antibiotikaprofylax: null,
                bedomning_korrekt_upplagd: null,
                bedomning_kritiska_moment: null,
                bedomning_patientspecifika_problem: null,
                bedomning_problem_utrustning: null,
                bedomning_sterilitet_bekraftat: null,
                bekraftar_muntligt: null,
                bildinformation: null,
                medlemmar_presenterat_sig: null,
                trombosprofylax: null,
                updated_at: null,
                updated_by: null,
            },
            whochecklistaAvslutningFormData: {
                antal_instrument_torkar_nalar: null,
                huvudpunkter_postoperativt_omhandertagande: null,
                preparat_markta: null,
                problem_utrustning_uppmarksammas: null,
                vad_kan_vi_lara: null,
                vilket_ingrepp: null,
                updated_at: null,
                updated_by: null,
            },
            materialFormData: { material: [{}] },
            anestesiFormData: { anestesikomplikationer: [{}], bianestesikoder: [{}] },
            tiderFormData: {
                uppdukning_start: null,
                uppdukning_slut: null,
                antibiotikaprofylax_start: null,
                ankomsttid_preopenhet: null,
                preoperativ_anestesi_start: null,
                patienttid_start: null,
                patient_in_opsal: null,
                anestesitid_start: null,
                anestesitid_klar: null,
                operationsforberedelse_klar: null,
                opstart: null,
                x_minuter_kvar: null,
                opslut: null,
                anestesi_slut: null,
                patient_ut_opsal: null,
                patienttid_slut: null,
            },
            operationsuppgifterSavedFormData: {},
            patientdataSavedFormData: {},
            personalSavedFormData: {},
            materialSavedFormData: {},
            anestesiSavedFormData: {},
            tiderSavedFormData: {},

            typoperationer: [],
            huvuddiagnoser: [],
            bidiagnoser: [],
            huvudoperationskoder: [],
            bioperationskoder: [],
            avtal: [],
            salar: [],
            lateraliteter: [
                { value: "", label: "" },
                { value: "HÖGER_SIDA", label: "Höger sida" },
                { value: "VÄNSTER_SIDA", label: "Vänster sida" },
                { value: "BILATERALT", label: "Bilateralt" },
                { value: "EJ_AKTUELLT", label: "Ej aktuellt" },
            ],
            id_kontroll: [],
            operatorer: [],
            ass_operatorer: [],
            operationssjukskoterskor: [],
            operationsunderskoterskor: [],
            material: [],
            anestesilakare: [],
            anestesisjukskoterskor: [],
            huvudanestesikoder: [],
            bianestesikoder: [],
            rawAnestesikomplikationer: [],
            anestesikomplikationerKategorier: [],
            anestesikomplikationer: [],
            anestesikomplikationSvarighetsgrader: [],

            showWHOchecklistaForberedelse: false,
            showWHOchecklistaTimeOut: false,
            showWHOchecklistaAvslutning: false,

            forklaring: false,

            operationsanmalan: {},
            loading: false,
            operationjournal_id: "",
            errorMessages: [],
        };
    },

    computed: {
        ...mapGetters("systemData", ["operationXMinuterKvar"]),
        isEmpty() {
            return isEmpty;
        },
        moment() {
            return moment;
        },
        hasUnsavedData() {
            // FIXME: Av någon anledning matchar inte patientdataFormData och patientdataSavedFormData vid start,
            // så den varnar utan att det finns ändringar. Funkar inte heller att jämföra this.patientdataFormData.patientdata
            // med this.patientdataSavedFormData.patientdata för av någon anledning ändras även patientdataSavedFormData
            // vid ändringar i formuläret. Utkommenterat tills vi hittat en lösning.
            return (
                this.formDataIsEqual(this.operationsuppgifterFormData, this.operationsuppgifterSavedFormData) ||
                // this.formDataIsEqual(this.patientdataFormData, this.patientdataSavedFormData) ||
                this.formDataIsEqual(this.personalFormData, this.personalSavedFormData) ||
                this.formDataIsEqual(this.materialFormData, this.materialSavedFormData) ||
                this.formDataIsEqual(this.anestesiFormData, this.anestesiSavedFormData) ||
                this.formDataIsEqual(this.tiderFormData, this.tiderSavedFormData)
            );
        },
        getCurrentHourMinute() {
            return moment().format("HH:mm");
        },
        getCurrentDate() {
            return moment().format("YYYY-MM-DD");
        },
    },

    methods: {
        ...mapActions("tabs", ["closeTab"]),
        ...mapActions("operation/operationoversikt", ["updateAnmalningarList"]),

        removeEmpty(obj) {
            return Object.fromEntries(
                Object.entries(obj)
                    // eslint-disable-next-line no-unused-vars
                    .filter(([_, v]) => v != null && v !== "")
                    .map(([k, v]) => [k, v === Object(v) ? this.removeEmpty(v) : v], this)
            );
        },
        formDataIsEqual(formData, savedFormData) {
            return JSON.stringify(this.removeEmpty(formData)) !== JSON.stringify(this.removeEmpty(savedFormData));
        },

        sectionEmitedErrors(errors) {
            this.formError = errors;
        },

        removeError(name) {
            delete this.formError[name];
            this.formError = parseError(this.formError);
        },

        typoperationChanged() {
            if (this.loading) {
                return;
            }

            let id = this.operationsuppgifterFormData.typoperation;
            if (isNull(id)) {
                this.removeError("typoperation");
                return;
            }

            const typoperation = this.typoperationer.find((item) => `${item.id}` === `${id}`);

            if (typoperation === undefined) {
                return;
            }

            this.removeError("typoperation");

            let operationsuppgifterFormData = {};

            this.huvudoperationskoder = [
                {
                    value: typoperation.huvudoperationskod_display.id,
                    label: `${typoperation.huvudoperationskod_display.code} ${typoperation.huvudoperationskod_display.displayName}`,
                },
            ];
            operationsuppgifterFormData.huvudoperationskod = `${typoperation.huvudoperationskod}`;

            operationsuppgifterFormData.bioperationskoder = [{}];
            typoperation.opkoder.forEach((value, index) => {
                this.$set(this.bioperationskoder, index, [
                    {
                        value: typoperation.opkoder_display[index].id,
                        label: `${typoperation.opkoder_display[index].code} ${typoperation.opkoder_display[index].displayName}`,
                    },
                ]);
                operationsuppgifterFormData.bioperationskoder[index] = { bioperationskod: `${value}` };
            });

            this.huvuddiagnoser = [
                {
                    value: typoperation.huvuddiagnos_display.id,
                    label: `${typoperation.huvuddiagnos_display.code} ${typoperation.huvuddiagnos_display.displayName}`,
                },
            ];
            operationsuppgifterFormData.huvuddiagnos = `${typoperation.huvuddiagnos}`;

            operationsuppgifterFormData.bidiagnoser = [{}];
            typoperation.bidiagnoser.forEach((value, index) => {
                this.$set(this.bidiagnoser, index, [
                    {
                        value: typoperation.bidiagnoser_display[index].id,
                        label: `${typoperation.bidiagnoser_display[index].code} ${typoperation.bidiagnoser_display[index].displayName}`,
                    },
                ]);
                operationsuppgifterFormData.bidiagnoser[index] = { bidiagnos: `${value}` };
            });

            this.operationsuppgifterFormData = {
                ...this.operationsuppgifterFormData,
                ...operationsuppgifterFormData,
            };
        },

        async clearTypoperation() {
            if (
                await this.$refs.dialog.show({
                    variant: BasicModalVariant.WARNING,
                    title: "De valda operationsuppgifterna stämmer inte med den valda typoperationen. Typoperationen kommer tast bort. Vill du fortsätta?",
                    okButton: "OK",
                    cancelButton: "Avbryt",
                })
            ) {
                this.operationsuppgifterFormData.typoperation = null;
            } else {
                this.typoperationChanged();
            }
        },

        huvuddiagnosChanged() {
            if (isNull(this.operationsuppgifterFormData.huvuddiagnos)) {
                fetchData({ url: "/core/kodverk/icdkoder/" }).then((respsone) => {
                    this.huvuddiagnoser = cvOptions(respsone);
                });
            } else {
                this.removeError("huvuddiagnos");
            }

            if (!this.loading) {
                const typoperation = this.typoperationer.find(
                    (item) => `${item.id}` === `${this.operationsuppgifterFormData.typoperation}`
                );
                if (
                    typoperation &&
                    `${this.operationsuppgifterFormData.huvuddiagnos}` !== `${typoperation.huvuddiagnos}`
                ) {
                    this.clearTypoperation();
                }
            }
        },

        bidiagnosChanged(index) {
            if (isNull(this.operationsuppgifterFormData.bidiagnoser[index].bidiagnos)) {
                fetchData({
                    url: "/core/kodverk/icdkoder/",
                }).then((response) => {
                    this.$set(this.bidiagnoser, index, cvOptions(response));
                });
            }

            if (!this.loading) {
                const typoperation = this.typoperationer.find(
                    (item) => `${item.id}` === `${this.operationsuppgifterFormData.typoperation}`
                );
                if (
                    typoperation &&
                    `${this.operationsuppgifterFormData.bidiagnoser[index].bidiagnos}` !==
                        `${typoperation.bidiagnoser[index]}`
                ) {
                    this.clearTypoperation();
                }
            }
        },

        huvudoperationskodChanged() {
            if (isNull(this.operationsuppgifterFormData.huvudoperationskod)) {
                fetchData({
                    url: "/operation/kodverk/operationskoder/",
                }).then((response) => {
                    this.huvudoperationskoder = cvOptions(response);
                });
            } else {
                this.removeError("huvudoperationskod");
            }

            if (!this.loading) {
                const typoperation = this.typoperationer.find(
                    (item) => `${item.id}` === `${this.operationsuppgifterFormData.typoperation}`
                );
                if (
                    typoperation &&
                    `${this.operationsuppgifterFormData.huvudoperationskod}` !== `${typoperation.huvudoperationskod}`
                ) {
                    this.clearTypoperation();
                }
            }
        },

        bioperationskodChanged(index) {
            if (isNull(this.operationsuppgifterFormData.bioperationskoder[index].bioperationskod)) {
                fetchData({
                    url: "/operation/kodverk/operationskoder/",
                }).then((response) => {
                    this.$set(this.bioperationskoder, index, cvOptions(response));
                });
            }

            if (!this.loading) {
                const typoperation = this.typoperationer.find(
                    (item) => `${item.id}` === `${this.operationsuppgifterFormData.typoperation}`
                );
                if (
                    typoperation &&
                    `${this.operationsuppgifterFormData.bioperationskoder[index].bioperationskod}` !==
                        `${typoperation.opkoder[index]}`
                ) {
                    this.clearTypoperation();
                }
            }
        },

        async searchHuvudoperationskod(search, loading) {
            if (search.trim().length >= 3) {
                loading(true);
                this.huvudoperationskoder = cvOptions(
                    await fetchData({
                        url: "/operation/kodverk/operationskoder/all/",
                        params: { q: search },
                    })
                );
                loading(false);
            }
        },

        async searchBioperationskoder(index, search, loading) {
            if (search.trim().length >= 3) {
                loading(true);
                const options = cvOptions(
                    await fetchData({
                        url: "/operation/kodverk/operationskoder/all/",
                        params: { q: search },
                    })
                );
                this.$set(this.bioperationskoder, index, options);
                loading(false);
            }
        },

        removeBioperationskoder(index, removeItem) {
            removeItem(index);
            let bioperationskoder = this.bioperationskoder;
            bioperationskoder.splice(index, 1);
            for (let i = 0; i < this.bioperationskoder.length; i++) {
                this.$set(this.bioperationskoder, i, bioperationskoder[i]);
            }
        },

        async searchHuvuddiagnos(search, loading) {
            if (search.trim().length >= 3) {
                loading(true);
                this.huvuddiagnoser = cvOptions(
                    await fetchData({
                        url: "/core/kodverk/icdkoder/all/",
                        params: { q: search },
                    })
                );
                loading(false);
            }
        },

        async searchBidiagnoser(index, search, loading) {
            if (search.trim().length >= 3) {
                loading(true);
                const options = cvOptions(
                    await fetchData({
                        url: "/core/kodverk/icdkoder/all/",
                        params: { q: search },
                    })
                );
                this.$set(this.bidiagnoser, index, options);
                loading(false);
            }
        },

        removeBidiagnoser(index, removeItem) {
            removeItem(index);
            let bidiagnoser = this.bidiagnoser;
            bidiagnoser.splice(index, 1);
            for (let i = 0; i < this.bidiagnoser.length; i++) {
                this.$set(this.bidiagnoser, i, bidiagnoser[i]);
            }
        },

        async addMoreBidiagnoser(addMore) {
            addMore();
            let bidiagnoser = cvOptions(
                await fetchData({
                    url: "/core/kodverk/icdkoder/",
                })
            );

            this.$set(this.bidiagnoser, this.bidiagnoser.length, bidiagnoser);
        },

        async addMoreBioperationskoder(addMore) {
            addMore();

            let bioperationskoder = cvOptions(
                await fetchData({
                    url: "/operation/kodverk/operationskoder/",
                })
            );

            this.$set(this.bioperationskoder, this.bioperationskoder.length, bioperationskoder);
        },

        hudstatusChanged(index) {
            const hudstatus = this.patientdataFormData.patientdata[index]?.hud[index]?.hudstatus;
            if (hudstatus === null || hudstatus === "ua") {
                this.patientdataFormData.patientdata[index].hud[index].anmarkninghud = "";
            }
        },

        async searchProfile(url, search, loading) {
            loading(true);
            const options = profileOptions(
                await fetchData({
                    url: url,
                    params: { q: search },
                })
            );
            loading(false);
            return options;
        },

        personalChanged(index, fieldName) {
            this.removeError(`${fieldName}.${index}.profile`);
            if (this.personalFormData[fieldName][index].profile === null) {
                this.personalFormData[fieldName][index] = {};
            }
        },

        async addMoreAssOperatorer(addMore) {
            addMore();

            const ass_operatorer = profileOptions(
                await fetchData({
                    url: "core/profiles/?roll__code=kirurg",
                })
            );

            this.$set(this.ass_operatorer, this.ass_operatorer.length, ass_operatorer);
        },

        async addMoreOperationssjukskoterska(addMore) {
            addMore();

            const operationssjukskoterskor = profileOptions(
                await fetchData({
                    url: "core/profiles/?roll__code=operationssjukskoterska",
                })
            );

            this.$set(this.operationssjukskoterskor, this.operationssjukskoterskor.length, operationssjukskoterskor);
        },

        async addMoreOperationsunderskoterska(addMore) {
            addMore();

            const operationsunderskoterskor = profileOptions(
                await fetchData({
                    url: "core/profiles/?roll__code=operationsunderskoterska",
                })
            );

            this.$set(this.operationsunderskoterskor, this.operationsunderskoterskor.length, operationsunderskoterskor);
        },

        async searchOperatorer(search, loading) {
            if (search.trim().length >= 2) {
                this.operatorer = await this.searchProfile("core/profiles/?roll__code=kirurg", search, loading);
            }
        },

        async searchOpassistenter(index, search, loading) {
            if (search.trim().length >= 2) {
                const options = await this.searchProfile("core/profiles/?roll__code=kirurg", search, loading);
                this.$set(this.ass_operatorer, index, options);
            }
        },

        removeOpassistenter(index, removeItem) {
            removeItem(index);
            let ass_operatorer = this.ass_operatorer;
            ass_operatorer.splice(index, 1);
            for (let i = 0; i < this.ass_operatorer.length; i++) {
                this.$set(this.ass_operatorer, i, ass_operatorer[i]);
            }
        },

        async searchOperationssjukskoterska(index, search, loading) {
            if (search.trim().length >= 2) {
                const options = await this.searchProfile(
                    "core/profiles/?roll__code=operationssjukskoterska",
                    search,
                    loading
                );
                this.$set(this.operationssjukskoterskor, index, options);
            }
        },

        removeOperationssjukskoterska(index, removeItem) {
            removeItem(index);
            let operationssjukskoterskor = this.operationssjukskoterskor;
            operationssjukskoterskor.splice(index, 1);
            for (let i = 0; i < this.operationssjukskoterskor.length; i++) {
                this.$set(this.operationssjukskoterskor, i, operationssjukskoterskor[i]);
            }
        },

        async searchOperationsunderskoterska(index, search, loading) {
            if (search.trim().length >= 2) {
                const options = await this.searchProfile(
                    "core/profiles/?roll__code=operationsunderskoterska",
                    search,
                    loading
                );
                this.$set(this.operationsunderskoterskor, index, options);
            }
        },

        removeOperationsunderskoterska(index, removeItem) {
            removeItem(index);
            let operationsunderskoterskor = this.operationsunderskoterskor;
            operationsunderskoterskor.splice(index, 1);
            for (let i = 0; i < this.operationsunderskoterskor.length; i++) {
                this.$set(this.operationsunderskoterskor, i, operationsunderskoterskor[i]);
            }
        },

        async searchMaterial(index, search, loading) {
            if (search.trim().length >= 2) {
                loading(true);
                const options = materialOptions(
                    await fetchData({
                        url: "/operation/material/all/",
                        params: { q: search },
                    })
                );
                this.$set(this.material, index, options);
                loading(false);
            }
        },

        materialSelected(selectedOption, index) {
            if (propertyExistsNotNull(selectedOption) && isNotEmptyObject(selectedOption)) {
                const { id, namn, artikelnummer } = selectedOption;

                this.$set(this.materialFormData.material, index, {
                    material: id,
                    materialnamn: namn,
                    artikelnummer,
                });

                this.$nextTick(() => {
                    this.$refs.antal.$el.querySelector("input").focus();
                });
            } else {
                this.$set(this.materialFormData.material, index, {});
            }
        },

        async materialOnEnter(search = "", index = NaN) {
            this.$set(this.errorMessages, index, "");

            const options = materialOptions(
                await fetchData({
                    url: "/operation/material/all/",
                    params: { q: search },
                })
            );

            if (options.length > 1) {
                this.$set(this.errorMessages, index, "För många artikelnummer.");
            } else if (options.length === 0) {
                this.$set(this.errorMessages, index, `Hittar inget material på artikelnummret: ${search}`);
                this.$refs.artikelnummer.$el.querySelector("input").focus();
            } else {
                const Option = options[0];
                const { id, namn, artikelnummer } = Option;
                this.$set(this.materialFormData.material, index, {
                    material: id,
                    materialnamn: namn,
                    artikelnummer,
                });
                this.$set(this.material, index, options);
                this.$nextTick(() => {
                    this.$refs.antal.$el.querySelector("input").focus();
                });
            }
        },

        artikelNummerOnLeave(input = "", index = NaN) {
            if (input.length === 0) {
                this.$set(this.materialFormData.material, index, {});
            }
        },

        setWarningMessage({ groupIndex = NaN, formGroup = [], formGroupFieldName = "", customWarningMessage = "" }) {
            let warningMessage;

            if (groupIndex >= 1) {
                const { hasDuplicates, indexofDuplicated } = hasDuplicatedObjectsInArray({
                    list: formGroup,
                    propertyFieldName: formGroupFieldName,
                });

                if (hasDuplicates && groupIndex === indexofDuplicated) {
                    warningMessage = customWarningMessage;
                }
            }

            return warningMessage;
        },

        antalOnEnter(event) {
            if (event.target.value) {
                this.materialFormData.material.push({});
                this.$nextTick(() => {
                    this.$refs.artikelnummer.$el.querySelector("input").focus();
                });
            }
        },

        removeMaterial(index, removeItem) {
            removeItem(index);
            let material = this.material;
            material.splice(index, 1);
            for (let i = 0; i < this.material.length; i++) {
                this.$set(this.material, i, material[i]);
            }
        },

        async addMoreMaterial(addMore) {
            addMore();
        },

        async searchAnestesilakare(search, loading) {
            if (search.trim().length >= 2) {
                this.anestesilakare = await this.searchProfile(
                    "core/profiles/?roll__code=anestesilakare",
                    search,
                    loading
                );
            }
        },

        async searchAnestesisjukskoterskor(search, loading) {
            if (search.trim().length >= 2) {
                this.anestesisjukskoterskor = await this.searchProfile(
                    "core/profiles/?roll__code=anestesisjukskoterska",
                    search,
                    loading
                );
            }
        },

        anestesikomplikationerKategorierChanged(index) {
            const options = anestesikomplikationerOptions(
                this.rawAnestesikomplikationer.filter(
                    (obj) => obj.kapitel === this.anestesiFormData.anestesikomplikationer[index].kategori
                )
            );
            this.$set(this.anestesikomplikationer, index, options);
            if (this.anestesiFormData.anestesikomplikationer[index].kategori === "A0") {
                const obj = this.rawAnestesikomplikationer.find((obj) => obj.code === "A0");
                this.$set(this.anestesiFormData.anestesikomplikationer, index, {
                    kategori: this.anestesiFormData.anestesikomplikationer[index].kategori,
                    kod: `${obj.id}`,
                    svarighetsgrad: "1",
                });
                this.removeError("anestesikomplikationer");
                this.removeError(`anestesikomplikationer.${index}.kod`);
                this.removeError(`anestesikomplikationer.${index}.svarighetsgrad`);
            } else {
                this.$set(this.anestesiFormData.anestesikomplikationer, index, {
                    kategori: this.anestesiFormData.anestesikomplikationer[index].kategori,
                    kod: "",
                    svarighetsgrad: "",
                });
            }
        },

        anestesikomplikationerErrorChanged(name, index) {
            this.removeError(`anestesikomplikationer.${index}.${name}`);
        },
        operationssjukskoterskaErrorChanged(name, index) {
            this.removeError(`operationssjukskoterskor.${index}.${name}`);
        },

        removeAnestesikomplikationer(index, removeItem) {
            removeItem(index);
            let anestesikomplikationer = this.anestesikomplikationer;
            anestesikomplikationer.splice(index, 1);
            for (let i = 0; i < this.anestesikomplikationer.length; i++) {
                this.$set(this.anestesikomplikationer, i, anestesikomplikationer[i]);
            }
        },

        async searchHuvudanestesikod(search, loading) {
            if (search.trim().length >= 3) {
                loading(true);
                this.huvudanestesikoder = cvOptions(
                    await fetchData({
                        url: "/operation/kodverk/anestesikoder/all/",
                        params: { q: search },
                    })
                );
                loading(false);
            }
        },

        async searchBianestesikoder(index, search, loading) {
            if (search.trim().length >= 3) {
                loading(true);
                const anestesikoder = cvOptions(
                    await fetchData({
                        url: "/operation/kodverk/anestesikoder/all/",
                        params: { q: search },
                    })
                );
                this.$set(this.bianestesikoder, index, anestesikoder);
                loading(false);
            }
        },

        removeBianestesikoder(index, removeItem) {
            removeItem(index);
            let bianestesikoder = this.bianestesikoder;
            bianestesikoder.splice(index, 1);
            for (let i = 0; i < this.bianestesikoder.length; i++) {
                this.$set(this.bianestesikoder, i, bianestesikoder[i]);
            }
        },

        async addMoreBianestesikoder(addMore) {
            addMore();
            let bianestesikoder = cvOptions(
                await fetchData({
                    url: "/operation/kodverk/anestesikoder/all/",
                })
            );

            this.$set(this.bianestesikoder, this.bianestesikoder.length, bianestesikoder);
        },

        removeNullPropertyValuesFromWhoList(payload) {
            for (let i in payload) {
                if (payload[i] === null) {
                    delete payload[i];
                }
            }
            return payload;
        },

        // Ska hela översiktslistan refreshas och ska det göras här?
        async updateEntry(id) {
            try {
                const response = await fetchData({
                    url: `/operation/oversikt/${id}/`,
                });
                await this.updateAnmalningarList(response);
            } catch (error) {
                openDialog(error, "error");
            }
        },

        async submitOperationsuppgifter(data) {
            let payload = cloneDeep(data);
            if (payload.typoperation === "null") {
                delete payload.typoperation;
            }
            if (payload.bioperationskoder === [null]) {
                delete payload.bioperationskoder;
            }

            if (payload.bidiagnoser) {
                payload.bidiagnoser = this.extractValues(payload.bidiagnoser);
                payload.bidiagnoser = removeEmptyObjectsFromArray(payload.bidiagnoser);
            }
            if (payload.bioperationskoder) {
                payload.bioperationskoder = this.extractValues(payload.bioperationskoder);
                payload.bioperationskoder = removeEmptyObjectsFromArray(payload.bioperationskoder);
            }

            await this.submit(payload, "Operationsuppgifter", "operationsuppgifter/");

            this.operationsuppgifterSavedFormData = this.operationsuppgifterFormData;
        },

        // Används för att formatera bidiagnoskoder, bioperationskoder och bianestesikoder i payload till backend
        extractValues(list) {
            const valueList = list.map((item) => getPropertyValue(item));
            return valueList;
        },

        async submitPatientdata(data) {
            let payload = cloneDeep(data);
            await this.submit(payload, "Patientdata", "patientdata/");
            this.patientdataSavedFormData = this.patientdataFormData;
        },

        formatPersonalGroup(payload, name) {
            // Tar bort rader utan vald profil
            payload[name] = payload[name].filter((obj) => Boolean(obj.profile));
            // Extraherar uuid från profilen
            payload[name].forEach((obj) => (obj.profile = obj.profile.value ?? obj.profile));
            // Lägger till datum på in- och uttider
            payload[name].forEach((item, index) => {
                payload[name][index].intid = this.setDateOnTid(item.intid);
                payload[name][index].uttid = this.setDateOnTid(item.uttid);
            });
        },

        async submitPersonal(data) {
            let payload = cloneDeep(data);

            payload.huvudoperator_intid = this.setDateOnTid(payload.huvudoperator_intid);
            payload.huvudoperator_uttid = this.setDateOnTid(payload.huvudoperator_uttid);

            if (payload.ass_operatorer) this.formatPersonalGroup(payload, "ass_operatorer");
            if (payload.operationssjukskoterskor) this.formatPersonalGroup(payload, "operationssjukskoterskor");
            if (payload.operationsunderskoterskor) this.formatPersonalGroup(payload, "operationsunderskoterskor");

            await this.submit(payload, "Personal", "personal/");

            this.personalSavedFormData = this.personalFormData;
        },

        async submitWHOchecklistaForberedelse(data) {
            let payload = cloneDeep(data);
            payload = this.removeNullPropertyValuesFromWhoList(payload);
            this.showWHOchecklistaForberedelse = false;
            let response = await this.submit(payload, "WHO-checklista 1 - Förberedelse", "whochecklista/forberedelse/");
            if (response !== undefined) {
                this.whochecklistaForberedelseFormData.updated_by = response.updated_by;
                this.whochecklistaForberedelseFormData.updated_at = moment(response.updated_at).format("YYYY-MM-DD");
            }
        },

        async submitWHOchecklistaTimeOut(data) {
            let payload = cloneDeep(data);
            payload = this.removeNullPropertyValuesFromWhoList(payload);
            this.showWHOchecklistaTimeOut = false;
            let response = await this.submit(payload, "WHO-checklista 2 - Timeout", "whochecklista/timeout/");
            if (response !== undefined) {
                this.whochecklistaTimeoutFormData.updated_by = response.updated_by;
                this.whochecklistaTimeoutFormData.updated_at = moment(response.updated_at).format("YYYY-MM-DD");
            }
        },

        async submitWHOchecklistaAvslutning(data) {
            let payload = cloneDeep(data);
            payload = this.removeNullPropertyValuesFromWhoList(payload);
            this.showWHOchecklistaAvslutning = false;
            let response = await this.submit(payload, "WHO-checklista 3 - Avslutning", "whochecklista/avslutning/");
            if (response !== undefined) {
                this.whochecklistaAvslutningFormData = response;
                this.whochecklistaAvslutningFormData.updated_at = moment(response.updated_at).format("YYYY-MM-DD");
            }
        },

        async submitMaterial(data = {}) {
            data.material.map((item) => {
                const itemKeys = Object.keys(item);
                const quantity = "antal";

                if (itemKeys.length === 1 && itemKeys.includes(quantity) && item[quantity] === 0) {
                    delete item[quantity];
                }
            });

            let payload = cloneDeep(data);
            if (payload.material) payload.material = removeEmptyObjectsFromArray(payload.material);
            await this.submit(payload, "Material", "material/");
            this.materialSavedFormData = this.materialFormData;
        },

        async submitAnestesi(data) {
            let payload = cloneDeep(data);

            if (payload.bianestesikoder) {
                payload.bianestesikoder = this.extractValues(payload.bianestesikoder);
                payload.bianestesikoder = removeEmptyObjectsFromArray(payload.bianestesikoder);
            }

            if (payload.anestesikomplikationer) {
                payload.anestesikomplikationer = removeEmptyObjectsFromArray(payload.anestesikomplikationer);
                payload.anestesikomplikationer.forEach((komplikation, index) => {
                    payload.anestesikomplikationer[index].tidpunkt = this.setDateOnTid(komplikation.tidpunkt);
                });
            }

            await this.submit(payload, "Anestesi", "anestesi/");

            this.anestesiSavedFormData = this.anestesiFormData;
        },

        setDateOnTid(tid) {
            return tid ? changeTimeToDate(this.operationsuppgifterFormData.operationsdatum, tid) : null;
        },

        async submitTider(data) {
            let payload = cloneDeep(data);

            payload.uppdukning_start = this.setDateOnTid(payload.uppdukning_start);
            payload.uppdukning_slut = this.setDateOnTid(payload.uppdukning_slut);
            payload.antibiotikaprofylax_start = this.setDateOnTid(payload.antibiotikaprofylax_start);
            payload.ankomsttid_preopenhet = this.setDateOnTid(payload.ankomsttid_preopenhet);
            payload.preoperativ_anestesi_start = this.setDateOnTid(payload.preoperativ_anestesi_start);
            payload.patienttid_start = this.setDateOnTid(payload.patienttid_start);
            payload.patient_in_opsal = this.setDateOnTid(payload.patient_in_opsal);
            payload.anestesitid_start = this.setDateOnTid(payload.anestesitid_start);
            payload.anestesitid_klar = this.setDateOnTid(payload.anestesitid_klar);
            payload.operationsforberedelse_klar = this.setDateOnTid(payload.operationsforberedelse_klar);
            payload.opstart = this.setDateOnTid(payload.opstart);
            payload.x_minuter_kvar = this.setDateOnTid(payload.x_minuter_kvar);
            payload.opslut = this.setDateOnTid(payload.opslut);
            payload.anestesi_slut = this.setDateOnTid(payload.anestesi_slut);
            payload.patient_ut_opsal = this.setDateOnTid(payload.patient_ut_opsal);
            payload.patienttid_slut = this.setDateOnTid(payload.patienttid_slut);

            await this.submit(payload, "Tider", "tider/");

            if (
                this.tiderFormData.uppdukning_start !== this.tiderSavedFormData.uppdukning_start ||
                this.tiderFormData.patienttid_start !== this.tiderSavedFormData.patienttid_start ||
                this.tiderFormData.x_minuter_kvar !== this.tiderSavedFormData.x_minuter_kvar ||
                this.tiderFormData.opslut !== this.tiderSavedFormData.opslut ||
                this.tiderFormData.patienttid_slut !== this.tiderSavedFormData.patienttid_slut
            ) {
                emitter.emit("pagaende-op-tider-updated");
            }

            this.tiderSavedFormData = this.tiderFormData;
        },

        async submit(payload, sektion, url) {
            if (!isEmptyObject(this.validation) && arrayPropertyExistsNotEmpty(this.validation.errors)) {
                return;
            }

            // Första gången en sektion för en operationsjournal sparas måste operationsjournalen först skapas
            if (!this.operationjournal_id) {
                try {
                    const response = await klinikenApi.post(
                        "operation/operationsjournaler/",
                        { anmalan: this.operationsanmalan.id },
                        getConfig({}, "Operationsjournal")
                    );
                    this.operationjournal_id = response.data.id;
                } catch (error) {
                    await this.$refs.dialog.show({
                        variant: BasicModalVariant.ALERT,
                        title: `Det gick inte att spara operationsjournalen.`,
                        okButton: "OK",
                    });
                    return;
                }
            }

            try {
                await klinikenApi.put(
                    `operation/operationsjournaler/${this.operationjournal_id}/sektioner/${url}`,
                    payload,
                    getConfig({}, "Operationsjournal")
                );

                flashMessage(`${sektion} sparade`);
                await this.updateEntry(this.operationsanmalan.id);
            } catch (error) {
                await this.$refs.dialog.show({
                    variant: BasicModalVariant.WARNING,
                    title: `Det gick inte att spara ${sektion}.`,
                    cancelButton: "Avbryt",
                });
                this.formError = parseError(error.response.data);
            }
        },

        async cancel() {
            if (this.hasUnsavedData) {
                if (
                    await this.$refs.dialog.show({
                        variant: BasicModalVariant.WARNING,
                        title: "Denna flik har osparad data. Vill du stänga ändå?",
                        okButton: "Ja",
                        cancelButton: "Nej",
                    })
                ) {
                    await this.$store.dispatch("tabs/closeTab", this.tabId);
                }
            } else {
                await this.$store.dispatch("tabs/closeTab", this.tabId);
            }
        },

        async openWhoForberedelse() {
            this.showWHOchecklistaForberedelse = true;
            if (this.operationjournal_id) {
                let _res = await fetchData({
                    url: `/operation/operationsjournaler/${this.operationjournal_id}/sektioner/whochecklista/forberedelse/`,
                });
                this.whochecklistaForberedelseFormData = _res;
                const { kand_allergi, risk_for_aspiration, risk_for_blodforlust, risk_for_hypotermi } = _res;
                if (typeof kand_allergi === "boolean") {
                    this.whochecklistaForberedelseFormData.kand_allergi = kand_allergi.toString();
                }
                if (typeof risk_for_aspiration === "boolean") {
                    this.whochecklistaForberedelseFormData.risk_for_aspiration = risk_for_aspiration.toString();
                }
                if (typeof risk_for_blodforlust === "boolean") {
                    this.whochecklistaForberedelseFormData.risk_for_blodforlust = risk_for_blodforlust.toString();
                }
                if (typeof risk_for_hypotermi === "boolean") {
                    this.whochecklistaForberedelseFormData.risk_for_hypotermi = risk_for_hypotermi.toString();
                }
            }
        },
        async openWhoTimeout() {
            this.showWHOchecklistaTimeOut = true;
            if (this.operationjournal_id) {
                let _res = await fetchData({
                    url: `/operation/operationsjournaler/${this.operationjournal_id}/sektioner/whochecklista/timeout/`,
                });
                const { antibiotikaprofylax, bildinformation, trombosprofylax } = _res;
                this.whochecklistaTimeoutFormData = _res;
                if (typeof antibiotikaprofylax === "boolean") {
                    this.whochecklistaTimeoutFormData.antibiotikaprofylax = antibiotikaprofylax.toString();
                }

                if (typeof trombosprofylax === "boolean") {
                    this.whochecklistaTimeoutFormData.trombosprofylax = trombosprofylax.toString();
                }

                if (typeof bildinformation === "boolean") {
                    this.whochecklistaTimeoutFormData.bildinformation = bildinformation.toString();
                }
            }
        },
        async openWhoAvslutning() {
            this.showWHOchecklistaAvslutning = true;
            if (this.operationjournal_id) {
                let _res = await fetchData({
                    url: `/operation/operationsjournaler/${this.operationjournal_id}/sektioner/whochecklista/avslutning/`,
                });
                this.whochecklistaAvslutningFormData = _res;
            }
        },

        setOperationsuppgifter(operation) {
            this.operationsuppgifterFormData.typoperation = `${operation.typoperation}`;

            if (propertyExists(operation.huvuddiagnos_display) && isNotEmptyObject(operation.huvuddiagnos_display)) {
                this.huvuddiagnoser = SetField({
                    value: operation.huvuddiagnos_display.id,
                    label: `${operation.huvuddiagnos_display.code} ${operation.huvuddiagnos_display.displayName}`,
                    formData: this.operationsuppgifterFormData,
                    objectPropertyKey: "huvuddiagnos",
                });
            }
            if (propertyExists(operation.bidiagnoser_display) && isNotEmptyObject(operation.bidiagnoser_display)) {
                operation.bidiagnoser_display.forEach(function (obj, index) {
                    this.$set(this.bidiagnoser, index, [
                        {
                            value: obj.id,
                            label: `${obj.code} ${obj.displayName}`,
                        },
                    ]);
                    this.$set(this.operationsuppgifterFormData.bidiagnoser, index, { bidiagnos: `${obj.id}` });
                }, this);
            }

            if (
                propertyExists(operation.huvudoperationskod_display) &&
                isNotEmptyObject(operation.huvudoperationskod_display)
            ) {
                this.huvudoperationskoder = SetField({
                    value: operation.huvudoperationskod_display.id,
                    label: `${operation.huvudoperationskod_display.code} ${operation.huvudoperationskod_display.displayName}`,
                    formData: this.operationsuppgifterFormData,
                    objectPropertyKey: "huvudoperationskod",
                });
            }
            if (
                propertyExists(operation.bioperationskoder_display) &&
                isNotEmptyObject(operation.bioperationskoder_display)
            ) {
                operation.bioperationskoder_display.forEach(function (obj, index) {
                    this.$set(this.bioperationskoder, index, [
                        {
                            value: obj.id,
                            label: `${obj.code} ${obj.displayName}`,
                        },
                    ]);
                    this.$set(this.operationsuppgifterFormData.bioperationskoder, index, {
                        bioperationskod: `${obj.id}`,
                    });
                }, this);
            }

            if (!this.isEditing) {
                propertyExistsNotNull(operation.bokad_operation_datum) &&
                    (this.operationsuppgifterFormData.operationsdatum = operation.bokad_operation_datum);
                !isNull(operation.bokad_sal) && (this.operationsuppgifterFormData.sal = `${operation.bokad_sal}`);
            } else {
                propertyExistsNotNull(operation.operationsdatum) &&
                    (this.operationsuppgifterFormData.operationsdatum = operation.operationsdatum);
                !isNull(operation.sal) && (this.operationsuppgifterFormData.sal = `${operation.sal}`);
            }
            !isNull(operation.reop) && (this.operationsuppgifterFormData.reop = operation.reop);
            !isNull(operation.garantiop) && (this.operationsuppgifterFormData.garantiop = operation.garantiop);
            !isNull(operation.lateralitet) && (this.operationsuppgifterFormData.lateralitet = operation.lateralitet);
            !isNull(operation.avtal) && (this.operationsuppgifterFormData.avtal = operation.avtal);
            !isNull(operation.anmarkning) && (this.operationsuppgifterFormData.anmarkning = operation.anmarkning);
        },

        setPersonaler(operation) {
            if (propertyExists(operation.huvudoperator)) {
                this.personalFormData.huvudoperator = operation.huvudoperator;
                this.personalFormData.huvudoperator_intid = getTimeFromDatetime(operation.huvudoperator_intid);
                this.personalFormData.huvudoperator_uttid = getTimeFromDatetime(operation.huvudoperator_uttid);
            }

            if (!this.isEditing) {
                if (arrayPropertyExistsNotEmpty(operation.ass_operatorer)) {
                    operation.ass_operatorer.forEach((ass_operator, groupIndex) => {
                        this.$set(this.ass_operatorer, groupIndex, [
                            {
                                value: `${ass_operator}`,
                                label: operation.ass_operatorer_display[groupIndex].user_display.name,
                            },
                        ]);
                        this.$set(this.personalFormData.ass_operatorer, groupIndex, {
                            profile: `${ass_operator}`,
                        });
                    });
                }
            } else {
                this.setPersonalField(operation, "ass_operatorer");
            }

            if (arrayPropertyExistsNotEmpty(operation.operationssjukskoterskor)) {
                this.setPersonalField(operation, "operationssjukskoterskor");
            }

            if (arrayPropertyExistsNotEmpty(operation.operationsunderskoterskor)) {
                this.setPersonalField(operation, "operationsunderskoterskor");
            }
        },

        setPatient(patient_data) {
            this.patientdataFormData = patient_data;
        },

        setWHOCheckLista(data) {
            this.whochecklistaForberedelseFormData.updated_at = moment(
                data.sektion_whochecklistaforberedelse.updated_at
            ).format("YYYY-MM-DD");
            this.whochecklistaForberedelseFormData.updated_by = data.sektion_whochecklistaforberedelse.updated_by;

            this.whochecklistaTimeoutFormData.updated_at = moment(data.sektion_whochecklistatimeout.updated_at).format(
                "YYYY-MM-DD"
            );
            this.whochecklistaTimeoutFormData.updated_by = data.sektion_whochecklistatimeout.updated_by;

            this.whochecklistaAvslutningFormData.updated_at = moment(
                data.sektion_whochecklistaavslutning.updated_at
            ).format("YYYY-MM-DD");
            this.whochecklistaAvslutningFormData.updated_by = data.sektion_whochecklistaavslutning.updated_by;
        },

        setMaterial(operation) {
            if (arrayPropertyExistsNotEmpty(operation.material)) {
                this.materialFormData.material = operation.material.map((item) => {
                    const { antal, batchnummer } = item;
                    const { artikelnummer, id, namn } = item.material_display;
                    return { material: id, artikelnummer, materialnamn: namn, antal, batchnummer };
                });
            }
        },

        setAnestesi(operation) {
            if (propertyExistsNotNull(operation.anestesilakare)) {
                this.anestesiFormData.anestesilakare = `${operation.anestesilakare}`;
            }

            if (propertyExistsNotNull(operation.anestesisjukskoterska)) {
                this.anestesiFormData.anestesisjukskoterska = `${operation.anestesisjukskoterska}`;
            }

            if (propertyExists(operation.huvuddiagnos_display) && isNotEmptyObject(operation.huvuddiagnos_display)) {
                this.huvuddiagnoser = SetField({
                    value: operation.huvuddiagnos_display.id,
                    label: `${operation.huvuddiagnos_display.code} ${operation.huvuddiagnos_display.displayName}`,
                    anestesiFormData: this.anestesiFormData,
                    objectPropertyKey: "huvuddiagnos",
                });
            }

            if (propertyExistsNotNull(operation.huvudanestesikod)) {
                this.anestesiFormData.huvudanestesikod = `${operation.huvudanestesikod}`;
            }

            if (arrayPropertyExistsNotEmpty(operation.bianestesikoder)) {
                operation.bianestesikoder.forEach((bianestesikod, groupIndex) => {
                    this.$set(this.bianestesikoder, groupIndex, [
                        {
                            value: `${bianestesikod}`,
                            label: operation.bianestesikoder_display[groupIndex].displayName,
                        },
                    ]);
                    this.$set(this.anestesiFormData.bianestesikoder, groupIndex, {
                        kod: `${bianestesikod}`,
                    });
                });
            }

            if (arrayPropertyExistsNotEmpty(operation.anestesikomplikationer)) {
                operation.anestesikomplikationer.forEach((anestesikomplikation, groupIndex) => {
                    this.anestesikomplikationer[groupIndex] = [
                        {
                            value: `${anestesikomplikation.kod}`,
                            label: anestesikomplikation.kod_display.displayName,
                        },
                    ];

                    this.$set(this.anestesiFormData.anestesikomplikationer, groupIndex, {
                        kategori: `${anestesikomplikation.kod_display.kapitel}`,
                        kod: `${anestesikomplikation.kod}`,
                        svarighetsgrad: `${anestesikomplikation.svarighetsgrad}`,
                        tidpunkt: extractHourMinuteFromDateTime(anestesikomplikation.tidpunkt),
                    });
                });
            }

            if (propertyExistsNotNull(operation.anestesikomplikationer)) {
                operation.anestesikomplikationer && isNotEmptyArray(operation.anestesikomplikationer)
                    ? (this.anestesiFormData.anestesikomplikationer = operation.anestesikomplikationer.map(
                          (komplikation) => {
                              return {
                                  kategori: komplikation.kod_display.kapitel && `${komplikation.kod_display.kapitel}`,
                                  svarighetsgrad: `${komplikation.svarighetsgrad}`,
                                  kod: `${komplikation.kod}`,
                                  tidpunkt: extractHourMinuteFromDateTime(komplikation.tidpunkt),
                              };
                          }
                      ))
                    : (operation.anestesikomplikationer = [{}]);
            }
        },

        setTider(operation) {
            operation.uppdukning_start &&
                (this.tiderFormData.uppdukning_start = `${extractHourMinuteFromDateTime(operation.uppdukning_start)}`);
            operation.uppdukning_slut &&
                (this.tiderFormData.uppdukning_slut = `${extractHourMinuteFromDateTime(operation.uppdukning_slut)}`);
            operation.antibiotikaprofylax_start &&
                (this.tiderFormData.antibiotikaprofylax_start = `${extractHourMinuteFromDateTime(
                    operation.antibiotikaprofylax_start
                )}`);
            operation.ankomsttid_preopenhet &&
                (this.tiderFormData.ankomsttid_preopenhet = `${extractHourMinuteFromDateTime(
                    operation.ankomsttid_preopenhet
                )}`);
            operation.preoperativ_anestesi_start &&
                (this.tiderFormData.preoperativ_anestesi_start = `${extractHourMinuteFromDateTime(
                    operation.preoperativ_anestesi_start
                )}`);
            operation.patienttid_start &&
                (this.tiderFormData.patienttid_start = `${extractHourMinuteFromDateTime(operation.patienttid_start)}`);
            operation.patient_in_opsal &&
                (this.tiderFormData.patient_in_opsal = `${extractHourMinuteFromDateTime(operation.patient_in_opsal)}`);
            operation.anestesitid_start &&
                (this.tiderFormData.anestesitid_start = `${extractHourMinuteFromDateTime(
                    operation.anestesitid_start
                )}`);
            operation.anestesitid_klar &&
                (this.tiderFormData.anestesitid_klar = `${extractHourMinuteFromDateTime(operation.anestesitid_klar)}`);
            operation.operationsforberedelse_klar &&
                (this.tiderFormData.operationsforberedelse_klar = `${extractHourMinuteFromDateTime(
                    operation.operationsforberedelse_klar
                )}`);
            operation.opstart && (this.tiderFormData.opstart = `${extractHourMinuteFromDateTime(operation.opstart)}`);
            operation.x_minuter_kvar &&
                (this.tiderFormData.x_minuter_kvar = `${extractHourMinuteFromDateTime(operation.x_minuter_kvar)}`);
            operation.opslut && (this.tiderFormData.opslut = `${extractHourMinuteFromDateTime(operation.opslut)}`);
            operation.anestesi_slut &&
                (this.tiderFormData.anestesi_slut = `${extractHourMinuteFromDateTime(operation.anestesi_slut)}`);
            operation.patient_ut_opsal &&
                (this.tiderFormData.patient_ut_opsal = `${extractHourMinuteFromDateTime(operation.patient_ut_opsal)}`);
            operation.patienttid_slut &&
                (this.tiderFormData.patienttid_slut = `${extractHourMinuteFromDateTime(operation.patienttid_slut)}`);
        },

        setPersonalField(personalSection = {}, field) {
            personalSection[field].forEach((item, groupIndex) => {
                if (isEmptyObject(item)) throw new Error(`item object is empty! ${item}`);

                this.$set(personalSection[field], groupIndex, [
                    {
                        value: `${item.profile}`,
                        label: `${item.profile_display.user_display.name}`,
                    },
                ]);
                this.$set(this.personalFormData[field], groupIndex, {
                    profile: {
                        value: `${item.profile}`,
                        label: `${item.profile_display.user_display.name}`,
                    },
                    intid: getTimeFromDatetime(item.intid),
                    uttid: getTimeFromDatetime(item.uttid),
                });
            });
        },

        async getOperationAnmalan(id) {
            return await fetchData({ url: `/operation/anmalan/${id}/` });
        },

        async getOperation(id) {
            return await fetchData({ url: `/operation/operationsjournaler/${id}/`, tabbnamn: "Operationsjournal" });
        },

        setOperation(data) {
            if (this.isEditing) {
                this.setOperationsuppgifter(data.sektion_operationsuppgifter);
                this.setPatient(data.sektion_patientdata);
                this.setPersonaler(data.sektion_personal);
                this.setWHOCheckLista(data);
                this.setMaterial(data.sektion_material);
                this.setAnestesi(data.sektion_anestesi);
                this.setTider(data.sektion_tider);
            } else {
                this.setOperationsuppgifter(data);
                this.setPersonaler(data);
            }

            this.operationsuppgifterSavedFormData = this.operationsuppgifterFormData;
            this.patientdataSavedFormData = this.patientdataFormData;
            this.personalSavedFormData = this.personalFormData;
            this.materialSavedFormData = this.materialFormData;
            this.anestesiSavedFormData = this.anestesiFormData;
            this.tiderSavedFormData = this.tiderFormData;
        },
    },

    async created() {
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
        this.loading = true;

        this.typoperationer = typoperationerOptions(
            await fetchData({
                url: "/operation/typoperationer/",
            })
        );

        let diagnoser = cvOptions(
            await fetchData({
                url: "/core/kodverk/icdkoder/",
            })
        );

        this.huvuddiagnoser = diagnoser;
        this.$set(this.bidiagnoser, 0, diagnoser);

        let operationskoder = cvOptions(
            await fetchData({
                url: "/operation/kodverk/operationskoder/",
            })
        );

        this.huvudoperationskoder = operationskoder;
        this.$set(this.bioperationskoder, 0, operationskoder);

        this.salar = salarOptions(
            await fetchData({
                url: "/operation/salar/",
            })
        );

        this.avtal = avtalOptions(
            await fetchData({
                url: "/core/avtal/",
            })
        );

        let operator = profileOptions(
            await fetchData({
                url: "core/profiles/?roll__code=kirurg",
            })
        );

        this.operatorer = operator;
        this.$set(this.ass_operatorer, 0, operator);

        let operationssjukskoterskor = profileOptions(
            await fetchData({
                url: "core/profiles/?roll__code=operationssjukskoterska",
            })
        );

        let operationsunderskoterska = profileOptions(
            await fetchData({
                url: "core/profiles/?roll__code=operationsunderskoterska",
            })
        );

        this.id_kontroll = operationssjukskoterskor;

        this.$set(this.operationssjukskoterskor, 0, operationssjukskoterskor);

        this.$set(this.operationsunderskoterskor, 0, operationsunderskoterska);

        this.anestesilakare = profileOptions(
            await fetchData({
                url: "core/profiles/?roll__code=anestesilakare",
            })
        );

        this.anestesisjukskoterskor = profileOptions(
            await fetchData({
                url: "core/profiles/?roll__code=anestesisjukskoterska",
            })
        );

        this.huvudanestesikoder = cvOptions(
            await fetchData({
                url: "/operation/kodverk/anestesikoder/",
            })
        );

        let bianestesikoder = cvOptions(
            await fetchData({
                url: "/operation/kodverk/anestesikoder/",
            })
        );

        this.$set(this.bianestesikoder, 0, bianestesikoder);

        this.rawAnestesikomplikationer = await fetchData({
            url: "/operation/kodverk/anestesikomplikationer/",
        });
        this.anestesikomplikationerKategorier = anestesikomplikationerKategorierOptions(this.rawAnestesikomplikationer);

        this.anestesikomplikationSvarighetsgrader = cvOptionsShort(
            await fetchData({
                url: "/operation/kodverk/anestesikomplikationsvarighetsgrader/",
            })
        );

        this.operationsanmalan = await this.getOperationAnmalan(this.parameters.operationsanmalan.id);
        this.operationjournal_id = this.operationsanmalan.operation_id;
        this.isEditing = propertyExistsNotNull(this.operationjournal_id);

        if (this.isEditing) {
            await this.setOperation(await this.getOperation(this.operationsanmalan.operation_id));
        } else {
            await this.setOperation(this.operationsanmalan);
        }

        this.loading = false;
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.operationsjournal {
    width: $form-width;
    margin: auto;

    .grey-div {
        background-color: $color-grey-bg;
        display: flex;
        padding: 10px;

        .buttonGroup {
            margin-left: 10px;

            button {
                align-self: flex-end;
                position: relative;

                img {
                    width: 20px;
                    position: sticky;
                    color: red;
                    z-index: 1;
                    filter: invert(42%) sepia(93%) saturate(2%) hue-rotate(118deg) brightness(159%) contrast(309%);
                }
            }
        }

        .rotate {
            animation: rotation 1s infinite linear;
        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(359deg);
            }
        }
    }

    .operationsuppgifter {
        ::v-deep .formulate-input-group-repeatable {
            column-gap: 18px;
        }

        .typoperationer {
            padding: 20px;
            margin-bottom: 20px;
            background-color: $color-blue-light;
        }

        ::v-deep .formulate-input-wrapper {
            #huvuddiagnos,
            #huvudoperationskod {
                width: 75.3%;
            }
        }

        .row {
            display: flex;
            gap: $column-gap;
            width: 75%;

            [data-type="checkbox"] {
                align-self: end;
            }

            .anmarkning {
                max-width: 50%;

                ::v-deep textarea {
                    max-height: 46px;
                }
            }
        }
    }

    .patientdata {
        h4 {
            margin: 0;
        }

        ::v-deep
            .patientdata-group
            > .formulate-input-wrapper
            > .formulate-input-group
            > .formulate-input-group-repeatable {
            display: flex;
            flex-direction: column;
        }

        .patientdata-groups {
            ::v-deep .formulate-input-group-repeatable {
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            .formulate-input {
                width: $column-width;
            }
        }
    }

    .personal {
        .wrapper-3-1 {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 26px;
        }
    }

    .who-checklista {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;

        .button-link {
            height: 70px;
            width: 480px;
            padding: 15px inherit 15px 20px;

            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: stretch !important;
            text-align: left;

            small {
                display: flex;
                justify-content: space-between;

                .updated {
                    font-style: italic;
                }
            }
        }
    }

    .who-modal {
        .header {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            gap: 85px;
            margin-bottom: 30px;

            .dialog-aktiv-patient {
                margin-right: auto;
            }

            .datum,
            .tid {
                strong {
                    display: block;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 21px;
                    color: $color-input-help;
                }
            }
        }

        hr {
            border: 0;
            border-bottom: 1px solid $divider-color;
            margin-bottom: 20px;
        }

        .formulate-input[data-type="checkbox"] {
            ul {
                padding-left: 18px;
                margin: 0;
            }
        }

        i {
            font-size: 14px;
            font-style: italic;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0;
            text-align: left;
        }

        .checkbox-group {
            p {
                margin-bottom: 10px;
            }

            & > .formulate-input {
                margin-bottom: 15px;
            }
        }
    }

    .anestesi {
        ::v-deep .formulate-input-wrapper {
            #anestesikomplikationer-kategori {
                width: 200px;
            }

            #anestesikomplikationer-kod,
            #anestesikomplikationer-svarighetsgrad {
                width: 150px;
            }
        }

        .wrapper-3-1 {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 26px;
        }

        .huvudanestesikod {
            width: calc(100% - 275px);
        }

        .forklaring {
            background-color: transparent;
            border: none;
            cursor: pointer;
            background-image: url("@/assets/link_internal.svg");
            padding-right: 44px;
            background-size: 18px 18px;
        }

        .komplikationsgrad-forklaring {
            .forklaringar {
                width: 100%;
                overflow: hidden;
                padding: 0;
                margin: 0;

                dt {
                    float: left;
                    width: 15%;
                    padding: 0;
                    margin: 0 0 20px 0;
                }

                dd {
                    float: left;
                    width: 85%;
                    padding: 0;
                    margin: 0 0 20px 0;
                }
            }

            .button-group {
                margin-top: 42px;
            }
        }
    }

    .tider {
        .formulate-input {
            width: $column-width;
        }
    }
}
</style>
