<template>
    <div class="dagsoversikt-lista">
        <div class="group-header">
            <h4>
                Datum: <strong>{{ datum }}</strong>
            </h4>
            <h4>
                Operationssal: <strong>{{ group.sal }}</strong>
            </h4>
        </div>

        <div v-for="anmalan in group.anmalningar" class="anmalan">
            <div class="anmalan-header">
                Huvudoperationskod:
                <strong>
                    {{ anmalan.huvudoperationskod_display.code }}
                    {{ anmalan.huvudoperationskod_display.displayName }}
                </strong>
            </div>

            <div class="anmalan-body">
                <table>
                    <tr>
                        <th>Förberedelsetid start:</th>
                        <td>{{ timeFromTimeDate(anmalan.salstid_tidpunkt_start) }}</td>
                    </tr>
                    <tr>
                        <th>Optid start:</th>
                        <td>{{ timeFromTimeDate(anmalan.optid_tidpunkt_start) }}</td>
                    </tr>
                    <tr>
                        <th>Optid antal minuter:</th>
                        <td>{{ anmalan.optid }}</td>
                    </tr>
                </table>

                <table>
                    <tr>
                        <th>Huvudoperatör:</th>
                        <td>{{ anmalan.bokad_huvudoperator_display.user_display.name }}</td>
                    </tr>
                    <tr>
                        <th>Assisterande operatörer:</th>
                        <td>
                            <ul v-for="(op, index) in anmalan.ass_operatorer_display">
                                <li :key="index">{{ op.user_display.name }}</li>
                            </ul>
                        </td>
                    </tr>
                </table>

                <table>
                    <tr>
                        <th>Huvuddiagnos:</th>
                        <td>
                            {{ anmalan.huvuddiagnos_display.code }}
                            {{ anmalan.huvuddiagnos_display.displayName }}
                        </td>
                    </tr>
                    <tr>
                        <th>Typoperation:</th>
                        <td>{{ anmalan.typoperation_display.namn }}</td>
                    </tr>
                    <tr>
                        <th>Reoperation:</th>
                        <td>{{ anmalan.reop ? "Ja" : "Nej" }}</td>
                    </tr>
                </table>

                <table>
                    <tr>
                        <th>Lateralitet:</th>
                        <td>{{ anmalan.lateralitet_display }}</td>
                    </tr>
                    <tr>
                        <th>Patientläge:</th>
                        <td>{{ anmalan.oplage_display.displayName }}</td>
                    </tr>
                    <tr>
                        <th>Resurser:</th>
                        <td>
                            <ul>
                                <li v-for="(resurs, index) in anmalan.resurser_display" :key="index">
                                    {{ resurs.namn }}
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Implantat</th>
                        <td>
                            <ul>
                                <li v-for="(implantat, index) in anmalan.implantat_display" :key="index">
                                    {{ implantat.namn }}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>

                <table>
                    <tr>
                        <th>Personnummer:</th>
                        <td>{{ anmalan.patient_display.personId }}</td>
                    </tr>
                    <tr>
                        <th>Namn:</th>
                        <td>{{ anmalan.patient_display.namn }}</td>
                    </tr>
                </table>
            </div>

            <div class="anmalan-footer"><strong>Info till operation:</strong> {{ anmalan.operationsinformation }}</div>
        </div>

        <div class="group-footer">
            <div>Totalsumma optid {{ datum }}, {{ sal }}</div>
            <div class="sum-optid">{{ sumOptid }} min</div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { sumBy } from "lodash";

const props = defineProps({
    group: { type: Object, default: () => [] },
});

const sal = computed(() => {
    return props.group.sal;
});

const datum = computed(() => {
    return props.group.anmalningar[0].bokad_operation_datum;
});

const timeFromTimeDate = (th) => {
    return th?.slice(11, 16);
};

const sumOptid = computed(() => {
    return sumBy(props.group.anmalningar, (anmalan) => anmalan.optid);
});
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.dagsoversikt-lista {
    .group-header {
        display: flex;
        align-items: flex-end;
        gap: 25px;
        margin-top: 19px;

        h4 {
            margin-bottom: 0;
        }

        strong {
            color: $color-active-dark;
        }
    }

    .group-footer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        padding: 10px 20px;
        border-bottom: 2px solid $color-input-help;
        background: linear-gradient(270deg, #f1f4f8 0%, rgba(241, 244, 248, 0) 52.01%);

        font-size: 14px;
        color: $color-active-dark;

        .sum-optid {
            color: black;
        }
    }

    .anmalan-header {
        background: $color-bg-table-header;
        border-bottom: 2px solid $color-input-help;
        padding: 10px;
        font-size: 14px;
        color: $color-active-dark;
        margin-top: 19px;

        strong {
            font-size: 14px;
            color: $color-active-dark;
        }
    }

    .anmalan-body {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px;

        font-size: 14px;
        line-height: 20px;

        table {
            width: 25%;
            flex-grow: 1;

            th {
                white-space: nowrap;
                width: 10%;
                vertical-align: top;
            }

            td {
                width: 90%;
                vertical-align: top;
            }
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
    }

    .anmalan-footer {
        border-top: 1px solid $list-border-color;
        border-bottom: 1px solid $list-border-color;
        padding: 10px;

        font-size: 14px;
        line-height: 16px;

        strong {
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
        }
    }
}

@media print {
    .dagsoversikt-lista {
        break-inside: avoid;

        .anmalan {
            break-inside: avoid;
        }
    }
}
</style>
