<script>
import BaseTab from "@/tabs/mixins/BaseTab.js";
import dialogEvents from "@/dialogEvents";
import { getConfig, getErrorMessage, klinikenApi } from "@/api.js";
import { openDialog } from "@/utils";
import { mapActions } from "vuex";
import { cloneDeep, isEqual } from "lodash";
import ButtonGroup from "@/components/ButtonGroup.vue";
import Button from "@/components/Button.vue";

const defaultLag = {
    id: undefined,
    namn: "",
    idrott: "",
    division: "",
    plats: "",
};
export default {
    extends: BaseTab,
    name: "FormLag",
    tabName: "Lag",
    components: { ButtonGroup, Button },

    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            defaultLag: cloneDeep(defaultLag),
            lag: cloneDeep(defaultLag),
            idrotter: [],
            platser: [],
            formErrors: [],
            inputErrors: {},
        };
    },

    computed: {
        hasUnsavedData() {
            return !isEqual(this.defaultLag, this.lag);
        },
        heading() {
            return this.lag.id === undefined ? "Skapa nytt lag" : "Ändra på lag";
        },
    },

    methods: {
        ...mapActions("skreg/lag", ["save"]),
        async submit(data) {
            try {
                await this.save(data);
                await this.$store.dispatch("tabs/closeTab", this.tabId);
            } catch (e) {
                if (e.response) {
                    this.formErrors = e.response.data["non_field_errors"];
                    this.inputErrors = e.response.data;
                }
                openDialog("Kunde inte spara laget", "error");
            }
        },
        cancel() {
            this.closeTab(this.hasUnsavedData);
        },
    },

    async created() {
        try {
            let response = await klinikenApi.get(`skreg/idrotter/`, getConfig());
            this.idrotter = response.data;
            response = await klinikenApi.get(`skreg/platser/`, getConfig());
            this.platser = response.data;
        } catch (e) {
            openDialog("Kunde inte hämta Idrotter eller Platser." + getErrorMessage(e), "warning");
        }

        if (this.parameters !== undefined && "lag" in this.parameters) {
            this.defaultLag = cloneDeep(this.parameters["lag"]);
            this.lag = cloneDeep(this.parameters["lag"]);
        }
    },
};
</script>

<template>
    <FormulateForm v-model="lag" :form-errors="formErrors" :errors="inputErrors" @submit="submit">
        <h2>{{ heading }}</h2>

        <FormulateInput name="namn" label="Namn" type="text" validation="required" />

        <FormulateInput
            name="idrott"
            label="Idrott"
            type="select"
            validation="required"
            :options="
                idrotter.map(({ id, namn }) => {
                    return { value: id, label: namn };
                })
            "
        />

        <FormulateInput name="division" label="Division" type="text" />

        <FormulateInput
            name="plats"
            label="Plats"
            type="select"
            :options="
                platser.map(({ id, namn }) => {
                    return { value: id, label: namn };
                })
            "
        />

        <ButtonGroup>
            <Button size="primary" role="button" type="submit" :disabled="!hasUnsavedData">Spara och stäng</Button>
            <Button @click="cancel()" size="secondary" type="button">Avbryt</Button>
        </ButtonGroup>
    </FormulateForm>
</template>

<style lang="scss" scoped>
.control {
    span {
        display: block;
    }

    &.invalid {
        label,
        input,
        span {
            color: #9e354b;
        }

        input {
            border: 1px #9e354b solid;
        }
    }

    &.required {
        label:after {
            content: " *";
        }
    }
}
</style>
