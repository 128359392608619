<template>
    <div class="smsmallform">
        <div class="header">
            <h1 v-if="parameters?.isEditing">Redigera sms-mall</h1>
            <h1 v-else-if="parameters?.isDuplicating">Duplicera sms-mall</h1>
            <h1 v-else>Skapa sms-mall</h1>

            <div class="grid-container">
                <div class="grid-item-span-6">
                    <div>
                        <strong>Typ av mall</strong><br />
                        <span>Operation</span>
                    </div>
                    <br />
                    <div>
                        <FormulateForm @submit="submitForm" v-model="formValues">
                            <FormulateInput
                                type="text"
                                name="namn"
                                label="Namn på mall *"
                                help="Max 40 tecken"
                                :value="parameters?.namn || ''"
                                validation="required"
                                :validation-messages="{
                                    required: 'Namn på mall är obligatoriskt',
                                }"
                            />

                            <FormulateInput
                                type="frasregister"
                                name="body"
                                :options="options"
                                label="Text i sms *"
                                help="Infoga info med dollartecken $, tex $Opdatum. Tänk på att inte inkludera känsliginformation eller personuppgifter i sms. "
                                :value="parameters?.body || ''"
                                validation="required"
                                :validation-messages="{
                                    required: 'Text i sms är obligatoriskt',
                                }"
                            />

                            <ButtonGroup>
                                <Button>Spara</Button>
                                <Button type="button" @click="cancel" variant="contour" size="secondary">Avbryt</Button>
                            </ButtonGroup>
                        </FormulateForm>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab.js";
import { getConfig, klinikenApi } from "@/api";
import { flashMessage, openDialog } from "@/utils";
import tabEvents from "@/tabEvents";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";

export default {
    extends: BaseTab,
    name: "SMSMallForm",
    tabName: "Skapa | Redigera sms-mall",
    components: {
        Button,
        ButtonGroup,
    },

    props: {
        tabId: Number,
        parameters: Object,
    },

    data() {
        return {
            formValues: {
                namn: "",
                body: "",
            },
            options: [
                {
                    value: "$pat_namn",
                    text: "Patients namn",
                },
                {
                    value: "$pat_fornamn",
                    text: "Patients förnamn",
                },
                {
                    value: "$pat_efternamn",
                    text: "Patients efternamn",
                },
                {
                    value: "$opdatum",
                    text: "Operationsdatum, datum för bokad operation",
                },
                {
                    value: "$optid",
                    text: "Operationstid, tidpunkt för bokad operation",
                },
            ],
        };
    },

    methods: {
        async submitForm(formData = {}) {
            formData.typ = "operation";

            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });

            try {
                let response = {};

                if (this.parameters?.id) {
                    const id = this.parameters?.id;

                    response = await klinikenApi.put(`/sms/mallar/${id}/`, formData, getConfig());
                } else {
                    response = await klinikenApi.post("/sms/mallar/", formData, getConfig());
                }
                if (response.status === 201 || response.status === 200) {
                    tabEvents.$emit("refreshSmsMallar", {});
                    flashMessage(response.status === 201 ? "Sms-mall skapad" : "Sms-mall redigerad");
                    await this.$store.dispatch("tabs/closeTab", this.tabId);
                }
            } catch (error) {
                openDialog(error.response.data["namn"][0] ?? "", "error");
            } finally {
                await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
            }
        },

        async cancel() {
            await this.$store.dispatch("tabs/closeTab", this.tabId);
        },
    },

    created() {
        if (this.parameters?.isEditing) {
            this.$store.commit("tabs/setTabName", { id: this.tabId, name: "Redigera sms-mall" });
        } else if (this.parameters?.isDuplicating) {
            this.$store.commit("tabs/setTabName", { id: this.tabId, name: "Duplicera sms-mall" });
        } else {
            this.$store.commit("tabs/setTabName", { id: this.tabId, name: "Skapa sms-mall" });
        }
    },
};
</script>

<style lang="scss" scoped>
.smsmallform {
    position: relative;

    ::v-deep(.formulate-input) {
        .formulate-input-help {
            &:before {
                content: url("@/assets/info_gray.svg");
                margin-right: 5px;
                position: relative;
                bottom: -2px;
            }
        }
    }

    .grid-container {
        display: flex;
        flex-wrap: wrap;

        .grid-item {
            flex-basis: calc(100% / 12);
            padding: 10px;
        }

        .grid-item-span-6 {
            flex-basis: calc(100% / 12 * 6); /* Set the width to span 6 columns */
        }
    }
}
</style>
