<template>
    <div class="OperationInformation-wrapper" v-if="operationsanmalan">
        <div class="header-div">
            <h1>Operationsinformation</h1>
            <div class="grey-div">
                <div class="left">
                    <span
                        >Operationsanmälan skapad {{ operationsanmalan.created_at?.substr(0, 10) }} av
                        {{ operationsanmalan.created_by_display?.user_display.name }},
                        {{ operationsanmalan.created_by_display?.yrkeskategori_display?.displayName }}</span
                    >
                    <span
                        >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Senast redigerad
                        {{ operationsanmalan.updated_at?.substr(0, 10) }}
                        {{
                            operationsanmalan.updated_by_display &&
                            ` av ${operationsanmalan.updated_by_display.user_display.name}, ${operationsanmalan.updated_by_display.yrkeskategori_display?.displayName}`
                        }}</span
                    >
                </div>
                <div class="right">
                    <div class="status">
                        <img :src="getStatusImage(operationsanmalan.status)" alt="" />
                        <span>{{ operationsanmalan.status }}</span>
                    </div>
                </div>
            </div>
            <div class="header-body">
                <h2>Bokningsinformation</h2>
                <div class="gridlayout">
                    <div class="date column columns-3">
                        <label>Datum *</label>
                        <span>{{
                            operationsanmalan.bokad_operation_datum ? operationsanmalan.bokad_operation_datum : "–"
                        }}</span>
                    </div>
                    <div class="time column columns-3">
                        <label>Tid</label>
                        <span>{{
                            operationsanmalan.bokad_operation_tid
                                ? operationsanmalan.bokad_operation_tid.substr(0, 5)
                                : "–"
                        }}</span>
                    </div>
                    <div class="sal column columns-3">
                        <label>Sal</label>
                        <span>{{ operationsanmalan.bokad_sal ? operationsanmalan.bokad_sal : "–" }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-body">
            <collapsible-section class="tidsplaneringsdata" number="1" heading="Tidsplaneringsdata" :grid="true">
                <div class="column columns-3">
                    <label>Huvudoperatör *</label>
                    <span>{{
                        operationsanmalan.bokad_huvudoperator_display?.user_display.name
                            ? operationsanmalan.bokad_huvudoperator_display?.user_display.name
                            : "–"
                    }}</span>
                </div>
                <div class="column columns-3">
                    <label>Assisterande operatörer</label>
                    <div
                        v-if="
                            operationsanmalan.ass_operatorer_display &&
                            operationsanmalan.ass_operatorer_display.length > 0
                        "
                    >
                        <div v-for="item in operationsanmalan.ass_operatorer_display" :key="item.id">
                            {{ item.user_display.name }}
                        </div>
                    </div>
                    <span v-else>–</span>
                </div>
                <div class="columns-3"></div>
                <div class="column columns-3">
                    <label>Prioritet *</label>
                    <span>{{
                        operationsanmalan.prio_display && operationsanmalan.prio_display.displayName
                            ? operationsanmalan.prio_display.displayName
                            : "–"
                    }}</span>
                </div>

                <div class="column columns-3">
                    <label>Kort varsel</label>
                    <span> {{ kortVarsel() }} </span>
                </div>
                <div class="column columns-3">
                    <label>Avtal (vårdprocess)</label>
                    <span>{{
                        operationsanmalan.avtal_display?.displayName
                            ? operationsanmalan.avtal_display?.displayName
                            : "–"
                    }}</span>
                </div>

                <div class="column columns-3">
                    <label>Önskat operationsdatum</label>
                    <span>{{ operationsanmalan.preliminart_datum ? operationsanmalan.preliminart_datum : "–" }}</span>
                </div>
            </collapsible-section>

            <collapsible-section class="operationsData" number="2" heading="Operationsdata" :grid="true">
                <div class="column columns-12">
                    <label>Huvuddiagnos *</label>
                    <span>{{
                        operationsanmalan.huvuddiagnos_display?.displayName
                            ? operationsanmalan.huvuddiagnos_display?.displayName
                            : "–"
                    }}</span>
                </div>
                <div class="column columns-12">
                    <label>Bidiagnos</label>
                    <div v-if="operationsanmalan.bidiagnoser && operationsanmalan.bidiagnoser.length > 0">
                        <div v-for="item in operationsanmalan.bidiagnoser_display" :key="item.id">
                            {{ item.displayName }}
                        </div>
                    </div>
                    <span v-else>–</span>
                </div>

                <div class="column columns-12">
                    <label>Huvudoperationskod *</label>
                    <span>{{
                        operationsanmalan.huvudoperationskod_display?.displayName
                            ? operationsanmalan.huvudoperationskod_display?.displayName
                            : "–"
                    }}</span>
                </div>

                <div class="columns-3 column-duo">
                    <div class="column duo-item">
                        <label>Reoperation</label>
                        <span>{{ reoperation() }}</span>
                    </div>
                    <div class="column duo-item">
                        <label>Garantioperation</label>
                        <span>{{ garantioperation() }}</span>
                    </div>
                </div>
                <div class="column columns-9"></div>
                <div class="column columns-3">
                    <label>Operationstid (min)</label>
                    <span>{{ operationsanmalan.optid ? operationsanmalan.optid : "–" }}</span>
                </div>
                <div class="column columns-3">
                    <label>Förberedelsetid (min)</label>
                    <span>{{ operationsanmalan.forberedelser ? operationsanmalan.forberedelser : "–" }}</span>
                </div>
                <div class="column columns-3">
                    <label>Avvecklingstid (min)</label>
                    <span>{{ operationsanmalan.avveckling ? operationsanmalan.avveckling : "–" }}</span>
                </div>
                <div class="column columns-3"></div>

                <div class="column columns-3">
                    <label>Patientläge *</label>
                    <span>{{
                        operationsanmalan.oplage_display && operationsanmalan.oplage_display.displayName
                            ? operationsanmalan.oplage_display.displayName
                            : "–"
                    }}</span>
                </div>
                <div class="column columns-3">
                    <label>Sida *</label>
                    <span>{{
                        operationsanmalan.lateralitet_display ? operationsanmalan.lateralitet_display : "–"
                    }}</span>
                </div>
                <div class="column columns-3">
                    <label>Anestesi *</label>
                    <span>{{
                        operationsanmalan.anestesi_display?.displayName
                            ? operationsanmalan.anestesi_display.displayName
                            : "–"
                    }}</span>
                </div>

                <div class="column columns-3">
                    <label>Preanestesibedöming via</label>
                    <span>{{
                        operationsanmalan.anestesibedomning_via_display &&
                        operationsanmalan.anestesibedomning_via_display?.displayName
                            ? operationsanmalan.anestesibedomning_via_display.displayName
                            : "–"
                    }}</span>
                </div>

                <div class="column columns-12">
                    <label>Resurser *</label>
                    <div v-if="operationsanmalan.resurser && operationsanmalan.resurser.length > 0">
                        <div v-for="item in operationsanmalan.resurser_display" :key="item.id">{{ item.namn }}</div>
                    </div>
                    <span v-else>–</span>
                </div>
            </collapsible-section>

            <collapsible-section class="patientData" number="3" heading="Patientdata" :grid="true">
                <div class="column columns-3">
                    <label>Rökare *</label>
                    <span>{{ operationsanmalan.rokare_display ? operationsanmalan.rokare_display : "–" }}</span>
                </div>
                <div class="column columns-3">
                    <label>Rökavvänjning given</label>
                    <span>{{
                        operationsanmalan.rokavvanjning_display ? operationsanmalan.rokavvanjning_display : "–"
                    }}</span>
                </div>
                <div class="column columns-3">
                    <label>Tolk</label>
                    <span>{{ operationsanmalan.tolk_display ? operationsanmalan.tolk_display : "–" }}</span>
                </div>
                <div class="column columns-3">
                    <label>Språk</label>
                    <span>{{ operationsanmalan.sprak_display ? operationsanmalan.sprak_display : "–" }}</span>
                </div>

                <div class="column columns-3">
                    <label>Vikt kg</label>
                    <span>{{ operationsanmalan.vikt ? operationsanmalan.vikt : "–" }}</span>
                </div>
                <div class="column columns-3">
                    <label>Längd (cm)</label>
                    <span>{{ operationsanmalan.langd ? operationsanmalan.langd : "–" }}</span>
                </div>
                <div class="column columns-3">
                    <label>BMI</label>
                    <span>{{ bmi() }}</span>
                </div>
                <div class="column columns-3">
                    <label>Invägning</label>
                    <span> {{ invagning() }}</span>
                </div>
                <div class="column columns-3">
                    <label>Önskad viktminskning (kg)</label>
                    <span>{{ operationsanmalan.viktminskning ? operationsanmalan.viktminskning : "–" }}</span>
                </div>
            </collapsible-section>

            <collapsible-section
                class="operationsData"
                number="4"
                heading="Operationsförberedande ordinationer"
                :grid="true"
            >
                <div class="column columns-3">
                    <label>Trombolysprofylax *</label>
                    <span>{{
                        operationsanmalan.trombosprofylax_display &&
                        operationsanmalan.trombosprofylax_display.displayName
                            ? operationsanmalan.trombosprofylax_display.displayName
                            : "–"
                    }}</span>
                </div>
                <div class="column columns-3">
                    <label>Antibiotikaprofylax *</label>
                    <span>{{
                        operationsanmalan.antibiotikaprofylax_display &&
                        operationsanmalan.antibiotikaprofylax_display.displayName
                            ? operationsanmalan.antibiotikaprofylax_display.displayName
                            : "–"
                    }}</span>
                </div>
                <div class="column columns-3">
                    <label>Antikoagulantia *</label>
                    <span>{{
                        operationsanmalan.antikoagulantia_display ? operationsanmalan.antikoagulantia_display : "–"
                    }}</span>
                </div>
                <div class="column columns-3">
                    <label>Sätts ut dagar för op</label>
                    <span>{{
                        operationsanmalan.satts_ut_dagar_innan ? operationsanmalan.satts_ut_dagar_innan : "–"
                    }}</span>
                </div>
            </collapsible-section>

            <collapsible-section class="ovriginformation" number="5" heading="Övrig information" :grid="true">
                <div class="column columns-6">
                    <label>Operationsinformation</label>
                    <span>{{
                        operationsanmalan.operationsinformation ? operationsanmalan.operationsinformation : "–"
                    }}</span>
                </div>
                <div class="column columns-6">
                    <label>Info till operationskoordinering</label>
                    <span>{{
                        operationsanmalan.info_till_opplaneringen ? operationsanmalan.info_till_opplaneringen : "–"
                    }}</span>
                </div>
            </collapsible-section>

            <collapsible-section class="status" number="6" heading="Status" :grid="true">
                <div class="column columns-6">
                    <label>Status på operationanmälan</label>
                    <div v-if="operationsanmalan.status" class="columns-12 status-margin status">
                        <img :src="getStatusImage(operationsanmalan.status)" alt="" />
                        <span>{{ operationsanmalan.status }}</span>
                    </div>
                    <span v-else>–</span>
                </div>
            </collapsible-section>
        </div>

        <ButtonGroup class="topmargin">
            <Button @click="bokningsPopupVisible = true">Redigera bokning</Button>
            <Button @click="redigeraOperationsanmalan()" :disabled="disable(operationsanmalan.status)">
                Redigera operationsanmälan
            </Button>
            <Button @click="avbokningsPopupVisible = true" variant="alert">Avboka operation</Button>
            <Button @click="closeTab()" variant="contour">Avbryt</Button>
        </ButtonGroup>

        <ModalContent
            v-if="bokningsPopupVisible"
            title="Redigera bokning"
            :showCloseButton="true"
            @close="bokningsPopupVisible = false"
        >
            <BokaOperation @closePopup="bokningsPopupVisible = false" :value="operationsanmalan" />
        </ModalContent>

        <ModalContent
            v-if="avbokningsPopupVisible"
            title="Avboka eller stryk"
            :showCloseButton="true"
            @close="avbokningsPopupVisible = false"
        >
            <AvbokaOperation @close="avbokningsPopupVisible = false" :value="operationsanmalan" />
        </ModalContent>
    </div>
</template>

<script>
import AvbokaOperation from "./AvbokaOperation.vue";
import BaseTab from "@/tabs/mixins/BaseTab.js";
import BokaOperation from "@/tabs/Operation/BokaOperation.vue";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import CollapsibleSection from "@/components/generic/CollapsibleSection.vue";
import DialogAktivPatient from "@/tabs/Operation/DialogAktivPatient.vue";
import KlinikenPopup from "@/components/Popup/KlinikenPopup.vue";
import ModalContent from "@/components/generic/ModalContent.vue";
import Operationsanmalan from "./Operationsanmalan.vue";
import StatusAttHanteraIcon from "@/assets/actionIcons/statusatthantera.svg";
import StatusAvbokadIcon from "@/assets/actionIcons/statusavbokad.svg";
import StatusAvvaktanIcon from "@/assets/actionIcons/statusavvaktan.svg";
import StatusBokadIcon from "@/assets/actionIcons/statusbokad.svg";
import StatusGenomford from "@/assets/actionIcons/statusgenomford.svg";
import StatusKlaropIcon from "@/assets/actionIcons/statusklarop.svg";
import StatusPagaende from "@/assets/actionIcons/statuspagaende.svg";
import StatusSignerad from "@/assets/sign_white.svg";
import StatusStrukenIcon from "@/assets/actionIcons/statusstruken.svg";
import StatusWaitingIcon from "@/assets/actionIcons/statuswaiting.svg";
import tabIcon from "@/assets/bed_icon.svg";
import { fetchObj } from "./utils";
import { get } from "lodash";
import { mapActions, mapGetters } from "vuex";
import emitter from "tiny-emitter/instance";

export default {
    extends: BaseTab,
    name: "Operationsinformation",
    icon: tabIcon,
    tabName: "Operationsinformation",
    patientBound: true,
    props: {
        parameters: {
            type: Object,
            required: false,
        },
        tabId: Number,
    },
    components: {
        BokaOperation,
        ModalContent,
        CollapsibleSection,
        Button,
        ButtonGroup,
        DialogAktivPatient,
        AvbokaOperation,
        KlinikenPopup,
        Operationsanmalan,
    },
    data() {
        return {
            icons: {
                "Att hantera": StatusAttHanteraIcon,
                "Väntar på viktnedgång": StatusWaitingIcon,
                "Väntar på rökstopp": StatusWaitingIcon,
                "Väntar på prover/undersökning": StatusWaitingIcon,
                "Klar för op": StatusKlaropIcon,
                "Patientvald väntan": StatusAvvaktanIcon,
                "Medicinsk väntan": StatusAvvaktanIcon,
                "Administrativ väntan": StatusAvvaktanIcon,
                Bokad: StatusBokadIcon,
                Avbokad: StatusAvbokadIcon,
                Genomförd: StatusGenomford,
                Signerad: StatusSignerad,
                Pågående: StatusPagaende,
                Struken: StatusStrukenIcon,
            },
            operationsanmalan: {},
            bokningsPopupVisible: false,
            avbokningsPopupVisible: false,
        };
    },

    computed: {
        ...mapGetters("operation/operationsanmalan", ["operationsanmalningar", "getAlreadyBooked"]),
    },

    methods: {
        ...mapActions("operation/operationsanmalan", ["bokaOpAnmalan", "avbokaOperationAnmalan", "resetOverbokning"]),
        getStatusImage(status) {
            return `${get(this.icons, status, "")}`;
        },
        kortVarsel() {
            return this.operationsanmalan.kort_varsel ? "Ja" : "Nej";
        },
        reoperation() {
            return this.operationsanmalan.reop ? "Ja" : "Nej";
        },
        garantioperation() {
            return this.operationsanmalan.garantiop ? "Ja" : "Nej";
        },
        invagning() {
            return this.operationsanmalan.invagning ? "Ja" : "Nej";
        },
        bmi() {
            if (this.operationsanmalan.langd && this.operationsanmalan.vikt) {
                return Number.parseFloat(this.operationsanmalan.vikt / Math.pow(this.operationsanmalan.langd / 100, 2))
                    .toFixed(1)
                    .toString()
                    .replace(".", ",");
            }
            return "–";
        },
        disable(status) {
            return ["Pågående", "Genomförd", "Signerad"].includes(status);
        },
        async redigeraOperationsanmalan() {
            await this.$store.dispatch("operation/operationsanmalan/openOpAnmalan", {
                component: Operationsanmalan,
                id: this.operationsanmalan.id,
                patient: this.operationsanmalan.patient,
                mode: "opcoordinator",
            });
        },
        closeTab() {
            this.$store.dispatch("tabs/closeTab", this.tabId);
        },
    },

    async created() {
        const { operationsanmalan_id } = this.parameters;
        this.operationsanmalan = await fetchObj({
            url: `operation/anmalan/${operationsanmalan_id}`,
            tabbnamn: "OperationInformation",
        });
    },
    mounted() {
        emitter.on("operationsanmalan-uppdaterad", async (operationsanmalan) => {
            this.operationsanmalan = {
                ...this.operationsanmalan,
                ...operationsanmalan,
            };
        });
        emitter.on("operationsanmalan-bokad", async (operationsanmalan) => {
            this.operationsanmalan = {
                ...this.operationsanmalan,
                ...operationsanmalan,
            };
        });
        emitter.on("operationsanmalan-avbokad", async (operationsanmalan) => {
            this.operationsanmalan = {
                ...this.operationsanmalan,
                ...operationsanmalan,
            };
        });
    },
    beforeDestroy() {
        emitter.off("operationsanmalan-uppdaterad");
        emitter.off("operationsanmalan-avbokad");
        emitter.off("operationsanmalan-bokad");
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.gridlayout {
    display: grid;
    width: 1100px;
    grid-template-columns: repeat(12, 68px);
    grid-row-gap: 346px;
    grid-column-gap: 26px;
    align-items: center;
}

.textalign-center {
    text-align: center;
}

.column {
    display: flex;
    flex-direction: column;
}

.columns-1 {
    grid-column: span 1;
}

.columns-2 {
    grid-column: span 2;
}

.columns-3 {
    grid-column: span 3;
}

.columns-6 {
    grid-column: span 6;
}

.columns-9 {
    grid-column: span 9;
}

.columns-12 {
    grid-column: 1 / -1;
}

.column-duo {
    display: flex;
    flex-direction: row;
}

.duo-item {
    width: 50%;
}

label {
    color: $color-label-grey;
}

.status img {
    margin-right: 8px;
    margin-bottom: -2px;
}

.status-margin {
    margin: 11px;
}

.OperationInformation-wrapper {
    .collapsible-section {
        margin-top: 10px;

        ::v-deep .outer {
            padding: 20px 0;
        }
    }

    ::v-deep .modal-content {
        background-color: transparent;
        overflow: auto;
    }

    .grey-div {
        padding: 20px;
        background-color: $color-tablerow;
        display: flex;
        justify-content: space-between;
    }

    .header-div {
        padding: 0 0 0 26px;

        .header-body {
            margin-top: 10px;
        }
    }

    .section-body {
        margin-top: 40px;
    }

    @media only screen and (max-width: 1100px) {
        .gridlayout {
            grid-template-columns: repeat(6, 50px);
            grid-row-gap: 16px;
            grid-column-gap: 6px;
        }
        .grey-div {
            display: block;

            .left {
                display: flex;
                flex-direction: column;
            }
        }
        width: 95%;
        .columns-1 {
            grid-column: span 12;
        }
        .columns-2 {
            grid-column: span 12;
        }
        .columns-3 {
            grid-column: span 12;
        }
        .columns-4 {
            grid-column: span 12;
        }
        .columns-6 {
            grid-column: span 12;
        }
        .columns-8 {
            grid-column: span 12;
        }
    }

    ::v-deep(.modal-content .body) {
        padding: 1.25rem 2rem;
    }
}
</style>
