<template>
    <form @submit.prevent="submit">
        <admin-lock-border />
        <h2 v-if="parameters">Redigera enhet</h2>
        <h2 v-else>Skapa ny enhet</h2>
        <div class="form-group k3-form">
            <input-wrapper
                :component="widgets.TextWidget"
                label="Namn"
                name="name"
                v-model="orgUnit.name"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="HSA-id"
                name="hsaid"
                v-model="orgUnit.hsaid"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="HSA-id Vårdgivare"
                name="hsaid_vg"
                v-model="orgUnit.hsaid_vg"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="HSA-id Vårdenhet"
                name="hsaid_ve"
                v-model="orgUnit.hsaid_ve"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="Arbetsplatskod"
                name="arbetsplatskod"
                v-model="orgUnit.arbetsplatskod"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="Telefon"
                name="telecom"
                v-model="orgUnit.telecom"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="Email"
                name="email"
                v-model="orgUnit.email"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="Gatuadress"
                name="streetName"
                v-model="orgUnit.streetName"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="Lokalitet"
                name="numberOrNameOfHouse"
                v-model="orgUnit.numberOrNameOfHouse"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="Postkod"
                name="postalCode"
                v-model="orgUnit.postalCode"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="Ort"
                name="city"
                v-model="orgUnit.city"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="Plats"
                name="location"
                v-model="orgUnit.location"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.CheckboxWidget"
                label="Aktiv"
                name="is_active"
                v-model="orgUnit.is_active"
                @input="updateFormHasBeenEdited"
            />
        </div>
        <ButtonGroup>
            <Button size="primary" role="button" type="submit" :disabled="!formHasBeenEdited">Spara och stäng</Button>
            <Button @click="cancel()" size="secondary" type="button">Avbryt</Button>
        </ButtonGroup>
    </form>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import dialogEvents from "@/dialogEvents";
import tabIcon from "@/assets/receptforskrivning.svg";
import widgets from "@/components/widgets/inputs";
import { flashMessage } from "@/utils";
import { mapActions } from "vuex";
import { cloneDeep } from "lodash";
import AdminLockBorder from "@/components/AdminLockBorder.vue";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";

export default {
    extends: BaseTab,
    name: "OrgUnit",
    icon: tabIcon,
    tabName: "Enhet",
    components: {
        Button,
        ButtonGroup,
        AdminLockBorder,
        ...widgets,
        InputWrapper,
    },
    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            widgets: widgets,
            formHasBeenEdited: false,
            orgUnit: {
                id: "",
                name: "",
                hsaid: "",
                hsaid_vg: "",
                hsaid_ve: "",
                arbetsplatskod: "",
                telecom: "",
                email: "",
                streetName: "",
                numberOrNameOfHouse: "",
                postalCode: "",
                city: "",
                location: "",
                is_active: false,
            },
        };
    },
    computed: {
        hasUnsavedData() {
            return this.formHasBeenEdited;
        },
    },
    methods: {
        ...mapActions("orgUnit", ["save", "update", "load"]),
        updateFormHasBeenEdited() {
            this.formHasBeenEdited = true;
        },
        async submit() {
            let response, msg, status;

            if (this.orgUnit.id) {
                this.orgUnit.is_active = Boolean(this.orgUnit.is_active);
                response = await this.update({ orgUnit: this.orgUnit });
                msg = "Enheten har uppdaterats";
                status = 200;
            } else {
                this.orgUnit.is_active = Boolean(this.orgUnit.is_active);
                response = await this.save({ orgUnit: this.orgUnit });
                msg = "Enheten har sparats";
                status = 201;
            }

            if (!response) return;
            if (response.status === status) {
                await this.$store.dispatch("orgUnit/load");
                await this.$store.dispatch("tabs/closeTab", this.tabId);
                flashMessage(msg);
            }
        },
        cancel() {
            this.closeTab(this.hasUnsavedData);
        },
    },
    async created() {
        if (this.parameters) this.orgUnit = cloneDeep(this.parameters);
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/deprecated_main";
</style>
