<template>
    <form @submit.prevent="submit.prevent" id="op-registration-details">
        <h2>{{ editingExistingAnmalan ? "Redigera" : "Skapa" }} operationsanmälan</h2>
        <div :class="'form-group k3-form ' + (colspanMax === 8 ? 'fullscreen' : '')">
            <div class="colspan whole"><h3>Tidsplaneringsdata</h3></div>
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutSuggestWidget"
                label="Huvudoperatör"
                name="operator"
                v-model="formData.huvudoperator"
                apisearch="/core/profiles/"
                :extraParams="{ operator: true }"
                :map="
                    (el) => {
                        return {
                            id: el.pk,
                            code: el.healthCareProviderHsaId,
                            displayName: el.user.name,
                        };
                    }
                "
                required="true"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutExpandableSuggestWidget"
                label="Assisterande operatörer"
                name="ass_operatorer"
                v-model="formData.ass_operatorer"
                apisearch="/core/profiles/"
                :extraParams="{ operator: true }"
                :map="
                    (el) => {
                        return {
                            id: el.pk,
                            code: el.healthCareProviderHsaId,
                            displayName: el.user.name,
                        };
                    }
                "
                display-name="ass operatörer"
                colspan="4"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Planerad vårdform"
                required="true"
                name="planerad_vardform"
                v-model="formData.planerad_vardform"
                @input="planeradVardformChanged($event)"
                :options="[
                    { text: 'Sluten vård', value: 'SLUTENVÅRD' },
                    { text: 'Öppen vård', value: 'ÖPPENVÅRD' },
                ]"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Prioritet"
                required="true"
                name="prio"
                v-model="formData.prio"
                apisearch="/operation/kodverk/prioriteter/"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.CheckboxWidget"
                :show-empty-label="true"
                checkbox-label="Kort varsel"
                name="kort_varsel"
                v-model="formData.kort_varsel"
            />

            <!--input-wrapper
          :preview="mode === 'preview'"
          :component="widgets.NumberWidget"
          :disabled="planeradVardformIsOppenvard"
          label="Totalt antal dygn på avdelningen"
          name="antal_dagar_pa_avdelning"
          v-model="formData.antal_dagar_pa_avdelning"
          :min="1"
      /-->
            <!--input-wrapper
          :preview="mode === 'preview'"
          :component="widgets.NumberWidget"
          :disabled="planeradVardformIsOppenvard"
          :warning="inlaggningDagarForeEqualsDagarPaAvdelning"
          label="Inläggning antal dygn före op"
          name="inlaggning_antal_dagar_fore"
          v-model="formData.inlaggning_antal_dagar_fore"
          :min="0"
          :max="formData.antal_dagar_pa_avdelning"
      /-->
            <!-- <input-wrapper
          :preview="mode === 'preview'"
          :component="widgets.DateWidget"
          label="Önskat operationsdatum"
          name="preliminart_datum"
          v-model="formData.preliminart_datum"
      /> -->
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Avtal"
                name="avtal"
                v-model="formData.avtal"
                :options="avtalOptions"
            />
            <template v-if="mode === 'opcoordinator'">
                <input-wrapper
                    :preview="mode === 'preview'"
                    :component="widgets.SelectWidget"
                    label="Prel debiteringskod"
                    name="prel_debit_kod"
                    v-model="formData.prel_debit_kod"
                />
                <input-wrapper
                    v-if="!editingExistingAnmalan"
                    :preview="mode === 'preview'"
                    :component="widgets.NumberWidget"
                    label="Pris"
                    name="manuellt_pris"
                    v-model="formData.manuellt_pris"
                    :min="0"
                    :step="0.01"
                />
                <input-wrapper
                    :preview="mode === 'preview'"
                    :component="widgets.TextAreaWidget"
                    label="Kommentar till pris"
                    :colspan="4"
                    :maxlength="1048"
                    name="manuellt_pris_kommentar"
                    v-model="formData.manuellt_pris_kommentar"
                />
            </template>

            <div class="colspan whole"><h3>Operationsdata</h3></div>

            <div class="colspan whole">
                <typoperation-select-widget
                    @setTypoperation="setTypoperation"
                    @purgeTypoperation="purgeTypoperation"
                    :preselectedTypoperation="preselectedTypoperation"
                />
            </div>

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutSuggestWidget"
                label="Huvuddiagnos"
                name="huvuddiagnos"
                @input="huvuddiagnosChanged"
                v-model="formData.huvuddiagnos"
                apisearch="/core/kodverk/icdkoder/"
                colspan="6"
                :displayCode="true"
                required="true"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutExpandableSuggestWidget"
                label="Bidiagnoser"
                name="bidiagnoser"
                v-model="formData.bidiagnoser"
                apisearch="/core/kodverk/icdkoder/"
                colspan="8"
                :displayCode="true"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutSuggestWidget"
                label="Huvudoperationskod"
                name="huvudoperationskod"
                @input="huvudopkodChanged"
                v-model="formData.huvudoperationskod"
                apisearch="/operation/kodverk/operationskoder/"
                colspan="6"
                :displayCode="true"
                required="true"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutExpandableSuggestWidget"
                label="Bioperationskoder"
                name="bioperationskoder"
                v-model="formData.bioperationskoder"
                apisearch="/operation/kodverk/operationskoder/"
                colspan="8"
            />
            <!-- <input-wrapper
          :preview="mode === 'preview'"
          :component="widgets.TextAreaWidget"
          label="Beskrivning"
          colspan="4"
          :maxlength="1048"
          name="beskrivning"
          @input="beskrivningManuallyChanged = true"
          v-model="formData.beskrivning"
      /> -->
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutExpandableSuggestWidget"
                label="Resurser"
                name="resurser"
                v-model="formData.resurser"
                :map="
                    (item) => {
                        return {
                            id: item.id,
                            code: item.id,
                            displayName: item.namn,
                        };
                    }
                "
                apisearch="/operation/resurser/"
                colspan="4"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.NumberWidget"
                label="Operationstid (min)"
                name="optid"
                v-model="formData.optid"
                :min="1"
                required="true"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.NumberWidget"
                label="Förberedelsetid (min)"
                name="forberedelser"
                v-model="formData.forberedelser"
                :min="1"
            />

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutExpandableSuggestWidget"
                label="Implantat"
                name="implantat"
                colspan="8"
                v-model="formData.implantat"
                apisearch="/operation/implantat/"
                :map="
                    (item) => {
                        return {
                            id: item.id,
                            code: item.id,
                            displayName: item.namn,
                        };
                    }
                "
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.NumberWidget"
                label="Avvecklingstid (min)"
                name="avveckling"
                v-model="formData.avveckling"
                :min="1"
            />
            <template v-if="mode === 'opcoordinator'">
                <input-wrapper
                    :preview="mode === 'preview'"
                    :component="widgets.SelectWidget"
                    label="Patientläge"
                    name="oplage"
                    v-model="formData.oplage"
                    :apisearch="
                        formData.oplage
                            ? '/operation/kodverk/oplagen/?include=' + formData.oplage
                            : '/operation/kodverk/oplagen/'
                    "
                    :extraParams="{ include: formData.oplage }"
                    :map="
                        (item) => {
                            return {
                                value: item.id,
                                text: item.displayName,
                                enabled: item.enabled,
                            };
                        }
                    "
                    required="true"
                />
            </template>
            <template v-else>
                <input-wrapper
                    :preview="mode === 'preview'"
                    :component="widgets.SelectWidget"
                    label="Patientläge"
                    name="oplage"
                    v-model="formData.oplage"
                    apisearch="/operation/kodverk/oplagen/"
                    :map="
                        (item) => {
                            return {
                                value: item.id,
                                text: item.displayName,
                                enabled: item.enabled,
                            };
                        }
                    "
                    required="true"
                />
            </template>

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Lateralitet"
                name="lateralitet"
                v-model="formData.lateralitet"
                apisearch="/operation/kodverk/lateralitet/"
                required="true"
                :map="
                    (item) => {
                        return { value: item.code, text: item.displayName };
                    }
                "
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Anestesi"
                name="anestesi"
                v-model="formData.anestesi"
                apisearch="/operation/kodverk/anestesier/"
                required="true"
                :map="
                    (item) => {
                        return { value: item.id, text: item.displayName };
                    }
                "
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Preanestesibedöming via"
                name="anestesibedomning_via"
                v-model="formData.anestesibedomning_via"
                apisearch="/operation/kodverk/anestesibedomningar/"
                :map="
                    (item) => {
                        return { value: `${item.id}`, text: item.displayName };
                    }
                "
            />

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.CheckboxWidget"
                :show-empty-label="true"
                checkbox-label="Garantioperation"
                name="garantiop"
                v-model="formData.garantiop"
            />

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.CheckboxWidget"
                :show-empty-label="true"
                checkbox-label="Reoperation"
                name="reop"
                v-model="formData.reop"
            />

            <div class="colspan whole"><h3>Patientdata</h3></div>
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Rökare"
                name="rokare"
                @input="rokareChanged($event)"
                v-model="formData.rokare"
                :options="[
                    { text: 'Ja', value: 'JA' },
                    { text: 'Nej', value: 'NEJ' },
                    { text: 'Okänt', value: 'OKÄNT' },
                ]"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Rökavvänjning har erbjudits"
                :disabled="!isRokare"
                name="rokavvanjning"
                v-model="formData.rokavvanjning"
                :options="[
                    { text: 'Ja', value: 'JA' },
                    { text: 'Nej', value: 'NEJ' },
                    { text: 'Okänt', value: 'OKÄNT' },
                ]"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Tolk"
                name="tolk"
                v-model="formData.tolk"
                @input="tolkChanged($event)"
                :options="[
                    { text: 'Ja', value: 'JA' },
                    { text: 'Nej', value: 'NEJ' },
                ]"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutSuggestWidget"
                label="Språk"
                name="sprak"
                :disabled="!hasTolk"
                v-model="formData.sprak"
                apisearch="/core/kodverk/iso_639_1/"
                :map="
                    (item) => {
                        return { id: item.code, code: item.code, displayName: item.displayName };
                    }
                "
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.NumberWidget"
                label="Vikt (kg)"
                name="vikt"
                v-model="formData.vikt"
                :min="0.5"
                :max="350"
                :step="0.1"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.NumberWidget"
                label="Längd (cm)"
                name="langd"
                v-model="formData.langd"
                :min="30"
                :max="240"
                :step="0.1"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.ReadOnlyTextWidget"
                label="BMI"
                name="bmi"
                :value="bmi"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Invägning"
                name="invagning"
                v-model="formData.invagning"
                :options="[
                    { text: 'Ja', value: true },
                    { text: 'Nej', value: false },
                ]"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.NumberWidget"
                label="Önskad viktminskning (kg)"
                name="viktminskning"
                v-model="formData.viktminskning"
                :min="0.1"
                :step="0.1"
            />

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Diabetes"
                name="diabetes"
                v-model="formData.diabetes"
                :options="[
                    { text: '', value: null },
                    { text: 'Ja', value: true },
                    { text: 'Nej', value: false },
                ]"
            />

            <input-wrapper
                v-if="formData.diabetes"
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Behandlingsform för Diabetes"
                name="diabetes_lakemedelsform"
                v-model="formData.diabetes_lakemedelsform"
                :options="[
                    { text: 'Tablett', value: 'tablett' },
                    { text: 'Insulin', value: 'insulin' },
                    { text: 'Obehandlad/Kost', value: 'obehandlad_kost' },
                    { text: 'Vet ej', value: 'vet_ej' },
                ]"
            />

            <div class="colspan whole">
                <h3>Operationsförberedande ordinationer</h3>
            </div>
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Trombosprofylax"
                name="trombosprofylax"
                v-model="formData.trombosprofylax"
                apisearch="/operation/kodverk/trombosprofylaxer/"
                required="true"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Antibiotikaprofylax"
                name="antibiotikaprofylax"
                v-model="formData.antibiotikaprofylax"
                apisearch="/operation/kodverk/antibiotikaprofylaxer/"
                required="true"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Antikoagulantia"
                name="antikoagulantia"
                v-model="formData.antikoagulantia"
                @input="antikoagulantiaChanged($event)"
                :options="[
                    { text: 'Ja', value: 'JA' },
                    { text: 'Nej', value: 'NEJ' },
                    { text: 'Okänt', value: 'OKÄNT' },
                ]"
                required="true"
                v-tooltip="{
                    content: 'Ange om patienten står på Antikoagulantia sedan tidigare',
                    theme: 'kliniken-tooltip',
                    placement: 'bottom',
                }"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.NumberWidget"
                label="Sätts ut dagar före op"
                name="satts_ut_dagar_innan"
                :disabled="hasAntikoagulatia"
                v-model="formData.satts_ut_dagar_innan"
                :min="1"
            />

            <div class="colspan whole"><h3>Övrig information</h3></div>
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.TextAreaWidget"
                label="Operationsinformation"
                colspan="4"
                :maxlength="1048"
                name="operationsinformation"
                v-model="formData.operationsinformation"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.TextAreaWidget"
                label="Info till opplaneringen"
                colspan="4"
                :maxlength="1048"
                name="info_till_opplaneringen"
                v-model="formData.info_till_opplaneringen"
            />

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Status på operationsanmälan"
                :disabled="formData.status === 'Bokad' || !this.isEditing"
                name="status"
                v-model="formData.status"
                :options="statusar"
                colspan="4"
            />

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.TextAreaWidget"
                label="Info till vårdavdelningen"
                colspan="4"
                :maxlength="1048"
                name="info_till_vardavdelningen"
                v-model="formData.info_till_vardavdelningen"
            />
        </div>

        <div class="btn-group form-group">
            <button class="btn btn-primary" role="button" type="button" @mousedown="save">
                {{ editingExistingAnmalan ? "Uppdatera operationsanmälan" : "Skicka opanmälan till opkoordinator" }}
            </button>
            <button class="btn btn-secondary" type="button" @click="close">Avbryt</button>
        </div>
    </form>
</template>
<script lang="js">
import BaseTab from "@/tabs/mixins/BaseTab";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import TypoperationSelectWidget from "@/tabs/Operation/TypoperationSelectWidget.vue";
import { cloneDeep, isEqual } from "lodash";
import tabIcon from "@/assets/operationsanmalan.svg";
import widgets from "@/components/widgets/inputs";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { mapActions } from "vuex";
import { openDialog } from "@/utils";
import { VTooltip } from "floating-vue";


export default {
    extends: BaseTab,
    name: "Operationsanmalan",
    tabName: "Operationsanmälan",
    icon: tabIcon,
    patientBound: true,
    props: {
        uid: Number,
        tabId: Number,
        parameters: Object,
        editing: Boolean,
    },
    components: {
        InputWrapper,
        TypoperationSelectWidget,
        VTooltip,
    },
    data() {
        return {
            currentTypOperation: null,
            clientWidth: 0,
            widgets: widgets,
            defaultFormData: {
                status: "",
                newStatus: [],
            },
            formData: {
                status: "",
                newStatus: [],
            },
            formDataObject: {},
            id: this.parameters ? this.parameters.id : null,
            mode: this.parameters ? this.parameters.mode : null,
            beskrivningManuallyChanged: false,
            displayPurgeTypoperation: false,
            preselectedTypoperation: null,
            isEditing: false,
            statusar: [
                { value: "Att hantera", text: "Att hantera", icon: "statusatthantera", disabled: false },
                {
                    value: "Väntar på viktnedgång",
                    text: "Väntar på viktnedgång",
                    icon: "statuswaiting",
                    disabled: false,
                },
                { value: "Väntar på rökstopp", text: "Väntar på rökstopp", icon: "statuswaiting", disabled: false },
                {
                    value: "Väntar på prover/undersökning",
                    text: "Väntar på prover/undersökning",
                    icon: "statuswaiting",
                    disabled: false,
                },
                { value: "Klar för op", text: "Klar för op", icon: "statusklarop", disabled: false },
                { value: "Patientvald väntan", text: "Patientvald väntan", icon: "statusavvaktan", disabled: true },
                { value: "Medicinsk väntan", text: "Medicinsk väntan", icon: "statusavvaktan", disabled: true },
                {
                    value: "Administrativ väntan",
                    text: "Administrativ väntan",
                    icon: "statusavvaktan",
                    disabled: true,
                },
                { value: "Bokad", text: "Bokad", icon: "statusbokad", disabled: true },
                { value: "Avbokad", text: "Avbokad", icon: "statusavbokad", disabled: true },
                { value: "Struken", text: "Struken", icon: "statusstruken", disabled: true },
                { value: "Genomförd", text: "Genomförd", icon: "statusgenomford", disabled: true },
                { value: "Pågående", text: "Pågående", icon: "statuspagaende", disabled: true },
                { value: "Signerad", text: "Signerad", icon: "statussignerad", disabled: true },
            ],
            avtal: [],
        };
    },
    computed: {
        hasUnsavedData() {
            return !isEqual(this.defaultFormData, this.formData);
        },

        colspanMax() {
            if (this.clientWidth < 650) return 4;
            else if (this.clientWidth < 900) return 6;
            else return 8;
        },
        planeradVardformIsOppenvard() {
            return this.formData.planerad_vardform === "ÖPPENVÅRD";
        },
        isRokare() {
            return this.formData.rokare === "JA";
        },
        hasTolk() {
            return this.formData.tolk === "JA";
        },
        hasAntikoagulatia() {
            return this.formData.antikoagulatia === "JA";
        },
        bmi() {
            if (this.formData.langd && this.formData.vikt) {
                return Number.parseFloat(this.formData.vikt / Math.pow(this.formData.langd / 100, 2))
                    .toFixed(1)
                    .toString()
                    .replace(".", ",");
            }
            return "";
        },
        // "Varna om “Inläggning antal dagar före” är lika med “Antal dagar på avdelning”. Förhindra om antal dagar före är större.""
        inlaggningDagarForeEqualsDagarPaAvdelning() {
            if (
                this.formData.inlaggning_antal_dagar_fore &&
                this.formData.inlaggning_antal_dagar_fore === this.formData.antal_dagar_pa_avdelning
            ) {
                return "Antal dagar på avdelning är samma som antal dagar före inläggning";
            } else {
                return "";
            }
        },

        editingExistingAnmalan() {
            return this.parameters !== undefined ? true : false;
        },

        avtalOptions() {
            return this.avtal.map(({ id, namn }) => ({ value: id, text: namn }));
        },
    },

    methods: {
        ...mapActions("operation/anmalan", ["resetStatusar"]),
        // Nollställer disablade fält. Oklart om detta behövs, men kan ev påverka BE-validering
        planeradVardformChanged(value) {
            if (value === "ÖPPENVÅRD") {
                delete this.formData.antal_dagar_pa_avdelning;
                delete this.formData.inlaggning_antal_dagar_fore;
            }
        },
        rokareChanged(value) {
            if (value !== "JA") delete this.formData.rokavvanjning;
        },
        tolkChanged(value) {
            if (value !== "JA") delete this.formData.sprak;
        },
        antikoagulantiaChanged(value) {
            if (value !== "JA") delete this.formData.satts_ut_dagar_innan;
        },

        purgeTypoperation() {
            this.formData.typoperation = null;

            let purge = {
                namn: null,
                beskrivning: "",
                lateralitet: null,
                optid: null,
                forberedelser: null,
                avveckling: null,
                huvuddiagnos: { code: "", id: "", displayName: "" },
                huvudoperationskod: { code: "", id: "", displayName: "" },
                oplage: null,
                anestesi: null,
                anestesibedomning_via: null,
                bidiagnoser: [{ code: "", id: "", displayName: "" }],
                bioperationskoder: [{ code: "", id: "", displayName: "" }],
                resurser: [{ code: "", id: "", displayName: "" }],
                opkoder: [{ code: "", id: "", displayName: "" }],
                lateralitet_display: null,
            };

            this.currentTypOperation = purge;
            this.formData = { ...this.formData, ...purge };
            this.displayPurgeTypoperation = false;
        },

        async setTypoperation({ id }) {
            let data;
            const config = getConfig();
            await klinikenApi
                .get("/operation/typoperationer/" + id + "/", config)
                .then((response) => {
                    data = response.data;
                    this.currentTypOperation = data;
                })
                .catch((e) => {
                    openDialog("Kunde inte uppdatera typoperationer från servern. " + getErrorMessage(e), "error");
                });
            delete data.id; // Typoperation has an id, prevent it from overwriting formData.id;
            this.formData = { ...this.formData, ...data };
            this.formData.typoperation = id;
            this.beskrivningManuallyChanged = true;
        },

        huvudopkodChanged(value) {
            if (!this.currentTypOperation) return;
            if (value !== this.currentTypOperation.huvudoperationskod) this.formData.typoperation = null;
        },

        huvuddiagnosChanged(value) {
            if (!this.currentTypOperation) {
                return;
            }

            if (value !== this.currentTypOperation.huvuddiagnos) {
                this.formData.typoperation = null;
            }
        },
        validateForm(formName) {
            var form = document.getElementById(formName);
            if (form.checkValidity() === true) return true;
            else {
                var list = form.querySelectorAll(":invalid");
                for (var item of list) {
                    item.focus();
                }
                form.querySelector(":invalid").focus();
            }
            return false;
        },
        close() {
            this.closeTab(this.hasUnsavedData);
        },
        async save() {
            if (!this.validateForm("op-registration-details")) return;

            let cleanFormData = cloneDeep(this.formData);

            // Backend doesn't accept "" as an empty value, so we need to remove it. It _does_ accept empty arrays, but removing those too for cleaner props.
            // TODO: this should probably be done in store, but this particular component doesn't have a store binding yet.
            for (var key in cleanFormData) {
                // Should allow null values
                if (/* cleanFormData[key] === null || */ cleanFormData[key] === undefined || cleanFormData[key] === "")
                    delete cleanFormData[key];
                else if (Array.isArray(cleanFormData[key])) {
                    cleanFormData[key] = cleanFormData[key].filter((el) => {
                        return el !== "";
                    });
                    if (cleanFormData[key] === []) delete cleanFormData[key];
                }
            }

            try {
                await this.$store.dispatch("operation/operationsanmalan/save", cleanFormData);
            } catch (e) {
                return;
            }

            await this.$store.dispatch("tabs/closeTab", this.tabId);
        },

        async loadOpAnmalan(pk) {
            const config = getConfig({}, "Operationsanmälan");
            return klinikenApi
                .get("/operation/anmalan/" + pk + "/", config)
                .then((response) => {
                    this.formData = response.data;

                    if (response.data.typoperation) {
                        const typoperationId = response.data.typoperation;
                        klinikenApi
                            .get("/operation/typoperationer/" + typoperationId + "/", config)
                            .then((response) => {
                                this.preselectedTypoperation = {
                                    id: response.data.id,
                                    displayName: response.data.namn,
                                };
                            })
                            .catch((e) => {
                            });
                    }

                })
                .catch((e) => {
                    openDialog(
                        "Kunde inte uppdatera operationsregistreringar från servern. " + getErrorMessage(e),
                        "warning",
                    );
                });
        },
    },
    async created() {
        const response = await klinikenApi.get(`core/avtal/`, getConfig());
        this.avtal = response.data;

        if (this.id) {
            this.loadOpAnmalan(this.id);
            this.isEditing = true;
        } else {
            this.isEditing = false;
            this.formData.status = "Att hantera";
        }

        this.defaultFormData = cloneDeep(this.formData);
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";
@import "@/style/vendors/bootstrap";
@import "@/style/deprecated_main";

#op-registration-details {
    position: relative;
    max-width: 1100px;
}

h2 {
    padding-left: 10px;
}

.form-group-border {
    border-top: 1px solid #6fb8d2;
    border-bottom: 1px solid #6fb8d2;
    padding-top: 10px;
}

.form-group-first {
    border-left: 1px solid #6fb8d2;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.form-group-last {
    border-right: 1px solid #6fb8d2;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.typoperationer-info-image {
    margin-right: 2px;
    margin-left: 6px;
    margin-top: -6px;
    opacity: 0.65;
}

.formulate-input {
    width: 485px;
    margin: 0 10px;

    ::v-deep label {
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        color: #277692;
    }
}
</style>
