<template>
    <FormulateForm class="avboka-operation" v-model="formData" @submit="submit" autocomplete="off">
        <div class="patient-dialog-container">
            <DialogAktivPatient :patient="patient" />

            <DialogAnmalaninfo :anmalaninfo="anmalaninfo" />
        </div>

        <div class="form">
            <FormulateInput
                validation="required"
                type="vueselect"
                :options="sorteradeOrsaker"
                label="Orsak till avboka eller stryk *"
                name="orsak"
            />

            <FormulateInput
                :disabled="formData.orsak && formData.orsak === '0'"
                type="vueselect"
                :options="sorteradeForklaringar"
                name="forklaring"
                label="Förklaring"
            />

            <FormulateInput
                :disabled="formData.orsak && formData.orsak === '0'"
                type="textarea"
                label="Kommentar"
                name="kommentar"
            />

            <FormulateInput
                :disabled="(formData.orsak && formData.orsak === '0') || status !== 'Bokad'"
                :type="status !== 'Bokad' ? 'select' : 'vueselect'"
                :options="[
                    { value: 'NEJ', label: 'Nej, stryk inte från väntelista' },
                    { value: 'JA', label: 'Ja, stryk från väntelista' },
                ]"
                name="avfors_vantelista"
                label="Vill du även stryka operationen från väntelistan? *"
                validation="required"
                validation-name="Fältet"
                :readonly="status !== 'Bokad'"
            />
        </div>

        <ButtonGroup>
            <Button size="primary" variant="alert" :disabled="!avboka" type="submit">Avboka operation</Button>
            <Button size="primary" variant="alert" :disabled="avboka" type="submit">Stryk operation</Button>
            <Button size="secondary" variant="contour" type="button" @click.prevent="cancel()">Avbryt</Button>
        </ButtonGroup>
    </FormulateForm>
</template>

<script>
import { mapActions } from "vuex";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import dialogEvents from "@/dialogEvents";
import DialogAktivPatient from "./DialogAktivPatient.vue";
import DialogAnmalaninfo from "./DialogAnmalaninfo.vue";
import { getConfig, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

export default {
    name: "AvBokaEllerStrykOp",
    components: {
        Button,
        ButtonGroup,
        DialogAktivPatient,
        DialogAnmalaninfo,
    },
    props: {
        value: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            params: this.$attrs.response,
            operationsanmalan: {},
            status: "",
            patient: {
                personId: "",
                namn: "",
            },
            anmalaninfo: {
                datum: "",
                tid: "",
                huvudoperator: "",
                huvudoperationskod: "",
            },
            strykningsorsaker: [],
            formData: {},
        };
    },
    computed: {
        avboka() {
            return this.formData.avfors_vantelista !== "JA";
        },
        sorteradeOrsaker() {
            return this.strykningsorsaker
                .filter((item) => item.niva2 === null || item.niva2.length === 0)
                .map((item) => ({
                    value: item.id,
                    label: `${item.displayName}`,
                }));
        },
        sorteradeForklaringar() {
            const orsak = this.strykningsorsaker.find((o) => `${o.id}` === `${this.formData.orsak}`);
            if (orsak !== undefined) {
                return this.strykningsorsaker
                    .filter((item) => {
                        if (item.niva2 === null || item.niva2.length === 0) return false;
                        return item.niva1 === orsak.code;
                    })
                    .map((item) => ({
                        value: item.id,
                        label: `${item.displayName}`,
                    }));
            } else {
                return [];
            }
        },
    },
    methods: {
        ...mapActions("operation/operationsanmalan", ["avbokaOperationAnmalan"]),
        cancel() {
            dialogEvents.$emit("closePopup");
            this.$emit("closePopup");
            this.$emit("close");
        },
        async submit(formData) {
            if (formData.avfors_vantelista === "Nej") {
                delete this.formData.forklaring;
            }

            const strykningsorsak = this.formData.forklaring !== "" ? this.formData.forklaring : this.formData.orsak;

            const payload = {
                id: this.operationsanmalan.id,
                strykningsorsak: strykningsorsak,
                strykningskommentar: this.formData.kommentar,
                avfors_vantelista: this.formData.avfors_vantelista,
            };
            await this.avbokaOperationAnmalan(payload);
            this.cancel();
        },
    },

    async created() {
        try {
            let response = await klinikenApi.get("/operation/kodverk/strykningsorsaker/", getConfig());
            this.strykningsorsaker = response.data.results;
        } catch (e) {
            openDialog("Kunde inte hämta strykningsorsaker från servern. ", "error");
        }

        this.operationsanmalan = this.params?.listItem ?? this.value;

        this.patient.personId = this.operationsanmalan.patient_display.personId;
        this.patient.namn = this.operationsanmalan.patient_display.namn;
        this.anmalaninfo.datum = this.operationsanmalan.current_bokad_operation_datum;
        this.anmalaninfo.tid = this.operationsanmalan.current_bokad_operation_tid;
        this.anmalaninfo.huvudoperator = this.operationsanmalan.current_bokad_huvudoperator_display.user_display.name;
        this.anmalaninfo.huvudoperationskod = `${this.operationsanmalan.huvudoperationskod_display.code} ${this.operationsanmalan.huvudoperationskod_display.klassdel_display} ${this.operationsanmalan.huvudoperationskod_display.displayName}`;

        this.formData.strykningskommentar = this.operationsanmalan.strykningskommentar;
        this.formData.avfors_vantelista = this.operationsanmalan.avfors_vantelista;

        this.status = this.operationsanmalan.status;
        if (this.status === "Bokad") {
            this.formData.orsak = null;
            this.formData.forklaring = null;
            this.formData.avfors_vantelista = "NEJ";
        } else {
            this.formData.orsak = null;
            this.formData.forklaring = null;
            this.formData.avfors_vantelista = "JA";
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/modal.scss";

::v-deep {
    select[readonly] {
        pointer-events: none;
    }
}

.avboka-operation {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: inherit;
    padding: inherit;
    padding-bottom: 0px;
    padding-top: 0px;

    .patient-dialog-container {
        padding: 0px 50px;
        display: grid;
        gap: 20px;
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 44px;
        padding: $dialog_box_padding;
    }
    ::v-deep(.button-group) {
        margin: 0px;
        padding: 0px 50px;

        button {
            white-space: nowrap;
        }
    }
}
</style>
