<template>
    <div class="journalanteckning">
        <div class="header-container">
            <h2 v-if="anteckning.pk">Redigera journalanteckning</h2>
            <h2 v-else>Skapa ny journalanteckning</h2>
            <button type="button" @click="openJournalanteckningsmallar">
                <img src="@/assets/plus_circle_blue.svg" /><span>Hantera Journalanteckningsmallar</span>
            </button>
        </div>

        <form @submit.prevent id="journaltyp" :class="{ 'disabled-form': lockJournaltyp }">
            <div>
                <div>
                    <span>Journaltyp</span>
                    <select-widget
                        v-if="anteckningsTypOptions.length"
                        name="journaltyp"
                        :disabled="Boolean(anteckning.pk)"
                        v-model="anteckning.anteckningstyp"
                        :options="anteckningsTypOptions"
                        @input="inputAnteckningstyp"
                    />
                </div>
                <br />
                <div>
                    <span>Välj Journalmall (frivillig)</span>
                    <select-widget
                        :disabled="!Boolean(anteckning.anteckningstyp)"
                        :style="{ opacity: !Boolean(anteckning.anteckningstyp) || lockJournaltyp ? 0.4 : 1 }"
                        name="journalanteckningsmall"
                        v-model="journalanteckningsmallId"
                        :options="journalanteckningsmallar"
                    />
                </div>
                <br />
                <Button
                    size="secondary"
                    @click="updateForm"
                    :disabled="!Boolean(this.anteckning?.anteckningstyp) || lockJournaltyp"
                >
                    Skapa
                </Button>
            </div>
            <div v-if="lockJournaltyp && !anteckning.pk" class="right-opaque">
                <button type="button" role="button" class="btn btn-outline-secondary" @click="unlockJournalTyp">
                    Ändra journaltyp
                </button>
            </div>
        </form>
        <template v-if="anteckning.sektioner.length > 0">
            <h2>{{ journalTypNamn }}</h2>
            <form @submit.prevent id="journal-author">
                <div class="k3-form form-group">
                    <input-wrapper
                        class="colspan c4"
                        :component="widgets.SelectWidget"
                        label="Signeringsansvarig"
                        :apisearch="apisearch"
                        :options="options"
                        name="accountableHealthcareProfessional"
                        v-model="anteckning.accountableHealthcareProfessional"
                        :required="true"
                        :map="mapSigneringsAnsvariga"
                        :extraParams="{
                            signeringsansvarig: true,
                            is_active: true,
                        }"
                    />
                </div>
            </form>
            <hr class="border" />
        </template>
        <form @submit.prevent id="journal-details">
            <div class="k3-journal">
                <!-- eslint-disable-next-line -->
                <template v-if="anteckning.anteckningstyp">
                    <!--
          NOTE: including sektion.disabled in key is, unlike in child components, not essential here since root elements get reactive updates anyway. Added anyway
          to harmonize behaviour.
      -->
                    <recursive-form
                        v-for="(sektion, index) in anteckning.sektioner"
                        :rootEl="rootEl"
                        :key="'sokord-' + sektion.sokord.namn + '-' + index + '-' + updateKey"
                        :sektion="sektion"
                        :depth="0"
                    />
                </template>
            </div>

            <template v-if="anteckning.anteckningstyp && lockJournaltyp">
                <hr class="border" />
                <br />
                <div class="warning-box">
                    <checkbox-widget
                        name="approvedForPatient"
                        label="Visa anteckning på 1177/Journalen"
                        v-model="anteckning.approvedForPatient"
                        :disabled="!selectedAnteckningstyp.visas_pa_npo"
                    />
                </div>
            </template>

            <ButtonGroup>
                <Button @click="save" :disabled="!isSaveable || lockJournaltyp === false"> Spara och stäng</Button>
                <Button
                    @click="sign"
                    :disabled="!isSignable || !anteckning.anteckningstyp || !isAccountableHealthcareProfessional"
                >
                    <img src="@/assets/sign_white.svg" />Signera och stäng
                </Button>
                <Button size="secondary" variant="contour" @click="cancel">Avbryt</Button>
            </ButtonGroup>
        </form>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseTab from "@/tabs/mixins/BaseTab";
import RecursiveForm from "@/tabs/Journalanteckningar/RecursiveForm.vue";
import widgets from "@/components/widgets/inputs";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import { getConfig, klinikenApi } from "@/api";
import tabEvents from "@/tabEvents";
import { openDialog } from "@/utils";
import dialogEvents from "@/dialogEvents";
import tabIcon from "@/assets/skapa_journal.svg";
import Vue from "vue";
import Journalanteckningsmallar from "@/tabs/Journalanteckningmallar/Journalanteckningsmallar.vue";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import { cloneDeep, isEqual } from "lodash";

const defaultAnteckning = {
    pk: null,
    patient: null,
    sektioner: [],
    authorTime: null,
    signatureTime: null,
    accountableHealthcareProfessional: null,
    is_nullified: null,
    nullifiedReason: null,
    is_signed: null,
    anteckningstyp: null,
};

export default {
    extends: BaseTab,
    name: "Journalanteckning",
    icon: tabIcon,
    tabName: "Ny journalanteckning",
    patientBound: true,
    components: {
        ...widgets,
        RecursiveForm,
        InputWrapper,
        Button,
        ButtonGroup,
    },

    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            defaultAnteckning: cloneDeep(defaultAnteckning),
            anteckning: cloneDeep(defaultAnteckning),
            initialized: false,
            lockJournaltyp: false,
            apisearch: null,
            options: null,
            widgets: widgets,
            rootEl: this,
            updateKey: 0, // Used to update :key and force re-rendering of root sektioner
            journalanteckningsmallId: NaN,
            journalanteckningsmallar: [],
            hasSigneringsansvarig: false,
            selectedAnteckningstyp: null,
        };
    },

    computed: {
        ...mapGetters("journalanteckningar", ["isSaveable", "isSignable", "journalTyper"]),
        ...mapGetters("userData", ["currentProfileId"]),
        hasUnsavedData() {
            return !isEqual(this.defaultAnteckning, this.anteckning);
        },
        // Maps and filters journalTyp-dropdown
        anteckningsTypOptions() {
            if (this.anteckning.pk)
                return this.journalTyper.map((item) => {
                    return { text: item.namn, value: item.pk };
                });
            else
                return this.journalTyper
                    .filter((el) => {
                        return el.is_valbar && el.is_active;
                    })
                    .map((item) => {
                        return { text: item.namn, value: item.pk };
                    });
        },
        journalTypNamn() {
            if (this.anteckning.anteckningstyp) return this.anteckning.anteckningstyp.namn || "";
            return "";
        },
        isAccountableHealthcareProfessional() {
            return this.anteckning.accountableHealthcareProfessional === this.currentProfileId;
        },
    },
    methods: {
        /**
         * HTML validation of inputs by using native Form.checkValidity().
         * Returns true if every input validates, otherwise false.
         * If an input does not validate it gets focus once since live-validation requires an input to have had focus once.
         */
        validateForm(formName) {
            var form = document.getElementById(formName);
            if (form.checkValidity() === true) return true;
            else {
                var list = form.querySelectorAll(":invalid");
                for (var item of list) {
                    item.focus();
                }
                form.querySelector(":invalid").focus();
            }
            return false;
        },
        mapSigneringsAnsvariga(el) {
            let yrkeskategoriCode = el.yrkeskategori?.displayName ? ` - ${el.yrkeskategori?.displayName}` : "";
            let orgUnitName = el.orgUnit?.name ? `, ${el.orgUnit?.name}` : "";
            let text = el.user.name + yrkeskategoriCode + orgUnitName;
            return { value: el.pk, text: text };
        },
        /**
         * Get a list if active accountableHealthcareProfessional with signeringsansvarig from backend and map to Options.
         * The caveat here is that this list should always contain the current accountableHealthcareProfessional, even if that profile
         * is currently inactive or has had signeringsansvarig removed.
         */
        async fetchSigneringsAnsvariga() {
            let options = [];
            const config = {
                ...getConfig(),
                ...{ params: { is_active: true, signeringsansvarig: true } },
            };
            await klinikenApi
                .get("/core/profiles/", config, {
                    params: { is_active: true, signeringsansvarig: true },
                })
                .then((response) => {
                    options = response.data.results.map(this.mapSigneringsAnsvariga);
                })
                .catch((e) => {
                    openDialog("Kunde inte hämta signeringsansvariga från servern. " + e, "warning");
                });
            if (
                options.findIndex((el) => {
                    return el.value === this.anteckning.accountableHealthcareProfessional;
                }) === -1
            )
                options.push({
                    text: this.anteckning.accountableHealthcareProfessional.user.name,
                    value: this.anteckning.accountableHealthcareProfessional.pk,
                });
            this.options = options;
            return new Promise((resolve) => resolve());
        },
        /**
         * Invoked when anteckningstyp changed. This method recursively (via traverseSokordsstruktur) copies the tree structure of
         * anteckningstyp into anteckning, but changes sokordsstruktur to sektioner so that the format and structure matches
         * what backend expects from PUT/POST. This allows for recursive data-binding directly into the anteckning-blob that
         * gets sent to backend. Sort order is also set for future-proofing.
         */
        async updateForm() {
            this.hasSigneringsansvarig = !this.hasSigneringsansvarig;
            this.selectedAnteckningstyp = this.journalTyper.find((el) => {
                return el.pk === this.anteckning.anteckningstyp;
            });
            const response = await klinikenApi.get(
                `anteckning/typer/${this.selectedAnteckningstyp.pk}/`,
                getConfig({})
            );
            let anteckningTemplate = cloneDeep(response.data);
            this.lockJournaltyp = true;
            this.traverseSokordsstruktur(anteckningTemplate, this.anteckning);
            this.triggerAllOmSaFields(this.anteckning);

            if (this.anteckning.approvedForPatient == null) {
                if (!this.selectedAnteckningstyp.visas_pa_npo) this.anteckning.approvedForPatient = false;
                else this.anteckning.approvedForPatient = this.selectedAnteckningstyp.visas_pa_1177 ?? false;
            }

            this.applyMall();
        },
        traverseSokordsstruktur(node, anteckning) {
            anteckning.sektioner = [];
            for (let i = 0; i < node.sokordsstruktur.length; i++) {
                let sektion = {
                    sokord: node.sokordsstruktur[i].sokord,
                    sokordsstruktur: cloneDeep(node.sokordsstruktur[i]),
                    text: "",
                    sortering: i,
                    updateKey: 0,
                };
                delete sektion.sokordsstruktur.sokordsstruktur;
                anteckning.sektioner.push(sektion);
                this.traverseSokordsstruktur(node.sokordsstruktur[i], anteckning.sektioner[i]);
            }
        },
        /**
         * Itererar igenom sektioner i skadekortsanteckningen, och kopierar in dem i
         * en ny anteckning som skapats från anteckningstypen.
         */
        newAnteckningFromSkadekort(storeAnteckning) {
            let newAnteckning = cloneDeep(storeAnteckning);
            let anteckningTemplate = newAnteckning.anteckningstyp;
            this.traverseSokordsstruktur(anteckningTemplate, newAnteckning);
            for (let i = 0; i < storeAnteckning.sektioner.length; i++) {
                let sektion = storeAnteckning.sektioner[i];
                let oldSektionIndex = newAnteckning.sektioner.findIndex((el) => {
                    return sektion.sokordsstruktur.pk === el.sokordsstruktur.pk;
                });
                if (oldSektionIndex !== -1) newAnteckning.sektioner[oldSektionIndex] = sektion;
            }
            return newAnteckning;
        },

        applyMall() {
            let mall = this.journalanteckningsmallar.find((el) => el.id === this.journalanteckningsmallId);
            if (!mall) return;
            this.applyMallToSektioner(mall.struktur, this.anteckning.sektioner);
        },
        applyMallToSektioner(mall, sektioner = []) {
            if (!mall) return;
            if (!sektioner) return;

            sektioner.forEach((sektion) => {
                if (Array.isArray(mall)) {
                    mall.forEach((item) => {
                        if (
                            typeof item[sektion.sokordsstruktur.pk] === "string" ||
                            typeof item[sektion.sokordsstruktur.pk] === "number"
                        ) {
                            sektion.text = item[sektion.sokordsstruktur.pk];
                        }
                        if (`group_${sektion.sokordsstruktur.pk}` in item) {
                            this.applyMallToSektioner(item[`group_${sektion.sokordsstruktur.pk}`], sektion.sektioner);
                        }
                    });
                } else if (typeof mall === "object") {
                    if (
                        typeof mall[sektion.sokordsstruktur.pk] === "string" ||
                        typeof mall[sektion.sokordsstruktur.pk] === "number"
                    ) {
                        sektion.text = mall[sektion.sokordsstruktur.pk];
                    } else if (Array.isArray(mall[sektion.sokordsstruktur.pk])) {
                        this.applyMallToSektioner(mall[sektion.sokordsstruktur.pk], sektion.sektioner);
                    }
                }
            });
        },
        async inputAnteckningstyp(anteckningstyp = NaN) {
            if (anteckningstyp) {
                let response = await klinikenApi.get(
                    `/anteckning/journalanteckningsmallar/`,
                    getConfig({ params: { anteckningstyp } })
                );

                if (response.status === 200) {
                    this.journalanteckningsmallar = response.data.results.map((obj) => ({
                        value: obj.id,
                        text: obj.namn,
                        ...obj,
                    }));
                }
            }
        },
        async save() {
            let details = cloneDeep(this.anteckning);
            this.$store.commit("journalanteckningar/updateAnteckning", details);
            await this.$store.commit("tabs/displaySpinner", {
                id: this.tabId,
                flag: true,
            });
            await this.$store.dispatch("journalanteckningar/save");
            let anteckning = cloneDeep(this.$store.getters["journalanteckningar/anteckning"]);
            await this.$store.commit("tabs/displaySpinner", {
                id: this.tabId,
                flag: false,
            });
            tabEvents.$emit("refreshJournalanteckningar", anteckning);
            let status = this.$store.getters["journalanteckningar/status"];
            if (status === "saved") await this.close();
        },
        async sign() {
            let validated = this.validateForm("journal-details") && this.validateForm("journal-author");

            if (!validated) return;

            let details = cloneDeep(this.anteckning);

            this.$store.commit("journalanteckningar/updateAnteckning", details);

            await this.$store.commit("tabs/displaySpinner", {
                id: this.tabId,
                flag: true,
            });

            await this.$store.dispatch("journalanteckningar/sign");

            let anteckning = cloneDeep(this.$store.getters["journalanteckningar/anteckning"]);

            await this.$store.commit("tabs/displaySpinner", {
                id: this.tabId,
                flag: false,
            });

            tabEvents.$emit("refreshJournalanteckningar", anteckning);

            let status = this.$store.getters["journalanteckningar/status"];

            if (status === "signed") await this.close();

            if (status === "saved") dialogEvents.$emit("closePopup");
        },
        cancel() {
            this.closeTab(this.hasUnsavedData);
        },
        async close() {
            await this.$store.dispatch("tabs/closeTab", this.tabId);
        },
        unlockJournalTyp() {
            if (this.hasUnsavedData) {
                dialogEvents.$emit("open", {
                    type: "warning",
                    description: "Om du byter journaltyp kommer allt data försvinna. Vill du fortsätta?",
                    buttons: [
                        {
                            title: "Nej",
                            type: "secondary",
                        },
                        {
                            title: "Ja",
                            type: "primary",
                            action: () => {
                                const anteckning = {
                                    pk: null,
                                    patient: null,
                                    sektioner: [],
                                    authorTime: null,
                                    signatureTime: null,
                                    accountableHealthcareProfessional: null,
                                    is_nullified: null,
                                    nullifiedReason: null,
                                    is_signed: null,
                                };

                                this.lockJournaltyp = false;
                                this.anteckning = cloneDeep(anteckning);
                                this.journalanteckningsmallar = [];
                                this.journalanteckningsmallId = NaN;
                                this.hasSigneringsansvarig = !this.hasSigneringsansvarig;

                                document.getElementById("journal-details").reset();
                                document.getElementById("journal-author").reset();
                            },
                        },
                    ],
                });
            } else {
                const anteckning = {
                    pk: null,
                    patient: null,
                    sektioner: [],
                    authorTime: null,
                    signatureTime: null,
                    accountableHealthcareProfessional: null,
                    is_nullified: null,
                    nullifiedReason: null,
                    is_signed: null,
                };

                this.lockJournaltyp = false;
                this.anteckning = cloneDeep(anteckning);
                this.journalanteckningsmallar = [];
                this.journalanteckningsmallId = NaN;
                this.hasSigneringsansvarig = !this.hasSigneringsansvarig;

                document.getElementById("journal-details").reset();
                document.getElementById("journal-author").reset();
            }
        },
        findSektionBySokordsstruktur(node, sokordsstrukturPk) {
            if (node.sokordsstruktur && node.sokordsstruktur.pk === sokordsstrukturPk) return node;
            else if (node.sektioner) {
                let result = null;
                for (let i = 0; i < node.sektioner.length; i++) {
                    let hit = this.findSektionBySokordsstruktur(node.sektioner[i], sokordsstrukturPk);
                    if (hit) result = hit;
                }
                return result;
            }
            return null;
        },
        clearText(node) {
            Vue.set(node, "text", "");
            for (let i = 0; i < node.sektioner.length; i++) this.clearText(node.sektioner[i]);
        },
        updateOmSa(omSa, clearText = true) {
            let key = omSa.selectedValue;
            let keys = Object.keys(omSa.omSa);

            //move selectedValue/key to the end of the keys array to ensure that it runs last in for loop
            let selectedValueIndex = keys.indexOf(key);
            if (selectedValueIndex !== -1) {
                keys.splice(selectedValueIndex, 1);
                keys.push(key);
            }

            for (let i = 0; i < keys.length; i++) {
                let pks = omSa.omSa[keys[i]];
                for (let j = 0; j < pks.length; j++) {
                    let sektion = this.findSektionBySokordsstruktur(this.anteckning, pks[j]);
                    if (keys[i] === key) {
                        Vue.set(sektion, "disabled", false);
                    } else {
                        Vue.set(sektion, "disabled", true);

                        /**
                         * Children are automatically hidden by setting disabled, but other nodes which are not children
                         * are not affected. This invokes updateOmSa on all linked nodes.
                         */
                        if (sektion.sokordsstruktur.om_sa) {
                            let siblingOmSa = {
                                selectedValue: "",
                                omSa: JSON.parse(sektion.sokordsstruktur.om_sa),
                            };
                            this.updateOmSa(siblingOmSa);
                        }
                        if (clearText) this.clearText(sektion);
                    }
                    // update :key on sektioner. Only this.updateKey is strictly needed, but setting on subsektioner as well
                    this.updateKey += 1;
                    Vue.set(sektion, "updateKey", sektion.updateKey + 1);
                }
            }
        },
        triggerAllOmSaFields(node) {
            if (node.sokordsstruktur && node.sokordsstruktur.om_sa) {
                let omSa = {
                    selectedValue: node.text,
                    omSa: JSON.parse(node.sokordsstruktur.om_sa),
                };
                this.updateOmSa(omSa, false);
            }
            for (let i = 0; i < node.sektioner.length; i++) this.triggerAllOmSaFields(node.sektioner[i]);
        },
        openJournalanteckningsmallar() {
            this.$store.dispatch("tabs/openTab", {
                component: Journalanteckningsmallar,
            });
        },
    },
    async created() {
        await this.$store.dispatch("journalanteckningar/loadJournalTyper");

        if (this.parameters && this.parameters.pk)
            // inhopp from external site
            await this.$store.dispatch("journalanteckningar/getJournalanteckning", this.parameters.pk);

        let anteckning = this.$store.getters["journalanteckningar/anteckning"];
        /**
         * Akut fix. Ska ersättas helt iom redigering av anteckingstyper. Nya skadekort identifieras
         * genom att de inte har lika många sektioner i rotnivå som dess anteckningstyp har sökordsstrukturer.
         */
        if (
            anteckning &&
            anteckning.pk &&
            anteckning.anteckningstyp.sokordsstruktur.length !== anteckning.sektioner.length
        ) {
            this.anteckning = this.newAnteckningFromSkadekort(anteckning);
        } else this.anteckning = cloneDeep(anteckning);

        if (typeof this.anteckning.anteckningstyp === "object") {
            this.anteckning.anteckningstyp = this.anteckning.anteckningstyp.pk;
        }

        if (this.anteckning.anteckningstyp) {
            this.lockJournaltyp = true;
            this.selectedAnteckningstyp = this.journalTyper.find((el) => {
                return el.pk === this.anteckning.anteckningstyp;
            });
        }

        if (this.anteckning && this.anteckning.pk) {
            this.$store.commit("tabs/setTabName", {
                id: this.tabId,
                name: "Redigera journalanteckning",
            });
            if (!this.anteckning.accountableHealthcareProfessional) {
                this.apisearch = "/core/profiles/";
            } else {
                await this.fetchSigneringsAnsvariga();
            }
        } else this.apisearch = "/core/profiles/";

        this.$on("updateOmSa", this.updateOmSa);

        this.initialized = true;
        if (this.anteckning.pk) this.triggerAllOmSaFields(this.anteckning);

        this.defaultAnteckning = cloneDeep(this.anteckning);
    },
    async beforeDestroy() {
        this.$store.dispatch("journalanteckningar/clearAnteckning");
    },
};
</script>
<style lang="sass" scoped>
@import "@/style/variables"
@import @/style/deprecated_main
@import @/style/_variables

@-ms-keyframes spin
    0%
        -ms-transform: rotate(0deg)
    100%
        -ms-transform: rotate(360deg)

@-moz-keyframes spin
    0%
        -moz-transform: rotate(0deg)
    100%
        -moz-transform: rotate(360deg)

@-webkit-keyframes spin
    0%
        -webkit-transform: rotate(0deg)
    100%
        -webkit-transform: rotate(360deg)

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

.journalanteckning

    .header-container
        display: flex
        gap: 60px
        width: 720px
        justify-content: space-between

        h2
            margin: 0 !important
            font-size: 26px
            line-height: 28px
            font-weight: 400
            color: #1f6882

        button
            background: transparent
            border: none
            display: flex
            align-items: center
            justify-content: center


            img
                margin-right: 14px

            span
                color: $color-icons-dark
                font-family: Roboto Medium, serif

        &:focus
            outline: none
            box-shadow: none

            &:hover
                background: transparent !important

            &:focus
                border: 2px solid $color-input-focus

    .warning-box
        box-sizing: border-box
        border: 1px solid #EFC2D4
        border-radius: 3px
        background-color: #F7E1E9
        color: #354052
        display: flex
        padding: 20px
        margin-bottom: 30px
        max-width: 720px

        ::v-deep label
            line-height: 24px

            .label
                font-size: 18px
                color: #354052

.overlay
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: calc(100vh - 157px)
    z-index: 1
    pointer-events: all
    overflow: hidden

    .spinner-wrapper
        left: 50%
        top: 50%
        position: absolute
        transform: translate(-50%, -50%)

        .spin
            -webkit-animation-name: spin
            -webkit-animation-duration: 4000ms
            -webkit-animation-iteration-count: infinite
            -webkit-animation-timing-function: linear
            -moz-animation-name: spin
            -moz-animation-duration: 4000ms
            -moz-animation-iteration-count: infinite
            -moz-animation-timing-function: linear
            -ms-animation-name: spin
            -ms-animation-duration: 4000ms
            -ms-animation-iteration-count: infinite
            -ms-animation-timing-function: linear
            animation-name: spin
            animation-duration: 4000ms
            animation-iteration-count: infinite
            animation-timing-function: linear

.note-semi-opaque
    opacity: 0.5

#journaltyp
    padding: 10px 20px 20px 20px
    // margin: 40px 10px 40px 10px
    margin: 40px 0
    background-color: $color-blue-light
    width: 720px

    &.disabled-form
        background-color: rgba(235, 246, 248, 0.4)

        & ::v-deep input
            pointer-events: none

        .suggest-wrap, span
            opacity: 0.4

    .right-opaque
        width: 100%
        text-align: right

        button
            margin-top: 20px

.k3-journal
    padding: 10px
    max-width: 720px

.border
    margin-left: 10px
    height: 3px
    width: 720px
    background-color: #E4E8F0
    border: 0

.k3-journal
    textarea
        height: 46px

::v-deep .input-wrapper
    span
        font-weight: 400
        color: #277692
        font-size: 20px
        line-height: 21px
        margin-bottom: 5px
</style>
