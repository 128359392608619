<template>
    <!--
        CustomActionTable är i allt väsentligt en vanlig ActionTable, men renderar alla celler som CustomTableCell. Information om hur
        template ska se ut finns i den filen. CustomTable kan behöva anpassas för valbara rader o.d. Förhoppningsvis kan den ersätta både
        ActionTable och SelectActionTable i ett senare skede.
    -->
    <div class="action-table">
        <button @click="collapse = !collapse" v-if="collapseLabel" class="btn btn-default btn-block" type="button">
            <span :class="toggleCaret"></span>
            <span role="presentation">{{ collapseLabel }}</span>
            <span class="label" :key="label" v-for="label in labels">{{ label }}</span>
        </button>
        <collapse-transition>
            <div v-show="!collapse || !collapseLabel">
                <table class="table">
                    <thead>
                        <tr>
                            <!--
                            Sortable should be reworked.
                        -->
                            <th v-for="col in columns" :key="col.name" class="table-heading">
                                <template v-if="!col.hasSorting">
                                    <span class="col-title">
                                        {{ col.title }}
                                    </span>
                                </template>

                                <template v-else>
                                    <button
                                        type="button"
                                        class="style-sorting-button"
                                        @click="sort(col.name)"
                                        @mouseover="hoverColumn = col.name"
                                        @mouseleave="hoverColumn = ''"
                                    >
                                        <span class="col-title col-title-button">
                                            {{ col.title }}
                                        </span>
                                        <img :src="addIcon(col.name)" class="dropdown-icon" />
                                    </button>
                                </template>
                            </th>
                            <th v-if="actions" class="table-heading"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in items"
                            :actions="actions"
                            :columns="columns"
                            :key="item.id"
                            :class="{
                                'table-row': true,
                                'disabled-row': getDisabledRow(item),
                                'selectable-row': selectableRows,
                                'selected-row': selectedItem === item,
                            }"
                            @click.prevent="emitSelect($event, item)"
                        >
                            <template v-for="(col, index) in columns">
                                <custom-table-cell
                                    v-if="typeof col.template === 'function'"
                                    :template="col.template(item)"
                                    :key="col.name + '-' + index"
                                    class="custom-table-body-td"
                                />
                                <!-- Also the default template in custom-table-cell, but reducing the number of child components for eaiser debugging, performance impact is negligible -->
                                <td v-else :key="`${col.name} + '-' + ${index}`" class="custom-table-body-td">
                                    <span>{{ item[col.name] }}</span>
                                </td>
                            </template>
                            <td class="actions">
                                <actions-dropdown
                                    v-if="getActions(item).length"
                                    :id="item.id"
                                    :actions="getActions(item)"
                                    :item="item"
                                    @dropdownAction="dropdownAction"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </collapse-transition>
    </div>
</template>

<script>
import ActionTable from "@/components/generic/ActionTable.vue";
import ActionsDropdown from "@/components/generic/ActionsDropdown.vue";
import CustomTableCell from "@/components/generic/CustomTableCell.vue";
import { cloneDeep } from "lodash";
import multisort from "multisort";
import SortTableAscending from "@/assets/sort_folder/sort_table_ascending.svg";
import SortTableDescending from "@/assets/sort_folder/sort_table_descending.svg";
import SortTableHover from "@/assets/sort_folder/sort_table_hover.svg";
import SortTableEmpty from "@/assets/sort_folder/sort_table_empty.svg";

export default {
    extends: ActionTable,
    components: {
        ActionsDropdown,
        CustomTableCell,
    },
    data() {
        return {
            numberClicks: 0,
            sortColumn: "",
            sortOrder: "",
            hoverColumn: "",
            selectedRow: NaN,
        };
    },
    computed: {
        sortColumns() {
            return this.columns
                .filter((el) => {
                    return typeof el.sortOrder !== "undefined" && el.sortOrder !== null;
                })
                .sort((a, b) => {
                    return a.sortOrder - b.sortOrder;
                });
        },
        sortedItems() {
            let sortedItems = cloneDeep(this.items);
            let criteria = [];
            this.sortColumns.forEach((col) => {
                if (col.sortMethod) criteria.push(col.sortMethod);
                else criteria.push(col.name);
            });

            multisort(sortedItems, criteria);

            return sortedItems;
        },
    },
    methods: {
        hideMenu(item) {
            return item.status === "Genomförd" ? false : true;
        },
        getActions(item) {
            return this.actions.filter((el) => {
                if (el.condition) {
                    return el.condition(item);
                } else return true;
            });
        },
        sort(name) {
            if (this.sortColumn === name) {
                this.sortOrder = this.sortOrder === "-" ? "" : "-";
            } else {
                this.sortColumn = name;
                this.sortOrder = "";
            }

            this.$emit("sortingButton", { column: this.sortColumn, order: this.sortOrder });
        },
        addIcon(colName) {
            if (this.sortColumn === colName) {
                if (this.sortOrder === "") {
                    return SortTableAscending;
                } else {
                    return SortTableDescending;
                }
            } else {
                if (this.hoverColumn === colName) {
                    return SortTableHover;
                } else {
                    return SortTableEmpty;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";
@import "@/style/vendors/bootstrap";
@import "@/style/deprecated_main";

.action-table {
    table {
        thead span {
            font-family: Roboto Medium;
        }
        thead th {
            border-top: none;
        }
        .custom-table-body-td {
            font-size: 14px;
            line-height: 16px;
            font-family: Roboto;
            padding: 8px 10px;
            vertical-align: middle;
        }
    }
}
.style-sorting-button {
    display: flex;
    background: transparent;
    border: none;
    padding: 2px;
}

.style-sorting-button > img {
    padding-left: 10px;
    height: 20px;
    width: 20px;
}

.style-sorting-button:hover {
    border-radius: 5px;
    background-color: #cee9f3;
}
.col-title {
    color: #277692;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    white-space: nowrap;
}
.col-title-button {
    margin: 2px;
}
.selected-row {
    border-left: 4px solid #277692;
    background-color: #ebf6f8;
}
.table-row {
    cursor: pointer;
}
</style>
