<template>
    <div class="screen">
        <div class="center">
            <div id="kliniken-logo" style="background-color: #5baecb; opacity: 1; text-align: center">
                <img
                    style="margin-left: auto; margin-right: auto"
                    src="@/assets/kliniken-logo.svg"
                    alt="Kliniken patientsystem"
                />
            </div>
            <div class="login"></div>
            <login></login>
            <div class="login"></div>
        </div>
        <div class="separator"></div>
        <div class="row maxsize">
            <div class="col-lg-2"></div>
            <div class="col-md-8 col-lg-3 offset-1 offset-lg-0 large-login-text">
                Patientsystemet med hjärta - byggt från användarbehov
            </div>
            <div class="col-xs-12 col-lg-1"></div>
            <div class="col-md-8 col-lg-5 offset-1 offset-lg-0 login-text">
                Kliniken är ditt kompletta patientadministrativa system för både öppen och sluten vård. Vi strävar efter
                att ständigt utveckla Kliniken baserat på dina behov och värdesätter vår nära dialog med dig som
                användare.
                <br /><br />
                Hör av dig till vår support om du har frågor eller återkoppling!
            </div>
        </div>
        <div class="separator"></div>
        <div class="bottom row">
            <div class="col-lg-1"></div>
            <div class="col-8 medium-login-text col-lg-2 bottom-light">Kontakta vår support så hjälper vi dig</div>
            <div class="col-8 small-login-text col-lg-3 bottom-light">
                <img src="@/assets/epost.svg" alt="Epost" /><span class="small-login-text"
                    >&nbsp; support@kliniksystem.se</span
                >
            </div>
            <div class="col-8 small-login-text col-lg-3 bottom-light">
                <img src="@/assets/telefon.svg" alt="Telefon" /><span class="small-login-text"
                    >&nbsp; 0701-650 690</span
                >
            </div>
            <div class="col-8 medium-login-text col-lg-2 bottom-light pull-right">
                Vill du ha en demo?
                <br />
                <span class="small-login-text">info@kliniksystem.se<br />0701-650 690</span>
            </div>
        </div>
    </div>
</template>

<script>
import Login from "@/components/Login.vue";
export default {
    name: "StartVy",
    components: {
        Login,
    },
    methods: {},
};
</script>

<style lang="sass" scoped>
@import @/style/variables
@import @/style/deprecated_main

/* Bootstrap - Start */
@import bootstrap/scss/functions
@import bootstrap/scss/variables
@import bootstrap/scss/mixins
@import bootstrap/scss/root
@import bootstrap/scss/reboot

@import bootstrap/scss/grid
/* Bootstrap - End */

.body
    height: 3000px
.login
    margin-top: 28px
.screen
    overflow-x: hidden
    height: 1711px
    background-color: #5BAECB
    background-image: url("@/assets/Kliniken_sidfot_med_illu.svg")
    background-size: 3000px 760px
    background-repeat: no-repeat
    background-position: bottom

.bottom
    position: absolute
    bottom: 100px
    width: 100%

.bottom-light
    padding-bottom: 25px
    //position: absolute
    bottom: 0px
    //width: 100%
    text-align: left
    align-self: flex-end
.bottom-oriented
    //position: absolute
    //width: 100%
    //text-align: left
    //bottom: 129px
.small-login-text
    color: #FFFFFF
    font-family: Roboto
    font-size: 22px
    letter-spacing: 0
    line-height: 23px
.medium-login-text
    color: #FFFFFF
    font-family: Roboto
    font-size: 30px
    letter-spacing: 0
    line-height: 38px
    text-align: left
.separator
    height: 100px
.large-login-text
    color: #FFFFFF
    font-family: Roboto
    font-size: 38px
    letter-spacing: 0
    line-height: 50px
    margin-bottom: 16px
.login-text
    color: #FFFFFF
    font-family: Roboto
    font-size: 20px
    letter-spacing: 0
    line-height: 29px
    margin-bottom: 48px
.screen
    width: 100%
    position: relative
#kliniken-logo img
    width: 195px
    height: 100%
    margin-top: 45px

@media screen and (max-width: 1050px)
    .screen
       height: 1800px
    .small-login-text
        font-size: 18px

@media screen and (max-width: 987px)
    .screen
        height: 2000px
        //background-position-y: 100%
        background-image: url("@/assets/Kliniken_sidfot_med_illu_smal.svg")
        background-size: 3000px
    .bottom
        position: relative
    .bottom-light
        margin-left: 8.3333333%
    .small-login-text
        font-size: 22px

@media screen and (max-width: 534px)
    .screen
        height: 2100px

@media screen and (max-width: 459px)
    .screen
        height: 2300px
</style>
