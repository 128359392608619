<template>
    <div class="journalanteckningar">
        <ModalBasic ref="dialog" />
        <PrintableContent
            ref="printablecontent"
            v-if="!historiskJournalanteckning"
            header-height="100px"
            footer-height="50px"
        >
            <template>
                <h1>
                    Journalanteckningar

                    <Button
                        size="secondary"
                        variant="hollow"
                        class="skapa-ny-journalanteckning"
                        @click="openJournalanteckning"
                    >
                        <img src="@/assets/plus_circle_blue.svg" alt="" />
                        Skapa ny journalanteckning
                    </Button>
                </h1>

                <div class="journalanteckningar-body">
                    <div class="journalanteckningar-menu" :class="{ minimized: collapsed }">
                        <div>
                            <div class="journalanteckningar-menu-heading">
                                <div>
                                    <checkbox-widget
                                        name="journalAnteckning"
                                        :value="allJournalanteckningIsSelected"
                                        :handler="selectAllJournalanteckningar"
                                        :disabled="!journalanteckningar.length"
                                    />
                                </div>
                                <div>Välj alla</div>
                                <button
                                    class="journalanteckningar-menu-toggle"
                                    role="button"
                                    @click="collapsed = !collapsed"
                                >
                                    <img src="@/assets/menuclose.svg" alt="" width="29px" height="23px" />
                                </button>
                            </div>

                            <div class="journalanteckningar-menu-list">
                                <div
                                    class="journalanteckningar-menu-list-item"
                                    v-for="(item, index) in filteredJournalanteckningar"
                                    :key="index"
                                    @click="scrollToJournalanteckning(item.pk)"
                                >
                                    <checkbox-widget
                                        :name="item.pk"
                                        :value="journalanteckningIsSelected(item.pk)"
                                        :handler="(event) => toggleSelectJournalanteckning(event.target.name)"
                                    />
                                    <div>
                                        <span class="journalanteckningar-menu-list-item-date">
                                            {{ moment(item.created_at).format("YYYY-MM-DD [kl.] HH:mm") }} </span
                                        ><br />
                                        {{ item.anteckningstyp.namn }} - {{ ansvarigsNamn(item) }},
                                        {{ ansvarigsOrgUnit(item) }}
                                    </div>
                                    <div class="journalanteckningar-menu-list-item-icon">
                                        <img src="@/assets/skadekortjournal.svg" alt="" v-if="item.is_skadekort" />
                                        <status-widget
                                            v-if="getStatus(item)"
                                            :minimized="collapsed"
                                            :status="getStatus(item)"
                                        />
                                    </div>
                                </div>

                                <div v-if="journalanteckningar.length === 0 && journalanteckningarLaddade">
                                    Patienten har inga journalanteckningar. Välj Skapa journalanteckning om du vill
                                    påbörja en ny journalanteckning för patienten.
                                </div>
                            </div>

                            <div class="visa-makulerade" v-show="harMakulerade">
                                <checkbox-widget
                                    name="visaMakulerade"
                                    :value="visaMakulerade"
                                    :handler="toggleVisaMakulerade"
                                />
                                <div class="visa-makulerade-label">
                                    Visa makulerade ({{
                                        journalanteckningar.length - inteMakuleradeJournalanteckningar.length
                                    }})
                                </div>
                            </div>

                            <div class="print-button-wrapper" v-show="selectedJournalanteckningar.length > 0">
                                <Button
                                    v-show="inteMakuleradeJournalanteckningar.length > 0"
                                    size="secondary"
                                    class="print-button"
                                    @click="print()"
                                    >Skriv ut
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div class="journalanteckningar-list">
                        <JournalanteckningarListItem
                            v-for="(journalanteckning, index) in filteredSortedSelectedJournalanteckningar"
                            :id="`journalanteckning-${journalanteckning.pk}`"
                            :key="index"
                            :journalanteckning="journalanteckning"
                            @historik="historikJournalanteckning"
                            @redigera="redigeraJournalanteckning"
                            @makulera="makuleraJournalanteckning"
                        />
                    </div>
                </div>
            </template>

            <template #header>
                <div class="print-header">
                    <div class="print-info">
                        <span>Utskriftsdatum: {{ currentDate.format("YYYY-MM-DD") }}</span>
                        <span>Utskrivet av: {{ currentProfile.user_display.name }}</span>
                        <span>ID: {{ utskriftsID }}</span>
                    </div>
                    <div class="print-adress">
                        {{ currentProfile.orgUnit_display.name || "" }}<br />
                        {{ currentProfile.orgUnit_display.streetName || "" }}<br />
                        {{ currentProfile.orgUnit_display.postalCode || "" }}
                        {{ currentProfile.orgUnit.city || "" }}<br />
                        {{ currentProfile.orgUnit_display.telecom || "" }}
                    </div>
                    <div class="print-patient">
                        Personnummer {{ patient.personId || "" }}<br />
                        {{ patient.namn || "" }}
                    </div>
                </div>
            </template>

            <template #footer>
                <img src="@/assets/kliniken_logo_rgb.svg" alt="" />
            </template>
        </PrintableContent>

        <div class="historisk-journalanteckning" v-if="historiskJournalanteckning">
            <h1>Journalanteckning, tidigare versioner</h1>
            <Button variant="contour" size="secondary" @click="closeHistorikJournalanteckning">
                <img src="@/assets/menuclose.svg" alt="" />
                Tillbaka till nuvarande version
            </Button>
            <JournalanteckningarListItem :journalanteckning="historiskJournalanteckning" :historisk="true" />
        </div>
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import CheckboxWidget from "@/components/widgets/CheckboxWidget.vue";
import tabEvents from "@/tabEvents";
import { mapGetters } from "vuex";
import { getConfig, klinikenApi } from "@/api";
import { flashMessage } from "@/utils";
import tabIcon from "@/assets/journal.svg";
import StatusWidget from "@/components/widgets/StatusWidget.vue";
import Button from "@/components/Button.vue";
import ModalBasic, { BasicModalVariant } from "@/components/generic/ModalBasic.vue";
import PrintableContent from "@/components/PrintableContent.vue";
import moment from "moment";
import JournalanteckningarListItem from "@/tabs/Journalanteckningar/JournalanteckningarListItem.vue";
import Journalanteckning from "@/tabs/Journalanteckningar/Journalanteckning.vue";
import { cloneDeep, extend } from "lodash";
import MakuleraAnteckning from "@/tabs/Journalanteckningar/MakuleraAnteckning.vue";
import dialogEvents from "@/dialogEvents";

export default {
    extends: BaseTab,
    name: "JournalAnteckningar",
    icon: tabIcon,
    patientBound: true,
    components: {
        JournalanteckningarListItem,
        PrintableContent,
        ModalBasic,
        CheckboxWidget,
        StatusWidget,
        Button,
    },
    tabName: "Journalanteckningar",
    props: {
        tabId: Number,
        parameters: Object,
    },
    data() {
        return {
            collapsed: false,
            journalanteckningar: [],
            journalanteckningarLaddade: false,
            visaMakulerade: false,
            selectedJournalanteckningar: [],
            utskriftsID: "",
            historiskJournalanteckning: undefined,
        };
    },
    computed: {
        ...mapGetters("patientData", ["patient"]),
        ...mapGetters("userData", ["currentProfile", "enhetsadministrator"]),
        moment() {
            return moment;
        },
        currentDate() {
            return moment();
        },
        filteredJournalanteckningar() {
            return this.journalanteckningar.filter((item) => this.visaMakulerade || !item.is_nullified, this);
        },
        inteMakuleradeJournalanteckningar() {
            return this.journalanteckningar.filter((item) => !item.is_nullified);
        },
        harMakulerade() {
            return this.journalanteckningar.some((item) => item.is_nullified);
        },
        allJournalanteckningIsSelected() {
            return (
                this.filteredJournalanteckningar.length > 0 &&
                this.filteredJournalanteckningar.length === this.filteredSortedSelectedJournalanteckningar.length
            );
        },
        filteredSortedSelectedJournalanteckningar() {
            return this.selectedJournalanteckningar
                .filter((item) => this.visaMakulerade || !item.is_nullified, this)
                .sort((a, b) => {
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                });
        },
    },
    methods: {
        async openJournalanteckning() {
            this.$store.dispatch("journalanteckningar/clearAnteckning");
            await this.$store.dispatch("tabs/closeTabWithUnsavedDataCheck", Journalanteckning);
            await this.$store.dispatch("tabs/openTab", {
                component: Journalanteckning,
            });
        },

        scrollToJournalanteckning(pk) {
            this.$nextTick(() => {
                let el = this.$el.querySelector(`#journalanteckning-${pk}`);
                if (el) el.scrollIntoView({ behavior: "smooth" });
            });
        },

        selectAllJournalanteckningar() {
            if (this.allJournalanteckningIsSelected) {
                this.selectedJournalanteckningar = [];
            } else {
                this.filteredJournalanteckningar.forEach((journalanteckning) => {
                    this.selectJournalanteckning(journalanteckning.pk);
                }, this);
            }
        },

        toggleSelectJournalanteckning(pk) {
            if (this.journalanteckningIsSelected(pk)) {
                this.deSelectJournalanteckning(pk);
            } else {
                this.selectJournalanteckning(pk);
            }
        },

        async selectJournalanteckning(pk) {
            if (!this.journalanteckningIsSelected(pk)) {
                let journalanteckning = this.journalanteckningar.find((item) => item.pk === pk);
                this.selectedJournalanteckningar.push(journalanteckning);
                await this.fetchJournalanteckning(journalanteckning);
            }
        },

        deSelectJournalanteckning(pk) {
            this.selectedJournalanteckningar = this.selectedJournalanteckningar.filter((item) => item.pk !== pk);
            let journalanteckning = this.journalanteckningar.find((item) => item.pk === pk);
            delete journalanteckning.sektioner;
        },

        journalanteckningIsSelected(pk) {
            return this.selectedJournalanteckningar.some((item) => item.pk === pk);
        },

        ansvarigsNamn(anteckning) {
            if (anteckning.legalAuthenticator) {
                return anteckning.legalAuthenticator.user.name;
            } else if (anteckning.created_by) {
                return anteckning.created_by.user.name;
            } else {
                return "[Saknas]";
            }
        },

        ansvarigsOrgUnit(anteckning) {
            if (anteckning.legalAuthenticator) {
                return anteckning.legalAuthenticator.orgUnit.name;
            } else if (anteckning.created_by) {
                return anteckning.created_by.orgUnit.name;
            }
        },

        getStatus(item) {
            if (item.is_nullified) return "makulerad";
            if (item.is_signed) return "signerad";
            return null;
        },

        toggleVisaMakulerade() {
            this.visaMakulerade = !this.visaMakulerade;
        },

        async fetchJournalanteckningar() {
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });

            try {
                const response = await klinikenApi.get(
                    "/anteckning/journalanteckningar/",
                    getConfig(
                        {
                            params: {
                                patient: this.patient.id,
                                ordering: "-created_at",
                            },
                        },
                        "Journalanteckningar"
                    )
                );

                this.journalanteckningar = response.data.results;
                this.journalanteckningarLaddade = true;
            } catch (e) {
                flashMessage("Kunde inte läsa journalanteckningar från servern. " + e);
            }

            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
        },

        async fetchJournalanteckning(journalanteckning) {
            try {
                const response = await klinikenApi.get(
                    `/anteckning/journalanteckningar/${journalanteckning.pk}/`,
                    getConfig({}, "Journalanteckningar")
                );
                return extend(journalanteckning, response.data);
            } catch (e) {
                flashMessage(`Kunde inte läsa journalanteckning ${journalanteckning.pk} från servern. ${e}`);
            }
        },

        async fetchJournalanteckningHistorik(journalanteckning) {
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });

            let historik = [];
            try {
                const response = await klinikenApi.get(
                    `/anteckning/journalanteckningar/${journalanteckning.pk}/history/`,
                    getConfig({}, "Journalanteckningar")
                );
                historik = response.data;
            } catch (e) {
                flashMessage("Kunde inte läsa journalhistorik från servern. " + e);
            }

            let historiskJournalanteckning = cloneDeep(journalanteckning);

            historik.forEach((item) => {
                const version = {
                    created_at: item.edited_at || item.created_at,
                    created_by: item.edited_by || item.created_by,
                };

                item.historicalsektioner.forEach((item) => {
                    this.historiskSektion(item, historiskJournalanteckning, version);
                }, this);
            }, this);

            this.historiskJournalanteckning = historiskJournalanteckning;

            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
        },

        historiskSektion(historisk, original, version) {
            let sektion = this.findSektion(original.sektioner, historisk.pk);
            if (sektion !== undefined) {
                if (!sektion.historicalsektioner) sektion.historicalsektioner = [];
                sektion.historicalsektioner.push({
                    text: historisk.text,
                    created_by: version.created_by,
                    created_at: version.created_at,
                });
            }
            historisk.historicalsektioner.forEach((item) => {
                this.historiskSektion(item, original, version);
            });
        },

        findSektion(sektioner, pk) {
            const x = sektioner.find((item) => item.pk === pk);
            if (x === undefined) {
                for (let sektion of sektioner) {
                    if (sektion.sektioner) {
                        let y = this.findSektion(sektion.sektioner, pk);
                        if (y !== undefined) return y;
                    }
                }
            } else return x;
        },

        async refresh(journalanteckning) {
            await this.fetchJournalanteckningar();
            if (journalanteckning) {
                if (this.journalanteckningIsSelected(journalanteckning.pk)) {
                    extend(
                        this.selectedJournalanteckningar.find((item) => item.pk === journalanteckning.pk),
                        journalanteckning
                    );
                } else {
                    this.selectedJournalanteckningar.push(journalanteckning);
                }
            }
        },

        async historikJournalanteckning(journalanteckning) {
            await this.fetchJournalanteckningHistorik(journalanteckning);
        },

        closeHistorikJournalanteckning() {
            this.historiskJournalanteckning = undefined;
        },

        async redigeraJournalanteckning(journalanteckning) {
            await this.$store.dispatch("tabs/closeTabWithUnsavedDataCheck", Journalanteckning);
            this.$store.commit("journalanteckningar/setAnteckning", cloneDeep(journalanteckning));
            await this.$store.dispatch("tabs/openTab", { component: Journalanteckning });
        },

        async makuleraJournalanteckning(journalanteckning) {
            dialogEvents.$emit("openPopup", {
                title: "Makulera journalanteckning",
                component: MakuleraAnteckning,
                attachedProps: {
                    anteckning: journalanteckning,
                },
            });
        },

        async print() {
            if (this.visaMakulerade === true) {
                await this.$refs.dialog.show({
                    title: "Du har valt att visa makulerade anteckningar. Observera att dessa inte kommer att skrivas ut.",
                    okButton: "OK",
                });
            }

            const response = await klinikenApi.post(
                "/anteckning/journalanteckningar/utskrift/",
                {
                    journalanteckningar: this.selectedJournalanteckningar
                        .filter((journalanteckning) => !journalanteckning.is_nullified)
                        .map((journalanteckning) => journalanteckning.pk),
                },
                getConfig({}, "Journalanteckningar")
            );

            if (response.status !== 201) {
                await this.$refs.dialog.show({
                    variant: BasicModalVariant.ALERT,
                    title: "Det gick inte att få ett utskrift-ID.",
                    okButton: "OK",
                });
            } else {
                this.utskriftsID = response.data.utskrifts_id;
                this.$refs.printablecontent.print();
            }
        },
    },
    async created() {
        tabEvents.$on("refreshJournalanteckningar", this.refresh);
        await this.fetchJournalanteckningar();
        if (this.parameters && this.parameters.pk) this.selectJournalanteckning(this.parameters.pk);
    },
    beforeDestroy() {
        tabEvents.$off("refreshJournalanteckningar");
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";
@import "@/style/button";

.journalanteckningar {
    .skapa-ny-journalanteckning {
        margin-left: 60px;
    }

    .journalanteckningar-body {
        display: flex;
        gap: 80px;
        position: relative;

        .journalanteckningar-menu {
            width: 499px;
            font-size: 14px;

            & > div {
                background-color: $color-grey-bg;
                padding: 16px;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                gap: 10px;
            }

            ::v-deep .checkbox-container {
                min-height: 18px;
                padding-left: 28px;

                .checkmark {
                    height: 18px;
                    width: 18px;
                }

                .checkmark:after {
                    left: 6px;
                    top: 2px;
                }
            }

            .journalanteckningar-menu-heading {
                display: flex;
                padding-bottom: 10px;
                border-bottom: 1px solid #dbe3e3;
                color: $color-text-grey;

                .journalanteckningar-menu-toggle {
                    margin-left: auto;
                    @include button;
                }
            }

            .journalanteckningar-menu-list {
                overflow-y: auto;
                min-height: 100px;
                max-height: calc(100vh - 500px);
                padding-right: 20px;

                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .journalanteckningar-menu-list-item {
                    display: flex;
                    padding: 10px 0;
                    border-bottom: 1px solid #dbe3e3;

                    .journalanteckningar-menu-list-item-date {
                        color: $color-text-grey;
                    }

                    .journalanteckningar-menu-list-item-icon {
                        margin-left: auto;
                    }
                }
            }

            .visa-makulerade {
                display: flex;
                justify-content: flex-end;

                .visa-makulerade-label {
                    color: $color-text-grey;
                }
            }

            .print-button-wrapper {
                display: flex;
                justify-content: flex-end;
            }

            &.minimized {
                width: 316px;

                .journalanteckningar-menu-toggle {
                    rotate: 180deg;
                }
            }
        }

        .journalanteckningar-list {
            flex: 1;

            .journalanteckningar-list-item {
                margin-bottom: 50px;
            }
        }
    }

    .historisk-journalanteckning {
        button {
            margin-bottom: 20px;
        }
    }
}

@media print {
    .journalanteckningar {
        font-size: 10px;

        h1 {
            display: none;
        }

        .skapa-ny-journalanteckning {
            display: none;
        }

        .journalanteckningar-body {
            .journalanteckningar-menu {
                display: none;
            }

            .journalanteckningar-list {
                .journalanteckningar-list-item {
                    margin-bottom: 25px;
                }
            }
        }

        .print-header {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;

            line-height: initial;
            font-size: 8px;

            .print-info {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 45px;

                color: $color-text-popup;
            }

            .print-adress {
                color: $color-text-popup;
            }

            .print-patient {
                font-size: 11px;
                font-weight: initial;
                color: $color-text-popup;
            }
        }
    }
}
</style>
