<script>
import BaseTab from "@/tabs/mixins/BaseTab.js";
import { openDialog } from "@/utils";
import { mapActions, mapGetters } from "vuex";
import { cloneDeep, isEqual } from "lodash";
import { getErrorMessage } from "@/api";
import PlusCircleBlueIcon from "@/assets/plus_circle_blue.svg";
import DeleteIcon from "@/assets/actionIcons/delete.svg";
import tabIcon from "@/assets/narvarostatistik.svg";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";

export default {
    extends: BaseTab,
    patientBound: true,
    name: "Spelare",
    tabName: "Spelare",
    icon: tabIcon,

    components: {
        Button,
        ButtonGroup,
    },

    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            defaultSpelare: { spelarelag: [] },
            spelare: { spelarelag: [] },
            formErrors: [],
            inputErrors: {},
            PlusCircleBlueIcon,
            DeleteIcon,
        };
    },

    computed: {
        ...mapGetters("patientData", ["patient"]),
        ...mapGetters("skreg/lag", ["lag"]),
        hasUnsavedData() {
            return !isEqual(this.defaultSpelare, this.spelare);
        },
    },

    methods: {
        ...mapActions("skreg/spelare", ["load", "save"]),
        async submit(data) {
            try {
                await this.save(data);
                await this.$store.dispatch("tabs/closeTab", this.tabId);
            } catch (e) {
                if (e.response) {
                    this.formErrors = e.response.data["non_field_errors"];
                    this.inputErrors = e.response.data;
                }
                openDialog("Kunde inte spara spelaren.", "error");
            }
        },
        cancel() {
            this.closeTab(this.hasUnsavedData);
        },
    },

    async created() {
        try {
            await this.$store.dispatch("skreg/lag/load");
        } catch (e) {
            openDialog("Kunde inte hämta underlag." + getErrorMessage(e), "warning");
        }
        await this.load(this.patient);
        this.defaultSpelare = cloneDeep(this.$store.state.skreg.spelare.spelare);
        this.spelare = cloneDeep(this.$store.state.skreg.spelare.spelare);
    },
};
</script>

<template>
    <div class="spelare">
        <FormulateForm
            v-model="spelare"
            :form-errors="formErrors"
            :errors="inputErrors"
            @submit="submit"
            #default="{ hasValue }"
        >
            <h2>Spelare</h2>

            <FormulateInput name="langd" label="Längd" type="number" />

            <FormulateInput name="vikt" label="Vikt" type="number" />

            <FormulateInput name="dominerande_fot" label="Dominerande fot" type="text" />

            <FormulateInput name="klubbfattning" label="Klubbfattning" type="text" />

            <FormulateInput
                type="group"
                name="spelarelag"
                :repeatable="true"
                add-label="Lägg till lag"
                remove-position="after"
            >
                <template>
                    <FormulateInput
                        name="lag"
                        label="Lag"
                        type="select"
                        validation="required"
                        :options="
                            lag.map(({ id, namn }) => {
                                return { value: id, label: namn };
                            })
                        "
                    />

                    <FormulateInput name="trojnummer" label="Tröjnummer" type="number" />

                    <FormulateInput name="position" label="Position" type="text" />

                    <FormulateInput name="aktiv_fran" label="Från" type="date" validation="required" />

                    <FormulateInput name="aktiv_till" label="Till" type="date" />
                </template>
            </FormulateInput>

            <ButtonGroup>
                <Button variant="standard" type="submit" :disabled="!hasValue">Spara och stäng</Button>
                <Button variant="standard" size="slim" type="button" @click="cancel()">Avbryt</Button>
            </ButtonGroup>
        </FormulateForm>
    </div>
</template>

<style lang="scss" scoped>
@import "@/style/vendors/formulate";

.spelare {
}
</style>
