<template>
    <div class="dropdown">
        <button class="dropdown-icon" @click="toggle" v-on-clickaway="away">
            <img src="@/assets/message.svg" />
        </button>
        <div v-if="active" class="dropdown-menu">
            <button
                v-for="action in actions"
                v-show="!disabled(action, item)"
                :key="action.name"
                @click.stop.prevent="emit(action.name, item)"
                class="dropdown-item"
            >
                <img
                    v-if="action.img"
                    :class="action.title"
                    :src="getImgUrl(action.img)"
                    alt="symbol"
                    width="22"
                    height="22"
                />
                {{ action.title }}
            </button>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
    mixins: [clickaway],

    props: {
        id: undefined, // id of the dropdown, necessary for dropdown functionality
        item: Object, // the list item, which is returned when an action is invoked
        actions: Array, // an Array of actions, emitted to parent as ('dropdownAction', action.name, item)
        /**
         * This is currently only used in PrescriptionSet, where certain actions are locked to the currently logged in user.
         * Prop is passed down from ActionTable, which currently has no use for it. This solution is not very clean,
         * but leaving it like this in case the same access control is needed elsewhere.
         */
        currentProfile: String, // the currently logged in user
    },

    data() {
        return {
            active: false,
        };
    },

    methods: {
        emit(action, item) {
            this.$emit("dropdownAction", action, item);
            this.active = false;
        },
        toggle() {
            this.active = !this.active;
        },
        away() {
            this.active = false;
        },
        getImgUrl(pic) {
            return new URL(`/src/assets/actionIcons/${pic}`, import.meta.url).href;
        },
        disabled(action, item) {
            if (action.disabled) return action.disabled(item, this.currentProfile);
            else return false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "/src/style/variables";

/* Bootstrap - Start */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/tooltip";
/* Bootstrap - End */

@import "/src/style/deprecated_main";

.dropdown {
    float: right;
}

.dropdown-menu {
    display: block;
    min-width: 260px;
    max-width: 360px;
    left: auto !important; //override Bootstrap, se above comment
    right: 30px !important;
    box-shadow: $dropdown-box-shadow;

    .dropdown-item {
        padding-top: 14px;
        padding-bottom: 14px;

        img {
            margin-right: 14px !important;
            margin-top: -5px;
            background-color: transparent;
        }
    }

    .dropdown-item:hover {
        background-color: $dropdown-item;
    }
}

.dropdown-icon {
    height: 24px;
    width: 30px;
    border: 1px solid #728296;
    border-radius: 4px;
    background-color: white;
    vertical-align: -webkit-baseline-middle;
    margin-right: 30px;

    img {
        margin-top: -0.3rem;
    }
}

button {
    &:disabled {
        color: #ff0000;
        pointer-events: auto;
    }

    &:hover {
        background-color: #cee9f3;
        cursor: pointer;
    }
}
</style>
