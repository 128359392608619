<template>
    <!--
    -->
    <div class="action-table">
        <button @click="collapse = !collapse" v-if="collapseLabel" class="btn btn-default btn-block" type="button">
            <span :class="toggleCaret"></span>
            <span role="presentation">{{ collapseLabel }}</span>
            <span class="label" :key="label" v-for="label in labels">{{ label }}</span>
        </button>
        <collapse-transition>
            <div v-show="!collapse || !collapseLabel">
                <table class="table">
                    <thead>
                        <tr>
                            <th
                                v-for="col in columns"
                                :key="col.name"
                                :class="{
                                    sortable: sortMethod && col.sortable,
                                    'is-sorted': orderBy === col.name,
                                    reversed: orderReversed,
                                }"
                                @click="setOrder(col)"
                            >
                                {{ col.title }}
                                <img v-if="sortMethod && col.sortable" src="@/assets/dropdown-arrow-blue.svg" />
                            </th>
                            <th v-if="actions"></th>
                        </tr>
                    </thead>
                    <tbody v-if="selectableRows">
                        <tr
                            v-for="item in items"
                            :key="item.id"
                            :class="{ 'table-row': true, 'disabled-row': getDisabledRow(item) }"
                            @click.prevent="emitSelect($event, item)"
                        >
                            <td v-for="col in columns" :key="col.name">
                                <span v-if="col.renderer" v-html="col.renderer(item)" />
                                <span v-else>{{ item[col.name] }}</span>
                                <span class="missing" v-if="isRequiredField(col) && item[col.name] === ''">Saknas</span>
                            </td>
                            <td v-if="actions" class="actions">
                                <actions-dropdown
                                    :id="item.id"
                                    :actions="actions"
                                    :item="item"
                                    @dropdownAction="dropdownAction"
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr
                            v-for="(item, index) in items"
                            :class="{ 'strike opaque': item.is_nullified, opaque: item.recalled_at }"
                            :key="item.id"
                        >
                            <td v-for="col in columns" :key="col.name">
                                <span v-if="col.renderer" v-html="col.renderer(item, index)" />
                                <span v-else>{{ item[col.name] }}</span>
                                <span class="missing" v-if="isRequiredField(col) && !item[col.name]">Saknas</span>
                            </td>
                            <td v-if="actions" class="actions">
                                <actions-dropdown
                                    v-if="!getDisabledActionsDropdown(item)"
                                    :id="item.id"
                                    :actions="actions"
                                    :item="item"
                                    @dropdownAction="dropdownAction"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </collapse-transition>
    </div>
</template>

<script>
import ActionsDropdown from "@/components/generic/ActionsDropdown.vue";
import { CollapseTransition } from "@ivanv/vue-collapse-transition";

export default {
    components: {
        CollapseTransition,
        ActionsDropdown,
    },
    data() {
        return {
            orderBy: null,
            orderReversed: false,
            collapse: true,
        };
    },
    props: {
        items: Array, // the list items to be rendered in the table
        actions: Array, // actions available in a dropdown menu for each list item
        selectableRows: Boolean, // whether an item/row is selectable/clickable (true) or not (false)
        sortMethod: Function, // defines sort method for a single column, currently not in use
        id: String, // id of the table
        collapseLabel: String, // the label/text on the collapsable button
        labels: Array, // a small label (e.g. "REK") to be shown next to collapseLabel
        requiredFields: Array, // an Array with the field names that are required. An empty field that is required is marked differently.
        disabledRowHandler: Function, // a function passed by parent to determine which, if any, rows in a table that should not be selectable
        disabledActionsDropdownHandler: Function,
        columns: Array, // table columns, in the format: { name: String, title: String, renderer: Function(item) }
        data: Object, // the raw data object matching the list
        /**
         * used in SelectActionTable only
         */
        groupActions: Array, // actions that apply to all selected items
        labelActions: Array, // actions available in a dropdown menu in the collapsable button/header
        label: Object, // text, color and tooltip of the label in the collapsable button/header
        q: String, // the search term, used to highlight the search term in text
        selectedItem: Object, // the selected item value
    },
    computed: {
        toggleCaret() {
            return !this.collapse ? "rotatingCaret rotateonedegree" : "rotatingCaret rotateoneeighty";
        },
    },
    methods: {
        fetchImg(img) {
            return require("src/assets/" + img);
        },
        setOrder(col) {
            if (this.sortMethod && col.sortable) {
                if (this.orderBy === col.name) this.orderReversed = !this.orderReversed;
                else this.orderReversed = false;
                this.orderBy = col.name;
                this.sortMethod(col.name, this.orderReversed);
            }
        },
        /**
         *  Checks if a column is required. Can this be moved to definitions is PrescriptionSet and still be dynamic?
         */
        isRequiredField(col) {
            if (this.requiredFields == null)
                // important to use loose comparison, since requiredFields can also be undefined
                return false;
            var colName = col.name;
            var lastDot = colName.lastIndexOf(".");
            if (lastDot !== -1) colName = colName.slice(lastDot);
            if (this.requiredFields.indexOf(colName) !== -1) return true;
            return false;
        },
        emitSelect(event, item) {
            if (!this.selectableRows) return;
            if (this.disabledRowHandler) {
                if (this.disabledRowHandler(item)) this.$parent.$emit("actionTable::select", item);
            } else this.$parent.$emit("actionTable::select", item);
        },
        getDisabledRow(item) {
            if (this.disabledRowHandler) return !this.disabledRowHandler(item);
            else return false;
        },
        getDisabledActionsDropdown(item) {
            if (this.disabledActionsDropdownHandler) {
                return this.disabledActionsDropdownHandler(item);
            } else return false;
        },
        dropdownAction(name, item) {
            this.$parent.$emit(name, item);
        },
    },
};
</script>

<style lang="sass" scoped>
@import "/src/style/variables"

/* Bootstrap - Start */
@import bootstrap/scss/functions
@import bootstrap/scss/variables
@import bootstrap/scss/mixins
@import bootstrap/scss/root
@import bootstrap/scss/reboot

@import bootstrap/scss/buttons
@import bootstrap/scss/tables
/* Bootstrap - End */

@import "src/style/deprecated_main"

.strike
  text-decoration: line-through

.rotatingCaret
  background-image: url('@/assets/dropdown-arrow.svg')
  background-size: 14px
  width: 14px
  height: 14px
  display: inline-block
  margin: .75rem .8rem 0 0
  transform: rotate(180deg)
  transition: transform .2s
  float: right

.rotateonedegree
  transform: rotate(180deg)

.rotateoneeighty
  transform: rotate(0deg)

.action-table
  > button
    border-radius: 0
    text-align: left
    font-weight: bold
    box-shadow: none

    [role="presentation"]
      //min-width: 10rem
      display: inline-block
      line-height: 2.375rem

    .caret
      background-image: url('@/assets/dropdown-arrow.svg')
      /* NOT THE CORRECT ICON */
      background-size: 14px
      width: 14px
      height: 14px
      display: inline-block
      margin: .75rem .8rem 0 0
      transform: rotate(180deg)
      transition: transform .2s
      float: right

    &.collapsed .caret
      transform: rotate(0)

  table
    margin-bottom: 0 !important

    th
      color: $color-thead
      font-size: 14px
      font-weight: 500

      img
        width: 10px
        height: auto
        margin-left: .25rem
        opacity: .4

      &.is-sorted img
        opacity: 1

      &.is-sorted.reversed img
        transform: rotate(180deg)

      &.sortable
        cursor: pointer

    td
      font-size: 14px
      min-width: 6rem
      max-width: 6rem

      &.actions
        text-align: right

      &:first-child
        border-right: 1px solid $color-border-light-grey
        font-weight: bold
        font-size: 16px

    .table-row:last-child, tr:last-child
      border-bottom: 1px solid $color-border-light-grey

    .table-row
      display: table-row
      color: $color-text
      text-decoration: none

      &.selectable-row
        cursor: pointer

      span
        pointer-events: none

      &:hover
        background-color: $color-tablerow-hover

  .disabled-row
    td
      color: red

    &:hover, &:focus
      background-color: inherit !important
      cursor: default

  .collapse
    padding-bottom: .1px

  .action-btn
    padding-left: 48px
    padding-bottom: 10px
    padding-top: 10px

  .missing
    width: 100%
    color: #9E354B
    font-size: 14px
    line-height: 24px
    border-bottom: 1px dashed #9E354B
    display: block

  /* ::v-deep is required to make the class accessible by v-html slots */












  ::v-deep(.label)
    border-radius: 2px
    padding-left: 2px
    padding-right: 2px
    font-weight: bold
    font-size: 12px
    margin-top: 2px
    margin-left: 10px
    color: $color-label-grey
    border: 1px solid $color-border-grey
    max-height: 17px
    line-height: 17px

  ::v-deep(.label-status)
    min-width: 60px
    text-align: center
    font-weight: normal
    border-radius: 2px
    padding-left: 7px
    padding-right: 7px
    font-size: 10px
    float: right
    margin-top: 9px
    margin-right: 22px
    color: #FFFFFF
    border: none
    max-height: 20px
    line-height: 20px

    a
      font-size: 10px
      color: #FFFFFF
      text-decoration: none

  ::v-deep(.status)
    display: inline-block
    min-width: 60px
    text-align: center
    font-weight: normal
    border-radius: 2px
    padding-left: 7px
    padding-right: 7px
    font-size: 10px
    color: #FFFFFF
    border: none
    line-height: 20px

  ::v-deep(mark)
    padding: 0px !important
    background-color: #C8E6EC

  ::v-deep(.edited)
    color: #7B868A
    font-size: 12px
    letter-spacing: 0
    line-height: 14px
    font-weight: normal
    margin-left: 10px

  a.edited
    text-decoration: underline
    cursor: pointer
</style>
