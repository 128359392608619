<template>
    <div id="kliniken-frame" :class="{ minimized: minimized }">
        <KlinikenMenu @toggleSize="toggleSize" />
        <div class="content">
            <KlinikenHeader />
            <TabContainer />
        </div>

        <ModalIdle v-if="isIdle" :timeout="timeout" :countdown="countdown" />
    </div>
</template>

<script>
import TabContainer from "@/tabs/TabContainer.vue";
import KlinikenHeader from "@/components/KlinikenHeader.vue";
import ExternalCall from "@/mixins/ExternalCall.js";
import KlinikenMenu from "@/components/KlinikenMenu.vue";
import { useIdle, useInterval, useTimestamp } from "@vueuse/core";
import ModalIdle from "@/components/ModalIdle.vue";

export default {
    mixins: [ExternalCall],
    name: "KlinikenFrame",

    components: {
        ModalIdle,
        TabContainer,
        KlinikenHeader,
        KlinikenMenu,
    },

    data() {
        return {
            minimized: false,
            timeout: 10,
            now: undefined,
            idle: false,
            lastActive: undefined,
            counter: 0,
            reset: undefined,
            pause: undefined,
            resume: undefined,
        };
    },

    computed: {
        isIdle() {
            return import.meta.env.PROD && this.idle;
        },
        countdown() {
            return 30 - this.counter;
        },
    },

    watch: {
        isIdle(value) {
            if (value) {
                this.reset();
                this.resume();
            } else {
                this.pause();
            }
        },
        countdown(value) {
            if (value === 0) {
                this.$store.dispatch("userData/logout");
            }
        },
    },

    methods: {
        toggleSize(minimized) {
            this.minimized = minimized;
        },

        /**
         * Sets a unique name for the window that survives during the window's lifecycle. Unlike sessionStorage this name will be unique even for duplicated tabs.
         */
        setWindowName() {
            var GUID = function () {
                var S4 = function () {
                    return Math.floor(Math.random() * 0x10000 /* 65536 */).toString(16);
                };
                return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
            };
            if (!window.name.match(/^GUID-/)) window.name = "GUID-" + GUID();
        },
        checkWindowName() {
            let sessionWindow = sessionStorage.getItem("Window");
            let localWindow = localStorage.getItem("Window");
            if (sessionWindow !== localWindow) {
                this.$store.dispatch("userData/logout");
            }
        },
    },

    async created() {
        this.now = useTimestamp();

        const { idle, lastActive } = useIdle(this.timeout * 60 * 1000);
        this.idle = idle;
        this.lastActive = lastActive;

        const { counter, reset, pause, resume } = useInterval(1000, { controls: true, immediate: false });
        this.counter = counter;
        this.reset = reset;
        this.pause = pause;
        this.resume = resume;

        window.addEventListener("load", this.setWindowName);
        window.addEventListener("storage", this.checkWindowName);
        await this.externalCall();
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

$maximized-width: 290px;
$minimized-width: 64px;

#kliniken-frame {
    #kliniken-menu {
        height: 100%;
        width: $maximized-width;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
    }

    .content {
        margin-left: $maximized-width;
        height: 100vh;
    }

    &.minimized {
        #kliniken-menu {
            width: $minimized-width;
        }

        .content {
            margin-left: $minimized-width;
        }
    }

    main {
        height: 100vh;
        flex: 1 1 auto;
    }
}

@media print {
    #kliniken-frame {
        #kliniken-header,
        #kliniken-menu {
            display: none;
        }

        .content {
            height: auto;
            margin-left: 0;
        }
    }
}
</style>

<style lang="scss">
@page {
    size: A4 portrait;
    margin: 16px;
}
</style>
