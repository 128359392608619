<script>
import BaseTab from "@/tabs/mixins/BaseTab.js";
import { getConfig, getErrorMessage, klinikenApi } from "@/api.js";
import { openDialog } from "@/utils";
import { mapActions, mapGetters } from "vuex";
import dialogEvents from "@/dialogEvents";
import NumberedSection from "@/components/generic/NumberedSection.vue";
import SectionDivider from "@/components/generic/SectionDivider.vue";
import PlusCircleWhite from "@/assets/plus_circle_white.svg";
import PlusCircleBlue from "@/assets/plus_circle_blue.svg";
import EditCircleBlue from "@/assets/icons/edit_circle_blue.svg";
import PositiveNotification from "@/assets/positive_notification.svg";
import { cloneDeep, isEqual } from "lodash";
import JournalAnteckning from "@/tabs/Journalanteckningar/Journalanteckning.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import Button from "@/components/Button.vue";

const defaultAktivitet = {
    langd: null,
};

const defaultAvstamningar = { avstamningar: [] };
export default {
    extends: BaseTab,
    name: "FormAktivitet",
    tabName: "Aktivitet",

    components: {
        ButtonGroup,
        Button,
        NumberedSection,
        SectionDivider,
    },

    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },

    data: function () {
        return {
            PlusCircleWhite,
            PlusCircleBlue,
            PositiveNotification,
            EditCircleBlue,
            defaultAktivitet: cloneDeep(defaultAktivitet),
            formAktivitet: cloneDeep(defaultAktivitet),
            formAktivitetErrors: [],
            inputAktivitetErrors: {},
            defaultAvstamningar: cloneDeep(defaultAvstamningar),
            formAvstamningar: cloneDeep(defaultAvstamningar),
            formAvstamningarErrors: [],
            inputAvstamningarErrors: {},
            markedAvstamningar: [],
        };
    },

    computed: {
        ...mapGetters("userData", ["currentProfile"]),
        ...mapGetters("skreg/lag", ["lag"]),
        ...mapGetters("skreg/underlag", ["underlag"]),
        ...mapGetters("skreg/platser", ["platser"]),
        ...mapGetters("skreg/aktivitetstyper", ["aktivitetstyper"]),
        hasUnsavedData() {
            return this.aktivitetHasUnsavedData || this.avstamningarHasUnsavedData;
        },
        aktivitetHasUnsavedData() {
            return !isEqual(this.defaultAktivitet, this.formAktivitet);
        },
        avstamningarHasUnsavedData() {
            return !isEqual(this.defaultAvstamningar, this.formAvstamningar);
        },
        heading() {
            return this.formAktivitet.id === undefined ? "Skapa ny aktivitet" : "Ändra på aktivitet";
        },
    },

    methods: {
        ...mapActions("skreg/lag", { loadLag: "load" }),
        ...mapActions("skreg/underlag", { loadUnderlag: "load" }),
        ...mapActions("skreg/platser", { loadPlatser: "load" }),
        ...mapActions("skreg/aktivitetstyper", { loadAktivitetstyper: "load" }),
        ...mapActions("skreg/aktiviteter", ["save"]),
        async submitAktivitet(data) {
            try {
                let response = await this.save(data);
                this.formAktivitet = response.data;
                this.defaultAktivitet = this.formAktivitet;
            } catch (e) {
                if (e.response) {
                    this.formAktivitetErrors = e.response.data["non_field_errors"];
                    this.inputAktivitetErrors = e.response.data;
                }
                openDialog("Kunde inte spara aktiviteten", "error");
            }
            await this.loadAvstamningar();
        },
        cancelAktivitet() {
            this.closeTab(this.hasUnsavedData);
        },
        skapaYtterligareAktiviet() {
            this.formAktivitet = {};
            this.defaultAktivitet = {};
            this.$formulate.resetValidation("aktivitet");
            this.formAvstamningar = { avstamningar: [] };
            this.defaultAvstamningar = { avstamningar: [] };
            this.$formulate.resetValidation("avstamningar");
        },
        async loadAvstamningar() {
            try {
                const response = await klinikenApi.get(
                    `skreg/aktiviteter/${this.formAktivitet.id}/avstamningar/`,
                    getConfig()
                );
                const avstamningar = cloneDeep(response.data);
                this.formAvstamningar = { avstamningar: avstamningar };
                this.defaultAvstamningar = cloneDeep(this.formAvstamningar);
                this.$formulate.resetValidation("avstamningar");
            } catch (e) {
                openDialog("Det gick inte att ladda avstämningarna för aktiviteten: " + getErrorMessage(e), "warning");
            }
        },
        async copyStatusFromPrevious() {
            try {
                const response = await klinikenApi.get(
                    `skreg/aktiviteter/${this.formAktivitet.id}/avstamningar/previous/`,
                    getConfig()
                );
                const previous = response.data;
                previous.forEach((item) => {
                    const index = this.formAvstamningar.avstamningar.findIndex((i) => i.spelarelag === item.spelarelag);
                    if (index !== -1) {
                        if (item.status === "Skada sker") {
                            this.formAvstamningar.avstamningar[index].status = "Skadad";
                        } else {
                            this.formAvstamningar.avstamningar[index].status = item.status;
                        }
                        if (item.status === "Närvarande") {
                            this.formAvstamningar.avstamningar[index].narvarande = this.formAktivitet.langd;
                        }
                        if (this.formAvstamningar.avstamningar[index].status === "Skadad") {
                            this.formAvstamningar.avstamningar[index].journalanteckning = item.journalanteckning;
                            const i = this.defaultAvstamningar.avstamningar.findIndex(
                                (i) => i.spelarelag === item.spelarelag
                            );
                            if (i !== -1) {
                                this.defaultAvstamningar.avstamningar[i].status =
                                    this.formAvstamningar.avstamningar[index].status;
                                this.defaultAvstamningar.avstamningar[i].journalanteckning = item.journalanteckning;
                            }
                        }
                    }
                });
            } catch (e) {
                openDialog("Det gick inte att kopiera status från föregående aktivitet", "warning");
            }
        },
        getPatientNamn(index) {
            if (
                this.formAvstamningar !== undefined &&
                this.formAvstamningar.avstamningar !== undefined &&
                this.formAvstamningar.avstamningar[index] !== undefined &&
                this.formAvstamningar.avstamningar[index].patient_display !== undefined
            ) {
                return this.formAvstamningar.avstamningar[index].patient_display.namn;
            }
            return "";
        },
        setNarvarande(event, index) {
            if (event.target.value === "Närvarande") {
                this.formAvstamningar.avstamningar[index].narvarande = this.formAktivitet.langd;
            } else {
                this.formAvstamningar.avstamningar[index].narvarande = 0;
            }
        },
        statusColor(index) {
            if (
                this.formAvstamningar !== undefined &&
                this.formAvstamningar.avstamningar !== undefined &&
                this.formAvstamningar.avstamningar[index] !== undefined &&
                this.formAvstamningar.avstamningar[index].status !== undefined
            ) {
                return this.formAvstamningar.avstamningar[index].status;
            }
            return "";
        },
        async submitAvstamningar() {
            if (this.aktivitetHasUnsavedData) {
                dialogEvents.$emit("open", {
                    type: "warning",
                    description: "Du har osparade ändringar i aktiviteten. Vill du även spara dessa?",
                    buttons: [
                        {
                            title: "Ja",
                            type: "secondary",
                            action: async () => {
                                try {
                                    const response = await this.$store.dispatch(
                                        "skreg/aktiviteter/save",
                                        this.formAktivitet
                                    );
                                    this.defaultAktivitet = cloneDeep(response.data);
                                } catch (e) {
                                    openDialog(getErrorMessage(e));
                                }
                                await this.putAvstamningar();
                            },
                        },
                        {
                            title: "Nej",
                            type: "secondary",
                            action: async () => {
                                await this.putAvstamningar();
                                this.formAktivitet = this.defaultAktivitet;
                            },
                        },
                        {
                            title: "Avbryt",
                            type: "primary",
                        },
                    ],
                });
            } else {
                this.putAvstamningar();
            }
        },
        async putAvstamningar() {
            try {
                const response = await klinikenApi.put(
                    `skreg/aktiviteter/${this.formAktivitet.id}/avstamningar/`,
                    this.formAvstamningar.avstamningar,
                    getConfig()
                );
                this.formAvstamningar.avstamningar = cloneDeep(response.data);
                this.defaultAvstamningar.avstamningar = cloneDeep(response.data);
                await this.$store.dispatch("skreg/aktiviteter/load");

                const closeTab = !this.formAvstamningar.avstamningar.some(
                    (a, index) => this.showJournalanteckning(index) && !this.hasJournalanteckning(index)
                );
                if (closeTab) {
                    this.$store.dispatch("tabs/closeTab", this.tabId);
                }
            } catch (e) {
                if (e.response) {
                    this.formAvstamningarErrors = e.response.data["non_field_errors"];
                    this.inputAvstamningarErrors = e.response.data;
                }
                openDialog("Kunde inte spara avstämningar", "error");
            }
        },
        cancelAvstamningar() {
            if (this.avstamningarHasUnsavedData) {
                dialogEvents.$emit("open", {
                    type: "warning",
                    description: "Du har inte sparat. Vill du stänga utan att spara?",
                    buttons: [
                        {
                            title: "Fortsätt redigera",
                            type: "secondary",
                        },
                        {
                            title: "Ja, stäng utan att spara",
                            type: "primary",
                            action: () => {
                                this.$store.dispatch("tabs/closeTab", this.tabId);
                            },
                        },
                    ],
                });
            }
        },
        showJournalanteckning(index) {
            let initialAvstamning = this.defaultAvstamningar.avstamningar[index];
            let avstamning = this.formAvstamningar.avstamningar[index];
            return (
                initialAvstamning !== undefined &&
                avstamning !== undefined &&
                initialAvstamning.status === avstamning.status &&
                (initialAvstamning.status === "Skada sker" ||
                    initialAvstamning.status === "Sjukdom" ||
                    (initialAvstamning.status === "Skadad" && this.hasJournalanteckning(index)))
            );
        },
        hasJournalanteckning(index) {
            let avstamning = this.defaultAvstamningar.avstamningar[index];
            return avstamning.journalanteckning !== null;
        },
        async openJournalanteckning(index) {
            let avstamning = this.defaultAvstamningar.avstamningar[index];
            if (avstamning.journalanteckning !== null) {
                let id = avstamning.journalanteckning;
                await this.$store.dispatch("tabs/closeTab", JournalAnteckning, { root: true });
                await this.$store.dispatch("journalanteckningar/getJournalanteckning", id);
                let anteckning = this.$store.getters["journalanteckningar/anteckning"];
                await this.$store.dispatch("patientData/loadFromBackend", { id: anteckning.patient.id });
                await this.$store.dispatch("tabs/openTab", { component: JournalAnteckning });
            }
        },
        async createJournalanteckning(index) {
            let avstamning = this.defaultAvstamningar.avstamningar[index];
            if (
                avstamning.journalanteckning === null &&
                (avstamning.status === "Skada sker" ||
                    avstamning.status === "Sjukdom" ||
                    avstamning.status === "Skadad")
            ) {
                try {
                    const response = await klinikenApi.post(
                        `skreg/avstamningar/${avstamning.id}/journalanteckningar/`,
                        {},
                        getConfig()
                    );
                    this.defaultAvstamningar.avstamningar[index].journalanteckning = response.data["id"];
                    this.formAvstamningar.avstamningar[index].journalanteckning = response.data["id"];
                } catch (e) {
                    openDialog("Kunde inte skapa journalanteckning", "error");
                }
            }
        },
        getKortnamn(index) {
            let avstamning = this.defaultAvstamningar.avstamningar[index];
            if (avstamning !== undefined) {
                if (avstamning.status === "Skada sker" || avstamning.status === "Skadad") return "skadekort";
                if (avstamning.status === "Sjukdom") return "sjukdomskort";
            }
            return "OKÄND";
        },
        markAll(event) {
            if (event.target.checked) {
                this.formAvstamningar.avstamningar.forEach((item, index) => {
                    this.markedAvstamningar = [...new Set([...this.markedAvstamningar, ...[index]])];
                });
            } else {
                this.markedAvstamningar = [];
            }
        },
        setMarked(event) {
            this.markedAvstamningar.forEach((index) => {
                this.formAvstamningar.avstamningar[index].status = event.target.value;
                this.setNarvarande(event, index);
            });
            event.target.value = "";
        },
    },

    async created() {
        try {
            await this.loadLag();
            await this.loadUnderlag();
            await this.loadPlatser();
            await this.loadAktivitetstyper();
        } catch (e) {
            openDialog("Det gick inte att ladda värden för formuläret: " + getErrorMessage(e), "warning");
        }

        if (this.parameters !== undefined && "aktivitet" in this.parameters) {
            if (this.parameters.duplicate) {
                this.formAktivitet = {
                    lag: this.parameters["aktivitet"].lag,
                    plats: this.parameters["aktivitet"].plats,
                    tid: this.parameters["aktivitet"].tid,
                    langd: this.parameters["aktivitet"].langd,
                    aktivitetstyp: this.parameters["aktivitet"].aktivitetstyp,
                    underlag: this.parameters["aktivitet"].underlag,
                    beskrivning: this.parameters["aktivitet"].beskrivning,
                };
                this.$formulate.resetValidation("aktivitet");
            } else {
                this.formAktivitet = cloneDeep(this.parameters["aktivitet"]);
                this.defaultAktivitet = cloneDeep(this.formAktivitet);
                this.$formulate.resetValidation("aktivitet");
                await this.loadAvstamningar();
            }
        }
    },
};
</script>

<template>
    <div class="aktivitet">
        <h2>{{ heading }}</h2>

        <numbered-section number="1" heading="Aktivitet">
            <FormulateForm
                name="aktivitet"
                v-model="formAktivitet"
                :form-errors="formAktivitetErrors"
                :errors="inputAktivitetErrors"
                @submit="submitAktivitet"
            >
                <div class="first-row">
                    <FormulateInput
                        class="control required"
                        name="lag"
                        label="Lag"
                        type="select"
                        validation="required"
                        placeholder="Välj lag"
                        :options="
                            lag.map(({ id, namn }) => {
                                return { value: id, label: namn };
                            })
                        "
                    />

                    <FormulateInput
                        class="control required"
                        name="plats"
                        label="Plats"
                        type="select"
                        validation="required"
                        placeholder="Välja plats"
                        :options="
                            platser.map(({ id, namn }) => {
                                return { value: id, label: namn };
                            })
                        "
                    />

                    <FormulateInput
                        class="control required"
                        name="datum"
                        label="Datum"
                        type="date"
                        validation="required"
                    />

                    <FormulateInput class="control required" name="tid" label="Tid" type="time" validation="required" />

                    <FormulateInput
                        class="control required"
                        name="langd"
                        label="Längd i minuter"
                        type="number"
                        min="0"
                        validation="required"
                    />
                </div>

                <div class="second-row">
                    <FormulateInput
                        class="control aktivitetstyp required"
                        name="aktivitetstyp"
                        label="Typ"
                        type="select"
                        validation="required"
                        placeholder="Välj typ"
                        :options="
                            aktivitetstyper.map(({ id, namn }) => {
                                return { value: id, label: namn };
                            })
                        "
                    />

                    <FormulateInput
                        class="control required"
                        name="underlag"
                        label="Underlag"
                        type="select"
                        validation="required"
                        placeholder="Välj underlag"
                        :options="
                            underlag.map(({ id, namn }) => {
                                return { value: id, label: namn };
                            })
                        "
                    />

                    <FormulateInput class="control" name="beskrivning" label="Beskrivning" type="text" />
                </div>

                <ButtonGroup>
                    <span v-if="formAktivitet.id !== undefined" class="aktiviten_skapad">
                        <img alt="" :src="PositiveNotification" />
                        Aktiviten skapad
                    </span>

                    <Button :disabled="!aktivitetHasUnsavedData" size="primary" role="button" type="submit">
                        <template v-if="formAktivitet.id"> Uppdatera aktivitet</template>
                        <template v-else><img alt="+" :src="PlusCircleWhite" /> Skapa aktivitet</template>
                    </Button>

                    <Button
                        :disabled="!aktivitetHasUnsavedData && formAktivitet.id !== undefined"
                        @click="cancelAktivitet()"
                        size="secondary"
                        type="button"
                    >
                        Avbryt
                    </Button>

                    <Button
                        v-if="formAktivitet.id"
                        :disabled="aktivitetHasUnsavedData || avstamningarHasUnsavedData"
                        @click.prevent="skapaYtterligareAktiviet"
                        size="secondary"
                        type="button"
                    >
                        Skapa ytterligare aktivitet
                    </Button>
                </ButtonGroup>
            </FormulateForm>
        </numbered-section>

        <section-divider />

        <numbered-section
            number="2"
            heading="Stäm av närvaro"
            :disabled="formAktivitet.id === undefined || !formAvstamningar.avstamningar"
            class="avstamningar"
        >
            <FormulateForm
                name="avstamningar"
                v-model="formAvstamningar"
                :form-errors="formAvstamningarErrors"
                :errors="inputAvstamningarErrors"
                @submit="submitAvstamningar"
                v-if="formAktivitet.id !== undefined"
            >
                <p>Om du vill stämma av aktiviteten senare går det bra.</p>
                <Button @click="copyStatusFromPrevious" size="secondary" role="button" type="button">
                    Kopiera status från föregående
                </Button>
                <div class="table">
                    <div class="tr th">
                        <div class="td">
                            <label class="checkbox">
                                <input
                                    type="checkbox"
                                    :checked="
                                        formAvstamningar.avstamningar &&
                                        formAvstamningar.avstamningar.length === markedAvstamningar.length
                                    "
                                    @change="markAll($event)"
                                />
                                <span></span>
                            </label>
                        </div>
                        <div class="td">Deltagare</div>
                        <div class="td">Status</div>
                        <div class="td">Närvaro minuter (från start)</div>
                        <div class="td">Kommentar (frivillig)</div>
                    </div>

                    <FormulateInput type="group" name="avstamningar" #default="{ index }">
                        <div class="tr">
                            <div class="td">
                                <label class="checkbox">
                                    <input type="checkbox" :value="index" v-model="markedAvstamningar" />
                                    <span></span>
                                </label>
                            </div>
                            <div class="td">
                                {{ getPatientNamn(index) }}
                            </div>
                            <div class="td status">
                                <FormulateInput
                                    name="status"
                                    type="select"
                                    label="Status"
                                    validation="required"
                                    @change.prevent="setNarvarande($event, index)"
                                    :options="[
                                        'Ej avstämd',
                                        'Närvarande',
                                        'Skada sker',
                                        'Semester',
                                        'Deltar ej',
                                        'Sjukdom',
                                        'Skadad',
                                    ]"
                                />
                                <div v-if="showJournalanteckning(index)" class="journalanteckning">
                                    <a
                                        v-if="hasJournalanteckning(index)"
                                        @click="openJournalanteckning(index)"
                                        role="button"
                                    >
                                        <img alt="" :src="EditCircleBlue" /> Visa {{ getKortnamn(index) }}
                                    </a>
                                    <a v-else @click="createJournalanteckning(index)" role="button">
                                        <img alt="+" :src="PlusCircleBlue" /> Skapa {{ getKortnamn(index) }}
                                    </a>
                                </div>
                            </div>
                            <div class="td">
                                <FormulateInput
                                    class="control"
                                    name="narvarande"
                                    label="Närvaro (minuter)"
                                    type="number"
                                    min="0"
                                />
                            </div>
                            <div class="td">
                                <FormulateInput class="control" name="kommentar" label="Kommentar" type="text" />
                            </div>
                        </div>
                    </FormulateInput>
                </div>
                <div class="mark">
                    <div>Status markerade ({{ markedAvstamningar.length }})</div>
                    <div>
                        <FormulateInput
                            class="status-markerade-input"
                            type="select"
                            @change="setMarked"
                            :disabled="markedAvstamningar.length < 1"
                            :options="[
                                '',
                                'Ej avstämd',
                                'Närvarande',
                                'Skada sker',
                                'Semester',
                                'Deltar ej',
                                'Sjukdom',
                                'Skadad',
                            ]"
                        />
                    </div>
                </div>
                <ButtonGroup>
                    <Button size="primary" role="button" type="submit" :disabled="!avstamningarHasUnsavedData">
                        Spara avstämning
                    </Button>
                    <Button
                        @click="cancelAvstamningar()"
                        size="secondary"
                        type="button"
                        :disabled="!avstamningarHasUnsavedData"
                    >
                        Avbryt
                    </Button>
                </ButtonGroup>
            </FormulateForm>
        </numbered-section>
    </div>
</template>

<style lang="scss" scoped>
@import "@/style/variables";
@import "@/style/vendors/formulate";
@import "@/style/checkbox";

.aktivitet {
    .first-row {
        display: flex;
        gap: 10px;
        justify-content: space-evenly;
        align-items: stretch;

        .control {
            flex-basis: 20%;
        }
    }

    .second-row {
        display: flex;
        gap: 10px;
        justify-content: space-evenly;
        align-items: stretch;

        .control {
            flex-basis: 25%;
        }

        .aktivitetstyp {
            flex-basis: 50%;
        }
    }

    .control {
        span {
            display: block;
        }

        &.invalid {
            ::v-deep label,
            ::v-deep input,
            ::v-deep span {
                color: #9e354b;
            }

            ::v-deep input {
                border: 1px #9e354b solid;
            }
        }

        &.required {
            ::v-deep label {
                &::after {
                    content: " *";
                }
            }
        }
    }

    .aktiviten_skapad {
        display: inline-block;
        padding: 36px 6px;
        margin: 0px 1rem 0 0;
    }

    .avstamningar {
        .table {
            display: flex;
            flex-flow: column nowrap;
            margin: 0.5rem;
            line-height: 1.5;
            border-bottom: 1px solid #d0d0d0;
            flex: 1 1 auto;

            .th {
                display: none;
                font-weight: normal;
                color: $color-thead;
            }

            .th .td {
                white-space: normal;
            }

            .tr {
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                border-bottom: 1px solid #d3d8da;
            }

            ::v-deep .formulate-input-group-repeatable {
                width: 100%;
            }

            .td {
                display: flex;
                flex-flow: row nowrap;
                flex-grow: 10;
                flex-basis: 0;
                padding: 0.5em;
                word-break: break-word;
                overflow: hidden;
                text-overflow: ellipsis;
                min-width: 0px;
                white-space: nowrap;
                border-bottom: 1px solid #d0d0d0;

                &:first-child {
                    flex-grow: 1;
                }

                &.status {
                    flex-direction: column;

                    .journalanteckning {
                    }
                }
            }
        }

        .formulate-input {
            width: 100%;
        }

        .mark {
            display: flex;
            flex-flow: row nowrap;
            background-color: $color-background-list;
            padding: 1em 2em;

            div {
                line-height: 46px;
                flex-grow: 5;
                flex-basis: 0;
                align-items: center;

                &:first-child {
                    flex-grow: 1;
                }
            }

            ::v-deep .status-markerade-input {
                max-width: 20em;

                select {
                    background-color: #fff;
                }
            }
        }
    }
}
</style>
