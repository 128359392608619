<template>
    <div id="login-details">
        <template v-if="!profiles.length">
            <template>
                <div class="login-header">Logga in</div>
                <div class="customer">{{ kundNamn }}</div>
                <div v-if="!authenticationMethod" class="login-button-wrapper">
                    <button
                        class="login-button basic"
                        v-if="basicAllowed"
                        @click="setAuthentication('Basic')"
                        role="button"
                    >
                        <img src="@/assets/password.svg" /> Logga in med användarnamn och lösenord
                    </button>
                    <button
                        class="login-button bankid"
                        v-if="bankIDAllowed"
                        role="button"
                        @click="setAuthentication('BankID')"
                    >
                        <img src="@/assets/bankid.svg" /> Logga in med mobilt bankid
                    </button>
                    <button
                        class="login-button siths"
                        v-if="netIDAllowed"
                        @click="
                            setAuthentication('NetID');
                            login();
                        "
                        role="button"
                    >
                        <img src="@/assets/siths.svg" /> Logga in med SITHS-kort
                    </button>
                </div>
            </template>
            <template v-if="status">
                <form @submit.prevent>
                    <div class="login-message">{{ status.message }}</div>
                    <div style="margin-top: 49px; margin-bottom: 49px">
                        <img v-if="status.success" src="@/assets/success_big.svg" />
                        <img v-else src="@/assets/error_big.svg" />
                    </div>
                    <div class="login-action-button-wrapper" v-if="!status.success">
                        <button role="button" type="submit" disabled class="btn btn-secondary">Logga in</button>
                        <button role="button" type="button" @click="cancel" class="btn btn-outline-secondary">
                            Avbryt
                        </button>
                        <button
                            v-if="authenticationMethods.length > 1"
                            role="button"
                            type="button"
                            @click="cancel"
                            class="btn btn-outline-secondary"
                        >
                            Välj annat inloggningssätt
                        </button>
                    </div>
                </form>
            </template>
            <template v-if="authenticationMethod === 'BankID' && !status">
                <form @submit.prevent="login" v-if="!sessionParams.polling">
                    <div>
                        <span>Personnummer (ÅÅÅÅMMDDNNNN)</span>
                        <input class="personnummerInput" autofocus required pattern="[0-9]{12}" v-model="personId" />
                    </div>
                    <div class="login-action-button-wrapper">
                        <button role="button" type="submit" :disabled="loginActive" class="btn btn-secondary btn-login">
                            Logga in
                        </button>
                        <button role="button" type="button" @click="cancel" class="btn btn-outline-secondary btn-login">
                            Avbryt
                        </button>
                        <button
                            v-if="authenticationMethods.length > 1"
                            role="button"
                            type="button"
                            @click="cancel"
                            class="btn btn-outline-secondary btn-login"
                        >
                            Välj annat inloggningssätt
                        </button>
                    </div>
                </form>
                <form @submit.prevent="login" v-else>
                    <div class="login-message">Öppna Bank-ID appen på din mobila enhet.</div>
                    <div style="margin-top: 49px; margin-bottom: 49px">
                        <img class="spin" src="@/assets/loader.svg" />
                    </div>
                    <div class="login-action-button-wrapper">
                        <div class="button-span">Loggar in...</div>
                        <button role="button" type="button" @click="cancel" class="btn btn-outline-secondary">
                            Avbryt
                        </button>
                        <button
                            v-if="authenticationMethods.length > 1"
                            role="button"
                            type="button"
                            @click="cancel"
                            class="btn btn-outline-secondary"
                        >
                            Välj annat inloggningssätt
                        </button>
                    </div>
                </form>
            </template>
            <template v-if="authenticationMethod === 'NetID' && !status">
                <form @submit.prevent="login" v-if="!sessionParams.polling">
                    <div class="login-message" style="min-height: 162px">
                        Kontrollera att ditt SITHS-kort sitter i kortläsaren.
                    </div>
                    <div class="login-action-button-wrapper">
                        <button role="button" type="submit" class="btn btn-secondary btn-login">Logga in</button>
                        <button role="button" type="button" @click="cancel" class="btn btn-outline-secondary btn-login">
                            Avbryt
                        </button>
                        <button
                            v-if="authenticationMethods.length > 1"
                            role="button"
                            type="button"
                            @click="cancel"
                            class="btn btn-outline-secondary btn-login"
                        >
                            Välj annat inloggningssätt
                        </button>
                    </div>
                </form>
                <form @submit.prevent="login" v-else>
                    <div class="login-message">
                        Net ID Access-inloggningen öppnas i ett nytt webbläsarfönster. Du kan behöva godkänna en
                        förfrågan från webbläsaren om att öppna fönstret. Glöm inte att sätta i ditt kort för att
                        legitimera dig.
                    </div>
                    <div style="margin-top: 49px; margin-bottom: 49px">
                        <img class="spin" src="@/assets/loader.svg" />
                    </div>
                    <div class="login-action-button-wrapper">
                        <div class="button-span">Loggar in...</div>
                        <button role="button" type="button" @click="cancel" class="btn btn-outline-secondary">
                            Avbryt
                        </button>
                        <button
                            v-if="authenticationMethods.length > 1"
                            role="button"
                            type="button"
                            @click="cancel"
                            class="btn btn-outline-secondary"
                        >
                            Välj annat inloggningssätt
                        </button>
                    </div>
                </form>
            </template>
            <template v-if="authenticationMethod === 'Basic' && !status">
                <form @submit.prevent="login">
                    <div class="basicInputs">
                        <span>Användarnamn</span>
                        <text-widget class="basicField" autofocus placeholder="Användarnamn" v-model="userName" />
                        <span>Lösenord</span>
                        <text-widget
                            class="basicField"
                            placeholder="Lösenord"
                            type="password"
                            label="Lösenord"
                            v-model="password"
                        />
                    </div>
                    <div class="login-action-button-wrapper">
                        <button role="button" type="submit" class="btn btn-secondary btn-login">Logga in</button>
                        <button role="button" type="button" @click="cancel" class="btn btn-outline-secondary btn-login">
                            Avbryt
                        </button>
                    </div>
                </form>
            </template>
        </template>
        <template v-else>
            <div class="login-header">Välj din inloggningsprofil</div>
            <div class="customer">{{ kundNamn }}</div>
            <div class="k3-login-message">Du är inloggad som {{ profiles[0].user.name }}</div>
            <div class="login-button-wrapper" style="margin-top: 10px">
                <button
                    v-for="profile in profiles"
                    :key="'profile-button-' + profile.pk"
                    class="login-button profile"
                    @click="selectProfile(profile.pk)"
                    role="button"
                >
                    <img src="@/assets/profil.svg" /><b>Befattning:</b> {{ getOccupation(profile) }} <b>Vårdenhet:</b>
                    {{ profile.orgUnit.name }}
                </button>
            </div>
        </template>
    </div>
</template>
<script>
import widgets from "@/components/widgets/inputs";
import {
    getConfig,
    getErrorMessage,
    klinikenApi,
    refreshJWT,
    setAuthenticationMethod,
    setFallbackSignMethod,
    setJWT,
    setLogoutMethod,
    setRefreshToken,
} from "@/api";
import { init, poll } from "@/utils/auth";
import { mapGetters } from "vuex";
import { flashMessage, openDialog } from "@/utils";
import getUrlParams from "@/utils/getUrlParams";

export default {
    name: "Login",
    components: {
        ...widgets,
    },
    computed: {
        ...mapGetters("userData", ["profiles"]),
        ...mapGetters("userAdmin", ["getOccupation"]),
        ...mapGetters("systemData", ["kundNamn", "authenticationMethods", "fallbackSignMethod"]),
        bankIDAllowed() {
            return this.authenticationMethods ? this.authenticationMethods.indexOf("BankID") !== -1 : false;
        },
        netIDAllowed() {
            return this.authenticationMethods ? this.authenticationMethods.indexOf("NetID") !== -1 : false;
        },
        basicAllowed() {
            return this.authenticationMethods ? this.authenticationMethods.indexOf("Basic") !== -1 : false;
        },
    },
    data() {
        return {
            personId: "",
            userName: "",
            password: "",
            refresh: null,
            sessionParams: {
                polling: false,
                sessionId: null,
            },
            authenticationMethod: null,
            status: null,
            loginActive: false,
        };
    },
    methods: {
        async refreshSession() {
            let sessionWindow = sessionStorage.getItem("Window");
            let localWindow = localStorage.getItem("Window");
            let refresh = sessionStorage.getItem("Refresh");
            let method = sessionStorage.getItem("AuthenticationMethod");
            let pk = sessionStorage.getItem("Profile");
            if (sessionWindow === window.name && localWindow === window.name) {
                if (refresh && pk) {
                    setRefreshToken(refresh);
                    if (refresh) await refreshJWT();
                    this.getProfile();
                    if (this.profiles.length !== 1) this.selectProfile(pk);
                    if (method !== null) {
                        setAuthenticationMethod(method);
                    }
                    return true;
                }
            }
            return false;
        },
        async autoLogin() {
            let urlParams = getUrlParams();
            let jwt = urlParams.jwt;

            if (jwt) {
                let verified = false;
                flashMessage("Loggar in...");
                await klinikenApi

                    .post("/auth/external/verify/", { token: jwt })
                    .then((response) => {
                        let data = response.data;

                        if (data) {
                            if (data.access) setJWT(data.access, true);
                            if (data.refresh) setRefreshToken(data.refresh);
                            if (data.access && data.refresh) verified = true;
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 401) {
                            openDialog(
                                "Autentiseringsfel. Du kunde inte loggas in med de angivna uppgifterna.",
                                "error"
                            );
                        } else openDialog(getErrorMessage(e), "error");
                    });
                if (!verified) return new Promise((resolve) => resolve());

                await this.getProfile();

                return new Promise((resolve) => resolve());
            }
        },
        setAuthentication(method) {
            this.authenticationMethod = method;
        },
        async login() {
            if (this.loginActive) return;
            this.loginActive = true;
            this.status = null;
            let redirectUrl = null;
            if (this.authenticationMethod === "BankID" || this.authenticationMethod === "NetID") {
                await init({
                    apiUrl: this.authenticationMethod === "BankID" ? "auth/bankid/init" : "auth/netid/init",
                    params: this.authenticationMethod === "BankID" ? { personalNumber: this.personId } : null,
                    responseHandler: (data) => {
                        this.sessionParams.sessionId = data.sessionId;
                        this.sessionParams.polling = true;
                        redirectUrl = data.redirectUrl;
                    },
                });
                if (this.authenticationMethod === "NetID") {
                    if (!redirectUrl) return;
                    window.location = redirectUrl;
                }
                await poll({
                    state: this.sessionParams,
                    apiUrl: this.authenticationMethod === "BankID" ? "/auth/bankid/poll" : "/auth/netid/poll",
                    responseHandler: (status, response) => {
                        this.sessionParams.polling = false;
                        if (status === "error" || status === "failed") {
                            this.status = { success: false, message: response };
                        } else {
                            setRefreshToken(response.refresh);
                            setJWT(response.access);
                            this.status = {
                                success: true,
                                message: "Toppen! Du är nu inloggad.",
                            };
                            setTimeout(this.getProfile, 1500);
                        }
                    },
                });
            } else if (this.authenticationMethod === "Basic") {
                const config = getConfig();
                klinikenApi
                    .post("auth/basic/", { username: this.userName, password: this.password }, config)
                    .then((response) => {
                        let data = response.data;
                        setRefreshToken(data.refresh);
                        setJWT(data.access);
                        this.getProfile();
                    })
                    .catch((e) => {
                        if (e.response.status === 401) {
                            openDialog(
                                "Autentiseringsfel. Du kunde inte loggas in med de angivna uppgifterna.",
                                "error"
                            );
                        } else openDialog(getErrorMessage(e), "error");
                    });
            }
            this.loginActive = false;
        },
        /**
         * Get all profiles for the current user from backend and store in Vuex.
         */
        async getProfile() {
            if (this.authenticationMethod) setAuthenticationMethod(this.authenticationMethod);
            const config = getConfig();
            await klinikenApi
                .get("/core/users/current/profiles/", config)
                .then((response) => {
                    let profiles = response.data.results;
                    this.$store.dispatch("userData/loadProfiles", {
                        profiles: profiles,
                    });

                    if (profiles.length === 0) {
                        this.status = {
                            success: false,
                            message: "Användaren saknar aktiva profiler.",
                        };
                    }

                    //  If there only is one profile, set that automatically to current profile.
                    if (profiles.length === 1) {
                        this.selectProfile(profiles[0].pk);
                    }
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                });

            this.$store.dispatch("userAdmin/loadYrkeskoder");
        },
        setLoginMethod(method) {
            this.loginMethod = method;
            this.$store.commit("userData/setLoginMethod", method);
        },
        /**
         * Sets current profile (only called when there are at least two profiles).
         */
        selectProfile(pk) {
            this.$store.commit("userData/setCurrentProfile", pk);
            localStorage.setItem("Window", window.name);
            sessionStorage.setItem("Window", window.name);
        },
        cancel() {
            this.sessionParams.polling = false;
            this.authenticationMethod = null;
            this.status = null;
        },
    },
    async created() {
        /**
         * Before everything else, get systeminformation.
         * TODO: add improved error handling if this API call fails, since this is the first API call that takes place.
         */
        await this.$store.dispatch("systemData/loadFromBackend", {
            failSilently: true,
        });
        if (this.fallbackSignMethod) setFallbackSignMethod(this.fallbackSignMethod);
        setLogoutMethod(() => {
            this.$store.dispatch("userData/logout");
        });
    },
    async mounted() {
        /**
         * Check if there is an active session where user is already logged in (refresh jwt and profile pk stored in sessionStorage),
         * and if so use that information to bypass login. This happens when sessionStorage is intact, i.e. F5-refresh
         * or restored window after crash.
         */
        let isSessionRefresh = await this.refreshSession();
        // Else try an automatic login based on ?jwt= url parameter
        if (!isSessionRefresh) this.autoLogin();
    },
};
</script>
<style lang="sass" scoped>
@import "@/style/variables"

/* Bootstrap - Start */
@import "bootstrap/scss/functions"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"
@import "bootstrap/scss/root"
@import "bootstrap/scss/reboot"

@import "bootstrap/scss/type"
@import "bootstrap/scss/buttons"
/* Bootstrap - End */

@import "@/style/deprecated_main"

@-ms-keyframes spin
    0%
        -ms-transform: rotate(0deg)
    100%
        -ms-transform: rotate(360deg)

@-moz-keyframes spin
    0%
        -moz-transform: rotate(0deg)
    100%
        -moz-transform: rotate(360deg)

@-webkit-keyframes spin
    0%
        -webkit-transform: rotate(0deg)
    100%
        -webkit-transform: rotate(360deg)

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

@media screen and (max-width: 620px)
    #login-details
        > div
            padding-left: calc((100vw - 420px) / 2) !important
            padding-right: calc((100vw - 420px) / 2) !important
            min-width: 420px !important

@media screen and (max-width: 920px)
    .login-action-button-wrapper
        flex-direction: column !important

    input
        width: 400px !important

.btn-login
    margin-bottom: 24px

#login-details
    display: flex
    flex-direction: column
    background-color: white
    max-width: 953px
    min-height: 390px
    max-height: 750px
    padding: 48px 0px 48px 0px
    margin-left: auto
    margin-right: auto
    margin-top: 70px
    overflow-x: auto

    > div
        min-width: 600px
        width: 100%
        flex: 0
        text-align: center

        &.login-button-wrapper
            height: 283px
            padding-left: 114px
            padding-right: 114px
            margin-top: 20px
            flex: 1
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center

    > form
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        padding-top: 46px

        ::v-deep(input)
            width: 483px

        > div

            &.login-action-button-wrapper
                display: flex
                flex-direction: row
                align-items: center
                justify-content: center

                button, div
                    min-width: 260px
                    margin-left: 12px
                    margin-right: 12px
                    margin-bottom: 24px

    .spin
        -webkit-animation-name: spin
        -webkit-animation-duration: 4000ms
        -webkit-animation-iteration-count: infinite
        -webkit-animation-timing-function: linear
        -moz-animation-name: spin
        -moz-animation-duration: 4000ms
        -moz-animation-iteration-count: infinite
        -moz-animation-timing-function: linear
        -ms-animation-name: spin
        -ms-animation-duration: 4000ms
        -ms-animation-iteration-count: infinite
        -ms-animation-timing-function: linear
        animation-name: spin
        animation-duration: 4000ms
        animation-iteration-count: infinite
        animation-timing-function: linear

    .button-span
        height: 37px
        color: #354052
        font-size: 20px
        letter-spacing: 0
        line-height: 28px
        text-align: center
        display: inline-block

    span
        height: 21px
        color: #728296
        font-size: 16px
        font-weight: 500
        letter-spacing: 0
        line-height: 21px
        display: block

    input
        height: 46px
        max-width: 483px
        border-radius: 4px
        background-color: #FFFFFF
        color: #354052
        font-size: 16px
        letter-spacing: 0
        line-height: 21px

    .login-header
        font-family: Roboto
        height: 48px
        width: 100%
        color: #277692
        font-size: 38px
        letter-spacing: 0
        line-height: 42px
        text-align: center

    .login-message
        color: #354052
        font-size: 20px
        letter-spacing: 0
        line-height: 28px
        text-align: center

    .customer
        font-family: Roboto
        height: 29px
        width: 100%
        color: #277692
        font-size: 16px
        letter-spacing: 0
        line-height: 21px
        text-align: center
        margin-top: 6px

    .login-button
        box-sizing: border-box
        min-height: 78px
        width: 100%
        border: 1px solid #38A7CF
        border-radius: 2px
        margin: 17px 0px 17px 0px
        background-color: white
        font-family: Roboto Medium
        color: #2694BC
        font-size: 16px
        font-weight: 500
        letter-spacing: 0
        line-height: 21px
        text-align: left

        &:hover
            background-color: #C8E6EC

        &.basic
            img
                padding-left: 45px
                padding-right: 32px

        &.bankid
            img
                padding-left: 45px
                padding-right: 32px

        &.siths
            img
                padding-left: 38px
                padding-right: 23px

        &.profile
            img
                padding-left: 25px
                padding-right: 20px

    .k3-login-message
        height: 32px
        width: 100%
        color: #354052
        font-size: 20px
        letter-spacing: 0
        line-height: 28px
        text-align: center
        padding-top: 30px

.basicInputs
    margin-bottom: 37px
    max-width: 483px
    align-items: center

    ::v-deep(input)
        margin-bottom: 20px

.personnummerInput
    margin-bottom: 89px
</style>
