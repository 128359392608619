<template>
    <form @submit.prevent="submit" id="patient-registration-details">
        <h2 v-if="!editPatient">Skapa ny patient</h2>
        <h2 v-else>Redigera patient</h2>
        <div class="form-group k3-form">
            <input-wrapper
                :component="widgets.PersonIdWidget"
                label="Person-id"
                name="personId"
                v-model="formData.personId"
                :required="true"
            />
            <input-wrapper
                :component="widgets.SelectWidget"
                label="Typ av person-id"
                name="personIdType"
                @input="personIdTypeChanged"
                v-model="formData.personIdType"
                apisearch="/core/kodverk/personidtypes/"
                :map="
                    (el) => {
                        return { value: el.code, text: el.displayName };
                    }
                "
                :required="true"
                :no-results="true"
            />
            <input-wrapper
                :component="widgets.CheckboxWidget"
                label="Patienten är förmånsberättigad"
                :disabled="personIdTypeIsPersonnummer"
                name="careCoverage"
                v-model="formData.careCoverage"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="Förnamn"
                name="fornamn"
                v-model="formData.fornamn"
                :required="true"
                :maxlength="100"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="Efternamn"
                name="efternamn"
                v-model="formData.efternamn"
                :required="true"
                :maxlength="100"
            />
            <input-wrapper
                :component="widgets.DateWidget"
                label="Födelsedatum"
                name="dateOfBirth"
                v-model="formData.dateOfBirth"
                :required="true"
            />
            <input-wrapper
                :component="widgets.SelectWidget"
                label="Kön"
                name="gender"
                v-model="formData.gender"
                apisearch="/core/kodverk/genders/"
                :map="
                    (el) => {
                        return { value: el.code, text: el.displayName };
                    }
                "
                :required="true"
                :no-results="true"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="Adressrad 1"
                name="adressRad1"
                v-model="formData.adressRad1"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="Adressrad 2"
                name="adressRad2"
                v-model="formData.adressRad2"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="Postnummer"
                name="postnummer"
                v-model="formData.postnummer"
                :maxlength="5"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="Postort 1"
                name="postort"
                v-model="formData.postort"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="C/o-adress"
                name="coAdress"
                v-model="formData.coAdress"
            />
            <input-wrapper :component="widgets.TextWidget" label="Telefon" name="telefon" v-model="formData.telefon" />
            <input-wrapper
                :component="widgets.CheckboxWidget"
                :show-empty-label="true"
                checkbox-label="Önskar sms"
                name="onskar_sms"
                v-model="formData.onskar_sms"
            />
            <input-wrapper :component="widgets.TextWidget" label="E-post" name="email" v-model="formData.email" />
            <input-wrapper
                :component="widgets.CheckboxWidget"
                :show-empty-label="true"
                checkbox-label="Sekretessmarkerad"
                name="sekretess"
                v-model="formData.sekretess"
            />
            <input-wrapper
                :component="widgets.CheckboxWidget"
                :show-empty-label="true"
                checkbox-label="Skyddad identitet"
                name="skyddad_identitet"
                v-model="formData.skyddad_identitet"
            />
        </div>
        <ButtonGroup class="form-group">
            <Button type="submit" size="primary" :disabled="!hasUnsavedData">Spara och stäng</Button>
            <Button type="button" size="secondary" @click="close()">Avbryt</Button>
        </ButtonGroup>
    </form>
</template>
<script>
import widgets from "@/components/widgets/inputs";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import BaseTab from "@/tabs/mixins/BaseTab";
import { flashMessage, openDialog } from "@/utils";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { mapGetters } from "vuex";
import tabEvents from "@/tabEvents";
import tabIcon from "@/assets/receptforskrivning.svg";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import { cloneDeep, isEqual } from "lodash";

export default {
    extends: BaseTab,
    name: "PatientRegistration",
    tabName: "Skapa ny patient",
    icon: tabIcon,
    patientBound: false,

    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },

    components: {
        ...widgets,
        InputWrapper,
        Button,
        ButtonGroup,
    },
    data() {
        return {
            defaultFormData: {},
            formData: {},
            widgets: widgets,
        };
    },
    computed: {
        ...mapGetters("patientData", ["editPatient", "currentId"]),
        hasUnsavedData() {
            return !isEqual(this.defaultFormData, this.formData);
        },
        personIdTypeIsPersonnummer() {
            return this.formData.personIdType === "1.2.752.129.2.1.3.1";
        },
    },
    methods: {
        async submit() {
            let details = this.formData;
            const config = getConfig();
            details.sekretess = Boolean(details.sekretess);
            details.skyddad_identitet = Boolean(details.skyddad_identitet);
            details.onskar_sms = Boolean(details.onskar_sms);
            await (this.editPatient
                ? klinikenApi.put("/core/patienter/" + this.editPatient + "/", details, config)
                : klinikenApi.post("/core/patienter/", details, config)
            )
                .then((response) => {
                    if (this.currentId && this.editPatient === this.currentId) {
                        this.$store.commit("patientData/load", response.data);
                    }
                    flashMessage("Patient sparad.");
                    this.$store.dispatch("tabs/closeTab", this.tabId);
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                });
            tabEvents.$emit("refreshPatientSearch", {});
        },
        close() {
            this.closeTab(this.hasUnsavedData);
        },
        async getPatient(id) {
            let patient = null;
            const config = getConfig();
            await klinikenApi
                .get("core/patienter/" + id + "/", config)
                .then((response) => {
                    patient = response.data;
                })
                .catch((e) => {
                    openDialog("Kunde inte hämta patient från servern." + getErrorMessage(e), "warning");
                });
            return patient;
        },
        personIdTypeChanged(eventValue) {
            if (eventValue === "1.2.752.129.2.1.3.1") this.formData.careCoverage = true;
        },
    },
    async created() {
        if (this.editPatient) {
            let patient = await this.getPatient(this.editPatient);

            this.defaultFormData = cloneDeep(patient);
            this.formData = patient;

            if (patient.personIdType === "1.2.752.129.2.1.3.1") this.formData.careCoverage = true;

            this.$store.commit("tabs/setPatientBound", {
                id: this.tabId,
                flag: true,
            });
            this.$store.commit("tabs/setTabName", { id: this.tabId, name: "Redigera patient" });
        }
    },
    beforeDestroy() {
        this.$store.commit("patientData/setEditPatient", null);
    },
};
</script>
<style lang="sass" scoped>
@import "@/style/variables"
@import "@/style/deprecated_main"

#patient-registration-details
    position: relative
    max-width: 1100px

::v-deep .colspan
    padding-left: 0px !important
    padding-right: 20px !important

    input
        width: 232px
</style>
