import AlertInformation from "@/tabs/AlertInformation/AlertInformation.vue";
import AlertInformationForm from "@/tabs/AlertInformation/AlertInformationForm.vue";
import Anvandare from "@/tabs/Admin/Anvandare.vue";
import BaseTab from "@/tabs/mixins/BaseTab";
import Favorit from "@/tabs/PrescriptionSet/Favorit.vue";
import FilhanteringFiler from "@/tabs/Filhantering/FilhanteringFiler.vue";
import FilhanteringFilval from "@/tabs/Filhantering/FilhanteringFilval.vue";
import FormAktivitet from "@/tabs/Skreg/Aktiviter/FormAktivitet.vue";
import FormAktivitetstyp from "@/tabs/Skreg/Aktivitetstyper/FormAktivitetstyp.vue";
import FormLag from "@/tabs/Skreg/Lag/FormLag.vue";
import FormPlats from "@/tabs/Skreg/Platser/FormPlats.vue";
import FormUnderlag from "@/tabs/Skreg/Underlag/FormUnderlag.vue";
import Intyg from "@/tabs/Intyg/Intyg.vue";
import IntygsmallForm from "@/tabs/Intyg/IntygsmallForm.vue";
import Journalanteckning from "@/tabs/Journalanteckningar/Journalanteckning.vue";
import JournalAnteckningar from "@/tabs/Journalanteckningar/JournalAnteckningar.vue";
import Journalloggar from "@/tabs/Journallogg/Journalloggar.vue";
import LabPortalenInkorg from "@/tabs/LabPortalen/LabPortalenInkorg.vue";
import LabPortalenPatientoversikt from "@/tabs/LabPortalen/LabPortalenPatientoversikt.vue";
import LabPortalenUtkorg from "@/tabs/LabPortalen/LabPortalenUtkorg.vue";
import ListAktiviteter from "@/tabs/Skreg/Aktiviter/ListAktiviteter.vue";
import ListAktivitetstyper from "@/tabs/Skreg/Aktivitetstyper/ListAktivitetstyper.vue";
import ListAnteckningstyp from "@/tabs/Admin/ListAnteckningstyp.vue";
import ListAnvandare from "@/tabs/Admin/ListAnvandare.vue";
import ListICDKod from "@/tabs/Admin/ListICDKod.vue";
import ListKVAKod from "@/tabs/Admin/ListKVAKod.vue";
import ListLag from "@/tabs/Skreg/Lag/ListLag.vue";
import ListOrgUnit from "@/tabs/Admin/ListOrgUnit.vue";
import ListPlatser from "@/tabs/Skreg/Platser/ListPlatser.vue";
import ListSokord from "@/tabs/Admin/ListSokord.vue";
import ListSokordsstruktur from "@/tabs/Admin/ListSokordsstruktur.vue";
import ListUnderlag from "@/tabs/Skreg/Underlag/ListUnderlag.vue";
import ListaFavoriter from "@/tabs/PrescriptionSet/ListaFavoriter.vue";
import ListaIntyg from "@/tabs/Intyg/ListaIntyg.vue";
import MakuleraAlertInformation from "@/tabs/AlertInformation/MakuleraAlertInformation.vue";
import Operationsanmalan from "@/tabs/Operation/Operationsanmalan.vue";
import Operationsinformation from "@/tabs/Operation/Operationsinformation.vue";
import Operationsjournal from "@/tabs/Operation/Operationsjournal.vue";
import Operationskalender from "@/tabs/Operation/Kalender/Operationskalender.vue";
import Operationskoordinering from "@/tabs/Operation/Operationskoordinering.vue";
import Operationsoversikt from "@/tabs/Operation/Operationsoversikt.vue";
import OrgUnit from "@/tabs/Admin/OrgUnit.vue";
import Preanestesibedomning from "@/tabs/Operation/Preanestesibedomning.vue";
import Preanestesibedomningar from "@/tabs/Operation/Preanestesibedomningar.vue";
import PrescriptionSet from "@/tabs/PrescriptionSet/PrescriptionSet.vue";
import Samtycke from "@/tabs/Samtycke/Samtycke.vue";
import SamtyckesForm from "@/tabs/Samtycke/SamtyckesForm.vue";
import Sokordsstruktur from "@/tabs/Admin/Sokord.vue";
import Spelare from "@/tabs/Skreg/Spelare/Spelare.vue";
import NarvarostatistikLag from "@/tabs/Skreg/Statistik/Narvarostatistik/NarvarostatistikLag.vue";
import NarvarostatistikSpelare from "@/tabs/Skreg/Statistik/Narvarostatistik/NarvarostatistikSpelare.vue";
import StatistikMain from "@/tabs/Skreg/Statistik/StatistikMain.vue";
import TabContainer from "@/tabs/TabContainer.vue";
import TypoperationForm from "@/tabs/Operation/Admin/TypoperationForm.vue";
import Typoperationer from "@/tabs/Operation/Admin/Typoperationer.vue";
import Journalanteckningsmallar from "@/tabs/Journalanteckningmallar/Journalanteckningsmallar.vue";
import Journalanteckningsmall from "@/tabs/Journalanteckningmallar/Journalanteckningsmall.vue";
import SMSMallar from "@/tabs/SMS/SMSMallar.vue";
import SMSKallelser from "@/tabs/SMS/SMSKallelser.vue";
import PagaendeOperation from "@/tabs/Operation/PagaendeOperation.vue";

export default {
    AlertInformation,
    AlertInformationForm,
    Anvandare,
    BaseTab,
    Favorit,
    FilhanteringFiler,
    FilhanteringFilval,
    FormAktivitet,
    FormAktivitetstyp,
    FormLag,
    FormPlats,
    FormUnderlag,
    Intyg,
    IntygsmallForm,
    Journalanteckning,
    JournalAnteckningar,
    Journalloggar,
    LabPortalenInkorg,
    LabPortalenPatientoversikt,
    LabPortalenUtkorg,
    ListaFavoriter,
    ListaIntyg,
    ListAktiviteter,
    ListAktivitetstyper,
    ListAnteckningstyp,
    ListAnvandare,
    ListICDKod,
    ListKVAKod,
    ListLag,
    ListOrgUnit,
    ListPlatser,
    ListSokord,
    ListSokordsstruktur,
    ListUnderlag,
    MakuleraAlertInformation,
    Operationsanmalan,
    Operationsinformation,
    Operationsjournal,
    Operationskalender,
    Operationskoordinering,
    Operationsoversikt,
    OrgUnit,
    Preanestesibedomning,
    Preanestesibedomningar,
    PrescriptionSet,
    Samtycke,
    SamtyckesForm,
    SMSMallar,
    SMSKallelser,
    Sokordsstruktur,
    Spelare,
    NarvarostatistikLag,
    NarvarostatistikSpelare,
    StatistikMain,
    TabContainer,
    Typoperationer,
    TypoperationForm,
    Journalanteckningsmallar,
    Journalanteckningsmall,
    PagaendeOperation,
};
