<template>
    <div class="orsak-till-avvaktan">
        <div class="patient-dialog-container">
            <DialogAktivPatient :patient="patient" />

            <DialogAnmalaninfo :anmalaninfo="anmalaninfo" />
        </div>

        <div class="data">
            <div>
                <strong>Orsak till avvaktan: </strong>
                {{ params.undantagskod_display }}
            </div>
            <div>
                <strong>Kommentar: </strong>
                {{ params.undantagskommentar }}
            </div>

            <ButtonGroup>
                <Button size="primary" @click="close()">Stäng</Button>
            </ButtonGroup>
        </div>
    </div>
</template>

<script>
import dialogEvents from "@/dialogEvents";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import DialogAktivPatient from "@/tabs/Operation/DialogAktivPatient.vue";
import DialogAnmalaninfo from "@/tabs/Operation/DialogAnmalaninfo.vue";
import moment from "moment";

export default {
    name: "OrsakTillAvvaktan",
    components: {
        Button,
        ButtonGroup,
        DialogAktivPatient,
        DialogAnmalaninfo,
    },
    data() {
        return {
            params: this.$attrs.response,
        };
    },
    computed: {
        patient: function () {
            return {
                personId: this.params.patient_display.personId,
                namn: this.params.patient_display.namn,
            };
        },
        anmalaninfo: function () {
            return {
                datum: this.datum,
                tid: this.tid,
                huvudoperator: this.params.current_bokad_huvudoperator_display.user_display.name,
                huvudoperationskod: `${this.params.huvudoperationskod_display.code} ${this.params.huvudoperationskod_display.klassdel_display} ${this.params.huvudoperationskod_display.displayName}`,
            };
        },

        datum: function () {
            if (this.params.undantagsdatum !== undefined && this.params.undantagsdatum !== null) {
                return moment(this.params.undantagsdatum).format("YYYY-MM-DD");
            }
            return "-";
        },
        tid: function () {
            if (this.params.undantagsdatum !== undefined && this.params.undantagsdatum !== null) {
                return moment(this.params.undantagsdatum).format("HH:mm");
            }
            return "-";
        },
    },
    methods: {
        close() {
            dialogEvents.$emit("closePopup");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";
.orsak-till-avvaktan {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: inherit;
    padding: inherit;
    padding-bottom: 0px;
    padding-top: 0px;

    .patient-dialog-container {
        padding: 0px 50px;
        display: grid;
        gap: 20px;
    }

    .data {
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 0px 50px;

        strong {
            display: block;
            font-weight: 500;
            line-height: 30px;
            color: $color-label-grey;
        }
    }
}
</style>
