<template>
    <div class="orsak-till-avbokning-och-strykning">
        <div class="patient-dialog-container">
            <DialogAktivPatient :patient="patient" />

            <DialogAnmalaninfo :anmalaninfo="anmalaninfo" />
        </div>

        <div class="data">
            <div v-if="params.strykningsorsak_display?.base !== undefined">
                <strong>Orsak till avbokning eller strykning</strong>
                {{ params.strykningsorsak_display?.base?.displayName }}
            </div>
            <div>
                <strong>Förklaring</strong>
                {{ params.strykningsorsak_display?.displayName }}
            </div>
            <div>
                <strong>Kommentar</strong>
                {{ params.strykningskommentar }}
            </div>
            <div>
                <strong>Avförd från väntelistan</strong>
                {{ avfors_vantelista }}
            </div>

            <ButtonGroup>
                <Button size="primary" @click="close()">Stäng</Button>
            </ButtonGroup>
        </div>
    </div>
</template>

<script>
import dialogEvents from "@/dialogEvents";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import DialogAktivPatient from "@/tabs/Operation/DialogAktivPatient.vue";
import DialogAnmalaninfo from "@/tabs/Operation/DialogAnmalaninfo.vue";
import moment from "moment";

export default {
    name: "OrsakTillAvbokningOchStrykning",
    components: {
        Button,
        ButtonGroup,
        DialogAktivPatient,
        DialogAnmalaninfo,
    },
    data() {
        return {
            params: this.$attrs.response,
        };
    },
    computed: {
        patient: function () {
            return {
                personId: this.params.patient_display.personId,
                namn: this.params.patient_display.namn,
            };
        },
        anmalaninfo: function () {
            return {
                datum: this.datum,
                tid: this.tid,
                huvudoperator: this.params.current_bokad_huvudoperator_display?.user_display?.name,
                huvudoperationskod: `${this.params.huvudoperationskod_display?.code} ${this.params.huvudoperationskod_display?.klassdel_display} ${this.params.huvudoperationskod_display?.displayName}`,
            };
        },
        datum: function () {
            if (this.params.strykningstidpunkt !== undefined) {
                return moment(this.params.strykningstidpunkt).format("YYYY-MM-DD");
            }
            return "-";
        },
        tid: function () {
            if (this.params.strykningstidpunkt !== undefined) {
                return moment(this.params.strykningstidpunkt).format("HH:mm");
            }
            return "-";
        },
        avfors_vantelista: function () {
            if (this.params.avfors_vantelista !== undefined && this.params.avfors_vantelista !== null) {
                let v = this.params.avfors_vantelista.toLowerCase();
                return v[0].toUpperCase() + v.substring(1);
            }
            return "Okänt";
        },
    },
    methods: {
        close() {
            dialogEvents.$emit("closePopup");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.orsak-till-avbokning-och-strykning {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: inherit;
    padding: inherit;
    padding-bottom: 0px;
    padding-top: 0px;

    .patient-dialog-container {
        padding: 0px 50px;
        display: grid;
        gap: 20px;
    }

    .data {
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 0px 50px;

        strong {
            display: block;
            font-weight: 500;
            line-height: 30px;
            color: $color-label-grey;
        }
    }
}
</style>
