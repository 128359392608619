<template>
    <div class="smsmallar">
        <modal-basic ref="dialog" />
        <div class="header">
            <h1>Sms-mallar</h1>
            <button type="button" @click="openCreateSMSTab">
                <img src="@/assets/plus_circle_blue.svg" />
                <span>Skapa sms-mall</span>
            </button>
        </div>

        <search-filter v-model="filterValues" @input="handleSearch" :fields="filterFields">
            <template v-slot:header-filters>
                <FormulateInput class="search-field" name="q" placeholder="Sök" />
            </template>
            <template v-slot:fields>
                <FormulateInput type="text" name="namn__icontains" label="Mall namn" />
                <FormulateInput type="text" name="typ" label="Typ av sms-mall " />
                <FormulateInput
                    type="vueselect"
                    class="autocomplete"
                    name="created_by"
                    label="Skapad av"
                    @search="createdBySearch"
                    :options="createdBy"
                />
            </template>
        </search-filter>

        <kliniken-table
            :items="items"
            :columns="columns"
            :count="smsmallarCount"
            :dropdown-actions="dropdownActions"
            :has-dropdown="hasDropdown"
            @sort="sort"
            @redigeraSmsmall="redigeraSmsmall"
            @dupliceraSmsmall="dupliceraSmsmall"
            @raderaSmsmall="raderaSmsmall"
        />
    </div>
</template>

<script>
import { debounce } from "lodash";
import BaseTab from "@/tabs/mixins/BaseTab.js";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { flashMessage, openDialog } from "@/utils";
import tabEvents from "@/tabEvents";
import SMSMallForm from "@/tabs/SMS/SMSMallForm.vue";
import KlinikenTable from "@/components/Table/KlinikenTable.vue";
import EditIcon from "@/assets/actionIcons/edit.svg";
import DeleteIcon from "@/assets/actionIcons/delete.svg";
import DuplicateIcon from "@/assets/actionIcons/duplicate.svg";
import ModalBasic, { BasicModalVariant } from "@/components/generic/ModalBasic.vue";
import SearchFilter from "@/components/SearchFilter.vue";
import tabIcon from "@/assets/blank.png";

export default {
    extends: BaseTab,
    name: "SMSMallar",
    tabName: "Sms-mallar",
    icon: tabIcon,
    components: {
        KlinikenTable,
        ModalBasic,
        SearchFilter,
    },
    data() {
        return {
            filterValues: {},
            sortField: "",
            items: [],
            columns: [
                {
                    title: "Mall namn",
                    name: "namn",
                    sort: "namn",
                },
                {
                    title: "Typ av sms-mall",
                    name: "typ_display",
                    sort: "typ",
                },
                {
                    title: "Senast använd",
                    name: "updated_at",
                    type: "date",
                },
                {
                    title: "Skapat av",
                    name: "created_by_display.user_display.name",
                    sort: "created_by",
                },
            ],
            dropdownActions: [
                {
                    name: "redigeraSmsmall",
                    title: "Redigera",
                    icon: EditIcon,
                    show: () => {
                        return true;
                    },
                },
                {
                    name: "dupliceraSmsmall",
                    title: "Duplicera",
                    icon: DuplicateIcon,
                    show: () => {
                        return true;
                    },
                },
                {
                    name: "raderaSmsmall",
                    title: "Radera",
                    icon: DeleteIcon,
                    show: () => {
                        return true;
                    },
                },
            ],
            hasDropdown: true,
            smsmallarCount: 0,
            createdBy: [],
        };
    },
    computed: {
        filterFields() {
            return {
                namn__icontains: { label: "Namn " },
                typ: { label: "Typ " },
                created_by: { label: "Skapat av ", options: this.createdBy },
                q: { label: "Sök " },
            };
        },
    },

    methods: {
        openCreateSMSTab() {
            this.$store.dispatch("tabs/openTab", {
                component: SMSMallForm,
            });
        },

        redigeraSmsmall(options = {}) {
            this.$store.dispatch("tabs/openTab", {
                component: SMSMallForm,
                parameters: { ...options, isEditing: true },
            });
        },

        dupliceraSmsmall(options = {}) {
            const { id, ...rest } = options;

            this.$store.dispatch("tabs/openTab", {
                component: SMSMallForm,
                parameters: { ...rest, isDuplicating: true },
            });
        },

        async raderaSmsmall({ id }) {
            if (id) {
                const confirm = await this.$refs.dialog.show({
                    variant: BasicModalVariant.WARNING,
                    title: "Vill du verkligen radera hela sms-mallen?",
                    okButton: "Ja, radera",
                    cancelButton: "Nej, avbryt",
                });

                if (confirm) {
                    await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
                    try {
                        const response = await klinikenApi.delete(`/sms/mallar/${id}/`, getConfig());
                        if (response.status === 204) {
                            flashMessage("Sms-mall raderad");
                            this.items = this.items.filter((item) => item.id === id);
                            await this._fetchSmsMallar();
                        }
                    } catch (error) {
                        openDialog(getErrorMessage(error), "error");
                    } finally {
                        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
                    }
                }
            }
        },

        async sort(field) {
            this.sortField = field;
            await this._fetchSmsMallar();
        },

        async handleSearch(filterData) {
            this.filterValues = filterData;
            await this._fetchSmsMallar();
        },

        createdBySearch(search, loading) {
            if (search.trim().length >= 3) {
                loading(true);
                this._searchCreatedBy(loading, search, this);
            }
        },

        _searchCreatedBy: debounce((loading, search, vm) => {
            return klinikenApi
                .get(
                    "/core/profiles/",
                    getConfig({
                        params: { q: search },
                    })
                )
                .then((res) => {
                    vm.options = res.data.results.map(({ pk, user }) => ({
                        value: pk,
                        label: user.name,
                    }));

                    loading(false);

                    vm.createdBy = vm.options;
                });
        }, 350),

        async _fetchSmsMallar() {
            let params = {
                ...this.filterValues,
                ...{
                    ordering: this.sortField,
                },
            };

            try {
                const response = await klinikenApi.get("/sms/mallar/", getConfig({ params }));
                const { results: items, count } = response?.data;
                this.items = items;
                this.smsmallarCount = count;
            } catch (error) {
                throw new Error(error);
            }
        },

        _refreshSmsMallar() {
            this._fetchSmsMallar();
        },
    },

    async created() {
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
        tabEvents.$on("refreshSmsMallar", this._refreshSmsMallar);
        await this._fetchSmsMallar();
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
    },
    beforeDestroy() {
        tabEvents.$off("refreshSmsMallar");
    },
};
</script>

<style lang="scss" scoped>
.smsmallar {
    .header {
        display: flex;
        align-items: center;
        gap: 40px;
        margin-bottom: 26px;

        h1 {
            margin: 0 !important;
            font-family: Roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 34px;
            color: #277692;
        }

        .button-hollow:hover {
            background-color: transparent;
        }

        button {
            background: transparent;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-right: 8px;
            }

            span {
                color: #6c8188;
                font-family: "Roboto Medium", serif;
                font-size: 16px;
                line-height: 0;
                white-space: nowrap;
            }
        }
    }

    .search-field {
        width: calc(256px * 1.5);
    }
}
</style>
