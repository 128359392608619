<script>
import BaseTab from "@/tabs/mixins/BaseTab.js";
import PlusCircleWhite from "@/assets/plus_circle_white.svg";
import { openDialog } from "@/utils";
import { cloneDeep } from "lodash";
import { getConfig, klinikenApi } from "@/api";
import HollowButton from "@/components/widgets/HollowButton.vue";
import { mapActions } from "vuex";
import dialogEvents from "@/dialogEvents";

export default {
    extends: BaseTab,
    name: "TypoperationForm",
    tabName: "Typoperation",

    components: {
        HollowButton,
    },

    props: {
        parameters: {
            type: Object,
            required: false,
        },
    },

    data: function () {
        return {
            PlusCircleWhite,
            typoperation: {},
            oplagen: [],
            anestesi: [],
            formErrors: [],
            inputErrors: {},
        };
    },

    computed: {
        heading() {
            return this.typoperation.id === undefined ? "Skapa ny typoperation" : "Ändra på typoperation";
        },
    },

    methods: {
        ...mapActions("operation/typoperationer", ["save"]),

        async loadKodverk(url) {
            let response = await klinikenApi.get(url, getConfig());

            return response.data.map(({ id, code, displayName }) => {
                return {
                    value: id,
                    label: `${code} ${displayName}`,
                };
            });
        },
        async submit(data) {
            try {
                // Backend förväntar sig få bidiagnoser och opkoder som en lista med id
                if (data.bidiagnoser) {
                    data.bidiagnoser = data.bidiagnoser.map(({ id }) => {
                        return id;
                    });
                }
                if (data.opkoder) {
                    data.opkoder = data.opkoder.map(({ id }) => {
                        return id;
                    });
                }
                await this.save(data);
                await this.$store.dispatch("tabs/closeTab", this.tabId);
            } catch (e) {
                console.log(e);
                if (e.response) {
                    this.formErrors = e.response.data["non_field_errors"];
                    this.inputErrors = e.response.data;
                }
                openDialog("Kunde inte spara typoperationen", "error");
            }
        },
        cancel(dirty) {
            this.closeTab(dirty);
        },
    },

    async created() {
        try {
            this.oplagen = await this.loadKodverk("/operation/kodverk/oplagen/all/");
            this.anestesi = await this.loadKodverk("/operation/kodverk/anestesier/all/");
        } catch (e) {
            openDialog("Det gick inte att ladda värden för formuläret: " + getErrorMessage(e), "warning");
        }

        if (this.parameters !== undefined && "typoperation" in this.parameters) {
            let typoperation = cloneDeep(this.parameters["typoperation"]);
            // VueFormulate förväntar sig få bidiagnoser och opkoder som en lista med objekt
            typoperation.bidiagnoser = typoperation.bidiagnoser.map((id) => {
                return { id: id };
            });
            typoperation.opkoder = typoperation.opkoder.map((id) => {
                return { id: id };
            });
            this.typoperation = typoperation;
        }
    },
};
</script>

<template>
    <FormulateForm
        class="pad"
        v-model="typoperation"
        :form-errors="formErrors"
        :errors="inputErrors"
        @submit="submit"
        #default="{ hasErrors, hasValue }"
    >
        <h2>{{ heading }}</h2>

        <FormulateInput name="namn" label="Namn" type="text" validation="required" />

        <FormulateInput
            name="huvuddiagnos"
            label="Huvuddiagnos"
            type="autocomplete"
            validation="required"
            url="/core/kodverk/icdkoder/"
        />

        <FormulateInput
            name="bidiagnoser"
            label="Bidiagnoser"
            type="group"
            :repeatable="true"
            add-label="+ Lägg till fler bidiagnoser"
            remove-label="- Ta bort bidiagnos"
            remove-position="after"
        >
            <FormulateInput name="id" type="autocomplete" url="/core/kodverk/icdkoder/" />
        </FormulateInput>

        <FormulateInput
            name="huvudoperationskod"
            label="Huvudoperationskod"
            type="autocomplete"
            validation="required"
            url="/operation/kodverk/operationskoder/"
        />

        <FormulateInput
            name="opkoder"
            label="Opkoder"
            type="group"
            :repeatable="true"
            add-label="+ Lägg till fler opkoder"
            remove-label="- Ta bort opkoder"
            remove-position="after"
        >
            <FormulateInput name="id" type="autocomplete" url="/operation/kodverk/operationskoder/" />
        </FormulateInput>

        <FormulateInput name="beskrivning" label="Beskrivning" type="textarea" />

        <FormulateInput
            name="lateralitet"
            label="Lateralitet"
            type="select"
            :options="[
                { value: '', label: '' },
                { value: 'HÖGER_SIDA', label: 'Höger sida' },
                { value: 'VÄNSTER_SIDA', label: 'Vänster sida' },
                { value: 'BILATERALT', label: 'Bilateralt' },
                { value: 'EJ_AKTUELLT', label: 'Ej aktuellt' },
            ]"
        />

        <FormulateInput name="oplage" label="Oplage" type="select" :options="oplagen" />

        <FormulateInput name="anestesi" label="Anestesi" type="select" :options="anestesi" />

        <FormulateInput name="optid" label="Optid" type="number" min="0" />

        <FormulateInput name="forberedelser" label="Förberedelser" type="number" min="0" />

        <FormulateInput name="avveckling" label="Avveckling" type="number" min="0" />

        <FormulateErrors />

        <FormulateInput type="submit" label="Spara" />
        <FormulateInput type="button" @click="cancel(hasValue)"> Avbryt</FormulateInput>
    </FormulateForm>
</template>

<style lang="scss" scoped></style>
