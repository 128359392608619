<template>
    <div class="SimpleSuggestWidget">
        <div class="inputAndOptions">
            <input
                class="text-input"
                type="search"
                ref="input"
                :value="query"
                @input.stop="updateQuery"
                @focus.stop="onFocus"
                @keyup.enter.stop="onEnter"
            />
            <input type="hidden" />

            <ul ref="suggest-list" class="suggest-list" v-show="optionsIsOpen_">
                <li class="suggest-item" v-for="item in this.options_" @click.stop="clickItem(item)" :key="item.id">
                    <slot name="item.id"> {{ item.displayName }}</slot>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="js">


export default {
    name: "SimpleSuggestWidget",
    props: {
        options: Array,
        preselectedOption: Object,
        optionsIsOpen: Boolean,
        customFilterFunction: Function,

    },

    mounted()  {
        // We do not want to muck about with data passed as props so we make a copy.
        this.options_ = this.options;
        this.optionsIsOpen_ = this.optionsIsOpen;

        if (this.preselectedOption) {
            this.query = this.preselectedOption.displayName;
        }
    },

    data()  {
        return {
            query: "",
            options_: [],

            // Dropdown is hidden by default
            optionsIsOpen_: false,
        };
    },

    methods: {
        // Update the query based on the input box && refilter options
        updateQuery (event) {
            this.query = event.target.value;
            this.updateOptions();
        },

        clearQuery() {
            this.query = "";
        },

        // Refilter options
        updateOptions() {
            if (this.customFilterFunction)   {
                this.options_ = this.customFilterFunction(this.options, this.query);
            } else {
                try {
                    // By default we try to do a case insensitive regexp query
                    const  pattern = new RegExp(this.query, "i");
                    this.options_ = this.options.filter(item => pattern.test(item.displayName));
                }  catch(error) {
                    // If that fails we fall back to string comparison
                    this.options_ = this.options.filter(item => item.displayName.includes(this.query));
                }
            }
        },


        // When the user clicks an item select it
        // && close dropdown
        clickItem(item) {
            this.setValue(item);
            this.optionsIsOpen_ = false;
        },

        // Set query to displayName if supplied item
        // and emit an event+ value that  our parent component will handle
        setValue(item) {
            this.query = item.displayName;
            this.$emit("setValue", item);
        },

        // Whenever the input is focused we show the dropdown
        onFocus() {
            this.updateOptions();
            this.optionsIsOpen_ = true;
        },

        // When user presses enter we pick the top item if there is one
        // && remove focus from the component
        onEnter()  {
            // setValue to top item in options_ if there is one
            if(this.options_[0]){
                this.setValue(this.options_[0]);
            }
            this.optionsIsOpen_ = false;
            this.$refs.input.blur();
        },
    }
}
</script>

<style lang="scss" scoped>
div.SimpleSuggestWidget {
    p.query {
        background-color: pink;
    }

    display: block;
    position: relative;
    margin: 0;
    div.inputAndOptions {
        input.text-input {
            width: 100%;
            // background-color: lightgreen;
        }

        ul.suggest-list {
            position: absolute;
            background-color: white;
            z-index: 10;
            margin: 0;
            padding: 0;
            // background-color: lightblue;
            // -webkit-box-sizing: border-box;
            // -moz-box-sizing: border-box;
            // box-sizing: border-box;
            // position: absolute;
            // top: 48px;
            // width: 100%;
            // z-index: 101;
            // padding: 0px !important;
            // max-height: 390px;
            // overflow-y: auto;

            // left: 0;
            list-style: none;

            li.suggest-item {
                list-style: none;
                border: 1px solid #eee;
                margin: 0;
                padding: 0;
                border-width: 0 1px 1px 1px;
                padding: 0.4rem;

                &:first-of-type {
                    border-top: 1px solid #eee;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .highlighted {
            background: #eee;
            cursor: pointer;
        }
    }
}
</style>

<style lang="sass" scoped>
div.TypoperationSelectWidget
    @import "@/style/_variables.scss"

    /* Bootstrap - Start */
    @import "bootstrap/scss/functions"
    @import "bootstrap/scss/variables"
    @import "bootstrap/scss/mixins"
    @import "bootstrap/scss/root"
    @import "bootstrap/scss/reboot"

    @import "bootstrap/scss/forms"
    /* Bootstrap - End */

    @import "@/style/_deprecated_main.scss"

    padding-bottom: 12px
    margin-bottom: 20px
    position: relative

    .definition
        height: 21px
        color: #7F8FA4
        font-size: 14px
        letter-spacing: 0
        line-height: 21px
        display: block
        margin-bottom: 5px

    .expanded-warning
        box-sizing: border-box
        border: 1px solid #EFC2D4
        border-radius: 3px
        background-color: #F7E1E9
        color: #354052
        display: flex
        padding: 10px 0px 10px 0px

        &.suggest
            width: calc(100% - 150px)

        span
            font-size: 14px
            color: #354052
            white-space: normal
            display: block

        .expanded-warning-icon
            padding-left: 5.5px
            padding-top: 2px
            color: #FFF
            font-size: 12px
            line-height: 12px
            font-weight: bold
            height: 14px
            width: 14px
            border-radius: 7px
            border: none
            background-color: #354052
            margin-top: 5px
            margin-left: 5px
            margin-right: 10px

        img
            margin-top: 25px
            margin-left: 5px
            margin-right: 10px

            &.rotate
                transform: rotate(180deg)

    > button
        img
            height: 12px
            width: 12px
            margin-right: 5px
            margin-top: -5px

            &.rotate
                transform: rotate(180deg)

    input, textarea, select
        -webkit-border-radius: 4px
        -moz-border-radius: 4px
        border-radius: 4px
        border: 1px solid #95A1B1
        //margin-bottom: 32px
        padding: 10px

    .error
        input, textarea, select
            border-bottom: 4px solid #9E354B !important

    input, select, textarea
        &.error
            border-bottom: 4px solid #9E354B !important

    span
        color: #728296
        font-size: 16px
        font-weight: 500
        line-height: 21px
        display: block
        margin-bottom: 2px
        position: relative
        //white-space: nowrap
        white-space: normal

        &.error
            color: #9E354B

        &.error:not(:first-of-type)
            font-size: 14px
            line-height: 21px
            font-weight: 400
            //margin-top: -32px
            margin-left: 16px

            &::before
                content: '!'
                padding-left: 5.5px
                padding-top: 2px
                color: #FFF
                font-size: 12px
                line-height: 12px
                font-weight: bold
                height: 14px
                width: 14px
                border-radius: 7px
                border: none
                background-color: #9E354B
                position: absolute
                left: -16px
                top: 2px

        &.warning:not(:first-of-type)
            color: #354052
            font-size: 14px
            line-height: 21px
            font-weight: 400

            &.suggest
                white-space: normal
                width: calc(100% - 150px)
    //overflow: hidden
    //text-overflow: ellipsis

    /* Input sizes */

    input, select
        height: 46px !important
        width: 100%

    textarea
        //min-height: 81px
        width: 100%

    div[contenteditable="true"]
        border-radius: 4px
        border: 1px solid #95A1B1
        padding: 5px
        width: 60em
        min-height: 5em
        overflow: auto
        background-color: #FFFFFF !important

    .k3-form
        textarea
            min-height: 81px
</style>
