<template>
    <div class="smskallelser">
        <div class="header">
            <h1>Kallelser och sms</h1>
            <button type="button" @click="openSmsMallarTab">
                <img src="@/assets/icons/edit_circle_blue.svg" />
                Hantera sms-mallar
            </button>
        </div>

        <search-filter v-model="filterValues" @input="handleSearch" :fields="filterFields">
            <template v-slot:header-filters>
                <FormulateInput class="search-field" name="q" placeholder="Sök patient" />
            </template>
            <template v-slot:fields>
                <FormulateInput
                    name="huvudoperationskod"
                    label="Huvudoperationskod"
                    type="vueselect"
                    :options="huvudoperationskoder"
                    @search="searchHuvudoperationskoder"
                    class="autocomplete"
                />
                <FormulateInput type="datepicker" name="bokad_operation_datum" label="Bokat operationsdatum" />
                <FormulateInput type="text" name="smsmall" label="Sms-mall" />
                <FormulateInput
                    type="vueselect"
                    name="sms_status"
                    label="Status sms"
                    :options="[
                        { value: 'Sms skickat', label: 'Sms skickat' },
                        { value: 'Ej skickat', label: 'Ej skickat' },
                        { value: 'Ej sms', label: 'Ej sms' },
                    ]"
                />
            </template>
        </search-filter>
        <kliniken-table
            ref="klinikenTable"
            :items="items"
            :columns="columns"
            @sort="sort"
            :dropdown-actions="dropdownActions"
            :has-dropdown="hasDropdown"
            :show-checkboxes="true"
            :hideCheckboxProperty="'sms_status'"
            :hideCheckboxValue="'Ej sms'"
            :showBottom="false"
            @sendSms="sendSms"
            :selectedItem="selectedItem"
            @tableRowClicked="tableRowClicked"
            @handleCheckedIds="handleCheckedIds"
        >
            <template v-slot:custom-bottom>
                <div class="send-sms-container">
                    <span> {{ selectedCheckboxes.length }} objekt valda</span>
                    <ButtonGroup>
                        <Button size="secondary" @click="sendMultipleSms" :disabled="selectedCheckboxes.length === 0"
                            >Skapa sms ({{ selectedCheckboxes.length }})
                        </Button>
                        <Button type="button" @click="cancel" variant="contour" size="secondary">Avbryt</Button>
                    </ButtonGroup>
                </div>
            </template>
        </kliniken-table>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseTab from "@/tabs/mixins/BaseTab.js";
import tabIcon from "@/assets/actionIcons/send.svg";
import KlinikenTable from "@/components/Table/KlinikenTable.vue";
import { getConfig, klinikenApi } from "@/api";
import SearchFilter from "@/components/SearchFilter.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import Button from "@/components/Button.vue";
import SMSSend from "@/tabs/SMS/SMSSend.vue";
import tabEvents from "@/tabEvents";
import StatusSMSSendIcon from "@/assets/actionIcons/status_sms_send.svg";
import StatusSMSNotSendIcon from "@/assets/actionIcons/status_sms_not_send.svg";
import StatusEjSMSIcon from "@/assets/actionIcons/status_ej_sms.svg";
import IconSMS from "@/assets/actionIcons/sms.svg";
import SMSMallar from "@/tabs/SMS/SMSMallar.vue";

export default {
    extends: BaseTab,
    name: "SMSKallelser",
    tabName: "Kallelser och sms",
    icon: tabIcon,
    components: {
        KlinikenTable,
        SearchFilter,
        ButtonGroup,
        Button,
    },
    props: {
        tabId: Number,
    },
    data() {
        return {
            count: 0,
            filterValues: {},
            sortField: "",
            huvudoperationskoder: [],
            items: [],
            columns: [
                {
                    title: "PersonID",
                    name: "patient_display.personId",
                },
                {
                    title: "Patientnamn",
                    name: "patient_display.namn",
                },
                {
                    title: "Huvudopkod",
                    name: "huvudoperationskod_display.code",
                },
                {
                    title: "Bokat opdatum",
                    name: "bokad_operation_datum",
                    sort: "bokad_operation_datum",
                },
                {
                    title: "Optid",
                    name: "optid",
                },
                {
                    title: "Kallelsemall",
                    name: "",
                },
                {
                    title: "Status kallelse",
                    name: "",
                },
                {
                    title: "Sms-mall",
                    name: "smsmall.namn",
                },
                {
                    title: "Status sms",
                    name: "sms_status",
                    type: "icon",
                    icons: {
                        "Sms skickat": StatusSMSSendIcon,
                        "Ej skickat": StatusSMSNotSendIcon,
                        "Ej sms": StatusEjSMSIcon,
                    },
                },
            ],
            dropdownActions: [
                {
                    title: "Skapa sms",
                    name: "sendSms",
                    show: ({ patient_display }) => patient_display?.onskar_sms === true,
                    icon: IconSMS,
                },
            ],
            hasDropdown: true,
            selectedCheckboxes: [],
            selectedItem: null,
        };
    },

    watch: {
        currentId() {
            if (this.currentId !== this.selectedItem?.id) {
                this.selectedItem = null;
            }
        },
    },

    computed: {
        ...mapGetters("patientData", ["currentId"]),
        filterFields() {
            return {
                q: { label: "Sök " },
                huvudoperationskod: { label: "Huvudoperationskod ", options: this.huvudoperationskoder },
                bokad_operation_datum: { label: "Bokat operationsdatum " },
                smsmall: { label: "Sms-mall " },
                sms_status: { label: "Status sms " },
            };
        },
    },

    methods: {
        async handleSearch(filterData) {
            this.filterValues = filterData;
            await this._fetchOperationerMedStatusBokad();
        },

        async sort(field) {
            this.sortField = field;
            await this._fetchOperationerMedStatusBokad();
        },

        async tableRowClicked(item) {
            if (item) {
                this.selectedItem = item;
                const id = item?.patient_display?.id;
                await this.$store.dispatch("patientData/loadFromBackend", {
                    id,
                    failSilently: false,
                });
            }
        },

        async searchHuvudoperationskoder(search, _loading) {
            if (search.length >= 3) {
                const response = await klinikenApi.get(
                    "/operation/kodverk/operationskoder/all/",
                    getConfig({ params: { q: search } })
                );
                const { data: huvudoperationskoder } = response;

                this.huvudoperationskoder = huvudoperationskoder.map(({ id, code, displayName }) => ({
                    value: id,
                    label: `${code} ${displayName}`,
                }));
            }
        },

        sendSms(smsReceiver) {
            this._openSmsSend([smsReceiver]);
        },

        sendMultipleSms() {
            this._openSmsSend(this.selectedCheckboxes);
        },

        openSmsMallarTab() {
            this.$store.dispatch("tabs/openTab", {
                component: SMSMallar,
            });
        },

        async cancel() {
            await this.$store.dispatch("tabs/closeTab", this.tabId);
        },

        /**
         * Handles checked IDs.
         *
         * @param {Array.<string>} checkedIds - An array of string IDs.
         * @returns {void}
         */
        handleCheckedIds(checkedIds = []) {
            const foundItems = this._findItemsByIds(checkedIds, this.items);
            this.selectedCheckboxes = foundItems;
        },

        /**
         * Fetches operations with the status "Bokad" (booked).
         *
         * @returns {Promise<void>} A promise that resolves when the operations are fetched successfully.
         * @throws {Error} If there is an error while fetching the operations.
         */
        async _fetchOperationerMedStatusBokad() {
            let params = {
                status: "Bokad",
                ...this.filterValues,
                ...{
                    ordering: this.sortField,
                },
            };
            try {
                const response = await klinikenApi.get("operation/koordinering/", getConfig({ params }));
                const { results: items, count } = response?.data;
                this.items = items;
                this.count = count;
            } catch (error) {
                throw new Error(error);
            }
        },

        _refreshSmsKallelser() {
            this.$refs.klinikenTable.uncheckAll();
            this.selectedCheckboxes = [];
            this._fetchOperationerMedStatusBokad();
        },

        /**
         * Opens an Sms send tab.
         *
         * @param {Array} smsReceivers - Containing a list of SMS receivers.
         * @param {string} [baseUrl="/operation/anmalan/"] - The base URL for the operation.
         * @param {string} [smsPath="/sms/"] - The path for the SMS operation.
         */
        _openSmsSend(smsReceivers, baseUrl = "/operation/anmalan/", smsPath = "/sms/") {
            this.$store.dispatch("tabs/openTab", {
                component: SMSSend,
                parameters: { smsReceivers, baseUrl, smsPath },
            });
        },

        /**
         * Filter items based on a list of IDs.
         * @param {string[]} list - The list of IDs to filter.
         * @param {Array} items - The array of items to filter.
         * @returns {Array} - The filtered items.
         */
        _findItemsByIds(list = [], items = {}) {
            return items.filter((item) => list.includes(item.id)) ?? [];
        },
    },
    async created() {
        this.$on("sendSms", (item) => {
            this.sendSms(item);
        });
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
        tabEvents.$on("refreshSmsKallelser", this._refreshSmsKallelser);
        await this._fetchOperationerMedStatusBokad();
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
    },

    beforeDestroy() {
        tabEvents.$off("refreshSmsKallelser");
    },
};
</script>

<style lang="scss" scoped>
.smskallelser {
    padding: 30px 0px;

    .header {
        display: flex;
        align-items: center;
        gap: 40px;
        margin-bottom: 26px;
        margin-top: -30px;

        h1 {
            margin: 0 !important;
        }

        .button-hollow:hover {
            background-color: transparent;
        }

        button {
            background: transparent;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-right: 8px;
            }
        }
    }

    .search-field {
        width: calc(256px * 1.5);
    }

    .send-sms-container {
        background-color: #f3f5f9;
        min-height: 75px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        row-gap: 20px;
        padding: 10px;
        margin: 15px 0px;

        span {
            margin: 0 16px;
        }
    }
}
</style>
