<template>
    <div>
        <div class="preanestesiList pad">
            <div class="preanestesiMain">
                <h2 class="preanestesi">Preanestesibedömningar</h2>
                <button role="button" @click="openPreAne" class="hollow-button">
                    <img src="@/assets/plus_circle_blue.svg" />Lägg till preanestesibedömning
                </button>
            </div>

            <div class="visaMakulerade">
                <label
                    class="checkbox-container checkbox-container-preanestesi"
                    style="margin-right: 30px; padding-top: 5px"
                    >Visa ej giltiga
                    <input class="mr-1 checkbox-samtycke" type="checkbox" @click="hideInvalid = !hideInvalid" />
                    <span class="checkmark checkmark-preanestesi" />
                </label>
            </div>

            <custom-action-table
                v-if="preanestesibedomningar"
                class="no-top actionTablepreanestesi"
                :selectableRows="false"
                :columns="preanestesiTableColumns"
                :items="hideInvalid ? preanestesibedomningarUtanInvalid : preanestesibedomningar"
                :actions="preanestesiListActions"
            ></custom-action-table>
            <div class="mt-2" v-if="!this.loadedPreanestesibedomningarFinished">
                <skeleton-box width="100%" height="30px" radius="4px"></skeleton-box>
                <hr />
                <skeleton-box width="100%" height="30px" radius="4px"></skeleton-box>
                <hr />
                <skeleton-box width="100%" height="30px" radius="4px"></skeleton-box>
                <hr />
                <skeleton-box width="100%" height="30px" radius="4px"></skeleton-box>
                <hr />
                <skeleton-box width="100%" height="30px" radius="4px"></skeleton-box>
                <hr />
                <skeleton-box width="100%" height="30px" radius="4px"></skeleton-box>
            </div>
            <div v-if="this.loadedPreanestesibedomningarFinished && preanestesibedomningar.length === 0">
                <br /><br />
                <label class="saknar-preanestesi"
                    ><img src="@/assets/info_dark.svg" /> Patienten saknar preanestesibedömningar. Välj Lägg till
                    preanestesibedömning för att lägga till data.</label
                >
            </div>
        </div>
    </div>
</template>

<script>
/*const noNull = (str) => {
    return str || ""
};*/

const PREANESTESI_TABLE_COLUMNS = [
    {
        name: "datum_godkänd",
        title: "Datum",
        template: (item) => {
            return `<span style="font-size: 14px;">` + item.datum_godkand + `</span>`;
        },
    },
    {
        name: "anestesiolog",
        title: "Anestesiolog",
        template: (item) => {
            return `<span style="font-size: 14px;">` + item.anestesiolog + `</span>`;
        },
    },
    {
        name: "blodningsbenagenhet",
        title: "Blödningsbenägenhet",
        template: (item) => {
            return `<span style="font-size: 14px;">` + item.blodningsbenagenhet + `</span>`;
        },
    },
    {
        name: "anestesi",
        title: "Anestesi",
        template: (item) => {
            return `<span style="font-size: 14px;">` + item.anestesi + `</span>`;
        },
    },
];

const PREANESTESI_ROW_ACTIONS = [
    /*{
        name: 'editSamtycke', title: 'Redigera', img: 'edit.svg', condition: (item) => {
            return !(item.is_nullified || item.is_recalled || !item.is_current);
        }
    },*/
];

import BaseTab from "@/tabs/mixins/BaseTab.js";
import tabIcon from "@/assets/ny_patient.svg";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import { mapActions, mapGetters } from "vuex";
import SkeletonBox from "@/components/widgets/Skeleton.vue";
import PreAne from "@/tabs/Operation/Preanestesibedomning.vue";

export default {
    extends: BaseTab,
    patientBound: true,
    name: "Preanestesibedömningar",
    icon: tabIcon,
    tabName: "Preanestesibedömningar",
    data() {
        return {
            hideInvalid: true,
            toggleSwitch: false,
            preanestesiTableColumns: PREANESTESI_TABLE_COLUMNS,
            preanestesiListActions: PREANESTESI_ROW_ACTIONS,
            loadedPreanestesibedomningarFinished: false,
        };
    },
    components: {
        SkeletonBox,
        CustomActionTable,
    },
    computed: {
        ...mapGetters("patientData", ["patient"]),
        ...mapGetters("operation/operationsanmalan", ["preanestesibedomningar"]),
        preanestesibedomningarUtanInvalid: function () {
            const todayDate = new Date().toISOString().slice(0, 10);
            return this.preanestesibedomningar.filter((item) => item.giltighetstid < todayDate);
        },
    },
    methods: {
        ...mapActions("operation/operationsanmalan", ["loadPreanestesibedomningar"]),
        openPreAne() {
            let information = this.item;
            this.$store.dispatch("tabs/openTab", {
                component: PreAne,
                parameters: information,
            });
        },
    },
    async created() {
        try {
            await this.loadPreanestesibedomningar(this.patient.id);
            this.loadedPreanestesibedomningarFinished = true;
        } catch (e) {
            //eslint-disable-next-line
            console.error(e);
        }
        /*this.$on('makuleraSamtycke', (data) => {
            dialogEvents.$emit("openPopup", {
                title: "Makulera Patientsamtycke",
                component: MakuleraSamtycke,
                attachedProps: {
                    'id': data.id,
                }
            });
        });*/
        /*this.$on('recallSamtycke', (data) => {
            dialogEvents.$emit("open", {
                type: "warning",
                description: "Är du säker på att du vill återkalla samtycket?",
                buttons: [
                    {
                        title: "Ja, återkalla!",
                        type: "normal btn-alert btn-primary",
                        action: () => this.recallSamtycke(data)
                    },
                    {
                        title: "Avbryt",
                        type: "outline-secondary",
                    },
                ],
            });
        })*/
        /*this.$on('editSamtycke', async (data) => {
            await this.$store.dispatch('tabs/openTab', {component: SamtyckesFormTab, parameters: data});
        });*/
    },
    beforeDestroy() {
        this.$store.commit("operation/operationsanmalan/reset");
    },
};
</script>

<style scoped>
.visaMakulerade {
    text-align: end;
    width: 100%;
    margin-right: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.preanestesi {
    display: inline-block;
    color: #277692;
    font-family: Roboto;
    letter-spacing: 0;
    line-height: 34px;
}

.preanestesiMain {
    background-color: #ffffff;
    padding-top: 30px;
}

.saknar-preanestesi {
    color: #354052;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
}

.saknar-preanestesi > img {
    height: 16px;
    width: 16px;
    margin-bottom: 3px;
}

.hollow-button {
    height: 28px;
    color: #6c8188;
    font-family: Roboto Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    border: none;
    background-color: #ffffff;
    margin-left: 50px;
}

.hollow-button > img {
    margin-right: 8px;
}

.strike {
    color: #bebebe;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}

.opaque {
    opacity: 0.7;
    color: #bebebe;
}

.checkbox-container-preanestesi {
    align-items: end;
    color: #354052;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
}

.preanestesiList {
    padding-bottom: 150px;
}

.dropdown-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 2px 2px 7px 0 rgba(157, 166, 187, 0.5);
    border: 1px solid #999c9e !important;
    border-radius: 4px;
    flex: 1;
    margin: 0.125rem 30px 0 0 !important;
}

.dropdown-item {
    color: #6c8188 !important;
    padding: 17px 39px 18px 23px !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    line-height: 21px !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: #cee9f3 !important;
}

.dropdown-item > img {
    margin-right: 18px;
}
</style>
