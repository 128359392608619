<template>
    <div id="kliniken-header" class="kliniken-header">
        <section>
            <div
                :class="{
                    'button-wrapper-alert': true,
                }"
                class="alertSymbol"
                id="patient-uppmarksamhet"
            >
                <a
                    v-tooltip="{
                        content: 'Uppmärksamhetssignaler',
                        theme: 'kliniken-tooltip',
                    }"
                    v-on:click="uppmarksamhetOpen = !uppmarksamhetOpen"
                >
                    <AlertInformationWidget
                        v-if="personId"
                        class="alertSymbol"
                        :patient="patient"
                        :alertInformationSymbol="alertInformationSymbol"
                    />
                    <div style="position: relative">
                        <div class="uppmarksamhet-details" v-show="uppmarksamhetOpen">
                            <template v-if="personId">
                                <div class="details-flex">
                                    <div class="patient-icon">
                                        <img src="../assets/patient_filled.svg" />
                                    </div>
                                    <div class="details-text">
                                        <div class="small">Aktiv patient</div>
                                        <div class="big">{{ personId }}</div>
                                        <div class="big">{{ name }}</div>
                                    </div>
                                </div>

                                <Button
                                    class="open-alertinformation"
                                    size="secondary"
                                    type="button"
                                    role="button"
                                    v-on:click="openAlertInformationTab()"
                                >
                                    Visa uppmärksamhetsignaler
                                </Button>
                            </template>
                        </div>
                    </div>
                </a>
            </div>
            <div :class="{ 'button-wrapper': true }" id="patient-information">
                <button
                    class="patient-details"
                    type="button"
                    role="button"
                    v-on:click="patientDetailsOpen = !patientDetailsOpen"
                    v-tooltip="{
                        content: 'Vald patient',
                        theme: 'kliniken-tooltip',
                        disabled: Boolean(personId) === false,
                    }"
                >
                    <!--<img src="../assets/patient.svg" />-->
                    <!-- <strong><span class="patient-patientText">Patient:</span></strong> -->
                    <span v-if="personId">{{ personId }}</span>
                    <span class="patient-personID" v-if="personId">{{ name }}</span>
                    <span v-if="!personId">Ingen patient vald</span>
                    <img class="patient-arrow" src="../assets/dropdown-arrow-white.svg" />
                </button>
                <div class="information-details" v-show="patientDetailsOpen">
                    <template v-if="personId">
                        <div class="details-flex">
                            <div class="patient-icon">
                                <img src="../assets/patient_filled.svg" />
                            </div>
                            <div class="details-text">
                                <div class="small">Aktiv patient</div>
                                <div class="big">{{ personId }}</div>
                                <div class="big">{{ name }}</div>
                                <div v-if="patient.telefon" class="medium">Telefon: {{ patient.telefon }}</div>
                                <div v-if="patient.email" class="medium">e-mail: {{ patient.email }}</div>
                            </div>
                        </div>
                        <!--
                    <button type="button" role="button" disabled class="blue-button">Visa utökad patientinformation</button>
                    -->
                        <div class="spacer" />
                        <button
                            class="hollow-button"
                            type="button"
                            role="button"
                            v-on:click="openEditTab(), (patientDetailsOpen = false)"
                        >
                            <img src="../assets/edit.svg" />Redigera patient
                        </button>
                        <button
                            class="hollow-button"
                            type="button"
                            role="button"
                            v-on:click="openSamtyckeTab(), (patientDetailsOpen = false)"
                        >
                            <img src="../assets/samtycker-gray.svg" />Patientsamtycken
                        </button>
                        <div class="pt-spacer" />
                        <button
                            class="hollow-button"
                            type="button"
                            role="button"
                            v-on:click="openSearchTab(), (patientDetailsOpen = false)"
                        >
                            <img class="soft-gray" src="../assets/byt_patient.svg" />Välj annan patient
                        </button>
                        <button
                            class="hollow-button"
                            type="button"
                            role="button"
                            v-on:click="openPatientTab(), (patientDetailsOpen = false)"
                        >
                            <img class="soft-gray" src="../assets/ny_patient.svg" />Skapa ny patient
                        </button>
                    </template>
                    <template v-else>
                        <span class="warning"> Vissa funktioner i Kliniken kan inte nås utan aktiv patient. </span>
                        <button
                            type="button"
                            role="button"
                            class="blue-button"
                            v-on:click="openSearchTab(), (patientDetailsOpen = false)"
                        >
                            <img src="../assets/byt_patient.svg" />Välj patient
                        </button>
                        <button
                            type="button"
                            role="button"
                            class="blue-button"
                            v-on:click="openPatientTab(), (patientDetailsOpen = false)"
                        >
                            <img src="../assets/ny_patient.svg" />Skapa ny patient
                        </button>
                    </template>
                </div>
            </div>
        </section>

        <div class="empty-item" />

        <custom-suggest-widget
            v-tooltip="{
                content: 'Sök patient',
                theme: 'kliniken-tooltip',
            }"
            placeholder="Sök patient"
            class="patient-searchBar"
            name="patientid"
            apisearch="/core/patienter/"
            :display-code="true"
            :map="
                (el) => {
                    return {
                        id: el.id,
                        code: el.personId,
                        displayName: el.fornamn + ' ' + el.efternamn,
                    };
                }
            "
        />

        <div
            :class="{
                'button-wrapper': true,
                right: true,
            }"
            id="user-information"
        >
            <button
                class="user-details"
                type="button"
                :disabled="!fullName"
                role="button"
                v-on:click="userDetailsOpen = !userDetailsOpen"
                v-tooltip="{
                    content: 'Inloggad användare',
                    theme: 'kliniken-tooltip',
                    disabled: Boolean(fullName) === false,
                }"
            >
                <div class="user-hsaid">
                    <span>{{ initials }}</span>
                </div>
                <span class="patient-fullName" v-if="fullName">{{ fullName }}</span>
                <span class="patient-fullName" v-if="!fullName">Ingen användare vald</span>
                <img class="patient-arrow" src="../assets/dropdown-arrow-white.svg" />
            </button>
            <div class="information-details right" v-show="userDetailsOpen">
                <div class="details-flex">
                    <div class="user-icon">
                        <span>{{ initials }}</span>
                    </div>
                    <div class="details-text">
                        <div class="small">Inloggad som</div>
                        <div class="big">{{ fullName }}</div>
                        <div class="medium">
                            {{ currentProfile.user.personId }}
                        </div>
                        <div class="medium">Befattning: {{ occupation }}</div>
                        <div class="medium">Vårdenhet: {{ currentProfile.orgUnit.name }}</div>
                    </div>
                </div>
                <Button
                    class="edit-user"
                    size="secondary"
                    type="button"
                    role="button"
                    @click="
                        editUser();
                        userDetailsOpen = false;
                    "
                >
                    Min användarprofil
                </Button>
                <div class="pt-spacer" />
                <HollowButton
                    type="button"
                    role="button"
                    :icon="LogoutIcon"
                    label="Logga ut"
                    v-on:click="
                        changeUser();
                        userDetailsOpen = false;
                    "
                />
            </div>
        </div>

        <svg xmlns:svg="<http://www.w3.org/2000/svg>" version="1.1" height="0" width="0" style="display: none">
            <defs>
                <filter id="gray">
                    <feColorMatrix
                        color-interpolation-filters="sRGB"
                        type="matrix"
                        values="0.52 0   0   0   0
                        0   0.61  0   0   0
                        0   0   0.64  0   0
                        0   0   0   1   0 "
                    />
                </filter>
            </defs>
            <defs>
                <filter id="soft-gray">
                    <feColorMatrix
                        color-interpolation-filters="sRGB"
                        type="matrix"
                        values="0.42 0   0   0   0
                        0   0.51  0   0   0
                        0   0   0.53  0   0
                        0   0   0   1   0 "
                    />
                </filter>
            </defs>
        </svg>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { flashMessage } from "@/utils";
import PatientSearch from "@/tabs/Patient/PatientSearch.vue";
import PatientRegistration from "@/tabs/Patient/PatientRegistration.vue";
import CustomSuggestWidget from "@/tabs/Patient/CustomSuggestWidget.vue";
import tabEvents, { setQueue } from "@/tabEvents";
import linkEvents from "@/linkEvents";
import Samtycke from "@/tabs/Samtycke/Samtycke.vue";
import AlertInformationWidget from "@/components/widgets/AlertInformationWidget.vue";
import AlertInformation from "@/tabs/AlertInformation/AlertInformation.vue";
import { VTooltip } from "floating-vue";
import HollowButton from "@/components/widgets/HollowButton.vue";
import Button from "@/components/Button.vue";
import LogoutIcon from "@/assets/logout.svg";

export default {
    name: "KlinikenHeader",
    components: {
        Button,
        HollowButton,
        CustomSuggestWidget,
        AlertInformationWidget,
        VTooltip,
    },
    data() {
        return {
            LogoutIcon,
            userDetailsOpen: false,
            uppmarksamhetOpen: false,
            patientDetailsOpen: false,
            alertInformationOpen: false,
            //tabQueue: null,
            hidden: false, // Used for masking of patient data
        };
    },
    computed: {
        ...mapGetters("patientData", ["patient", "personId", "name", "gender"]),
        ...mapGetters("alertInformation", ["alertInformationSymbol", "alertInformationList"]),
        ...mapGetters("userData", ["fullName", "initials", "HSAId", "profiles", "currentProfile", "occupation"]),
    },
    methods: {
        ...mapActions("alertInformation", ["loadAlertInformationSymbol", "loadAlertInformationList"]),
        openSearchTab() {
            /*
            if (this.tabQueue)
                setQueue(this.tabQueue);
                */
            this.$store.dispatch("tabs/openTab", { component: PatientSearch });
            //this.tabQueue = null;
        },
        openAlertTab() {
            this.alertInformationOpen = true;
        },
        closeAlertTab() {
            this.alertInformationOpen = false;
        },
        openPatientTab() {
            this.$store.dispatch("tabs/openTab", {
                component: PatientRegistration,
            });
        },
        openEditTab() {
            this.$store.dispatch("tabs/closeTab", {
                component: PatientRegistration,
            });
            this.$store.commit("patientData/setEditPatient", this.patient.id);
            this.$store.dispatch("tabs/openTab", {
                component: PatientRegistration,
            });
        },
        openAlertInformationTab() {
            this.uppmarksamhetOpen = !this.uppmarksamhetOpen;
            this.$store.dispatch("tabs/openTab", {
                component: AlertInformation,
            });
            this.uppmarksamhetOpen = !this.uppmarksamhetOpen;
        },
        openSamtyckeTab() {
            this.$store.dispatch("tabs/openTab", { component: Samtycke });
        },
        hidePatientData() {
            this.hidden = !this.hidden;
            this.$store.commit("patientData/hidePatientData", this.hidden);
        },
        changeUser() {
            this.$store.dispatch("userData/logout");
            /*
            dialogEvents.$emit('openPopup', {
                component: Login,
                dismissable: false,
            });
            */
        },
        editUser() {
            this.$store.dispatch("userAdmin/editSelf");
        },
        changeProfile() {
            sessionStorage.removeItem("Profile");
            sessionStorage.removeItem("AlertInformation");
            /*
            dialogEvents.$emit('openPopup', {
                component: Login,
                dismissable: false,
            });
            */
            flashMessage(
                "TEST: man måste manuellt stänga och öppna flikar för att ändringen ska slå igenom i respektive flik."
            );
        },
        queueOpenTab(component) {
            setQueue(component);
            this.patientDetailsOpen = true;
        },
        selectPatient(value) {
            if (value) {
                value = JSON.parse(value);
                if (value.id) {
                    if (this.alertInformationSymbol) {
                        this.alertInformationSymbol = {};
                    }
                    if (this.alertInformationList) {
                        this.alertInformationList = [];
                    }
                    this.$store.dispatch("patientData/loadFromBackend", {
                        id: value.id,
                        failSilently: true,
                    });
                    linkEvents.$emit("update_patientid", {});

                    this.$store.dispatch("alertInformation/loadAlertInformationSymbol", { patientId: value.id });
                    this.$store.dispatch("alertInformation/loadAlertInformationList", { patientId: value.id });
                }
            }
        },
        informationBarClick(event) {
            let clickCameFromPatientInformation = true;
            let clickCameFromUserInformation = true;

            let targetElement = event.target;
            while (targetElement.id !== "patient-information") {
                if (!targetElement.parentNode) {
                    clickCameFromPatientInformation = false;
                    break;
                } else targetElement = targetElement.parentNode;
            }
            if (!clickCameFromPatientInformation) this.patientDetailsOpen = false;

            targetElement = event.target;
            while (targetElement.id !== "user-information") {
                if (!targetElement.parentNode) {
                    clickCameFromUserInformation = false;
                    break;
                } else targetElement = targetElement.parentNode;
            }
            if (!clickCameFromUserInformation) this.userDetailsOpen = false;
        },
        uppmarksamhetBarClick(event) {
            if (
                event.target?.parentNode?.parentNode?.parentNode?.id === "patient-uppmarksamhet" ||
                event.target?.parentNode?.parentNode?.parentNode?.id === "Uppmärksamhetssignaler"
            ) {
                return true;
            } else {
                this.uppmarksamhetOpen = false;
            }
        },
    },
    beforeCreate() {
        let patient = sessionStorage.getItem("Patient");
        if (patient) {
            if (this.alertInformationSymbol) {
                this.alertInformationSymbol = {};
            }
            if (this.alertInformationList) {
                this.alertInformationList = [];
            }
            this.$store.dispatch("patientData/loadFromBackend", {
                id: patient,
                failSilently: true,
            });
            this.$store.dispatch("alertInformation/loadAlertInformationSymbol", { patientId: patient });
            this.$store.dispatch("alertInformation/loadAlertInformationList", {
                patientId: patient,
            });
        }
    },
    created() {
        document.addEventListener("click", this.informationBarClick);
        document.addEventListener("click", this.uppmarksamhetBarClick);
        tabEvents.$on("patientSelectRequired", this.queueOpenTab);
        tabEvents.$on("closingAlertInformationTab", this.closeAlertTab);
        tabEvents.$on("openingAlertInformationTab", this.openAlertTab);
        linkEvents.$on("patientid_changed", this.selectPatient);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.informationBarClick);
        tabEvents.$off("patientSelectRequired", this.queueOpenTab);
        tabEvents.$off("closingAlertInformationTab", this.closeAlertTab);
        tabEvents.$off("openingAlertInformationTab", this.openAlertTab);
        linkEvents.$off("patientid_changed", this.selectPatient);
    },
};
</script>

<style lang="scss" scoped>
.open-alertinformation,
.edit-user {
    width: 100%;
    margin-top: 24px;
}
</style>

<style lang="sass" scoped>

.kliniken-header
  width: 100%

  .suggest-wrap
    display: inline-block !important
    text-align: left

    input
      height: 44px
      width: 250px
      margin-left: 20px
      border-radius: 4px
      border: none
      -webkit-box-sizing: border-box
      -moz-box-sizing: border-box
      box-sizing: border-box
</style>

<style lang="sass" scoped>
@import "@/style/variables"
.kliniken-header
  background-color: #3092b5
  height: $header-height
  text-align: center
  display: inline-flex
  flex-direction: row
  align-items: baseline
  justify-content: space-between

  .button-wrapper-alert,
  .button-wrapper
    position: relative
    width: auto
    font-family: Roboto
    font-size: 17px
    line-height: 26px
    height: 85px
    display: inline-flex
    align-items: baseline
    justify-content: space-evenly

    &.active
      background: linear-gradient(to top, #CA346F 0%, #CA346F 100%)
      background-repeat: no-repeat
      background-size: 100% 5px
      background-position: center bottom

    &.right
      float: right
      margin-right: 30px

    .patient-details
      margin-top: 30px
      background-color: inherit
      border: none
      outline: none


    .user-details
      margin-top: 22px
      background-color: inherit
      border: none
      outline: none

    .user-hsaid
      height: 38px
      width: 38px
      margin-left: 20px
      border-radius: 19px
      background-color: #277692
      display: inline-block

      span
        color: #FAFBFD
        font-family: Roboto
        line-height: 38px
        text-align: center
        padding-left: 0px
        font-size: 14px
        letter-spacing: 0.63px

  .information-details
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box
    text-align: left
    width: 375px
    //border: 1px solid #728296
    box-shadow: 2px 2px 9px rgba(211, 216, 218, 0.52)
    border-radius: 4px
    position: absolute
    left: 20px
    top: 104px
    z-index: 200
    background: #ffffff
    color: #000000
    width: 375px
    padding: 24px

    .warning
      color: #354052
      font-size: 16px
      line-height: 24px
      font-weight: 400
      display: inline-block
      padding-left: 30px
      position: relative

      &::before
        content: '!'
        padding-left: 6.25px
        padding-top: 2.5px
        color: #FFF
        font-size: 12px
        line-height: 12px
        font-weight: bold
        height: 16px
        width: 16px
        border-radius: 8px
        border: none
        background-color: #354052
        position: absolute
        left: 0px
        top: 3px

    &.right
      right: 20px
      left: auto

    .details-flex
      display: flex

      .patient-icon
        height: 50px
        width: 50px
        border-radius: 25px
        background-color: #92C9DD
        flex: 0 0 50px
        margin-top: 30px
        margin-right: 12px

        img
          padding: 12px

      .user-icon
        height: 50px
        width: 50px
        border-radius: 25px
        background-color: #277692
        flex: 0 0 50px
        margin-top: 30px
        margin-right: 12px
        text-align: center

        span
          color: #FAFBFD
          font-size: 20px
          letter-spacing: 0.63px
          line-height: 50px
          padding-left: 0px

      .details-text
        flex: 1 1 auto

        .small
          color: #354052
          font-size: 14px
          line-height: 21px

        .medium
          color: #354052
          font-family: Roboto
          font-size: 16px
          line-height: 24px

        .big
          color: #55676D
          font-family: Roboto
          font-size: 20px
          line-height: 28px

    .blue-button
      border: none
      height: 46px
      width: 329px
      background-color: #38A7CF
      color: #FFFFFF
      border-radius: 4px
      margin-top: 24px

      img
        padding-left: 0px

      &:not(:first-of-type)
        margin-top: 16px

    .pt-spacer
      margin: 16px 0px
      background-color: #95A1B1
      height: 1px
      width: 329px

    .gray
      filter: url('#gray')

    .soft-gray
      filter: url('#soft-gray')

    .hollow-button
      height: 28px
      color: #6C8188
      font-family: Roboto Medium
      font-size: 16px
      font-weight: 500
      line-height: 28px
      border: none
      background-color: #FFFFFF

      &:not(:first-of-type)
        margin-top: 12px

  span
    padding-left: 20px
    color: #FFFFFF

  strong
    color: #FFFFFF

  img
    padding-right: 20px
    padding-left: 20px


  .alertSymbol
    justify-self: flex-end
    height: 58px
    cursor: pointer

  .button-wrapper-alert
    margin-left: 28px

@media screen and (max-width: 1550px)
  .kliniken-header
    .button-wrapper-alert
      margin-left: 70px


@media screen and (max-width: 1450px)

  .kliniken-header
    display: inline-flex
    flex-direction: row

    .button-wrapper-alert
      margin-left: 30px

  #user-information
    padding: 0

@media screen and (max-width: 1350px)

  .kliniken-header
    flex-direction: row

    .button-wrapper-alert
      margin-left: 10px

  #user-information
    padding: 0


@media screen and (max-width: 1250px)

  .patient-patientText
    display: none

  .kliniken-header
    display: inline-flex
    flex-direction: row
    justify-content: center
    text-align: center

    .button-wrapper
      margin-left: 10px


@media screen and (max-width: 1170px)
  .patient-patientText
    margin-left: -130px

  .patient-patientText,
  .patient-fullName
    display: none

  .kliniken-header
    display: inline-flex
    flex-direction: row
    justify-content: center
    text-align: center
    width: 100%

    .button-wrapper
      margin-left: 0

    img
      padding-right: 5px
      padding-left: 5px
      margin-right: 5px
      margin-left: 5px

  ::v-deep(.patient-searchBar)
    input:first-of-type
      width: 220px

  .alertSymbol
    margin-left: 10px

  .patient-arrow
    padding-left: 0px
    padding-right: 0px
    margin-left: 0px
    margin-right: 0px

  .button-wrapper
    padding-left: 0

@media screen and (max-width: 1050px)
  .patient-personID
    display: none

  ::v-deep(.patient-searchBar)
    input:first-of-type
      width: 150px

  .kliniken-header
    display: inline-flex
    flex-direction: row
    justify-content: center
    text-align: center
    width: 100%

  .button-wrapper
    margin-left: 0

@media screen and (max-width: 850px)
  .patient-personID
    display: none

  ::v-deep(.patient-searchBar)
    input:first-of-type
      display: none

  .kliniken-header
    display: inline-flex
    flex-direction: row
    justify-content: center
    text-align: center
    width: 100%

  .button-wrapper
    margin-left: 0


@media screen and (max-width: 680px)


  .kliniken-header
    display: flex
    flex-direction: column
    height: 350px
    width: 350px
    justify-content: space-evenly
    align-items: center


    .button-wrapper
      margin-left: 0

  ::v-deep(.patient-searchBar)
    input:first-of-type
      display: flex
      width: 250px

  .patient-patientText,
  .patient-personID,
  .patient-fullName,
  .alertSymbol
    display: flex

  #patient-information,
  .patient-searchBar,
  #user-information
    display: flex
    margin-top: 15px
    padding-bottom: 10px
    flex-direction: column
    justify-content: center

.empty-item
  display: flex
  flex-grow: 1

.spacer
  margin-top: 33px
  margin-bottom: 25px

.blue-button
  border: none
  height: 46px
  width: 100%
  background-color: #38A7CF
  color: #FFFFFF
  border-radius: 4px
  margin-top: 24px

.uppmarksamhet-details
  -webkit-box-sizing: border-box
  -moz-box-sizing: border-box
  box-sizing: border-box
  text-align: left
  width: 375px
  //border: 1px solid #728296
  box-shadow: 2px 2px 9px rgba(211, 216, 218, 0.52)
  border-radius: 4px
  position: absolute
  left: 20px
  top: 8px
  z-index: 200
  background: #ffffff
  color: #000000
  width: 375px
  padding: 24px

  .details-flex
    display: flex

    .patient-icon
      height: 50px
      width: 50px
      border-radius: 25px
      background-color: #92C9DD
      flex: 0 0 50px
      margin-top: 30px
      margin-right: 12px

      img
        padding: 12px


    .details-text
      flex: 1 1 auto
      color: #55676D

      .small
        font-size: 14px
        line-height: 21px

      .medium
        font-family: Roboto
        font-size: 16px
        line-height: 24px

      .big
        font-family: Roboto
        font-size: 20px
        line-height: 28px
</style>
