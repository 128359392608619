<template>
    <div>
        <admin-lock-border />
        <h2>
            Sök användare
            <hollow-button :icon="PlusCircleBlueIcon" label="Skapa ny användare" @click="openAnvandare()" />
        </h2>

        <div class="searchComponent">
            <filter-widget
                :queryInputs="queryInputs"
                :topSearchBar="true"
                label="Sök användare"
                v-model="filterData"
                @search="search"
            />
        </div>

        <checkbox-widget
            class="k3-checkbox-small"
            style="float: right; margin-bottom: 10px"
            name="showInactive"
            v-model="showInactive"
            label="Visa även inaktiva användarprofiler"
        />
        <custom-action-table
            class="k3-user-table"
            :columns="userTableColumns"
            :items="showInactive ? sortedProfilesSearchResult : activeSortedProfilesSearchResult"
            :actions="userListActions"
            :selectableRows="false"
        />
    </div>
</template>

<script>
import Anvandare from "@/tabs/Admin/Anvandare.vue";
import BaseTab from "@/tabs/mixins/BaseTab";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import FilterWidget from "@/components/widgets/FilterWidget.vue";
import dialogEvents from "@/dialogEvents";
import tabEvents from "@/tabEvents";
import tabIcon from "@/assets/anvandare.svg";
import widgets from "@/components/widgets/inputs";
import { mapGetters } from "vuex";
import AdminLockBorder from "@/components/AdminLockBorder.vue";
import HollowButton from "@/components/widgets/HollowButton.vue";
import PlusCircleBlueIcon from "@/assets/plus_circle_blue.svg";

const SEARCH_WAIT = 200;

// Checkboxar bör inte användas i filter eftersom de alltid är true/false, det går inte att "stänga av" filtret
// utan false blir ett negerande filter. NB: i filtret används tom sträng som tomt filter snarare än null.
const yes_no_empty = [
    { text: "Ja", value: true },
    { text: "Nej", value: false },
    { text: "", value: "" },
];

const queryInputs = [
    { name: "user__first_name", component: widgets.TextWidget, label: "Förnamn", colspan: "2" },
    { name: "user__last_name", component: widgets.TextWidget, label: "Efternamn", colspan: "2" },
    {
        name: "yrkeskategori",
        component: widgets.SelectWidget,
        label: "Yrkeskategori",
        colspan: "2",
        parameters: {
            apisearch: "/core/kodverk/yrkeskategorier/",
            map: (el) => {
                return { value: el.id, text: el.displayName };
            },
        },
    },
    {
        name: "signeringsansvarig",
        component: widgets.SelectWidget,
        label: "Signeringsansvarig",
        colspan: "2",
        parameters: { options: yes_no_empty },
    },
    {
        name: "enhetsadministrator",
        component: widgets.SelectWidget,
        label: "Enhetsadministratör",
        colspan: "2",
        parameters: { options: yes_no_empty },
    },
    {
        name: "orgUnit",
        component: widgets.SelectWidget,
        label: "Vårdenhet",
        colspan: "2",
        parameters: {
            apisearch: "/core/orgunits/",
            map: (el) => {
                return { value: el.id, text: el.name };
            },
        },
    },
];

const USER_TABLE_COLUMNS = [
    {
        name: "name",
        title: "Namn",
        template: (item) => {
            if (item.user.userrow) return "";
            if (item.user) return item.user.name || "&nbsp;";
            else return "&nbsp;";
        },
    },
    {
        name: "personId",
        title: "PersonId",
        template: (item) => {
            if (item.user.userrow) return "";
            if (item.user) return item.user.personId || "&nbsp;";
            else return "&nbsp;";
        },
    },
    {
        name: "username",
        title: "Användarnamn",
        template: (item) => {
            if (item.user.userrow) return "";
            if (item.user) return item.user.username || "&nbsp;";
            else return "&nbsp;";
        },
    },
    {
        name: "yrkeskategori",
        title: "Yrkeskategori",
        template: (item) => {
            if (item.yrkeskategori) return item.yrkeskategori.displayName || "&nbsp;";
            else return "&nbsp;";
        },
    },
    {
        name: "roll",
        title: "Roll i Kliniken",
        template: (item) => {
            if (item.roll) return item.roll.displayName || "&nbsp;";
            else return "&nbsp;";
        },
    },
    {
        name: "orgUnit",
        title: "Vårdenhet",
        template: (item) => {
            if (item.orgUnit) return item.orgUnit.name || "&nbsp;";
            else return "&nbsp;";
        },
    },
    {
        name: "status",
        title: "Status",
        template: (item) => {
            if (!item.user.userrow && !item.user.is_active) return `<status-widget status="inaktiv" />`;
            else if (item.user.userrow && !item.is_active) return `<status-widget status="inaktiv" />`;
            else if (!item.orgUnit.is_active) return `<status-widget status="inaktivEnhet" />`;
            else return "&nbsp;";
        },
    },
];

const USER_ROW_ACTIONS = [
    {
        name: "editUser",
        title: "Redigera användare",
        condition: (item) => {
            return !item.user.userrow;
        },
    },
    {
        name: "editProfile",
        title: "Redigera profil",
        condition: (item) => {
            return item.user.userrow;
        },
    },
    { name: "addProfile", title: "Lägg till ytterligare profil" },
    {
        name: "setInactiveUser",
        title: "Inaktivera användare",
        condition: (item) => {
            return !item.user.userrow && item.user.is_active && !item.user.currentuser;
        },
    },
    {
        name: "setActiveUser",
        title: "Aktivera användare",
        condition: (item) => {
            return !item.user.userrow && !item.user.is_active;
        },
    },
    {
        name: "setInactiveProfile",
        title: "Inaktivera profil",
        condition: (item) => {
            return item.is_active && !item.is_current_profile;
        },
    },
    {
        name: "setActiveProfile",
        title: "Aktivera profil",
        condition: (item) => {
            return !item.is_active;
        },
    },
];

export default {
    extends: BaseTab,
    name: "ListAnvandare",
    tabName: "Användare",
    icon: tabIcon,
    props: ["tabId"],
    components: {
        HollowButton,
        AdminLockBorder,
        FilterWidget,
        CustomActionTable,
        ...widgets,
    },
    computed: {
        ...mapGetters("userAdmin", [
            "yrkeskategorier",
            "sortedProfilesSearchResult",
            "activeSortedProfilesSearchResult",
        ]),
    },
    data() {
        return {
            PlusCircleBlueIcon,
            filterData: {},
            queryInputs: queryInputs,
            userListActions: USER_ROW_ACTIONS,
            userTableColumns: USER_TABLE_COLUMNS,
            showInactive: false,
        };
    },
    methods: {
        search() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = setTimeout(this.fetchResults, SEARCH_WAIT);
        },
        async fetchResults() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });

            let params = {};
            params.q = this.filterData.searchBarData ? this.filterData.searchBarData.search : null;
            if (this.filterData.filterData) {
                let filters = Object.keys(this.filterData.filterData);
                filters.forEach((key) => {
                    params[key] = this.filterData.filterData[key];
                });
            }

            await this.$store.dispatch("userAdmin/fetchProfilesSearchResults", params);

            this.loading = false;
            this.searchTimeout = false;
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
            return new Promise((resolve) => resolve());
        },
        async editUser(payload) {
            await this.$store.dispatch("userAdmin/editUser", payload);
        },
        async editProfile(payload) {
            await this.$store.dispatch("userAdmin/editProfile", payload);
        },
        async addProfile(payload) {
            await this.$store.dispatch("userAdmin/addProfile", payload);
        },
        setInactiveUser(payload) {
            dialogEvents.$emit("open", {
                type: "warning",
                description: "Är du säker på att du vill inaktivera användaren?",
                buttons: [
                    {
                        title: "Nej",
                        type: "secondary",
                    },
                    {
                        title: "Ja",
                        type: "primary",
                        action: async () => {
                            await this.$store.dispatch("userAdmin/setInactiveUser", payload);
                        },
                    },
                ],
            });
        },
        async setActiveUser(payload) {
            await this.$store.dispatch("userAdmin/setActiveUser", payload);
        },
        setInactiveProfile(payload) {
            dialogEvents.$emit("open", {
                type: "warning",
                description: "Är du säker på att du vill inaktivera profilen?",
                buttons: [
                    {
                        title: "Nej",
                        type: "secondary",
                    },
                    {
                        title: "Ja",
                        type: "primary",
                        action: async () => {
                            await this.$store.dispatch("userAdmin/setInactiveProfile", payload);
                        },
                    },
                ],
            });
        },
        async setActiveProfile(payload) {
            await this.$store.dispatch("userAdmin/setActiveProfile", payload);
        },
        openAnvandare() {
            this.$store.dispatch("tabs/openTab", { component: Anvandare });
        },
        init() {
            this.$on("editUser", this.editUser);
            this.$on("editProfile", this.editProfile);
            this.$on("addProfile", this.addProfile);
            this.$on("setInactiveUser", this.setInactiveUser);
            this.$on("setInactiveProfile", this.setInactiveProfile);
            this.$on("setActiveUser", this.setActiveUser);
            this.$on("setActiveProfile", this.setActiveProfile);
            tabEvents.$on("refreshUsers", this.fetchResults);
            this.fetchResults();
        },
    },
    created() {
        // Register event listeners
        this.init();
    },
    beforeDestroy() {
        tabEvents.$off("refreshUsers", this.fetchResults);
    },
};
</script>
<style lang="sass" scoped>
@import "../../style/variables"
/* Bootstrap - Start */
@import "bootstrap/scss/functions"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"
@import "bootstrap/scss/root"
@import "bootstrap/scss/reboot"

@import "bootstrap/scss/buttons"
@import "bootstrap/scss/utilities"
@import "bootstrap/scss/forms"

/* Bootstrap - End */
@import "../../style/deprecated_main"

.hollow-button
  height: 28px
  color: #6C8188
  font-family: Roboto Medium
  font-size: 16px
  font-weight: 500
  line-height: 28px
  border: none
  background-color: #FFFFFF
  margin-left: 50px

  img
    margin-right: 8px
</style>

<style lang="sass" scoped>
.k3-user-table
  td:first-child
    border-right: 0px !important
    font-weight: normal !important
    font-size: 14px !important

  td:empty
    border-top: none

.k3-checkbox-small

  line-height: 19px

  > span
    font-size: 14px
    letter-spacing: 0

  .checkmark
    height: 18px
    width: 18px

    &::after
      left: 6px !important
      top: 3px !important


.searchComponent
  margin: 20px 0 30px 0
  padding: 0px

.headerText
  display: inline-block
  color: #277692
  font-family: Roboto
  letter-spacing: 0
  line-height: 34px

.filterWidget
  margin: 0 !important
</style>
