<template>
    <div class="kliniken-table">
        <table>
            <kliniken-table-headers
                :columns="columns"
                :hasDropdown="hasDropdown"
                :showCheckboxes="showCheckboxes"
                :allChecked="checkedIds.length === items.length"
                @sort="sort"
                @checkAll="checkAll"
            />

            <kliniken-table-body
                :items="items"
                :columns="columns"
                :actions="actions"
                :hasDropdown="hasDropdown"
                :showCheckboxes="showCheckboxes"
                :hideCheckboxProperty="hideCheckboxProperty"
                :hideCheckboxValue="hideCheckboxValue"
                :dropdownActions="dropdownActions"
                :selectedItem="selectedItem"
                :checkedIds="checkedIds"
                @dropdownaction="dropdownaction"
                @tableRowClicked="tableRowClicked"
                @idChecked="idChecked"
            />
        </table>
        <template>
            <div v-if="showLimitDropdown" class="dropdown">
                <button @click="toggleLimitDropdown = !toggleLimitDropdown" v-on-clickaway="awayLimitDropdown">
                    <img src="@/assets/arrow_aropdown.svg" width="14px" height="14px" />
                    <span> Visar {{ limitLabel }} rader</span>
                </button>
            </div>
            <slot name="custom-bottom"></slot>
        </template>
        <template v-if="showBottom">
            <div class="next">
                <div class="dropdown">
                    <button @click="toggleLimitDropdown = !toggleLimitDropdown" v-on-clickaway="awayLimitDropdown">
                        <img src="@/assets/arrow_aropdown.svg" width="14px" height="14px" />
                        <span> Visar {{ limitLabel }} rader</span>
                    </button>
                </div>

                <div class="limit-list">
                    <ul v-show="toggleLimitDropdown">
                        <li v-for="(l, index) in availableLimits" :key="index">
                            <a @click="changeLimit(l.value)">{{ l.label }}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <kliniken-pagination
                v-if="paginationStyle === 'paging'"
                :count="count"
                :perPage="limit"
                :currentPage="currentPage"
                @pageChanged="onPageChange"
            />

            <div class="items-length">
                Visar
                <strong>
                    {{ items.length }}
                </strong>
                av
                <strong>
                    {{ count }}
                </strong>
                träffar
            </div>

            <div class="show-more" v-if="paginationStyle === 'loadmore'">
                <Button variant="standard" size="primary" :disabled="limit >= count" @click="loadMore">
                    Visa flera träffar
                </Button>
            </div>
        </template>
    </div>
</template>

<script>
import KlinikenTableHeaders from "./KlinikenTableHeaders.vue";
import KlinikenTableBody from "./KlinikenTableBody.vue";
import { mixin as clickaway } from "vue-clickaway";
import Button from "@/components/Button.vue";
import KlinikenPagination from "@/components/Table/KlinikenPagination.vue";

export default {
    name: "KlinikenTable",
    mixins: [clickaway],
    components: { KlinikenTableHeaders, KlinikenTableBody, Button, KlinikenPagination },
    props: {
        columns: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: true,
            default: () => {
                return [];
            },
        },
        actions: {
            type: Array,
        },
        hasDropdown: {
            type: Boolean,
            default: false,
        },
        dropdownActions: {
            type: Array,
            default: () => {
                return [];
            },
        },
        selectedItem: {
            type: Object,
            required: false,
        },
        count: {
            type: Number,
            default: 0,
        },
        limit: {
            type: Number,
            default: 20,
        },
        limits: {
            type: Array,
            default: () => {
                return [
                    { value: 20, label: "20" },
                    { value: 50, label: "50" },
                    { value: 100, label: "100" },
                    { value: 0, label: "Alla" },
                ];
            },
        },
        showCheckboxes: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideCheckboxProperty: {
            type: String,
            required: false,
        },
        hideCheckboxValue: {
            type: String,
            required: false,
        },
        showBottom: {
            type: Boolean,
            required: false,
            default: true,
        },
        paginationStyle: {
            type: String,
            default: "loadmore",
            validator: function (value) {
                return ["loadmore", "paging"].indexOf(value) !== -1;
            },
        },
        currentPage: {
            type: Number,
            required: false,
            default: 1,
        },
    },

    data() {
        return {
            toggleLimitDropdown: false,
            checkedIds: [],
        };
    },

    computed: {
        limitLabel() {
            const l = this.limits.find((i) => i.value === this.limit);
            return l ? `${l.label}`.toLowerCase() : this.limit;
        },
        showLimitDropdown() {
            return !!this.$slots["custom-bottom"]; // Check if slot exists
        },
        availableLimits() {
            if (this.paginationStyle === "paging") {
                return this.limits.filter((limit) => limit.value !== 0);
            }
            return this.limits;
        },
    },

    methods: {
        sort(field) {
            this.$emit("sort", field);
        },
        loadMore() {
            this.$emit("loadMore");
        },
        changeLimit(limit) {
            this.$emit("changeLimit", limit);
        },
        awayLimitDropdown() {
            this.toggleLimitDropdown = false;
        },
        dropdownaction({ action, item }) {
            this.$emit(action, item);
        },
        tableRowClicked(item) {
            this.$emit("tableRowClicked", item);
        },

        checkAll(value) {
            if (value) {
                this.checkedIds = this.items
                    .filter((item) => item[this.hideCheckboxProperty] !== this.hideCheckboxValue)
                    .map((i) => i.id);
            } else {
                this.checkedIds = [];
            }

            this.$emit("handleCheckedIds", this.checkedIds);
        },

        uncheckAll() {
            this.checkedIds = [];
        },

        idChecked({ id, value }) {
            if (!this.checkedIds.includes(id) && value) {
                this.checkedIds.push(id);
            } else if (this.checkedIds.includes(id) && !value) {
                this.checkedIds = this.checkedIds.filter((e) => e !== id);
            }

            this.$emit("handleCheckedIds", this.checkedIds);
        },

        onPageChange(page) {
            this.$emit("loadPage", page);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/_variables.scss";

.kliniken-table {
    margin-bottom: 40px;

    table {
        width: 100%;
        border-collapse: collapse;
    }

    .dropdown {
        display: flex;
        justify-content: flex-end;

        button {
            background: transparent;
            border: none;
            margin-top: 20px;
            cursor: pointer;

            span {
                color: $color-text-grey;
                margin-left: 2px;
            }
        }
    }

    .items-length {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        strong {
            margin-left: 4px;
            margin-right: 4px;
        }
    }

    .next {
        .limit-list {
            width: 100%;
            position: relative;
            display: flex;
            width: 100%;
            justify-content: flex-end;

            ul {
                top: -10px;
                position: absolute;
                z-index: 1;
                background: #ffffff;
                border: 1px solid #999c9e;
                border-radius: 4px;
                list-style-type: none;
                padding: 0px;

                li {
                    a {
                        display: block;
                        padding-right: 40px;
                        padding-left: 40px;
                        cursor: pointer;

                        &:hover {
                            background-color: $dropdown-item;
                        }
                    }
                }
            }
        }
    }

    .show-more {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 20px;
        font-family: Roboto Medium, sans-serif;

        button {
            width: 800px;
            height: 46px;
            display: inline-block;
            text-align: center;
            font-size: 14px;
        }
    }
}
</style>
