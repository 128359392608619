<template>
    <div>
        <admin-lock-border />
        <h2>
            Enheter
            <hollow-button :icon="PlusCircleBlueIcon" label="Skapa ny enhet" @click="openForm()" />
        </h2>

        <custom-action-table
            class="no-top"
            :selectableRows="false"
            :columns="TableColumns"
            :items="orgUnits"
            :actions="RowActions"
        >
        </custom-action-table>
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import OrgUnit from "@/tabs/Admin/OrgUnit.vue";
import { mapActions, mapGetters } from "vuex";
import tabIcon from "@/assets/blank.png";
import AdminLockBorder from "@/components/AdminLockBorder.vue";
import HollowButton from "@/components/widgets/HollowButton.vue";
import PlusCircleBlueIcon from "@/assets/plus_circle_blue.svg";

const noNull = (str) => {
    return str || "";
};

const TABLE_COLUMNS = [
    {
        name: "name",
        title: "Namn",
        template: (item) => {
            return noNull(item.name);
        },
    },
    {
        name: "hsaid",
        title: "HSA-id",
        template: (item) => {
            return noNull(item.hsaid);
        },
    },
    {
        name: "hsaid_vg",
        title: "HSA-id Vårdgivare",
        template: (item) => {
            return noNull(item.hsaid_vg);
        },
    },
    {
        name: "hsaid_ve",
        title: "HSA-id Vårdenhet",
        template: (item) => {
            return noNull(item.hsaid_ve);
        },
    },
    {
        name: "arbetsplatskod",
        title: "Arbetsplatskod",
        template: (item) => {
            return noNull(item.arbetsplatskod);
        },
    },
    {
        name: "telecom",
        title: "Telefon",
        template: (item) => {
            return noNull(item.telecom);
        },
    },
    {
        name: "email",
        title: "Email",
        template: (item) => {
            return noNull(item.email);
        },
    },
    {
        name: "numberOrNameOfHouse",
        title: "Lokalitet",
        template: (item) => {
            return noNull(item.numberOrNameOfHouse);
        },
    },
    {
        name: "streetName",
        title: "Gatuadress",
        template: (item) => {
            return noNull(item.streetName);
        },
    },
    {
        name: "postalCode",
        title: "Postkod",
        template: (item) => {
            return noNull(item.postalCode);
        },
    },
    {
        name: "city",
        title: "Org",
        template: (item) => {
            return noNull(item.city);
        },
    },
    {
        name: "location",
        title: "Plats",
        template: (item) => {
            return noNull(item.location);
        },
    },
    {
        name: "status",
        title: "Status",
        template: (item) => {
            if (!item.is_active) return `<status-widget status="inaktiv" />`;
            else return "&nbsp;";
        },
    },
];

const ROW_ACTIONS = [
    {
        name: "edit",
        title: "Redigera",
        img: "edit.svg",
        condition: () => {
            return true;
        },
    },
];

export default {
    extends: BaseTab,
    name: "ListOrgUnit",
    tabName: "Enheter",
    icon: tabIcon,
    components: {
        HollowButton,
        AdminLockBorder,
        CustomActionTable,
    },
    props: ["tabId"],
    data() {
        return {
            PlusCircleBlueIcon,
            TableColumns: TABLE_COLUMNS,
            RowActions: ROW_ACTIONS,
        };
    },
    computed: {
        ...mapGetters("orgUnit", ["orgUnits"]),
    },
    methods: {
        ...mapActions("orgUnit", ["load"]),
        openForm() {
            this.$store.dispatch("tabs/openTab", { component: OrgUnit });
        },
    },
    async created() {
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
        try {
            await this.load();
        } catch (e) {
            //eslint-disable-next-line
            console.error(e);
        }
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });

        this.$on("edit", async (data) => {
            await this.$store.dispatch("tabs/openTab", {
                component: OrgUnit,
                parameters: data,
            });
        });
    },
    beforeDestroy() {
        this.$store.commit("orgUnit/reset");
    },
};
</script>

<style lang="scss" scoped>
h2 {
    .hollow-button {
        margin-left: 50px;
    }
}
</style>
