<template>
    <div class="veckooversikt">
        <h1>Veckoöversikt</h1>

        <SearchFilter v-model="filterData" @input="filter" :expandable="false">
            <template #header-filters>
                <FormulateInput type="week" label="Datum" name="yearWeek" />
                <FormulateInput type="vueselect" label="Enhet" name="enhet" :options="enheter" />
                <FormulateInput type="checkbox" label="Sortera på sal" name="sortBySal" />
            </template>
        </SearchFilter>

        <Button class="print" size="secondary" @click="print()">Skriv ut</Button>

        <div class="count">Visar {{ anmalningar.length }} operationer</div>

        <hr />

        <PrintableContent ref="printablecontent" header-height="36px">
            <template #default>
                <div class="table-caption">Veckoöversikt operation</div>
                <div class="table-subcaption">
                    <span>
                        Datum:
                        <strong>
                            Vecka {{ selectedDate.isoWeek() }}, {{ firstDayOfWeek.format("YYYY-MM-DD") }} -
                            {{ lastDayOfWeek.format("YYYY-MM-DD") }}
                        </strong>
                    </span>
                    <span>
                        Enhet: <strong>{{ filterData.enhet }}</strong>
                    </span>
                </div>

                <table class="veckooversikt-tabel">
                    <thead>
                        <tr>
                            <th>Dag</th>
                            <th>Optid</th>
                            <th>Sal</th>
                            <th>Operatör</th>
                            <th>Huvudopkod</th>
                            <th>Implantat</th>
                            <th>Resurser</th>
                            <th>Reop</th>
                            <th>Operationsinfo</th>
                            <th>PersonID</th>
                            <th>Patientnamn</th>
                        </tr>
                    </thead>
                    <VeckooversiktList v-for="(group, index) in groupedAnmalningar" :key="index" :group="group" />
                    <tr class="footer">
                        <td colspan="11">
                            <div>
                                Summa optid vecka {{ selectedDate.format("W") }},
                                {{ firstDayOfWeek.format("YYYY-MM-DD") }} - {{ lastDayOfWeek.format("YYYY-MM-DD")
                                }}<br />
                                <strong>{{ sumOptid }} min</strong>
                            </div>
                        </td>
                    </tr>
                </table>
            </template>
            <template #header>
                <print-header
                    :currentDate="currentDate"
                    :currentProfileName="currentProfile.user_display.name"
                    :utskriftsID="utskriftsID"
                />
            </template>
            <template #footer></template>
        </PrintableContent>
        <ModalBasic ref="dialog" />
    </div>
</template>

<script>
import Button from "@/components/Button.vue";
import { fetchData, postData } from "../utils.js";
import { chain, sortBy, sumBy } from "lodash";
import { mapGetters } from "vuex";
import BaseTab from "@/tabs/mixins/BaseTab";
import VeckooversiktList from "@/tabs/Operation/Oversikt/VeckooversiktList.vue";
import PrintableContent from "@/components/PrintableContent.vue";
import moment from "moment/moment";
import PrintHeader from "@/tabs/Operation/Oversikt/PrintHeader.vue";
import ModalBasic, { BasicModalVariant } from "@/components/generic/ModalBasic.vue";
import SearchFilter from "@/components/SearchFilter.vue";

export default {
    extends: BaseTab,
    name: "Veckoöversikt",
    tabName: "Veckoöversikt",
    components: {
        SearchFilter,
        ModalBasic,
        PrintHeader,
        PrintableContent,
        Button,
        VeckooversiktList,
    },

    props: {
        uid: Number,
        tabId: Number,
        parameters: Object,
    },

    data() {
        return {
            anmalningar: [],
            groupedAnmalningar: [],
            filterData: {},
            enheter: ["Alla enheter"],
            utskriftsID: "",
        };
    },

    computed: {
        moment() {
            return moment;
        },
        ...mapGetters("userData", ["currentProfile"]),
        currentDate() {
            return moment().format("YYYY-MM-DD");
        },
        selectedDate() {
            const [year, week] = this.filterData.yearWeek.split("-W");
            let now = moment();
            now.year(year);
            now.isoWeek(week);
            return now;
        },
        sumOptid() {
            return sumBy(this.anmalningar, (anmalan) => anmalan.optid);
        },
        firstDayOfWeek() {
            let date = this.selectedDate.clone();
            date.isoWeekday(1);
            return date;
        },
        lastDayOfWeek() {
            let date = this.selectedDate.clone();
            date.isoWeekday(7);
            return date;
        },
    },

    methods: {
        filter(filterData) {
            this.filterData = filterData;
            this.fetchAnmalningar();
        },
        async fetchAnmalningar() {
            this.anmalningar = await fetchData({
                url: "/operation/anmalan/",
                params: {
                    bokad_operation_datum__gte: this.firstDayOfWeek.format("YYYY-MM-DD"),
                    bokad_operation_datum__lte: this.lastDayOfWeek.format("YYYY-MM-DD"),
                    ordering: "bokad_operation_datum",
                },
                tabbnamn: "Veckoöversikt",
            });
            this.groupedAnmalningar = chain(this.anmalningar)
                .groupBy((anmalan) => anmalan.bokad_operation_datum)
                .map((anmalningar, datum) => ({
                    anmalningar: sortBy(anmalningar, this.filterData.sortBySal ? "bokad_sal" : "bokad_operation_tid"),
                    datum,
                }))
                .value();
        },

        async print() {
            const response = await postData({
                url: "/operation/anmalan/utskrift/",
                params: {
                    anmalningar: this.anmalningar.map((anmalning) => anmalning.id),
                },
                tabbnamn: "Veckoöversikt",
            });

            if (response.hasError) {
                this.$refs.dialog.show({
                    variant: BasicModalVariant.ALERT,
                    title: "Det gick inte att få ett utskrift-ID.",
                    okButton: "OK",
                });
            } else {
                this.utskriftsID = response.data.utskrifts_id;
                this.$refs.printablecontent.print();
            }
        },
    },

    async created() {
        this.filter({
            yearWeek: `${moment().year()}-W${moment().isoWeek()}`,
            enhet: "",
            sortBySal: false,
        });
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.veckooversikt {
    .button.print {
        margin-top: 20px;
    }

    .count {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 24px;
        margin: 25px 0 15px;
    }

    hr {
        border: 1px solid $list-border-color;
    }

    .table-caption {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: $color-active-dark;
    }

    .table-subcaption {
        display: flex;
        align-items: flex-end;
        gap: 40px;

        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 28px;
        color: $color-active-dark;

        strong {
            color: $color-active-dark;
        }
    }

    .veckooversikt-tabel {
        width: 100%;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        color: $dark-text-color;

        th,
        ::v-deep td {
            padding: 10px;
            vertical-align: top;
        }

        thead {
            border-bottom: 2px solid $color-table-header-line;

            tr {
                background: $color-bg-table-header;
                font-weight: 500;
                color: $color-table-header;

                th {
                    vertical-align: bottom;
                }
            }
        }

        @mixin footer {
            text-align: right;
            background: linear-gradient(270deg, #f1f4f8 0%, rgba(241, 244, 248, 0) 52.01%);
            border-top: 1px solid $list-border-color;
            border-bottom: 2px solid $color-table-header-line;
            font-weight: 500;
            color: $color-table-header;

            strong {
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                color: $dark-text-color;
            }
        }

        ::v-deep .veckooversikt-list {
            .footer {
                @include footer;
            }
        }

        .footer {
            td {
                padding: 28px 0 0;
            }

            div {
                padding: 10px;
                @include footer;
            }
        }
    }

    @media print {
        h1,
        .filter,
        .count,
        hr {
            display: none;
        }

        .button.print {
            display: none;
            visibility: hidden;
        }
    }
}
</style>
