<template>
    <div class="operationsoversikt">
        <div class="header">
            <h1>Operationsöversikt</h1>
            <ButtonLink @click="dagsoversikt">Dagöversikt utskrivbar</ButtonLink>
            <ButtonLink @click="veckooversikt">Veckoöversikt utskrivbar</ButtonLink>
        </div>
        <search-filter v-model="filterValues" @input="handleSearch" :fields="filterFields">
            <template v-slot:header-filters>
                <FormulateInput class="search-field" name="q" placeholder="Sök operationen" />
            </template>
            <template v-slot:quick-filters>
                <a @click.prevent="filterByThisWeek()" :class="{ active: isFilterByThisWeek }">Denna vecka</a>
                <a @click.prevent="filterByNextWeek()" :class="{ active: isFilterByNextWeek }">Nästa vecka</a>
                <a @click.prevent="filterByToday()" :class="{ active: isFilterByToday }">Idag</a>
                <a @click.prevent="filterByTomorrow()" :class="{ active: isFilterByTomorrow }">Imorgon</a>
                <a @click.prevent="filterByAll()" :class="{ active: isFilterByAll }">Alla</a>
            </template>
            <template v-slot:fields>
                <FormulateInput type="datepicker" name="bokad_operation_datum__gte" label="Operationsdatum fr.o.m." />
                <FormulateInput type="datepicker" name="bokad_operation_datum__lte" label="Operationsdatum t.o.m." />
                <FormulateInput type="text" name="patient__personId" label="PersonID" />
                <FormulateInput type="text" name="patient__namn" label="Patientnamn" />
                <FormulateInput name="current_huvudoperator" label="Operatör" type="vueselect" :options="operatorer" />
                <FormulateInput
                    name="current_ass_operatorer"
                    label="Assisterande operatörer"
                    type="vueselect"
                    :options="opassistenter"
                />
                <FormulateInput
                    name="current_huvudoperationskod"
                    label="Huvudoperationskod"
                    type="vueselect"
                    :options="huvudoperationskoder"
                    @search="searchHuvudoperationskoder"
                    class="autocomplete"
                />
                <FormulateInput
                    name="current_typoperation"
                    label="Typoperation"
                    type="vueselect"
                    :options="typoperationer"
                />
                <FormulateInput name="current_sal" label="Sal" type="vueselect" :options="salar" />
                <FormulateInput type="text" name="opnr" label="Operationsnummer" />
                <FormulateInput type="vueselect" name="status" label="Status" :options="statusar" />
            </template>
        </search-filter>
        <div class="subheader">
            <span>
                Visar <strong>{{ getAnmalningar.length }}</strong> av <strong>{{ getAnmalningarCount }}</strong> träffar
            </span>
            <FormulateInput
                @change="showStruknaOperationer"
                type="checkbox"
                v-model="showStrukna"
                label="Visa även strukna operationer"
            />
        </div>
        <kliniken-table
            :columns="columns"
            :items="getAnmalningar"
            :hasDropdown="true"
            :dropdownActions="dropdownActions"
            :count="getAnmalningarCount"
            :limit="limit"
            :limits="limits"
            :selectedItem="selectedItem"
            @sort="sort"
            @loadMore="loadMore"
            @changeLimit="changeLimit"
            @opstartOpjournal="opstartOpjournal"
            @redigeraOpjournal="redigeraOpjournal"
            @visaOperationsinformartion="visaOperationsinformartion"
            @tableRowClicked="tableRowClicked"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseTab from "@/tabs/mixins/BaseTab.js";
import KlinikenTable from "@/components/Table/KlinikenTable.vue";
import SearchFilter from "@/components/SearchFilter.vue";
import ButtonLink from "@/components/ButtonLink.vue";
import moment from "moment";
import tabIcon from "@/assets/operationskoordinator.svg";
import { getConfig, klinikenApi } from "@/api";
import { openDialog } from "@/utils";
import StatusAttHanteraIcon from "@/assets/actionIcons/statusatthantera.svg";
import StatusWaitingIcon from "@/assets/actionIcons/statuswaiting.svg";
import StatusKlaropIcon from "@/assets/actionIcons/statusklarop.svg";
import StatusAvvaktanIcon from "@/assets/actionIcons/statusavvaktan.svg";
import StatusBokadIcon from "@/assets/actionIcons/statusbokad.svg";
import StatusAvbokadIcon from "@/assets/actionIcons/statusavbokad.svg";
import StatusStrukenIcon from "@/assets/actionIcons/statusstruken.svg";
import StatusPagaendeIcon from "@/assets/actionIcons/statuspagaende.svg";
import StatusGenomfordIcon from "@/assets/actionIcons/statusgenomford.svg";
import ActionIconPagaende from "@/assets/actionIcons/pagaendeop.svg";
import ActionIconOpanmalan from "@/assets/actionIcons/opanmalan.svg";
import Operationsjournal from "@/tabs/Operation/Operationsjournal.vue";
import Dagsoversikt from "@/tabs/Operation/Oversikt/Dagsoversikt.vue";
import Veckooversikt from "@/tabs/Operation/Oversikt/Veckooversikt.vue";
import Operationsinformation from "@/tabs/Operation/Operationsinformation.vue";
import {
    cvOptions,
    fetchData,
    profileOptions,
    salarOptions,
    statusar,
    typoperationerOptions,
} from "@/tabs/Operation/utils";
import emitter from "tiny-emitter/instance";
import { isEqual } from "lodash";

export default {
    extends: BaseTab,
    name: "Operationsoversikt",
    tabName: "Operationsöversikt",
    icon: tabIcon,
    components: { KlinikenTable, SearchFilter, ButtonLink },

    props: {},

    data() {
        return {
            oversikter: [],
            filterValues: {},
            sortField: "",
            fetchBatch: 1,
            limit: 20,
            limits: [
                { value: 20, label: "20" },
                { value: 50, label: "50" },
                { value: 100, label: "100" },
                { value: 0, label: "Alla" },
            ],
            operatorer: [],
            opassistenter: [],
            huvudoperationskoder: [],
            typoperationer: [],
            salar: [],
            statusar: statusar,
            columns: [
                {
                    title: "PersonID",
                    name: "patient_display.personId",
                    sort: "patient__personId",
                    background: "#fff",
                },
                {
                    title: "Patientnamn",
                    name: "patient_display.namn",
                    sort: "patient__namn",
                },
                {
                    title: "Operatör",
                    name: "current_huvudoperator_display.user_display.name",
                    sort: "current_huvudoperator",
                },
                {
                    title: "Ass. operatörer",
                    name: "current_ass_operatorer_display",
                    type: "list",
                    listName: "user_display.name",
                },
                {
                    title: "Opdatum",
                    name: "bokad_operation_datum",
                    sort: "bokad_operation_datum",
                },
                {
                    title: "Optid",
                    name: "current_operation_tid",
                    sort: "current_operation_tid",
                    type: "time",
                },
                {
                    title: "Opkod",
                    name: "current_huvudoperationskod_display.code",
                    sort: "current_huvudoperationskod__code",
                },
                {
                    title: "Typoperation",
                    name: "current_typoperation_display.namn",
                    sort: "current_typoperation__namn",
                },
                {
                    title: "Sal",
                    name: "current_sal_display.namn",
                    sort: "current_sal__namn",
                },
                {
                    title: "Opnummer",
                    name: "opnr",
                    sort: "opnr",
                },

                {
                    title: "Status",
                    name: "status",
                    sort: "status__sortering",
                    type: "icon",
                    icons: {
                        "Att hantera": StatusAttHanteraIcon,
                        "Väntar på viktnedgång": StatusWaitingIcon,
                        "Väntar på rökstopp": StatusWaitingIcon,
                        "Väntar på prover/undersökning": StatusWaitingIcon,
                        "Klar för op": StatusKlaropIcon,
                        "Patientvald väntan": StatusAvvaktanIcon,
                        "Medicinsk väntan": StatusAvvaktanIcon,
                        "Administrativ väntan": StatusAvvaktanIcon,
                        Pågående: StatusPagaendeIcon,
                        Genomförd: StatusGenomfordIcon,
                        Bokad: StatusBokadIcon,
                        Avbokad: StatusAvbokadIcon,
                        Struken: StatusStrukenIcon,
                    },
                },
            ],
            dropdownActions: [
                {
                    title: "Visa operationsinformation",
                    name: "visaOperationsinformartion",
                    icon: ActionIconOpanmalan,
                    show: () => true,
                },
                {
                    title: "Opstart i opjournal",
                    name: "opstartOpjournal",
                    icon: ActionIconPagaende,
                    show: (item) =>
                        item.status === "Bokad" && Boolean(moment(item.bokad_operation_datum).isSame(moment(), "day")),
                },
                {
                    title: "Redigera operationsjournal",
                    name: "redigeraOpjournal",
                    icon: ActionIconPagaende,
                    show: (item) => ["Pågående", "Genomförd"].includes(item.status),
                },
                {
                    title: "Granska operationsjournal",
                    name: "granskaOperationsjournal",
                    icon: ActionIconPagaende,
                    show: (item) => item.status === "Signerad",
                },
            ],
            numberofRowDropdownOptions: { 20: "20", 40: "40", 60: "60", 100: "100" },
            showStrukna: false,
            loading: false,
            selectedItem: null,
        };
    },

    computed: {
        ...mapGetters("patientData", ["currentId"]),
        ...mapGetters("operation/operationoversikt", ["getAnmalningar", "getAnmalningarCount"]),
        filterFields() {
            return {
                q: { label: "Sök" },
                bokad_operation_datum__gte: { label: "Operationsdatum fr.o.m." },
                bokad_operation_datum__lte: { label: "Operationsdatum t.o.m." },
                patient__personId: { label: "PersonID" },
                patient__namn: { label: "Patientnamn" },
                current_huvudoperator: { label: "Operatör", options: this.operatorer },
                current_ass_operatorer: { label: "Assisterande operatörer", options: this.operatorer },
                current_huvudoperationskod: { label: "Huvudoperationskod", options: this.huvudoperationskoder },
                current_typoperation: { label: "Typoperation", options: this.typoperationer },
                current_sal: { label: "Sal", options: this.salar },
                opnr: { label: "Operationsnummer" },
                status: { label: "Status" },
            };
        },
        thisWeek() {
            return [moment().format("YYYY-MM-DD"), moment().endOf("week").add(1, "d").format("YYYY-MM-DD")];
        },
        nextWeek() {
            return [
                moment().startOf("week").add(1, "week").add(1, "day").format("YYYY-MM-DD"),
                moment().endOf("week").add(1, "week").add(1, "day").format("YYYY-MM-DD"),
            ];
        },
        today() {
            return [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
        },
        tomorrow() {
            return [moment().add(1, "day").format("YYYY-MM-DD"), moment().add(1, "day").format("YYYY-MM-DD")];
        },
        filterDates() {
            return [this.filterValues["bokad_operation_datum__gte"], this.filterValues["bokad_operation_datum__lte"]];
        },
        isFilterByThisWeek() {
            return isEqual(this.thisWeek, this.filterDates);
        },
        isFilterByNextWeek() {
            return isEqual(this.nextWeek, this.filterDates);
        },
        isFilterByToday() {
            return isEqual(this.today, this.filterDates);
        },
        isFilterByTomorrow() {
            return isEqual(this.tomorrow, this.filterDates);
        },
        isFilterByAll() {
            return isEqual({}, this.filterValues);
        },
    },

    watch: {
        currentId() {
            if (this.currentId !== this.selectedItem?.patient) {
                this.selectedItem = null;
            }
        },
    },

    methods: {
        async fetchAnmalningar() {
            let params = {
                ...this.filterValues,
                ...{
                    ordering: this.sortField,
                },
            };
            params.show_strukna = this.showStrukna;
            if (this.limit > 0) {
                params["limit"] = this.limit * this.fetchBatch;
            }

            this.loading = true;

            try {
                const response = await klinikenApi.get("/operation/oversikt/", getConfig({ params }));
                this.$store.commit("operation/operationoversikt/setAnmalningar", response.data.results);
                this.$store.commit("operation/operationoversikt/setAnmalningarCount", response.data.count);
            } catch (error) {
                openDialog(error, "error");
            }
            this.loading = false;
        },

        async handleSearch(filterData) {
            this.filterValues = filterData;
            await this.fetchAnmalningar();
        },

        setFilterDates(from, to) {
            this.filterValues = {
                ...this.filterValues,
                bokad_operation_datum__gte: from,
                bokad_operation_datum__lte: to,
            };
        },
        async filterByThisWeek() {
            const [from, to] = this.thisWeek;
            this.setFilterDates(from, to);
            await this.fetchAnmalningar();
        },
        async filterByNextWeek() {
            const [from, to] = this.nextWeek;
            this.setFilterDates(from, to);
            await this.fetchAnmalningar();
        },
        async filterByToday() {
            const [from, to] = this.today;
            this.setFilterDates(from, to);
            await this.fetchAnmalningar();
        },
        async filterByTomorrow() {
            const [from, to] = this.tomorrow;
            this.setFilterDates(from, to);
            await this.fetchAnmalningar();
        },
        async filterByAll() {
            this.filterValues = {};
            await this.fetchAnmalningar();
        },

        async _filterByCurrentWeekStartByToday() {
            const from = moment().format("YYYY-MM-DD");
            const to = moment().endOf("week").add(1, "day").format("YYYY-MM-DD");
            this.setFilterDates(from, to);
            await this.fetchAnmalningar();
        },
        async showStruknaOperationer() {
            await this.fetchAnmalningar();
        },

        async sort(field) {
            this.sortField = field;
            await this.fetchAnmalningar();
        },
        async loadMore() {
            this.fetchBatch++;
            await this.fetchAnmalningar();
        },
        async changeLimit(limit) {
            this.fetchBatch = 1;
            this.limit = limit;
            await this.fetchAnmalningar();
        },

        async fetchData(url = "", param = {}) {
            let list = [];
            try {
                const response = await klinikenApi.get(url, getConfig({ param }));
                list = response.data.results;
            } catch (error) {
                throw new Error(error);
            }
            return list;
        },

        async opstartOpjournal(operationsanmalan) {
            await this.$store.dispatch("tabs/closeTab", Operationsjournal, { root: true });
            await this.$store.dispatch("patientData/loadFromBackend", { id: operationsanmalan.patient });
            await this.$store.dispatch("tabs/openTab", {
                component: Operationsjournal,
                parameters: { operationsanmalan },
            });
        },

        async redigeraOpjournal(operationsanmalan) {
            await this.$store.dispatch("tabs/closeTab", Operationsjournal, { root: true });
            await this.$store.dispatch("patientData/loadFromBackend", { id: operationsanmalan.patient });
            await this.$store.dispatch("tabs/openTab", {
                component: Operationsjournal,
                parameters: { operationsanmalan },
            });
        },

        async dagsoversikt(operationsanmalan) {
            await this.$store.dispatch("tabs/closeTab", Dagsoversikt, { root: true });
            await this.$store.dispatch("tabs/openTab", {
                component: Dagsoversikt,
                parameters: { operationsanmalan, params: this.params },
            });
        },

        async veckooversikt(operationsanmalan) {
            await this.$store.dispatch("tabs/closeTab", Veckooversikt, { root: true });
            await this.$store.dispatch("tabs/openTab", {
                component: Veckooversikt,
                parameters: { operationsanmalan, params: this.params },
            });
        },

        async visaOperationsinformartion(operationsanmalan) {
            await this.$store.dispatch("tabs/closeTab", Operationsinformation, { root: true });
            await this.$store.dispatch("patientData/loadFromBackend", { id: operationsanmalan.patient });
            await this.$store.dispatch("tabs/openTab", {
                component: Operationsinformation,
                parameters: { operationsanmalan_id: operationsanmalan.id },
            });
        },
        tableRowClicked(item) {
            if (item.patient) {
                this.selectedItem = item;
                this.$store.dispatch("patientData/loadFromBackend", {
                    id: item.patient,
                    failSilently: false,
                });
            }
        },
        async searchHuvudoperationskoder(search, loading) {
            if (search.length >= 3) {
                this.loading = loading;
                this.huvudoperationskoder = cvOptions(
                    await fetchData({
                        url: "/operation/kodverk/operationskoder/all/",
                        params: { q: search },
                    })
                );
                this.loading = loading;
            }
        },
        async dropdownAllOptions(url, des, par) {
            this.loading = true;
            const _options = await fetchData({ url: url, params: { ...par } });
            this.loading = false;
            return _options;
        },

        async updateEntry(operation) {
            let oversiktList = [];
            // Söker upp operationen i listan och uppdaterar den
            const oversiktEntry = this.getAnmalningar.find((entry) => entry.id === operation.id);
            if (oversiktEntry) {
                // Hämtar en enskild rad ur opöveriskten
                try {
                    const response = await klinikenApi.get(`/operation/oversikt/${operation.id}`, getConfig());
                    oversiktList = this.getAnmalningar.map((entry) => {
                        if (entry.id === operation.id) entry = response.data;
                        return entry;
                    });
                    this.$store.commit("operation/operationoversikt/setAnmalningar", oversiktList);
                } catch (error) {
                    openDialog(error, "error");
                }
            }
        },
    },

    async created() {
        await this._filterByCurrentWeekStartByToday();

        this.operatorer = profileOptions(
            await this.dropdownAllOptions("core/profiles/", "Hämtar kirurgar", {
                is_active: "true",
                roll__code: "kirurg",
            })
        );
        this.typoperationer = typoperationerOptions(
            await this.dropdownAllOptions("/operation/typoperationer/", "Hämta typoperationer", {})
        );
        this.salar = salarOptions(await this.dropdownAllOptions("/operation/salar/all/", "Hämta salar"), {});
        this.opassistenter = this.operatorer;
    },

    mounted() {
        emitter.on("operationsanmalan-uppdaterad", (operation) => {
            this.updateEntry(operation);
        });
        emitter.on("operationsanmalan-bokad", (operation) => {
            this.updateEntry(operation);
        });
        emitter.on("operationsanmalan-avbokad", (operation) => {
            this.updateEntry(operation);
        });
    },

    beforeDestroy() {
        emitter.off("operationsanmalan-uppdaterad");
        emitter.off("operationsanmalan-bokad");
        emitter.off("operationsanmalan-avbokad");
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/_variables.scss";

.operationsoversikt {
    .header {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 26px;

        h1 {
            margin: 0;
        }
    }

    .subheader {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        span {
            font-size: 22px;
            line-height: 30px;
            font-weight: normal;
        }

        strong {
            font-size: 22px;
            line-height: 30px;
            font-weight: normal;
            font-family: Roboto Medium, sans-serif;
        }
    }

    .search-field {
        width: calc(256px * 1.5);
    }
}
</style>
