<template>
    <div class="prescription-set bottom-border">
        <!-- KNAPPMENY -->

        <div class="top-bar-navigation" v-if="!selected && !preview && !history">
            <div
                class="top-icon"
                :class="{ active: activeView === 1 }"
                @click="
                    setActiveView(1);
                    activeTab = 1;
                "
            >
                <div class="icon-circle">
                    <img class="icon-image" src="@/assets/nytt_recept.svg" alt="" />
                </div>
                <span>Skapa nytt recept</span>
            </div>
            <div
                class="top-icon"
                :class="{ active: activeView === 2 }"
                @click="
                    setActiveView(2);
                    activeTab = 2;
                "
            >
                <div class="icon-circle">
                    <img class="icon-image" src="@/assets/tidigare_recept.svg" alt="" />
                </div>
                <span>Tidigare recept</span>
            </div>
            <div
                class="top-icon"
                :class="{ active: activeView === 3 }"
                @click="
                    setActiveView(3);
                    activeTab = 3;
                "
            >
                <div class="icon-circle">
                    <img class="icon-image" src="@/assets/favorit.svg" alt="" />
                </div>
                <span>Receptfavoriter</span>
            </div>

            <div class="top-bar-secondary-navigation">
                <div class="order" @click="scrollToOrder()">
                    <img class="icon-image" src="@/assets/bestallning_blue.svg" alt="" />Beställning ({{
                        items.length
                    }})
                </div>
                <div class="k3-lank-forskrivningskollen" @click="uthoppForskrivningskollen()">
                    <img src="@/assets/link_external.svg" alt="" />Förskrivningskollen
                </div>
            </div>
        </div>

        <!-- RUBRIK / SÖKRUTA -->

        <section v-if="!selected && !preview && !history" class="fixed-width-align-left">
            <div class="recipe-header" v-if="activeView < 2">
                <img class="recipe-icon" src="@/assets/nytt_recept.svg" />
                <h2>Skapa nytt recept</h2>
            </div>
            <div class="recipe-header" v-if="activeView === 2">
                <img class="recipe-icon" src="@/assets/tidigare_recept.svg" />
                <h2>Visa tidigare recept</h2>
            </div>
            <div class="recipe-header" v-if="activeView === 3">
                <img class="recipe-icon" src="@/assets/favorit.svg" />
                <h2>Receptfavoriter</h2>
            </div>
            <form
                class="form-active"
                @submit.prevent="
                    search();
                    focusFirst();
                "
            >
                <fieldset class="form-group" :disabled="selected">
                    <label for="presc_q"
                        >Sök läkemedel eller läkemedel och styrka
                        {{ activeView === 2 && !addToPrevious ? " bland tidigare recept" : ""
                        }}{{ activeView === 3 ? " bland favoriter" : "" }}</label
                    >
                    <div class="search-wrapper">
                        <input
                            class="search-field"
                            name="q"
                            id="presc_q"
                            type="search"
                            v-model="q"
                            autocomplete="off"
                            autofocus
                            :disabled="selected"
                        />
                        <!-- Well-known IE bug that text inputs in form groups are not set to disabled with the other fields. -->
                        <Button class="search-button" type="submit">Sök</Button>
                    </div>
                    <!-- NOTE: using the relevant widgets breaks v-model and has to use events instead.-->
                    <label class="checkbox-container" style="margin-right: 30px">
                        Visa endast rekommenderade läkemedel
                        <input type="checkbox" name="rec" id="presc_rec" v-model="rek" />
                        <span class="checkmark" />
                        <span class="label label-inline">REK</span>
                    </label>
                    <label class="checkbox-container" style="margin-right: 30px">
                        Visa icke godkända läkemedel
                        <input type="checkbox" name="igl" id="presc_igl" v-model="igl" />
                        <span class="checkmark" />
                        <span class="label label-inline">IGL</span>
                    </label>
                </fieldset>
            </form>
        </section>

        <!-- UNDERMENY FÖR NYTT RECEPT -->

        <section
            class="product-selection menu"
            :class="selected ? 'selected' : ''"
            v-if="activeView !== 0"
            v-show="!selected && !preview && !history"
        >
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a
                        data-toggle="tab"
                        class="nav-link"
                        :class="activeTab === 1 ? 'active' : ''"
                        href="#search-results"
                        v-on:click="
                            activeTab = 1;
                            activeView = 1;
                        "
                    >
                        <span>Sökträffar läkemedel ({{ searchResults.length }})</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        data-toggle="tab"
                        class="nav-link"
                        :class="activeTab === 2 ? 'active' : ''"
                        href="#previous-prescriptions"
                        v-on:click="
                            activeTab = 2;
                            activeView = 2;
                        "
                    >
                        <span>Tidigare recept ({{ this.filteredPreviousPrescriptionsArray.length }})</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        data-toggle="tab"
                        class="nav-link"
                        :class="activeTab === 3 ? 'active' : ''"
                        href="#favourites"
                        v-on:click="
                            activeTab = 3;
                            activeView = 3;
                        "
                    >
                        <span
                            >Receptfavoriter ({{
                                this.filteredPersonligaFavouriter.length +
                                this.filteredGemensamaFavouriter.length +
                                this.filterAndrasPersonligaFavoriter.length
                            }})</span
                        >
                    </a>
                </li>
            </ul>
        </section>

        <!--
This is an extremely weird and wonky workaround. For some reason, putting an ActionTable in lower depth in the DOM but this
triggers a re-render. I have no idea why, and due to time contraints gave up trying to solve this problem in a proper manner.
Instead I lifted the troublesome code into a separate component. TODO: this should be fixed properly!
-->
        <action-table-wrapper
            class="action-table-wrapper"
            v-show="showSearch || addToPrevious"
            :grouped-results="groupedSearchResults"
            :disabled-row-handler="rightToPrescribe"
            :drug-table-columns="drugTableColumns"
            :action-table-select-handler="actionTableSelectHandler"
        />

        <prescription-set-action-table-wrapper
            v-show="showPrevious && !addToPrevious"
            :show-icon="activeView === 0"
            :prescription-sets="
                activeView === 0 ? previousPrescriptions.slice(0, 5) : filteredPreviousPrescriptionsArray
            "
            :q="q"
            :columns="previousPrescriptionsTableColumns"
            :actions="previousActions"
            :group-actions="items.length === 0 && activeView === 2 ? previousGroupActions : null"
            :required-fields="requiredFields"
            :disabled-row-handler="rightToPrescribeItem"
            :current-profile="currentProfileId"
            :active-view="activeView"
            :previous-prescriptions-length="previousPrescriptions.length"
            :items-length="items.length"
            :sent-label-actions="sentLabelActions"
            :cancelled-label-actions="cancelledLabelActions"
            :previous-label-actions="previousLabelActions"
            :actionTableAddHandler="actionTableAddHandler"
            :actionTableEditHandler="actionTableEditHandler"
            :actionTableViewHandler="actionTableViewHandler"
            :actionTableCancelHandler="actionTableCancelHandler"
            :actionTableHistoryHandler="actionTableHistoryHandler"
            @show-all-previous="
                activeView = 2;
                activeTab = 2;
            "
            :tab-id="tabId"
            @prescriptionItemDetailsFetched="prescriptionItemDetailsFetched"
        />

        <article v-show="showFavourites">
            <header class="recipe-header" v-if="activeView === 0">
                <img class="recipe-icon" src="@/assets/favorit.svg" />
                <h2>Receptfavoriter</h2>
            </header>

            <section class="privata-favoriter">
                <h3 @click="showPrivataFavoriter = !showPrivataFavoriter">
                    Personliga Favoriter
                    <span class="caret" :class="{ rotate: showPrivataFavoriter }"></span>
                </h3>
                <collapse-transition>
                    <div v-show="showPrivataFavoriter">
                        <SelectActionTable
                            v-for="fav in filteredPersonligaFavouriter"
                            :key="fav.id"
                            :columns="previousPrescriptionsTableColumns"
                            :items="fav.favoritrader"
                            :data="fav"
                            :id="'fav-' + fav.id"
                            class="group"
                            :required-fields="requiredFields"
                            :disabled-row-handler="rightToPrescribeItem"
                            :collapse-label="getStatusText(fav)"
                            :actions="favouriteActions"
                            :label-actions="activeView === 3 ? favouriteLabelActions : null"
                            @favouriteView="favouriteView"
                            @favouriteAdd="favouriteAdd"
                        />
                        <Button
                            variant="contour"
                            size="secondary"
                            v-if="enableMorePersonligaFavouriter"
                            @click="showPersonliga = false"
                        >
                            Visa alla ({{ getPersonligaFavoriter.length }})
                        </Button>
                    </div>
                </collapse-transition>
            </section>

            <section class="organisationiska-favoriter">
                <h3 @click="showOrganisationiskaFavoriter = !showOrganisationiskaFavoriter">
                    Gemensamma Favoriter
                    <span class="caret" :class="{ rotate: showOrganisationiskaFavoriter }"></span>
                </h3>
                <collapse-transition>
                    <div v-show="showOrganisationiskaFavoriter">
                        <SelectActionTable
                            v-for="fav in filteredGemensamaFavouriter"
                            :key="fav.id"
                            :columns="previousPrescriptionsTableColumns"
                            :items="fav.favoritrader"
                            :data="fav"
                            :id="'fav-' + fav.id"
                            class="group"
                            :required-fields="requiredFields"
                            :disabled-row-handler="rightToPrescribeItem"
                            :collapse-label="getStatusText(fav)"
                            :actions="favouriteActions"
                            :label-actions="activeView === 3 ? favouriteLabelActions : null"
                            @favouriteView="favouriteView"
                            @favouriteAdd="favouriteAdd"
                        />
                        <Button
                            variant="contour"
                            size="secondary"
                            v-if="enableMoreGemensammaFavoriter"
                            @click="showGemensamma = false"
                        >
                            Visa alla ({{ getGemensammaFavoriter.length }})
                        </Button>
                    </div>
                </collapse-transition>
            </section>

            <section class="andras-personliga-favoriter" v-if="userData.is_verksamhetsadmin || userData.is_superuser">
                <h3 @click="showAndrasPersonligaFavoriter = !showAndrasPersonligaFavoriter">
                    Andras Personliga Favoriter
                    <span class="caret" :class="{ rotate: showAndrasPersonligaFavoriter }"></span>
                </h3>
                <collapse-transition>
                    <div v-show="showAndrasPersonligaFavoriter">
                        <SelectActionTable
                            v-for="fav in filterAndrasPersonligaFavoriter"
                            :key="fav.id"
                            :columns="previousPrescriptionsTableColumns"
                            :items="fav.favoritrader"
                            :data="fav"
                            :id="'fav-' + fav.id"
                            class="group"
                            :required-fields="requiredFields"
                            :disabled-row-handler="rightToPrescribeItem"
                            :collapse-label="getStatusText(fav)"
                            :actions="favouriteActions"
                            :label-actions="activeView === 3 ? favouriteLabelActions : null"
                            @favouriteView="favouriteView"
                            @favouriteAdd="favouriteAdd"
                        />
                        <Button
                            variant="contour"
                            size="secondary"
                            v-if="enableMoreAndrasPersonligaFavoriter"
                            @click="showAndrasPersonliga = false"
                        >
                            Visa alla ({{ getAndrasPersonligaFavoriter.length }})
                        </Button>
                    </div>
                </collapse-transition>
            </section>
        </article>

        <!-- BESTÄLLNINGSFORMULÄR -->

        <section class="product-selection selected order-form" v-if="selected">
            <div class="fixed-width-align-center">
                <Button variant="contour" size="secondary" @click="deSelect">Avbryt</Button>
                <h2 v-if="selected.tempId || selected.pk">Ändra beställning</h2>
                <h2 v-else>Lägg till läkemedel i beställning</h2>

                <div class="product-display">
                    <table class="table selected">
                        <tbody>
                            <td>
                                {{ selected.drugArticle.distributedTradeName }}
                                <span v-if="selected.drugArticle.isRecommended" class="label" title="Rekommenderat"
                                    >REK</span
                                >
                                <span
                                    v-if="!silApproved.includes(selected.drugArticle.productTypeCode)"
                                    class="label"
                                    title="Rekommenderat"
                                    >IGL</span
                                >
                                <br /><span class="manufacturer">{{
                                    selected.drugArticle.localRepresentative || selected.drugArticle.marketingAuthHolder
                                }}</span>
                            </td>
                            <td>{{ selected.drugArticle.strengthText }}</td>
                            <td>{{ selected.drugArticle.atcCode }}</td>
                            <td>{{ selected.drugArticle.drugFormTextSv }}</td>
                            <td>{{ selected.drugArticle.packSizeText }}</td>
                        </tbody>
                    </table>
                    <div
                        class="expanded-warning"
                        v-if="selected.drugArticle.limitedReimbursement"
                        style="max-width: 1100px"
                    >
                        <div>
                            <span class="expanded-warning-icon">!</span>
                        </div>
                        <div>
                            Observera att varan är förmånsbegränsad. Ange i doseringsanvisningen om förmånen uppfylls
                            eller ej.
                        </div>
                    </div>
                    <div
                        class="expanded-warning"
                        v-if="selected.drugArticle.packageText === 'Licensförpackning'"
                        style="max-width: 1100px"
                    >
                        <div>
                            <span class="expanded-warning-icon">!</span>
                        </div>
                        <div>
                            Information om förpackningsstorlek saknas. Dosering och behandlingstid anges i
                            doseringsanvisningar.
                        </div>
                    </div>
                    <table class="lm-info" style="margin-bottom: 20px">
                        <tbody>
                            <tr>
                                <td>Läkemedel</td>
                                <td>{{ selected.drugArticle.distributedTradeName }}</td>
                            </tr>
                            <tr>
                                <td>Styrka</td>
                                <td>{{ selected.drugArticle.strengthText }}</td>
                            </tr>
                            <tr>
                                <td>Förpackningsinnehåll</td>
                                <td>{{ selected.drugArticle.packageText }}</td>
                            </tr>
                            <tr>
                                <td>Form</td>
                                <td>{{ selected.drugArticle.drugFormTextSv }}</td>
                            </tr>
                            <tr>
                                <td>ATC</td>
                                <td>{{ selected.drugArticle.atcCode }}</td>
                            </tr>
                            <tr v-if="selected.drugArticle.productTypeCode !== 'LIC'">
                                <td>Pris</td>
                                <td>{{ parseInt(selected.drugArticle.aup) }} kr</td>
                            </tr>
                            <tr v-if="selected.drugArticle.productTypeCode !== 'LIC'">
                                <td>FASS-länk</td>
                                <td>
                                    <!-- Licensläkemedel saknar FASS-sida -->
                                    <a
                                        :href="
                                            'https://www.fass.se/LIF/product?userType=0&nplId=' +
                                            selected.drugArticle.nplId
                                        "
                                        target="_blank"
                                        >{{ selected.drugArticle.distributedTradeName }}
                                        {{ selected.drugArticle.strengthText }} (öppnas i nytt fönster)</a
                                    >
                                </td>
                            </tr>
                            <tr v-if="janusBaseURL">
                                <td>Janus-info:</td>
                                <!-- TODO: set height and width according to content of IFRAME -->
                                <td>
                                    <div class="janus-wrapper">
                                        <div class="janus-border" />
                                        <iframe
                                            name="janus"
                                            scrolling="no"
                                            seamless
                                            class="janus-info"
                                            :src="janusURLSelected"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- FIXME :key maybe not so clean -->
                    <prescription-edit-form
                        ref="orderForm"
                        :formcomponents="formComponents"
                        :requiredfields="requiredFields"
                        :data="selected"
                        :key="selected.tempId || selected.drugArticle.id"
                        @submit-order="orderSelected"
                        @cancel-order="deSelect"
                    />
                </div>
            </div>
        </section>

        <!-- GRANSKA / MAKULERA -->

        <section class="product-selection selected order-form full-width-align-center" @submit.prevent v-if="preview">
            <form id="cancellation-details" @submit.prevent="submitDeletion(preview.pk)">
                <div class="fixed-width-align-center">
                    <Button
                        variant="contour"
                        size="secondary"
                        @click="
                            preview = null;
                            deleteMode = false;
                        "
                    >
                        Avbryt
                    </Button>
                    <h2>Visar recept {{ getStatusText(preview) }}</h2>
                </div>
                <div
                    :class="{ 'product-display': true, 'top-border': index !== 0 }"
                    v-for="(item, index) in getPreviewItems()"
                    :key="'preview-' + index"
                >
                    <table class="table selected fixed-width-align-center">
                        <tbody>
                            <td>
                                {{ item.drugArticle.distributedTradeName }}
                                <span v-if="item.drugArticle.isRecommended" class="label" title="Rekommenderat"
                                    >REK</span
                                >
                                <span
                                    v-if="!silApproved.includes(item.drugArticle.productTypeCode)"
                                    class="label"
                                    title="Rekommenderat"
                                    >IGL</span
                                >
                                <br /><span class="manufacturer">{{
                                    item.drugArticle.localRepresentative || item.drugArticle.marketingAuthHolder
                                }}</span>
                            </td>
                            <td>{{ item.drugArticle.strengthText }}</td>
                            <td>{{ item.drugArticle.atcCode }}</td>
                            <td>{{ item.drugArticle.drugFormTextSv }}</td>
                            <td>{{ item.drugArticle.packSizeText }}</td>
                        </tbody>
                    </table>
                    <div class="fixed-width-align-center">
                        <table class="lm-info full-width-align-center">
                            <tbody>
                                <tr>
                                    <td>Läkemedel</td>
                                    <td>{{ item.drugArticle.distributedTradeName }}</td>
                                </tr>
                                <tr>
                                    <td>Styrka</td>
                                    <td>{{ item.drugArticle.strengthText }}</td>
                                </tr>
                                <tr>
                                    <td>Förpackningsinnehåll</td>
                                    <td>{{ item.drugArticle.packageText }}</td>
                                </tr>
                                <tr>
                                    <td>Form</td>
                                    <td>{{ item.drugArticle.drugFormTextSv }}</td>
                                </tr>
                                <tr>
                                    <td>ATC</td>
                                    <td>{{ item.drugArticle.atcCode }}</td>
                                </tr>
                                <tr>
                                    <td>Pris</td>
                                    <td>{{ parseInt(item.drugArticle.aup) }} kr</td>
                                </tr>
                                <tr>
                                    <td>FASS-länk</td>
                                    <td>
                                        <a
                                            :href="
                                                'https://www.fass.se/LIF/product?userType=0&nplId=' +
                                                item.drugArticle.nplId
                                            "
                                            target="_blank"
                                            >{{ item.drugArticle.distributedTradeName }}
                                            {{ item.drugArticle.strengthText }} (öppnas i nytt fönster)</a
                                        >
                                    </td>
                                </tr>
                                <tr v-if="janusBaseURL">
                                    <td>Janusmed</td>
                                    <td>
                                        <div class="janus-wrapper">
                                            <div class="janus-border" />
                                            <iframe
                                                name="janus"
                                                scrolling="no"
                                                seamless
                                                class="janus-info"
                                                :src="janusURLPreview"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="form-group k3-form fullscreen fixed-width-align-center bottom-border">
                        <div
                            v-for="(formComponent, index) in formComponents"
                            :class="
                                'preview-border c' +
                                (formComponent.colspan ? Math.min(formComponent.colspan, colspanMax) : '2')
                            "
                            :key="index"
                        >
                            <span>{{ formComponent.label }}</span>
                            {{ getDisplayName(item, formComponent) }}
                        </div>
                    </div>
                    <div class="makuleraBox" v-if="deleteMode">
                        <div class="fixed-width-align-center">
                            <h2 v-if="item.is_cancelled || item.is_nullified" :id="item.pk">Makulerad</h2>
                            <h2 v-else-if="item.is_expired" :id="item.pk">Giltighetstiden har passerats</h2>
                            <h2 v-else-if="!item.drugArticle.rightToPrescribe" :id="item.pk">
                                Endast användare med förskrivningsrätt för detta läkemedel kan makulera denna rad
                            </h2>
                            <prescription-delete-form
                                v-else
                                v-model="selectedMakulerade[index]"
                                :id="item.pk"
                                :index="index"
                                :total="preview.prescriptionItemDetails.length"
                            />
                        </div>
                    </div>
                    <div class="whiteBox"></div>
                </div>
            </form>
            <div
                :class="{
                    'btn-group full-width-align-center': true,
                    makuleraButtonsFrame: deleteMode,
                    'bottom-border': !deleteMode,
                }"
                role="group"
            >
                <ButtonGroup
                    :class="{ makuleraButtons: deleteMode, 'fixed-width-align-center full-width-align-center': true }"
                >
                    <Button
                        variant="alert"
                        @click="submitDeletion(preview.pk)"
                        v-if="deleteMode"
                        :disabled="!isCancellable"
                    >
                        Makulera ({{ selectedTrueMakulerade.length }} av {{ preview.prescriptionItemDetails.length }})
                    </Button>
                    <Button
                        v-if="externalPreview"
                        @click="
                            deleteMode = false;
                            addExternalPreview();
                        "
                    >
                        <img src="@/assets/plus_circle_white.svg" />Lägg till i beställning
                    </Button>
                    <Button
                        variant="contour"
                        size="secondary"
                        @click="
                            preview = null;
                            deleteMode = false;
                            cleanMakuleradeArray();
                        "
                    >
                        Avbryt
                    </Button>
                </ButtonGroup>
            </div>
        </section>

        <!-- HISTORIK -->

        <section class="product-selection selected order-form full-width-align-center" v-if="history">
            <form id="cancellation-details" @submit.prevent>
                <div class="fixed-width-align-center">
                    <Button variant="contour" size="secondary" @click="history = null">Avbryt</Button>
                    <h2>Visar recept {{ getStatusText(history) }}</h2>
                </div>

                <div
                    :class="{ 'product-display recept-historik': true, 'top-border': index !== 0 }"
                    v-for="(item, index) in history.prescriptionItemDetails"
                    :key="'history-' + index"
                >
                    <table class="table selected fixed-width-align-center">
                        <tr>
                            <td>
                                {{ getLatestVersion(item).drugArticle.distributedTradeName }}
                                <span
                                    v-if="getLatestVersion(item).drugArticle.isRecommended"
                                    class="label"
                                    title="Rekommenderat"
                                    >REK</span
                                >
                                <span
                                    v-if="!silApproved.includes(getLatestVersion(item).drugArticle.productTypeCode)"
                                    class="label"
                                    title="Rekommenderat"
                                    >IGL</span
                                >
                                <br /><span class="manufacturer">{{
                                    getLatestVersion(item).drugArticle.localRepresentative ||
                                    getLatestVersion(item).drugArticle.marketingAuthHolder
                                }}</span>
                            </td>
                            <td>{{ getLatestVersion(item).drugArticle.strengthText }}</td>
                            <td>{{ getLatestVersion(item).drugArticle.atcCode }}</td>
                            <td>{{ getLatestVersion(item).drugArticle.drugFormTextSv }}</td>
                            <td>{{ getLatestVersion(item).drugArticle.packSizeText }}</td>
                        </tr>
                        <tr>
                            <td colspan="5" class="recept-historik-sidhuvud">
                                <hr width="100%" style="margin: 0px 0px 0px 0px" height="1px" color="#DBEFF6" />
                                <span
                                    >Upprättad av {{ getProfile(getLatestVersion(item), "created_by") }}
                                    {{ getDateAndTime(getLatestVersion(item).created_at) }}.
                                </span>
                                <span v-if="getLatestVersion(item).is_edited"
                                    >Senast redigerad av {{ getProfile(getLatestVersion(item), "edited_by") }}
                                    {{ getDateAndTime(getLatestVersion(item).edited_at) }}</span
                                >
                            </td>
                        </tr>
                    </table>

                    <div class="fixed-width-align-center">
                        <table class="lm-info full-width-align-center">
                            <tbody>
                                <tr>
                                    <td>Läkemedel</td>
                                    <td>{{ getLatestVersion(item).drugArticle.distributedTradeName }}</td>
                                </tr>
                                <tr>
                                    <td>Styrka</td>
                                    <td>{{ getLatestVersion(item).drugArticle.strengthText }}</td>
                                </tr>
                                <tr>
                                    <td>Förpackningsinnehåll</td>
                                    <td>{{ getLatestVersion(item).drugArticle.packageText }}</td>
                                </tr>
                                <tr>
                                    <td>Form</td>
                                    <td>{{ getLatestVersion(item).drugArticle.drugFormTextSv }}</td>
                                </tr>
                                <tr>
                                    <td>ATC</td>
                                    <td>{{ getLatestVersion(item).drugArticle.atcCode }}</td>
                                </tr>
                                <tr>
                                    <td>Pris</td>
                                    <td>{{ parseInt(getLatestVersion(item).drugArticle.aup) }} kr</td>
                                </tr>
                                <tr v-if="getLatestVersion(item).drugArticle.nplId !== '00000000000000'">
                                    <td>FASS-länk</td>
                                    <td>
                                        <a
                                            :href="
                                                'https://www.fass.se/LIF/product?userType=0&nplId=' +
                                                getLatestVersion(item).drugArticle.nplId
                                            "
                                            target="_blank"
                                            >{{ getLatestVersion(item).drugArticle.distributedTradeName }}
                                            {{ getLatestVersion(item).drugArticle.strengthText }}</a
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="form-group k3-form fullscreen fixed-width-align-center bottom-border">
                        <div
                            v-for="(formComponent, index) in formComponents"
                            :class="
                                'preview-border c' +
                                (formComponent.colspan ? Math.min(formComponent.colspan, colspanMax) : '2')
                            "
                            :key="index"
                        >
                            <div style="display: flex">
                                <span style="flex: 0; margin-right: 6px">{{ formComponent.label }}</span>
                                <v-popover
                                    placement="top"
                                    trigger="click"
                                    popover-base-class="prescriptionset-tooltip popover"
                                    v-if="getLabelVersions(formComponent.parameters.name, item).length > 0"
                                >
                                    <a href="#" class="tooltip-target edited">(redigerad)</a>
                                    <history-popover
                                        :history="getLabelVersions(formComponent.parameters.name, item)"
                                        slot="popover"
                                    />
                                </v-popover>
                            </div>
                            {{ getDisplayName(getLatestVersion(item), formComponent) }}
                        </div>
                    </div>
                    <div class="whiteBox"></div>
                </div>
                <div :class="{ 'btn-group full-width-align-center bottom-border': true }" role="group">
                    <div :class="{ 'fixed-width-align-center full-width-align-center': true }">
                        <Button variant="contour" size="secondary" @click="history = null">Avbryt</Button>
                    </div>
                </div>
            </form>
        </section>

        <!-- AKTUELL BESTÄLLNING -->

        <section class="product-order" v-show="(pk && items.length === 0) || items.length || selected">
            <h3>{{ pk ? "Redigera tidigare recept" : "Ny beställning" }} ({{ items.length }})</h3>
            <action-table
                class="current-order"
                :key="'currentOrder'"
                :disabled-actions-dropdown-handler="disabledActionsDropdownHandler"
                :columns="orderTableColumns"
                :required-fields="requiredFields"
                :items="items"
                :actions="pk ? previousOrderRowActions : orderRowActions"
            ></action-table>
            <!-- :sort-method="(key,reversed) => $store.commit('prescriptionSet/sortItems', { key, reversed })" -->
            <div class="janus-table" v-if="janusBaseURL && items.length">
                Janusmed
                <div class="janus-wrapper">
                    <div class="janus-border" />
                    <iframe name="janus" scrolling="no" seamless class="janus-info" :src="janusURLAll" />
                </div>
            </div>
            <div class="order-buttons">
                <ButtonGroup role="group">
                    <Button @click="openSendDialog" :disabled="!isSendable">
                        <img src="@/assets/send_white.svg" />Skicka
                    </Button>
                    <Button @click="save" :disabled="!isModified">Spara</Button>
                    <Button
                        v-if="activeView >= 2"
                        @click="
                            addToPrevious = true;
                            activeTab = 1;
                        "
                    >
                        Lägg till nytt läkemedel
                    </Button>
                    <Button size="secondary" @click="printReceptblankett" :disabled="!items.length && !isSendable">
                        Skriv ut som pappersrecept
                    </Button>
                    <Button variant="alert" @click="clearOrder" :disabled="!items.length"> Avbryt</Button>
                </ButtonGroup>
            </div>
        </section>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ActionTable from "@/components/generic/ActionTable.vue";
import PrescriptionSetActionTableWrapper from "@/tabs/PrescriptionSet/PrescriptionSetActionTableWrapper.vue";
import ActionTableWrapper from "@/tabs/PrescriptionSet/ActionTableWrapper.vue";
import SelectActionTable from "@/components/generic/SelectActionTable.vue";
import PrescriptionEditForm from "@/tabs/PrescriptionSet/PrescriptionEditForm.vue";
import PrescriptionSendForm from "@/tabs/PrescriptionSet/PrescriptionSendForm.vue";
import PrescriptionDeleteForm from "@/tabs/PrescriptionSet/PrescriptionDeleteForm.vue";
import HistoryPopover from "@/tabs/PrescriptionSet/HistoryPopover.vue";
import dialogEvents from "@/dialogEvents";
import BaseTab from "@/tabs/mixins/BaseTab";
import widgets from "@/components/widgets";
import { openDialog } from "@/utils";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { getDosageTextK2 } from "@/tabs/PrescriptionSet/utils";
import addToOrder from "@/assets/plus_circle_white.svg";
import tabIcon from "@/assets/receptforskrivning.svg";
import PrescriptionSetActionTable from "@/tabs/PrescriptionSet/PrescriptionSetActionTable.vue";
import ReceptFavoriterVue from "./ReceptFavoriter.vue";
import Vue from "vue";
import { VTooltip } from "floating-vue";
import $ from "jquery";
import { cloneDeep } from "lodash";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import { CollapseTransition } from "@ivanv/vue-collapse-transition";

Vue.use(VTooltip);

const SEARCH_WAIT = 1000;

const SIL_APPROVED = [
    "MEP", // läkemedel
    "VBL", // växtbaserade läkemedel
    "ALL", // allergen
    "NLM", // naturläkemedel
    "VUM", // vissa utvärtes läkemedel
    "RAP", // radiofarmaka
];

const PREVIOUS_ACTIONS = [
    {
        name: "previous::add",
        title: "Lägg till i beställning",
        type: "primary",
        icon: addToOrder,
    },
];

const PREVIOUS_GROUP_ACTIONS = [{ name: "previous::print", title: "Skriv ut", type: "primary" }];

const PREVIOUS_LABEL_ACTIONS = [
    { name: "previous::view", title: "Granska", img: "show.svg" },
    { name: "previous::cancel", title: "Makulera valda rader", img: "delete.svg" },
    {
        name: "previous::edit",
        title: "Redigera",
        img: "edit.svg",
        disabled(item, currentId) {
            const pk = item.accountableHealthcareProfessional.pk;
            return pk !== "" && pk === currentId ? false : true;
        },
    },
];

const SENT_LABEL_ACTIONS = [
    { name: "previous::view", title: "Granska", img: "show.svg" },
    { name: "previous::cancel", title: "Makulera valda rader", img: "delete.svg" },
    {
        name: "previous::sms",
        title: "Skicka sms-notifiering",
        disabled: (item) => !item.can_send_sms,
    },
];

const CANCELLED_LABEL_ACTIONS = [{ name: "previous::view", title: "Granska", img: "show.svg" }];

const FAVOURITE_ACTIONS = [
    {
        name: "favouriteAdd",
        title: "Lägg till i beställning",
        type: "primary",
        img: addToOrder,
    },
];

const FAVOURITE_LABEL_ACTIONS = [{ name: "favouriteView", title: "Granska", img: "show.svg" }];

const ORDER_ROW_ACTIONS = [
    { name: "orderItem::edit", title: "Redigera" },
    { name: "orderItem::delete", title: "Ta bort" },
    { name: "orderItem::duplicate", title: "Duplicera" },
];

const PREVIOUS_ORDER_ROW_ACTIONS = [
    { name: "orderItem::edit", title: "Redigera" },
    { name: "orderItem::duplicate", title: "Duplicera" },
];

const DRUG_TABLE_COLUMNS = [
    {
        name: "distributedTradeName",
        title: "Namn",
        renderer: (item) => {
            let returnString = item.distributedTradeName;
            if (item.isRecommended) returnString += '<span class="label">REK</span>';
            if (!SIL_APPROVED.includes(item.productTypeCode)) returnString += '<span class="label">IGL</span>';
            returnString +=
                '<br/><span class="manufacturer">' +
                (item.localRepresentative || item.marketingAuthHolder || "") +
                "</span>";
            return returnString;
        },
    },
    {
        name: "atcCode",
        title: "ATC",
    },
    {
        name: "strengthNumeric",
        title: "Styrka",
        renderer: (item) => {
            return item.strengthText;
        },
    },
    {
        name: "drugFormTextSv",
        title: "Form",
    },
    {
        name: "packageText",
        title: "Förpackningsinformation",
    },
];
const PREVIOUS_PRESCRIPTIONS_COLUMNS = [
    {
        name: "drugArticle.distributedTradeName",
        title: "Namn",
        renderer: (item, Q) => {
            let returnString = "";
            if (Q) {
                let indexOfQ = item.drugArticle.distributedTradeName.toLowerCase().indexOf(Q);
                // Note that backend only returns hits starting with q, thus if q is found it's always at the start of the string.
                if (indexOfQ !== -1)
                    returnString +=
                        "<mark>" +
                        item.drugArticle.distributedTradeName.substring(0, Q.length) +
                        "</mark>" +
                        item.drugArticle.distributedTradeName.substring(Q.length);
                else returnString += item.drugArticle.distributedTradeName;
            } else returnString += item.drugArticle.distributedTradeName;
            if (item.is_withdrawn) returnString = "<strike>" + returnString + "</strike>";
            if (item.is_edited) returnString += '<span class="edited">(redigerad)</span>';
            if (item.drugArticle.isRecommended) returnString += ' <span class="label">REK</span>';
            if (!SIL_APPROVED.includes(item.drugArticle.productTypeCode))
                returnString += ' <span class="label">IGL</span>';
            returnString +=
                '<br/><span class="manufacturer">' +
                (item.drugArticle.localRepresentative || item.drugArticle.marketingAuthHolder || "") +
                "</span>";
            return returnString;
        },
    },
    {
        name: "drugArticle.strengthNumeric",
        title: "Styrka",
        renderer: (item) => {
            return item.drugArticle.strengthText;
        },
    },
    {
        name: "numberOfPackages",
        title: "Förpackning(ar)",
        renderer: (item) => {
            if (item.medicinalProduct === "00000000000000") return "";
            return (
                (item.numberOfPackages === null || item.numberOfPackages === undefined
                    ? "ej angivet antal"
                    : item.numberOfPackages) +
                " förp. à " +
                item.drugArticle.packSizeText
            );
        },
    },
    {
        name: "dispensingRepeatNumber",
        title: "Antal uttag",
        renderer: (item) => {
            return item.medicinalProduct !== "00000000000000" ? item.dispensingRepeatNumber : "";
        },
    },
    {
        name: "unstructuredDosageAdmin",
        title: "Ordination",
        renderer: (item) => {
            return item.medicinalProduct !== "00000000000000" ? item.unstructuredDosageAdmin : "";
        },
    },
    {
        name: "cancellationStatus",
        title: "",
        renderer: (item) => {
            let returnString = "";
            if (item.is_cancelled) {
                let title =
                    "Makulerad av " +
                    (item.nullified_by ? item.nullified_by.user.name : "[Saknas]") +
                    " den " +
                    (item.nullified_at
                        ? new Date(item.nullified_at).toLocaleString("sv-SE").split(" ")[0]
                        : "[Saknas]") +
                    ". Orsak: " +
                    (item.nullifiedReason || "[Saknas]");
                returnString +=
                    '<span class="label-status" style="background-color: #CB1339;"><a href="#" data-toggle="tooltip" data-placement="left" title="' +
                    title +
                    '">MAKULERAT</a></span>';
            } else if (item.is_withdrawn)
                returnString += '<span class="label-status" style="background-color: #e86781;">AVREGISTRERAT</span>';
            else if (!item.drugArticle.rightToPrescribe)
                returnString +=
                    '<span class="label-status" style="background-color: #CB1339;">EJ FÖRSKRIVNINGSRÄTT</span>';
            return returnString;
        },
    },
];

const ORDER_TABLE_COLUMNS = [
    {
        name: "drugArticle.distributedTradeName",
        title: "Namn",
        renderer: (item) => {
            return item.drugArticle.distributedTradeName;
        },
    },
    {
        name: "drugArticle.strengthNumeric",
        title: "Styrka",
        renderer: (item) => {
            return item.drugArticle.strengthText;
        },
    },
    {
        name: "drugArticle.drugFormTextSv",
        title: "Form",
        renderer: (item) => {
            return item.drugArticle.drugFormTextSv;
        },
    },
    {
        name: "numberOfPackages",
        title: "Antal beställning",
        renderer: (item) => {
            if (item.numberOfPackages) return item.numberOfPackages + " förp. à " + item.drugArticle.packSizeText;
            else return "";
        },
    },
    { name: "unstructuredDosageAdmin", title: "Ordination" },
];

const NullableString = (string) => string || null;
const NullableNumber = (string) => (string ? Number(string) : null);
const ORDER_DETAIL_TYPES = {
    dispensingRepeatNumber: Number,
    numberOfPackages: Number,
    timeValue: NullableNumber,
    latestRequestedTimeForDispensing: NullableString,
};

const formComponents = [
    {
        label: "Doseringsanvisning",
        component: widgets.DosageSuggestTextArea,
        parameters: {
            name: "unstructuredDosageAdmin",
            maxlength: 254,
            filter: /[^\u0020-\u007E\u00A1-\u00FF]+/g,
        },
        colspan: 6,
    },
    {
        label: "Ordinationsorsak (ICD)",
        component: widgets.AutoCompleteWidget,
        parameters: {
            name: "indicationForPrescription",
            apisearch: "/core/kodverk/icdkoder/",
            displayCode: true,
        },
    },
    {
        label: "Antal förpackningar",
        component: widgets.RangeWidget,
        parameters: {
            name: "numberOfPackages",
            min: 1,
            max: 9999,
        },
    },
    {
        label: "Antal uttag",
        component: widgets.RangeWidget,
        parameters: {
            name: "dispensingRepeatNumber",
            min: 1,
            max: 99,
        },
    },
    {
        label: "Startförpackning",
        component: widgets.SelectWidget,
        parameters: {
            name: "pro",
            options: [
                { text: "Ja", value: true },
                { text: "Nej", value: false },
            ],
        },
    },
    {
        label: "Språk",
        component: widgets.AutoCompleteWidget,
        parameters: {
            name: "languageOfLabel",
            apisearch: "/core/kodverk/iso_639_1/",
            map: (el) => {
                return {
                    id: el.code,
                    code: el.code,
                    displayName: el.displayName,
                };
            },
        },
    },
    {
        label: "Får bytas",
        component: widgets.SelectWidget,
        parameters: {
            name: "eby",
            // Frågan som BE hanterar är "Får ej bytas mot generika", så Ja på frågan "Får bytas" blir false i BE och Nej blir true
            options: [
                { text: "Ja", value: false },
                { text: "Nej", value: true },
            ],
        },
    },
    {
        label: "Sista dag för första uttag",
        component: widgets.DateWidget,
        parameters: {
            name: "latestRequestedTimeForDispensing",
            notBefore: "today",
            notAfter: "year",
        },
    },
    {
        label: "Tid mellan uttag",
        component: widgets.RangeWidget,
        parameters: {
            name: "timeValue",
            min: 1,
            max: 365,
        },
    },
    {
        label: "Tidsenhet",
        component: widgets.SelectWidget,
        parameters: {
            name: "timeUnit",
            options: [
                { text: "", value: "" },
                { text: "dag/dagar", value: "Dg" },
                { text: "vecka/veckor", value: "V" },
                { text: "månad(er)", value: "Mn" },
            ],
        },
    },
];

const DEFAULT_REQUIRED_FIELDS = [
    "unstructuredDosageAdmin",
    "numberOfPackages",
    "dispensingRepeatNumber",
    "pro",
    "careCoverageType",
    "eby",
];

export default {
    extends: BaseTab,
    name: "PrescriptionSet",
    tabName: "Receptförskrivning",
    icon: tabIcon,
    patientBound: true,
    components: {
        HistoryPopover,
        ActionTable,
        PrescriptionSetActionTableWrapper,
        PrescriptionSetActionTable,
        ActionTableWrapper,
        PrescriptionEditForm,
        PrescriptionDeleteForm,
        SelectActionTable,
        ReceptFavoriterVue,
        ...widgets,
        Button,
        ButtonGroup,
        CollapseTransition,
    },
    data() {
        return {
            q: "",
            rek: false,
            igl: false,
            loading: false,
            selected: null,
            silApproved: SIL_APPROVED,
            orderRowActions: ORDER_ROW_ACTIONS,
            previousOrderRowActions: PREVIOUS_ORDER_ROW_ACTIONS,
            drugTableColumns: DRUG_TABLE_COLUMNS,
            orderTableColumns: ORDER_TABLE_COLUMNS,
            previousActions: PREVIOUS_ACTIONS,
            previousGroupActions: PREVIOUS_GROUP_ACTIONS,
            previousLabelActions: PREVIOUS_LABEL_ACTIONS,
            cancelledLabelActions: CANCELLED_LABEL_ACTIONS,
            sentLabelActions: SENT_LABEL_ACTIONS,
            favouriteActions: FAVOURITE_ACTIONS,
            favouriteLabelActions: FAVOURITE_LABEL_ACTIONS,
            previousPrescriptionsTableColumns: PREVIOUS_PRESCRIPTIONS_COLUMNS,
            preview: null,
            history: null,
            activeView: 0, // Active tab in top menu (called view)
            activeTab: 1, // Active tab in "nytt recept" menu
            deleteMode: false,
            addToPrevious: false,
            qChanged: false,
            requiredFields: [],
            formComponents: formComponents,
            selectedMakulerade: [],
            searching: false,
            searchTimeout: false,
            externalPreviewLoaded: false,
            externalPrescriptionLoaded: false,
            showPersonliga: true,
            showAndrasPersonliga: true,
            showGemensamma: true,
            showAndrasPersonligaFavoriter: false,
            showOrganisationiskaFavoriter: true,
            showPrivataFavoriter: true,
        };
    },
    props: {
        tabId: Number,
    },
    computed: {
        ...mapGetters("prescriptionSet", [
            "items",
            "pk",
            "externalPreview",
            "externalParameters",
            "isSendable",
            "isWorking",
            "isModified",
            "accountableHealthcareProfessional",
            "searchResults",
            "groupedSearchResults",
            "previousPrescriptions",
            "filteredPreviousPrescriptions",
            "getPersonligaFavoriter",
            "getAndrasPersonligaFavoriter",
            "getGemensammaFavoriter",
        ]),
        ...mapState("userData", ["userData"]),
        ...mapGetters("userData", ["currentProfile", "arbKod", "currentProfileId"]),
        ...mapGetters("systemData", ["systemName", "systemVersion", "SMSEnabled", "janusBaseURL"]),
        ...mapGetters("patientData", ["binaryGender", "dateOfBirth", "patient"]),
        selectedTrueMakulerade() {
            return this.selectedMakulerade.filter((selected) => selected);
        },

        isCancellable() {
            if (!this.preview) return false;
            let items = this.preview.prescriptionItemDetails;
            let numberOfCancellableItems = items.length;
            items.forEach((item) => {
                if (!item.drugArticle.rightToPrescribe) {
                    numberOfCancellableItems--;
                } else if (item.is_expired) {
                    numberOfCancellableItems--;
                } else if (item.is_cancelled) {
                    numberOfCancellableItems--;
                } else if (item.is_nullified) {
                    numberOfCancellableItems--;
                }
            });
            return numberOfCancellableItems > 0;
        },

        hasUnsavedData() {
            return this.isModified;
        },
        filteredPreviousPrescriptionsArray() {
            return this.q.length > 2 ? this.filteredPreviousPrescriptions : this.previousPrescriptions;
        },

        filteredPersonligaFavouriter() {
            if (!this.getPersonligaFavoriter) return [];
            let itms = this.getPersonligaFavoriter.filter(this.prescriptionSetContainsQ);
            if (this.enableMorePersonligaFavouriter) itms = itms.slice(0, 5);
            return itms;
        },
        enableMorePersonligaFavouriter() {
            return this.hasMorePersonligaFavouriter && this.showPersonliga && this.activeView === 0;
        },
        hasMorePersonligaFavouriter() {
            return this.getPersonligaFavoriter && this.getPersonligaFavoriter.length > 5;
        },

        filteredGemensamaFavouriter() {
            if (!this.getGemensammaFavoriter) return [];
            let itms = this.getGemensammaFavoriter.filter(this.prescriptionSetContainsQ);
            if (this.enableMoreGemensammaFavoriter) itms = itms.slice(0, 5);
            return itms;
        },
        enableMoreGemensammaFavoriter() {
            return this.hasMoreGemensammaFavoriter && this.showGemensamma && this.activeView === 0;
        },
        hasMoreGemensammaFavoriter() {
            return this.getGemensammaFavoriter && this.getGemensammaFavoriter.length > 5;
        },

        filterAndrasPersonligaFavoriter() {
            if (!this.getAndrasPersonligaFavoriter) return [];
            let itms = this.getAndrasPersonligaFavoriter.filter(this.prescriptionSetContainsQ);
            if (this.enableMoreAndrasPersonligaFavoriter) itms = itms.slice(0, 5);
            return itms;
        },
        enableMoreAndrasPersonligaFavoriter() {
            return this.hasMoreAndrasPersonligaFavoriter && this.showAndrasPersonliga && this.activeView === 0;
        },
        hasMoreAndrasPersonligaFavoriter() {
            return this.getAndrasPersonligaFavoriter && this.getAndrasPersonligaFavoriter.length > 5;
        },

        showSearch() {
            return !this.selected && this.activeView === 1 && this.activeTab === 1;
        },
        showPrevious() {
            return (
                !this.selected &&
                !this.preview && // never show if editing a prescription
                !this.history &&
                (this.activeView === 0 ||
                    this.activeView === 2 || // show in start page and tidigare recept
                    (this.activeView === 1 && this.activeTab === 2))
            ); // only show in nytt recept if user has searched
        },
        showFavourites() {
            return (
                !this.selected &&
                !this.preview && // never show if editing a prescription
                !this.history &&
                (this.activeView === 0 ||
                    this.activeView === 3 || // show in start page and tidigare recept
                    (this.activeView === 1 && this.activeTab === 3))
            ); // only show in nytt recept if user has searched
        },
        colspanMax() {
            return 8;
        },
        latestVersion() {
            if (this.history) return this.history.prescriptionItemDetails[0];
            else return null;
        },
        janusURLSelected() {
            return this.janusURL(this.items.concat(this.selected));
        },
        janusURLPreview() {
            return this.janusURL(this.getPreviewItems());
        },
        janusURLAll() {
            return this.janusURL(this.items);
        },
    },
    methods: {
        ...mapMutations("prescriptionSet", [
            "setItemsOnPreviousPrescriptions",
            "setItemsOnFilteredPreviousPrescriptions",
            "deleteFavoritItem",
        ]),
        ...mapActions("prescriptionSet", ["fetchBothFavoriter"]),
        prescriptionItemDetailsFetched(responseData) {
            this.setItemsOnPreviousPrescriptions(responseData);
            this.setItemsOnFilteredPreviousPrescriptions(responseData);
        },
        validateForm(formName) {
            var form = document.getElementById(formName);
            if (form.checkValidity() === true) return true;
            else {
                var list = form.querySelectorAll(":invalid");
                for (var item of list) {
                    item.focus();
                }
                form.querySelector(":invalid").focus();
            }
            return false;
        },
        getLabel(prescriptionSet) {
            let label = {};
            label.text = "SPARAT";
            label.colour = "#859BA3";
            if (prescriptionSet.is_signed) {
                label.text = "SIGNERAT";
                label.colour = "#34AC8D";
            }
            if (prescriptionSet.is_sent) {
                label.text = "SKICKAT";
                label.colour = "#34AC8D";
            }
            let cancelled = parseInt(prescriptionSet.cancelled);
            if (cancelled === 1) {
                label.text = "PART MAKULERAT";
                label.colour = "#ff9933";
            } else if (cancelled === 2 || prescriptionSet.is_nullified) {
                label.text = "MAKULERAT";
                label.colour = "#CB1339";
            }
            if (prescriptionSet.is_printed) {
                label.text = "UTSKRIVET";
                label.colour = "#3399ff";
            }
            return label;
        },
        /**
         * Formats and returns the value of a property that is to be displayed on "Granska"
         */
        getDisplayName(item, formComponent) {
            let value = item[formComponent.parameters.name];

            // eHm fält EBY betyder "Får ej bytas mot generika", men FE visar ""Får bytas"
            if (typeof value === "boolean" && formComponent.parameters.name === "eby") {
                return value ? "Nej" : "Ja";
            }

            if (typeof value === "boolean") {
                return value ? "Ja" : "Nej";
            }

            if (value) {
                if (typeof value === "object") {
                    return value.code + " " + value.displayName;
                }

                let value_display = item[formComponent.parameters.name + "_display"];
                if (value_display) return value_display;
                else return value;
            } else return "-";
        },
        setActiveView(id) {
            this.activeView = id;
        },
        addExternalPreview() {
            this.$store.dispatch("prescriptionSet/AddMultipleItems", this.preview.data);
            this.$store.commit("prescriptionSet/setExternalPreview", null);
            this.preview = null;
        },
        select(item, duplicate) {
            if (duplicate)
                item = {
                    drugArticle: item.drugArticle,
                };
            this.selected = item;
        },
        deSelect() {
            this.select(null);
        },
        /**
         * Read form data from PrescriptionEditForm
         */
        getOrderDetails() {
            let details = {};
            // Note: inadequate support for FormData in IE, use JQuery serializeArray() instead
            $("form#recipe-details")
                .serializeArray()
                .forEach((keyValuePair) => {
                    var key = keyValuePair.name;
                    var value = undefined;
                    try {
                        value = JSON.parse(keyValuePair.value);
                    } catch (e) {
                        value = keyValuePair.value;
                    }
                    if (key in ORDER_DETAIL_TYPES) details[key] = ORDER_DETAIL_TYPES[key](value);
                    else {
                        // Check if details already exists
                        if (details[key]) {
                            // If does, check if it is an array, if not convert to array
                            if (!Array.isArray(details[key])) details[key] = [details[key]];
                            details[key].push(value);
                        } else details[key] = value;
                    }
                });
            return details;
        },
        groupLabel(group) {
            let labels = [];
            if (group.filter((el) => el.isRecommended).length > 0) labels.push("REK");
            if (group.filter((el) => !SIL_APPROVED.includes(el.productTypeCode)).length > 0) labels.push("IGL");
            return labels;
        },
        /* getLabelActions(prescriptionSet) {
let sent = prescriptionSet.is_sent;
let cancelled = prescriptionSet.cancelled;
let printed = prescriptionSet.is_printed;
let nullified = prescriptionSet.is_nullified;
if ((sent || printed) && (cancelled !== 2 || !nullified))
// TODO:Checka den här. Cancelled är om skickad, nullified är om inte.
return this.items.length === 0 ? this.sentLabelActions : this.sentLabelActions.slice(0, 1);
else if (cancelled === 2 || nullified)
return this.items.length === 0 ? this.cancelledLabelActions : this.cancelledLabelActions.slice(0, 1);
else return this.items.length === 0 ? this.previousLabelActions : this.previousLabelActions.slice(0, 1);
},*/
        getProfile(item, field) {
            let editedBy = item[field];
            if (editedBy) return editedBy.user.name;
            else return "[Saknas]";
        },
        getDateAndTime(date) {
            let d = new Date(date);
            return d.toLocaleString("sv-SE").slice(0, -9) + " kl. " + d.toLocaleString("sv-SE").slice(11, -3);
        },
        disabledActionsDropdownHandler(item) {
            return item.is_cancelled || item.is_withdrawn;
        },
        /**
         * Called upon form submit from PrescriptionEditForm
         */
        orderSelected() {
            const order = {
                item: this.selected,
                details: this.getOrderDetails(),
            };
            if (this.selected.pk || this.selected.tempId) {
                this.$store.dispatch("prescriptionSet/updateItem", order);
            } else {
                this.$store.dispatch("prescriptionSet/addItem", order);
            }
            this.selected = null;
            this.q = "";
            this.$nextTick(this.focusSearch);
        },
        getSearchParams() {
            let params = {
                q: this.q,
                limit: 1000,
            };
            if (this.rek) params.isRecommended = true;
            if (!this.igl) params.productTypeGroup = "APPROVED";
            return params;
        },
        search() {
            if (this.searching) return setTimeout(this.search, SEARCH_WAIT);
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            if (this.q.length >= 3) {
                this.searchTimeout = setTimeout(this.fetchResults, SEARCH_WAIT);
            } else {
                this.$store.commit("prescriptionSet/setSearchResults", []);
            }
        },
        async fetchResults() {
            this.searching = true;
            await this.$store.dispatch("prescriptionSet/fetchSearchResults", this.getSearchParams());
            this.searching = false;
            this.searchTimeout = false;
        },
        // NOTE: this will send same NPL id (at least) twice if editing an item (more times if several rows of the same NPL id in order), but doesn't matter since Janus Info only compares unique NPL ids.
        janusURL: function (items) {
            if (!this.janusBaseURL) return null;
            let url = this.janusBaseURL + "K=";
            url += this.binaryGender.charAt(0);
            url += "&F=" + this.dateOfBirth.substring(0, 4);
            url += "&FORSKOD=&ARBKOD=" + this.arbKod + "&SNAME=" + this.systemName + "&SVER=" + this.systemVersion;
            for (let i = 0; i < items.length; i++) {
                if (items[i].drugArticle.nplId !== "00000000000000") url += "&L" + i + "=" + items[i].drugArticle.nplId;
            }
            return url;
        },
        prescriptionSetContainsQ(prescriptionSet) {
            // Filter function roughly mimics backend PrescriptionSetFilter for similar matches to /sil/search/
            // One important distinction is that it only accepts alphanumerical characters, so ' ', ',' and
            // special characters e.g. '®' anywhere in the text are removed.
            if (this.q.length <= 2) return true;

            let shortQ = this.q.toLowerCase().replace(/[^a-z0-9+]+/g, "");

            // Added support for favoritrader
            for (const el of prescriptionSet.prescriptionItemDetails || prescriptionSet.favoritrader) {
                let shortName = el.drugArticle.distributedTradeName.toLowerCase().replace(/[^a-z0-9+]+/g, "");
                let shortAtcCode = el.drugArticle.atcCode.toLowerCase().replace(/[^a-z0-9+]+/g, "");
                let shortStrengthText = el.drugArticle.strengthText
                    ? el.drugArticle.strengthText.toLowerCase().replace(/[^a-z0-9+]+/g, "")
                    : "";
                let shortStrength = el.drugArticle.strengthNumeric
                    ? el.drugArticle.strengthNumeric
                          .toString()
                          .toLowerCase()
                          .replace(/[^a-z0-9+]+/g, "")
                    : "";

                let tradeNameStrengthText = shortName + shortStrengthText;
                let tradeNameStrength = shortName + shortStrength;

                if (
                    tradeNameStrengthText.startsWith(shortQ) ||
                    tradeNameStrength.startsWith(shortQ) ||
                    shortAtcCode.startsWith(shortQ)
                )
                    return true;
            }
            return false;
        },
        focusFirst() {
            const first = $(this.$el).find(".product-selection .group button").first();
            // Select first if only one result
            if (this.groupedSearchResults.length === 1) {
                first.click();
                $(this.$el).find(".product-selection table a").first().focus();
            } else {
                first.focus();
            }
        },
        focusSearch() {
            $("#presc_q").focus();
        },
        async printReceptblankett() {
            // 2023-10-10 - Ger en varning om i stället för att skriva ut.
            // dialogEvents.$emit("open", {
            //     type: "warning",
            //     description:
            //         "Det går tyvärr inte att skriva ut recept på papper för närvarande. Skriv för hand på en blankett.",
            //     buttons: [
            //         {
            //             title: "Ok",
            //             type: "primary",
            //         },
            //     ],
            // });

            this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
            await this.$store.dispatch("prescriptionSet/printReceptblankett");
            this.$store.commit("prescriptionSet/setStatus", "printing");
            this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
        },
        async save() {
            this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
            await this.$store.dispatch("prescriptionSet/save");
            this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
            this.$store.dispatch("prescriptionSet/fetchPreviousPrescriptions"); // Refresh list with previous prescriptions
        },
        openSendDialog() {
            dialogEvents.$emit("openPopup", {
                title: "Skicka beställning till apotek",
                component: PrescriptionSendForm,
            });
        },
        async send(receiverDetails) {
            this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
            await this.$store.dispatch("prescriptionSet/send", receiverDetails);
            this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
            this.$store.dispatch("prescriptionSet/fetchPreviousPrescriptions"); // Refresh list with previous prescriptions
        },
        rightToPrescribe(item) {
            return item.rightToPrescribe;
        },
        rightToPrescribeItem(item) {
            return item.drugArticle.rightToPrescribe && !item.is_cancelled;
        },
        /**
         * submitDeletion reads all fields from all cancellation-details (at least one), and sorts them according to pk of prescriptionItem.
         * Conversion from object to array is probably not necessary, but lazy coding.
         *
         * @param {*} pk of prescriptionSet
         */
        async submitDeletion(pk) {
            let validated = this.validateForm("cancellation-details");
            if (!validated) return;
            let cancellationItems = {};
            $("form#cancellation-details")
                .serializeArray()
                .forEach((keyValuePair) => {
                    var key = keyValuePair.name.split("-");
                    var value = keyValuePair.value;
                    let keyName = key[0];
                    let keyId = key[1];

                    if (keyId.indexOf("Display") === -1) {
                        if (!cancellationItems[keyId]) cancellationItems[keyId] = { prescriptionItem: keyId };

                        cancellationItems[keyId][keyName] = value;
                    }
                });
            let details = [];
            for (var key in cancellationItems) {
                if (cancellationItems[key].causeCode !== "0") details.push(cancellationItems[key]);
            }
            if (details.length > 0)
                await this.$store.dispatch("prescriptionSet/cancel", {
                    pk: pk,
                    details: details,
                });

            this.preview = null;
            this.deleteMode = false;
            this.$store.dispatch("prescriptionSet/fetchPreviousPrescriptions");
        },
        /**
         * Loads required fields from backend. If unable to load (IE10 or below), use default.
         */
        loadRequiredFields() {
            /*
try {
this.requiredFields = this.$spec.paths[
"/recept/prescriptionsets/"
].post.parameters[0].schema.properties.prescriptionItemDetails.items.required;
} catch (e) {
*/
            this.requiredFields = DEFAULT_REQUIRED_FIELDS;
            //}
        },
        loadExternalPreview() {
            const config = getConfig({}, "Receptförskrivning");
            if (this.externalPreview && !this.externalPreviewLoaded) {
                this.externalPreviewLoaded = true;
                return klinikenApi
                    .get("/recept/prescriptionsets/" + this.externalPreview + "/", config)
                    .then((response) => {
                        let prescriptionSet = response.data;
                        //this.preview = {pk: this.externalPreview, data: prescriptionSet.prescriptionItemDetails, extra: this.getStatusText(prescriptionSet)};
                        this.preview = prescriptionSet;
                        this.activeView = 1;
                        this.activeTab = 2;
                    })
                    .catch((e) => {
                        openDialog(getErrorMessage(e), "error");
                    });
            }
        },
        loadExternalPrescription() {
            if ((!this.externalParameters || !this.externalParameters.lakemedel) && !this.externalPrescriptionLoaded)
                return;

            this.externalPrescriptionLoaded = true;

            let lakemedel = cloneDeep(this.externalParameters.lakemedel);

            if (!Array.isArray(lakemedel)) lakemedel = [lakemedel];

            lakemedel.forEach((item) => {
                this.loadExternalPrescriptionItem(item.split("|"));
            });
        },

        async loadExternalPrescriptionItem(lakemedel) {
            const config = getConfig({}, "Receptförskrivning");
            let nplPackId = lakemedel[0];
            let unstructuredDosageAdmin = lakemedel[1];
            let drugArticle = null;
            await klinikenApi
                .get("/sil/drugarticles/" + nplPackId + "/", config)
                .then((response) => {
                    drugArticle = response.data;
                })
                .catch(() => {
                    openDialog(
                        "Kunde inte hämta läkemedelsinformationen för nplPackId " + nplPackId + " från servern.",
                        "warning"
                    );
                });

            if (!drugArticle) return;

            await klinikenApi
                .get("/sil/distributeddrugs/" + drugArticle.nplId + "/", config)
                .then((response) => {
                    drugArticle = { ...response.data, ...drugArticle };
                })
                .catch((e) => {
                    openDialog(
                        "Kunde inte hämta läkemedelsinformationen från servern." + getErrorMessage(e),
                        "warning"
                    );
                });

            let prescriptionItem = {
                unstructuredDosageAdmin: getDosageTextK2(unstructuredDosageAdmin),
                //these should not be required, but must be sent as null as backend validation fails if keys are not present.
                timeUnit: null,
                timeValue: null,
            };

            this.$store.dispatch("prescriptionSet/addItem", {
                item: { drugArticle: drugArticle },
                details: prescriptionItem,
                confirmSilently: true,
            });
        },

        getStatusText(prescriptionSet) {
            // Case 1: PrescriptionSet, identified by patient since PrescriptionSet always have that value
            if (prescriptionSet.patient) {
                let returnString = prescriptionSet.sent_at
                    ? "Skickat " + prescriptionSet.sent_at.split("T")[0] + " av " + prescriptionSet.sent_by.user.name
                    : "Sparat " +
                      prescriptionSet.authorTime.split("T")[0] +
                      " av " +
                      (prescriptionSet.accountableHealthcareProfessional
                          ? prescriptionSet.accountableHealthcareProfessional.user.name
                          : "[Saknas]");
                returnString += " (" + prescriptionSet.prescriptionItem_count + ")";
                /*
if (prescriptionSet.version > 1)
returnString += " (redigerad)"
*/

                return returnString;
            }
            // Case 2: Favorit
            else {
                let favorit = prescriptionSet;
                let anvandare =
                    " - Personlig favorit skapad av " + (favorit.anvandare ? favorit.anvandare.name : "okänd");
                let gemensam =
                    " - Gemensam favorit skapad av " +
                    (favorit.created_by && favorit.created_by.user && favorit.created_by.user.name
                        ? favorit.created_by.user.name
                        : "okänd");
                return (
                    favorit.namn + (favorit.anvandare ? anvandare : gemensam) + " (" + favorit.favoritrader.length + ")"
                );
            }
        },
        // Since preview can be either a PrescriptionSet or Favorit, return the relevant items
        getPreviewItems() {
            return this.preview.prescriptionItemDetails || this.preview.favoritrader;
        },
        scrollToOrder() {
            this.$nextTick(() => {
                this.$el.querySelector(".product-order").scrollIntoView({ behavior: "smooth" });
            });
        },
        clearOrder() {
            dialogEvents.$emit("open", {
                type: "warning",
                description: "Du har inte sparat. Vill du stänga utan att spara?",
                buttons: [
                    {
                        title: "Fortsätt redigera",
                        type: "secondary",
                    },
                    {
                        title: "Ja, stäng utan att spara",
                        type: "primary",
                        action: () => {
                            this.$store.dispatch("prescriptionSet/clearOrder");
                        },
                    },
                ],
            });
        },
        actionTableSelectHandler(item) {
            this.addToPrevious = false;
            this.select({
                drugArticle: item,
            });
        },
        actionTableAddHandler(selected) {
            this.activeView = 1;
            this.activeTab = 2;
            this.$store.dispatch("prescriptionSet/addMultipleItems", selected);
            this.$nextTick(() => {
                this.$el.querySelector(".product-order").scrollIntoView({ behavior: "smooth" });
            });
        },
        actionTableEditHandler(prescriptionSet) {
            this.$store.dispatch("prescriptionSet/load", prescriptionSet);
            this.$nextTick(() => {
                this.$el.querySelector(".product-order").scrollIntoView({ behavior: "smooth" });
            });
        },
        actionTableViewHandler(prescriptionSet) {
            this.preview = prescriptionSet;
            this.$el.scrollIntoView();
        },
        actionTableCancelHandler({ prescriptionSet, selectedItems }) {
            if (selectedItems.length === 0) return;
            this.preview = prescriptionSet;
            this.preview.prescriptionItemDetails = selectedItems;
            this.deleteMode = true;
            this.$el.scrollIntoView();
        },
        /**
         * Något oklart i nuläget hur historiken ska visas. I Invision visas enbart historiken för rader.
         * Sannolikt måste även "metadata" visas, men avvaktar med detta i nuläget.
         */
        buildHistory(currentVersion, historyArray) {
            let current = cloneDeep(currentVersion);

            //convert every item in prescriptionItemDetails to array
            for (let i = 0; i < current.prescriptionItemDetails.length; i++) {
                current.prescriptionItemDetails[i] = [current.prescriptionItemDetails[i]];
            }

            // merge all prescriptionitems in history with original
            // iterate through historical prescriptionsets...
            for (let i = 0; i < historyArray.length; i++) {
                let historicalPrescription = historyArray[i];

                //...and then prescriptionitems in historical prescriptionsets.
                for (let j = 0; j < historicalPrescription.prescriptionItemDetails.length; j++) {
                    // get pk of PI
                    let pk = historicalPrescription.prescriptionItemDetails[j].pk;

                    // get index of PI in currentVersion
                    let itemIndex = current.prescriptionItemDetails.findIndex((el) => {
                        if (Array.isArray(el)) {
                            let subIndex = el.findIndex((item) => {
                                return item.pk == pk;
                            });
                            return subIndex !== -1;
                        }
                        // important! must use loose comparison, since pk is string in history but number in current
                        else return el.pk == pk;
                    });

                    /*
//if the prescriptionitem is not an array, convert it to one
if (!Array.isArray(current.prescriptionItemDetails[itemIndex]))
current.prescriptionItemDetails[itemIndex] = [current.prescriptionItemDetails[itemIndex]]
*/

                    //then push the historical PI to currentversion
                    current.prescriptionItemDetails[itemIndex].push(historicalPrescription.prescriptionItemDetails[j]);

                    //TODO: updated_by is not set correctly on item level, copy from set level.
                }
            }
            this.history = current;
        },
        // Returns the latest (current) version of prescriptionItemDetails in history
        // Note that the later the version, the lower the index; current = index 0
        getLatestVersion(prescriptionItemDetails) {
            return prescriptionItemDetails[0];
        },
        // Returns an array of all versions of a specific label
        getLabelVersions(label, prescriptionItemDetails) {
            let versions = [];

            let latestChange = prescriptionItemDetails[0][label];
            for (let i = 0; i < prescriptionItemDetails.length; i++) {
                let change = prescriptionItemDetails[i][label];
                if (change !== latestChange) versions.push({ value: change, data: prescriptionItemDetails[i] });
                latestChange = change;
            }
            return versions;
        },
        actionTableHistoryHandler(prescriptionSet) {
            klinikenApi
                .get(
                    "/recept/prescriptionsets/history/",
                    getConfig(
                        {
                            params: {
                                id: prescriptionSet.pk,
                                patient: this.patient.id,
                            },
                        },
                        "Receptförskrivning"
                    )
                )
                .then((response) => {
                    let history = [];
                    if (response.data) history = response.data.results || [];
                    this.buildHistory(prescriptionSet, history);
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                });
        },
        cleanMakuleradeArray() {
            this.selectedMakulerade = [];
            //this.selectedTrueMakulerade = [];
        },
        uthoppForskrivningskollen() {
            let chromium = navigator.userAgent.indexOf("Chrome") !== -1;
            if (!chromium) {
                dialogEvents.$emit("open", {
                    type: "warning",
                    description: "Förskrivningskollen stöder endast Edge och Chrome.",
                    buttons: [
                        {
                            title: "Fortsätt",
                            type: "primary",
                            action: () => {
                                this.openForskrivningskollen();
                            },
                        },
                    ],
                });
            } else {
                this.openForskrivningskollen();
            }
        },
        openForskrivningskollen() {
            window.open("https://forskrivningskollen.se/webbingang/#/login");
        },

        orderItemDuplicate(item) {
            this.select(item, true);
        },
        orderItemEdit(item) {
            this.select(item);
        },
        orderItemDelete(item) {
            dialogEvents.$emit("open", {
                type: "warning",
                description: "Ta bort från beställningen?",
                buttons: [
                    {
                        title: "Avbryt",
                        type: "secondary",
                    },
                    {
                        title: "Ta bort",
                        type: "primary",
                        action: () => {
                            this.$store.commit("prescriptionSet/removeItem", item);
                        },
                    },
                ],
            });
        },
        favouriteAdd(selected) {
            this.activeView = 1;
            this.activeTab = 3;
            this.$store.dispatch("prescriptionSet/addMultipleItems", selected);
            this.$nextTick(() => {
                this.$el.querySelector(".product-order").scrollIntoView({ behavior: "smooth" });
            });
        },
        favouriteView(item) {
            this.preview = item;
        },
        previousDelete(prescriptionSet) {
            this.$store.dispatch("prescriptionSet/deleteOrder", prescriptionSet);
            this.$nextTick(() => {
                this.$el.querySelector(".product-order").scrollIntoView({ behavior: "smooth" });
            });
        },
        previousSms() {
            this.$store.dispatch("prescriptionSet/fetchPreviousPrescriptions");
        },
    },
    created() {
        dialogEvents.$on("sendReceiverDetails", this.send);

        this.$on("orderItem::duplicate", this.orderItemDuplicate);
        this.$on("orderItem::edit", this.orderItemEdit);
        this.$on("orderItem::delete", this.orderItemDelete);

        this.$on("favourite::add", this.favouriteAdd);
        this.$on("favourite::view", this.favouriteView);

        this.$on("previous::delete", this.previousDelete);
        this.$on("previous::sms", this.previousSms);
    },

    async mounted() {
        $(document).keyup((e) => {
            if (e.key === "Escape" && this.selected) {
                this.selected = null;
            }
        });
        this.$store.dispatch("prescriptionSet/fetchPreviousPrescriptions");
        this.loadRequiredFields();
        this.loadExternalPreview();
        this.loadExternalPrescription();
        this.fetchBothFavoriter(this.currentProfile.user);
    },
    beforeDestroy() {
        dialogEvents.$off("sendReceiverDetails", this.send);
        this.$store.dispatch("prescriptionSet/clearOrder");
    },
    watch: {
        q() {
            if (this.searchTimeout) setTimeout(this.search, 200);
            else this.search();
            if (this.q.length >= 3) {
                if (this.activeView === 0) this.activeView = 1;
            }
        },
        rek() {
            this.search();
        },
        igl() {
            this.search();
        },
        activeView() {
            this.addToPrevious = false;
        },
        externalParameters() {
            this.loadExternalPreview();
            this.loadExternalPrescription();
        },
    },
};
</script>

<style lang="sass" scoped>
@import @/style/variables
@import @/style/deprecated_main

.prescription-set
    .fixed-width-align-left
        max-width: $form-width

    .fixed-width-align-center
        max-width: $form-width
        margin: auto

    .full-width-align-center
        width: 100%

    .whiteBox
        width: calc(100% + 60px)
        // compensate for 30px padding in container - whiteBox should fill entire container and ignore padding
        margin-left: -30px
        background-color: white
        height: 23px
        padding: 0

    .label
        border-radius: 2px
        padding-left: 2px
        padding-right: 2px
        font-weight: bold
        font-size: 12px
        margin-top: 2px
        color: $color-label-grey
        border: 1px solid $color-border-grey
        max-height: 17px
        line-height: 17px


    // Styles for showing product details
    .makuleraBox
        width: calc(100% + 30px)
        margin-left: -15px
        background-color: #FFE8E0

    .makuleraButtonsFrame
        width: calc(100% + 60px)
        margin-left: -30px
        margin-top: 0px
        height: 100px
        background-color: #FFE8E0

    .makuleraButtons
        padding: 8px 30px 30px 30px

    .product-selection
        &.selected
            background-color: $color-blue-light

        &.selected .rek
            border-color: white
            color: white

        &.selected .table
            padding: 10px
            border-collapse: separate
            border-radius: 1px
            -moz-border-radius: 1px
            -webkit-border-radius: 1px
            border: 1px solid white
            background-color: white
            color: $color-thead
            width: 100%

            td
                border: none !important
                line-height: 24px

            td:first-of-type
                font-size: 24px
                font-weight: bold
                font-family: Roboto Medium

            a
                color: white

            a:hover,
            a:focus
                background-color: $color-tablerow-selected

            span
                display: inline

        .edited
            color: #7B868A
            font-size: 12px
            letter-spacing: 0
            line-height: 14px

        // Styles for tabs in new recipe
        h2
            margin-bottom: 20px

        ul.nav-tabs
            height: 42px
            display: flex
            list-style-type: none
            gap: 34px
            padding-left: 28px

            background: linear-gradient(to top, #E3E6EE 0%, #E3E6EE 100%)
            background-repeat: no-repeat
            background-size: 100% 7px
            background-position: bottom

            li.nav-item
                flex: 0 1 auto
                min-width: 0

            a.nav-link
                text-decoration: none
                box-sizing: border-box
                border: none
                padding-top: 0
                padding-bottom: 0
                color: #354052
                font-family: "Roboto Medium"
                font-weight: 500
                padding-bottom: 20px

                span
                    line-height: 24px

                &.active
                    color: #CA346F
                    background: linear-gradient(to top, #CA346F 0%, #CA346F 100%)
                    background-repeat: no-repeat
                    background-size: calc(100% - 32px) 7px
                    background-position: center bottom

                &:hover:not(.active)
                    background: linear-gradient(to top, #EAADC5 0%, #EAADC5 100%)
                    background-repeat: no-repeat
                    background-size: calc(100% - 32px) 7px
                    background-position: center bottom

    .recept-historik
        td
            padding-bottom: 8px

        .recept-historik-sidhuvud
            padding-top: 0px
            padding-bottom: 9px

            span
                color: #7B868A
                font-family: Roboto
                font-size: 14px
                font-weight: normal
                letter-spacing: 0
                line-height: 16px

    .product-order
        margin-top: 40px

        .table
            background-color: $color-blue-light

        &.disabled
            .table, .janus-table, .order-buttons
                opacity: 0.5

        .order-buttons
            background-color: $color-blue-light
            padding: 1px 0px 1px 12px

        .janus-table
            border-bottom: 1px solid $color-border-light-grey

    // Styles for search bar and button
    .search-wrapper
        display: flex
        margin-bottom: 30px

        .search-field
            height: 46px
            width: 693px
            border-radius: 4px
            font-family: Roboto
            font-size: 16px
            line-height: 21px
            border: 1px solid #95a1b1
            margin-right: 15px
            flex: 0 1 100%
            padding-left: 10px
            padding-right: 10px
            -webkit-box-sizing: border-box
            -moz-box-sizing: border-box
            box-sizing: border-box

        .search-button
            height: 46px
            flex: 0 0 211px

    .radiobutton-line
        display: inline-block
        margin-top: 12px

        label:not(:first-of-type)
            margin-left: 32px

    // Override form styling, PrescriptionSet uses different color scheme compared to other forms
    form
        padding: 20px

        &.form-active
            background-color: $color-blue-light

    // Styles for label outside of tables TODO: remember what this does, and move to better place
    .form-group
        .label
            float: none
            margin-left: 20px

            &.label-inline
                float: right

    .lm-info
        margin-top: 10px

        td
            padding: 5px

            &:first-child
                font-family: Roboto Medium
                text-align: right

    // Styles for Janus-med iframe
    .janus-table
        color: $color-thead
        font-family: Roboto Medium
        font-size: 14px
        line-height: 16px
        width: 100%
        padding: 10px 0px 10px 12px

    .janus-wrapper
        position: relative
        height: 32px
        width: 473px

        .janus-info
            position: absolute
            display: block
            height: 100%
            width: 100%
            border: none
            overflow-y: hidden
            overflow-x: hidden

        .janus-border
            pointer-events: none
            /* Allow click-through */
            position: absolute
            z-index: 1
            height: 100%
            width: 100%
            border: 4px solid $color-blue-light
    /* Paint over the grey background */























    .product-order .janus-table
        background-color: $color-blue-light

    // Spacers
    .pad
        .recipe-header
            margin-top: 40px
            margin-bottom: 5px

    .top-border
        padding-top: 30px

    .bottom-border
        padding-bottom: 30px

    .menu
        margin-top: 40px

    .preview-border
        margin-top: 40px

    section
        &.selected
            background-color: lighten($color-blue, 47%)


    .order-form
        background-color: #DCEFF6 !important
        padding: 30px 30px 13px 30px
        border-top: 23px solid white
        max-width: 1100px
        margin-left: auto
        margin-right: auto

        > div
            > button
                float: right
                margin-top: -10px

        form
            background-color: inherit
            padding: 0px !important
            //max-width: 1100px

            > div
                > button
                    float: right
                    margin-top: -10px

        .janus-border
            border: 4px solid #DCEFF6 !important
/* Paint over the grey background */
</style>

<style lang="sass">
@import @/style/variables

.prescription-set
    // Override table colors in ActionTable
    .product-selection
        padding-top: 40px

        .group
            margin: 10px 0
            background-color: $color-tablerow

        .action-table
            > button
                background-color: $color-tablerow-button-selected

                &.collapsed:not(:focus)
                    background-color: $color-tablerow

        td
            vertical-align: middle !important

        button:disabled
            background-color: #B4D6DD
            color: #FFFFFF
            cursor: default

            img
                filter: none

    // Override disabled button
    .product-order

        button:disabled
            background-color: #B4D6DD
            color: #FFFFFF
            cursor: default

        .table
            background-color: $color-blue-light

    .manufacturer
        font-weight: normal
        font-size: 12px
        display: inline-block
        color: inherit

    // Styles for recipe headers, mostly icon stuff and positioning
    .recipe-header
        height: 47px
        margin-top: 44px

        h2
            line-height: 47px

        .recipe-icon
            height: 24px
            width: 18px
            margin-right: 10px
            margin-top: 10px
            margin-left: 24px
            float: left
            filter: brightness(0) saturate(100%) invert(85%) sepia(39%) saturate(5683%) hue-rotate(163deg) brightness(85%) contrast(89%)

        .svg-recipe-icon
            height: 24px
            width: 18px
            margin-right: 10px
            margin-top: 10px
            margin-left: 24px
            float: left
</style>

<style lang="scss">
@import "@/style/variables";

.prescription-set {
    // Styles for top bar navigation buttons et al
    .top-bar-navigation {
        position: relative;
        display: flex;
        justify-content: center;

        .top-icon {
            text-align: center;
            margin-bottom: 10px;

            .icon-circle {
                height: 61px;
                width: 61px;
                border: none;
                border-radius: 31px;
                background-color: white;
                box-shadow: 0 2px 9px 0 rgba(174, 174, 174, 0.5);
                margin-left: 65px;
                margin-right: 65px;
                margin-bottom: 9px;
                display: flex;
                justify-content: center;
                align-items: center;

                .icon-image {
                    filter: brightness(0) saturate(100%) invert(85%) sepia(39%) saturate(5683%) hue-rotate(163deg)
                        brightness(85%) contrast(89%);
                }
            }

            span {
                color: $color-navigation;
                height: 24px;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }

            &:hover,
            &.active {
                .icon-circle {
                    border: 1px solid $color-secondary;

                    .icon-image {
                        filter: brightness(0) saturate(100%) invert(45%) sepia(82%) saturate(6414%) hue-rotate(320deg)
                            brightness(86%) contrast(81%);
                    }
                }

                span {
                    color: $color-secondary;
                    padding-bottom: 7px;
                    background: linear-gradient(to top, $color-secondary 0%, $color-secondary 100%);
                    background-repeat: no-repeat;
                    background-size: 100% 2px;
                    background-position: center bottom;
                }
            }
        }

        .top-bar-secondary-navigation {
            position: absolute;
            top: 2px;
            right: 40px;

            color: $color-navigation-order;
            font-size: 16px;
            line-height: 21px;
            text-align: right;

            img {
                margin-right: 10px;
            }

            .k3-lank-forskrivningskollen {
                margin-top: 35px;
                cursor: pointer;

                img {
                    height: 13px;
                    width: 13px;
                }
            }
        }
    }

    .andras-personliga-favoriter,
    .organisationiska-favoriter {
        margin-top: 20px;
    }

    .privata-favoriter,
    .andras-personliga-favoriter,
    .organisationiska-favoriter {
        h3 {
            cursor: pointer;

            .caret {
                background-image: url("@/assets/dropdown-arrow.svg");
                background-size: 14px;
                width: 14px;
                height: 14px;
                display: inline-block;
                margin: 0.75rem 0.8rem 0 0;
                transition: transform 0.2s;
                float: right;

                &.rotate {
                    transform: rotate(180deg);
                }
            }
        }

        .group {
            margin: 10px 0;

            .label {
                border-radius: 2px;
                padding-left: 2px;
                padding-right: 2px;
                font-weight: bold;
                font-size: 12px;
                margin-top: 2px;
                color: $color-label-grey;
                border: 1px solid $color-border-grey;
                max-height: 17px;
                line-height: 17px;
            }
        }
    }

    .prescriptionset-tooltip {
        display: block !important;
        z-index: 10000;
        flex: 0;
        max-width: none !important;

        .tooltip-inner {
            background: black;
            color: white;
        }

        .tooltip-arrow {
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            margin: 5px;
            border-color: black;
            z-index: 1;
            display: none;
        }

        &[x-placement^="top"] {
            margin-bottom: 5px;

            .tooltip-arrow {
                border-width: 5px 5px 0 5px;
                border-left-color: transparent !important;
                border-right-color: transparent !important;
                border-bottom-color: transparent !important;
                bottom: -5px;
                left: calc(50% - 5px);
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &[x-placement^="bottom"] {
            margin-top: 5px;

            .tooltip-arrow {
                border-width: 0 5px 5px 5px;
                border-left-color: transparent !important;
                border-right-color: transparent !important;
                border-top-color: transparent !important;
                top: -5px;
                left: calc(50% - 5px);
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &[x-placement^="right"] {
            margin-left: 5px;

            .tooltip-arrow {
                border-width: 5px 5px 5px 0;
                border-left-color: transparent !important;
                border-top-color: transparent !important;
                border-bottom-color: transparent !important;
                left: -5px;
                top: calc(50% - 5px);
                margin-left: 0;
                margin-right: 0;
            }
        }

        &[x-placement^="left"] {
            margin-right: 5px;

            .tooltip-arrow {
                border-width: 5px 0 5px 5px;
                border-top-color: transparent !important;
                border-right-color: transparent !important;
                border-bottom-color: transparent !important;
                right: -5px;
                top: calc(50% - 5px);
                margin-left: 0;
                margin-right: 0;
            }
        }

        &[aria-hidden="true"] {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.15s, visibility 0.15s;
        }

        &[aria-hidden="false"] {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.15s;
        }

        &.popover {
            $color: #f9f9f9;
            border-radius: 0px;
            border: 0px;
            background-color: rgba(0, 0, 0, 0);

            .popover-inner {
                background-color: rgba(0, 0, 0, 0);
                border-radius: 0px;
                padding: 0px;
                max-width: none !important;
            }

            .popover-arrow {
                border-color: $color;
            }
        }
    }

    ::v-deep .action-table-wrapper {
        .group {
            margin: 10px 0;
        }

        .btn-block {
            background-color: #e4e8f0;
        }

        table {
            background-color: #f3f5f9;
        }
    }

    ::v-deep .current-order {
        table {
            background-color: #ebf6f8;
        }
    }
}
</style>
