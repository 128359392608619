<template>
    <FormulateForm class="avvakta-operation" v-model="formData" @submit="submit" autocomplete="off">
        <div class="patient-dialog-container">
            <DialogAktivPatient :patient="patient" />

            <DialogAnmalaninfo :anmalaninfo="anmalaninfo" />
        </div>

        <div class="form">
            <FormulateInput
                type="vueselect"
                :options="[
                    { value: 'pvv', label: 'Patientvald väntan' },
                    { value: 'mov', label: 'Medicinsk väntan' },
                    { value: 'sov', label: 'Administrativ väntan' },
                ]"
                name="undantagskod"
                label="Orsak till avvaktan *"
            />

            <FormulateInput type="textarea" label="Kommentar" name="undantagskommentar" />
        </div>

        <ButtonGroup>
            <Button size="primary" type="submit">Avvakta operation</Button>
            <Button size="secondary" variant="contour" type="button" @click="cancel()">Avbryt</Button>
        </ButtonGroup>
    </FormulateForm>
</template>

<script>
import dialogEvents from "@/dialogEvents";
import { mapActions } from "vuex";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import DialogAktivPatient from "@/tabs/Operation/DialogAktivPatient.vue";
import DialogAnmalaninfo from "@/tabs/Operation/DialogAnmalaninfo.vue";

export default {
    name: "AvvaktaOperation",
    components: {
        Button,
        ButtonGroup,
        DialogAktivPatient,
        DialogAnmalaninfo,
    },
    data() {
        return {
            params: this.$attrs.response,
            patient: {
                personId: "",
                namn: "",
            },
            anmalaninfo: {
                datum: "",
                tid: "",
                huvudoperator: "",
                huvudoperationskod: "",
            },
            formData: {
                undantagskod: "",
                undantagskommentar: "",
            },
        };
    },
    methods: {
        ...mapActions("operation/operationsanmalan", ["avvaktaOperationAnmalan"]),
        cancel() {
            dialogEvents.$emit("closePopup");
        },
        async submit() {
            const payload = {
                id: this.params.listItem.id,
                undantagskod: this.formData.undantagskod,
                undantagskommentar: this.formData.undantagskommentar,
            };
            await this.avvaktaOperationAnmalan(payload);
            this.cancel();
        },
    },

    created() {
        const itemData = this.propsData?.listItem ?? this.$attrs.response.listItem;

        this.patient.personId = itemData.patient_display.personId;
        this.patient.namn = itemData.patient_display.namn;
        this.anmalaninfo.datum = itemData.current_bokad_operation_datum;
        this.anmalaninfo.tid = itemData.current_bokad_operation_tid;
        this.anmalaninfo.huvudoperator = itemData.current_bokad_huvudoperator_display.user_display.name;
        this.anmalaninfo.huvudoperationskod = `${itemData.huvudoperationskod_display.code} ${itemData.huvudoperationskod_display.klassdel_display} ${itemData.huvudoperationskod_display.displayName}`;
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/modal.scss";

.avvakta-operation {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: inherit;
    padding: inherit;
    padding-bottom: 0px;
    padding-top: 0px;

    .patient-dialog-container {
        padding: 0px 50px;
        display: grid;
        gap: 20px;
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: $dialog_box_padding;
    }

    ::v-deep(.button-group) {
        margin: 0px;
        padding: 0px 50px;
    }
}
</style>
