<template>
    <div class="dagsoversikt">
        <h1>Dagsöversikt</h1>

        <SearchFilter v-model="filterData" @input="filter" :expandable="false">
            <template #header-filters>
                <FormulateInput type="date" label="Datum" name="datum" />
                <FormulateInput type="checkbox" :options="salar" name="salar" />
            </template>
        </SearchFilter>

        <Button class="print" size="secondary" @click="print()">Skriv ut</Button>

        <div class="count">Visar {{ anmalningar.length }} operationer</div>

        <hr />

        <PrintableContent ref="printablecontent" header-height="36px">
            <template #default>
                <div class="table-caption">Dagsöversikt operation</div>
                <h4 v-if="dagskommentar.length" class="dagskommentar">
                    Kommentar: <strong>{{ dagskommentar }}</strong>
                </h4>
                <DagsoversiktList v-for="(group, index) in groupedAnmalningar" :key="index" :group="group" />
            </template>
            <template #header>
                <print-header
                    :currentDate="currentDate"
                    :currentProfileName="currentProfile.user_display.name"
                    :utskriftsID="utskriftsID"
                />
            </template>
            <template #footer></template>
        </PrintableContent>
        <ModalBasic ref="dialog" />
    </div>
</template>

<script>
import Button from "@/components/Button.vue";
import moment from "moment";
import { fetchData, postData, salarOptions } from "../utils.js";
import DagsoversiktList from "./DagsoversiktList.vue";
import { chain } from "lodash";
import { mapGetters } from "vuex";
import BaseTab from "@/tabs/mixins/BaseTab";
import PrintableContent from "@/components/PrintableContent.vue";
import PrintHeader from "@/tabs/Operation/Oversikt/PrintHeader.vue";
import ModalBasic, { BasicModalVariant } from "@/components/generic/ModalBasic.vue";
import SearchFilter from "@/components/SearchFilter.vue";
import { getConfig, klinikenApi } from "@/api";

export default {
    extends: BaseTab,
    name: "Dagsoversikt",
    tabName: "Dagsöversikt",
    components: {
        SearchFilter,
        ModalBasic,
        PrintHeader,
        PrintableContent,
        Button,
        DagsoversiktList,
    },

    props: {
        uid: Number,
        tabId: Number,
        parameters: Object,
    },

    data() {
        return {
            anmalningar: [],
            filterData: {
                datum: undefined,
                salar: [],
            },
            salar: [],
            utskriftsID: "",
            dagskommentar: "",
        };
    },

    computed: {
        ...mapGetters("userData", ["currentProfile"]),
        currentDate() {
            return moment().format("YYYY-MM-DD");
        },
        groupedAnmalningar() {
            return chain(this.anmalningar)
                .groupBy((anmalan) => anmalan.bokad_sal_display.namn)
                .map((anmalningar, sal) => ({ anmalningar, sal }))
                .value();
        },
    },

    methods: {
        async filter(filterData) {
            this.filterData = filterData;
            await this.fetchDagsinformation(this.filterData.datum);
            this.fetchAnmalningar();
        },

        async fetchAnmalningar() {
            this.anmalningar = await fetchData({
                url: "/operation/anmalan/",
                params: {
                    bokad_operation_datum: this.filterData.datum,
                    bokad_sal__id: this.filterData.salar.join(","),
                    ordering: "bokad_operation_datum",
                },
                tabbnamn: "Dagsöversikt",
            });
        },

        async print() {
            const response = await postData({
                url: "/operation/anmalan/utskrift/",
                params: {
                    anmalningar: this.anmalningar.map((anmalning) => anmalning.id),
                },
                tabbnamn: "Dagsöversikt",
            });

            if (response.hasError) {
                await this.$refs.dialog.show({
                    variant: BasicModalVariant.ALERT,
                    title: "Det gick inte att få ett utskrift-ID.",
                    okButton: "OK",
                });
            } else {
                this.utskriftsID = response.data.utskrifts_id;
                this.$refs.printablecontent.print();
            }
        },

        async fetchSalar() {
            this.salar = salarOptions(
                await fetchData({
                    url: "/operation/salar/",
                })
            );
        },

        async fetchDagsinformation(date) {
            try {
                const response = await klinikenApi.get(`/operation/dagsinformation/${date}/`, getConfig());
                this.dagskommentar = response.data?.kommentar || "";
            } catch (e) {
                this.dagskommentar = "";
            }
        },
    },

    async created() {
        await this.fetchSalar();
        this.filter({
            datum: moment().format("YYYY-MM-DD"),
            salar: this.salar.map((sal) => sal.value),
        });
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.dagsoversikt {
    .button.print {
        margin-top: 20px;
    }

    .count {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 24px;
        margin: 25px 0 15px;
    }

    hr {
        border: 1px solid $list-border-color;
    }

    .table-caption {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: $color-active-dark;
    }

    h4.dagskommentar {
        margin: 10px 0 -10px 0;
    }

    @media print {
        h1,
        .filter,
        .count,
        hr {
            display: none;
        }

        .button.print {
            display: none;
            visibility: hidden;
        }
    }
}
</style>
