<template>
    <FormulateForm class="search-filter" v-model="formData" @submit="search">
        <div class="header">
            <div class="header-filters">
                <slot name="header-filters"></slot>
                <Button v-if="!expandable" class="submit" type="submit" variant="standard" size="secondary">
                    Tillämpa sökalternativ
                </Button>
            </div>

            <div v-if="expandable" class="expand-filter" :class="{ 'is-expanded': expanded }">
                <a @click.prevent="expanded = !expanded">
                    <img
                        alt="Expandera sökfilter"
                        :class="{ rotate: expanded }"
                        src="@/assets/dropdown-arrow-blue.svg"
                    />Sökalternativ ({{ valueCount }})
                </a>
            </div>

            <div class="quick-filters">
                <slot name="quick-filters"></slot>
            </div>
        </div>
        <div v-show="expanded" class="main">
            <div class="fields">
                <slot name="fields"></slot>
            </div>

            <div class="submit">
                <Button type="submit" variant="standard" size="secondary" :disabled="isFormDataChanged"
                    >Tillämpa sökalternativ
                </Button>
            </div>

            <div class="tag-container" v-if="showTags">
                <div class="tags">
                    <span v-for="{ key, label, value } in tags" :key="key">
                        {{ label }}: {{ value }}
                        <img src="@/assets/cross_error_white.svg" alt="" @click="removeTag(key)" />
                    </span>
                </div>
                <div class="clear">
                    <Button @click="removeTags" variant="hollow" size="small"
                        ><img src="@/assets/cross_circle.svg" alt="" /> Rensa
                    </Button>
                </div>
            </div>
        </div>
    </FormulateForm>
</template>

<script setup lang="ts">
import Button from "@/components/Button.vue";
import { omit } from "lodash";
import { computed, PropType, ref, watch } from "vue";

const emit = defineEmits(["input", "filter"]);

const props = defineProps({
    value: {
        type: Object as PropType<Record<string, string>>,
        default: () => ({}),
    },
    fields: {
        type: Object as PropType<Record<string, { label: string; options?: { label: string; value: string }[] }>>,
        default: () => ({}),
    },
    expandable: {
        type: Boolean,
        default: true,
    },
    isOpen: {
        required: false,
        type: Boolean as PropType<boolean>,
    },
    showTags: {
        required: false,
        type: Boolean as PropType<boolean>,
        default: true,
    },
});

let formData = ref<Record<string, string | { id: string; value: string; label: string }>>({});
let expanded = ref<boolean>(props.isOpen ?? false);

const tags = computed<{ key: string; label: string; value: string }[]>(() => {
    let tags: { key: string; label: string; value: string }[] = [];
    for (const [k, v] of Object.entries(formData.value)) {
        if (k in props.fields) {
            let f = props.fields[k];
            let value = `${v}`;
            if (f.options) {
                let option = f.options.find((el) => el.value === value);
                if (option !== undefined) {
                    value = `${option.label}`;
                }
            }
            if (value !== "") {
                tags.push({ key: k, label: f.label, value: value });
            }
        }
    }
    return tags;
});

const isFormDataChanged = computed(() => {
    return Object.values(formData.value).every((x) => x === null || x === "");
});

const valueCount = computed(() => {
    let count = 0;
    Object.values(formData.value).forEach((i) => {
        if (i !== "") {
            count++;
        }
    });
    return count;
});

watch(
    () => props.value,
    (value) => {
        formData.value = value;
    },
    {
        immediate: true,
    }
);

function search(data: Record<string, string | { id: string; value: string; label: string }>) {
    formData.value = data;
    emitInput();
}

function removeTag(key: string) {
    formData.value = omit(formData.value, key);
    emitInput();
}

function removeTags() {
    formData.value = {};
    emitInput();
}

function emitInput() {
    emit("input", formData.value);
}
</script>

<style lang="scss" scoped>
@import "@/style/variables";
@import "@/style/mixins/button";

.search-filter {
    background-color: $color-background-puff;

    .formulate-input[data-classification="text"][data-type="search"] {
        ::v-deep input[type="search"] {
            width: 100%;
        }
    }

    ::v-deep .search-field {
        margin: 0;
    }
}

.header {
    padding: 15px 30px;
    border-bottom: 2px solid #ffffff;
    display: flex;
    align-items: center;
    gap: 20px;

    .header-filters {
        display: flex;
        align-items: flex-end;
        gap: 20px;
    }

    .submit {
        display: flex;
        align-items: flex-end;
    }

    .quick-filters {
        flex-grow: 3;
    }
}

.expand-filter {
    display: flex;
    align-items: center;

    a {
        @include button;
        font-family: Roboto, sans-serif;
        background-color: transparent;
        color: $color-primary-darken;
        padding: 5px 15px;
        cursor: pointer;

        &:hover {
            background-color: $color-blue-very-light;
        }

        img {
            margin-right: 10px;
        }
    }

    &.is-expanded {
        button {
            background-color: $color-blue-very-light;

            img {
                transform: rotate(180deg);
            }
        }
    }
}

.quick-filters {
    display: flex;
    justify-content: flex-end;
    gap: 30px;

    ::v-deep a {
        cursor: pointer;
        font-size: 14px;
        line-height: 16px;
        color: $color-primary-darken;
        display: flex;
        align-items: center;

        &:hover,
        &.active {
            border-bottom: 3px solid $color-primary-darken;
            margin-bottom: -3px;
            text-decoration: none;
        }
    }
}

.main {
    padding: 30px;

    .submit {
        padding: 30px 0;
    }

    .tag-container {
        max-width: 1100px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 15px;

        .tags {
            width: 100%;
            min-height: 46px;
            padding: 8px 10px;
            background-color: $color-blue-very-light;
            border-radius: $border-radius;

            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            span {
                background-color: $color-primary-darken;
                border-radius: $border-radius;
                color: #ffffff;
                padding: 6px 10px;
                font-family: Roboto, sans-serif;
                font-size: 14px;
                line-height: 19px;

                img {
                    margin-left: 10px;
                }
            }
        }

        .clear {
            min-height: 46px;
            display: flex;
            align-items: center;
        }
    }
}

.fields {
    max-width: 1100px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 25px;

    ::v-deep & > * {
        width: 256px;
    }
}

@media print {
    .search-filter {
        display: none;
    }
}
</style>
