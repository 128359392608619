<template>
    <form @submit.prevent id="preane-registration-details">
        <h2 class="left-pad">{{ parameters ? "Redigera" : "Skapa" }} preanestesibedömning</h2>
        <br />
        <template v-if="!currentOperation.id">
            <h3 class="left-pad">Vill du hämta data från operationsanmälan?</h3>
            <br />
        </template>
        <h3 class="left-pad" v-else>Kopplad till operationsanmälan</h3>
        <button
            v-if="!showOperations && !(loadComplete && !operations.length)"
            role="button"
            class="btn left-pad k3-big-font btn-primary"
            @click="showOperations = true"
        >
            <img src="@/assets/download_white.svg" />Hämta operationsanmälningar
        </button>
        <div class="left-pad" v-else-if="loadComplete && !currentOperation.id && operations.length">
            <img src="@/assets/positive_notification.svg" />Operationsanmälningar hämtade
        </div>
        <div class="left-pad" v-else-if="!loadComplete">Hämtar operationsanmälningar...</div>
        <div class="left-pad" v-if="loadComplete && !operations.length">
            <img src="@/assets/alert_dark.svg" />Patenten saknar operationsanmälningar
        </div>
        <br />
        <template v-if="showOperations && loadComplete && operations.length">
            <custom-action-table
                class="k3-op-select"
                :selectable-rows="true"
                :columns="opAnmalanTableColumns"
                :items="operations"
            />
            <div class="k3-fake-table" v-if="!currentOperation.id">
                <button
                    :disabled="!selectedOperation.id"
                    role="button"
                    class="btn btn-primary"
                    @click="selectCurrentOperation"
                >
                    <img src="@/assets/link_white.svg" />Koppla operationsanmälning
                </button>
            </div>
        </template>
        <br />

        <div class="form-group k3-form">
            <div class="k3-sub-form-flex-container">
                <div>
                    <div class="circle">
                        <div class="number">1</div>
                    </div>
                    <div class="vr"></div>
                </div>
                <div>
                    <div class="left-pad" @click="toggleVisible('operation')">
                        <h3>
                            Operation<img
                                :class="['k3-dropdown-icon', !hidden.operation ? 'k3-img-rotate' : '']"
                                src="@/assets/dropdown-arrow-blue.svg"
                            />
                        </h3>
                    </div>
                    <collapse-transition>
                        <div class="k3-sub-form k3-card" v-if="!hidden.operation">
                            <preview-widget
                                :component="widgets.SuggestWidget"
                                label="Operatör"
                                :value="currentOperation.huvudoperator"
                                apisearch="/core/profiles/"
                                :map="
                                    (el) => {
                                        return {
                                            id: el.pk,
                                            code: el.healthCareProviderHsaId,
                                            displayName: el.user.name,
                                        };
                                    }
                                "
                            />
                            <preview-widget
                                :component="widgets.ExpandableSuggest"
                                label="Opassistent"
                                :value="currentOperation.huvudoperator"
                                apisearch="/core/profiles/"
                                :map="
                                    (el) => {
                                        return {
                                            id: el.pk,
                                            code: el.healthCareProviderHsaId,
                                            displayName: el.user.name,
                                        };
                                    }
                                "
                            />
                            <preview-widget
                                :component="widgets.DateWidget"
                                label="Opdatum"
                                :value="currentOperation.opdatum"
                            />
                            <preview-widget
                                :component="widgets.SuggestWidget"
                                label="Huvudoperationskod"
                                :value="currentOperation.huvudoperationskod"
                                apisearch="/operation/kodverk/operationskoder/"
                                :display-code="true"
                                colspan="8"
                            />
                            <preview-widget
                                :component="widgets.ExpandableSuggest"
                                label="Ytterligare opkoder"
                                :value="currentOperation.bioperationskoder"
                                apisearch="/operation/kodverk/operationskoder/"
                                :display-code="true"
                                colspan="8"
                            />
                            <preview-widget
                                :component="widgets.SuggestWidget"
                                label="Huvuddiagnoskod"
                                :value="currentOperation.huvuddiagnos"
                                apisearch="/operation/kodverk/icdkoder/"
                                :display-code="true"
                                colspan="8"
                            />
                            <preview-widget
                                :component="widgets.ExpandableSuggest"
                                label="Ytterligare diagnoskoder"
                                :value="currentOperation.bidiagnoser"
                                apisearch="/operation/kodverk/icdkoder/"
                                :display-code="true"
                                colspan="8"
                            />
                        </div>
                    </collapse-transition>
                </div>
            </div>

            <div class="k3-sub-form-flex-container">
                <div>
                    <div class="circle">
                        <div class="number">2</div>
                    </div>
                    <div class="vr"></div>
                </div>
                <div>
                    <div class="left-pad" @click="toggleVisible('anestesi')">
                        <h3>
                            Anestesi<img
                                :class="['k3-dropdown-icon', !hidden.anestesi ? 'k3-img-rotate' : '']"
                                src="@/assets/dropdown-arrow-blue.svg"
                            />
                        </h3>
                    </div>
                    <collapse-transition>
                        <div class="k3-sub-form" v-if="!hidden.anestesi">
                            <input-wrapper
                                :component="widgets.SuggestWidget"
                                label="Anestesiolog"
                                required
                                name="anestesiolog"
                                v-model="formData.anestesiolog"
                                apisearch="/core/profiles/"
                                :extraParams="{ anestesiolog: true }"
                                :map="
                                    (el) => {
                                        return {
                                            id: el.pk,
                                            code: el.healthCareProviderHsaId,
                                            displayName: el.user.name,
                                        };
                                    }
                                "
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Anestesi"
                                name="anestesi"
                                v-model="formData.anestesi"
                                apisearch="/operation/kodverk/anestesier/"
                            />
                            <input-wrapper
                                :component="widgets.ExpandableSuggest"
                                label="Anestesikod"
                                name="anestesikod"
                                :displayCode="true"
                                v-model="formData.anestesikod"
                                apisearch="/operation/kodverk/anestesikoder/"
                                display-name="anestesikoder"
                                colspan="8"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Tidigare anestesi"
                                name="tidigare_anestesi"
                                v-model="formData.tidigare_anestesi"
                                apisearch="/operation/kodverk/anestesier/"
                            />
                            <input-wrapper
                                :component="widgets.ExpandableSuggest"
                                label="Tidigare anestesikod"
                                name="tidigare_anestesikod"
                                v-model="formData.tidigare_anestesikod"
                                apisearch="/operation/kodverk/anestesikoder/"
                                display-name="anestesikoder"
                                colspan="6"
                            />
                        </div>
                    </collapse-transition>
                </div>
            </div>

            <div class="k3-sub-form-flex-container">
                <div>
                    <div class="circle">
                        <div class="number">3</div>
                    </div>
                    <div class="vr"></div>
                </div>
                <div>
                    <div class="left-pad" @click="toggleVisible('patientdata')">
                        <h3>
                            Patientdata<img
                                :class="['k3-dropdown-icon', !hidden.patientdata ? 'k3-img-rotate' : '']"
                                src="@/assets/dropdown-arrow-blue.svg"
                            />
                        </h3>
                    </div>
                    <collapse-transition>
                        <div class="k3-sub-form" v-if="!hidden.patientdata">
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Rökare"
                                name="rokare"
                                @input="rokareChanged"
                                v-model="formData.rokare"
                                :options="yesNoUknown"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                :disabled="!rokare"
                                label="Rökavvänjning har erbjudits"
                                v-model="formData.erbjuden_rokavvanjning"
                                :options="yesNoUknown"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                :disabled="!rokare"
                                label="Krav på slutat röka"
                                name="krav_sluta_roka"
                                v-model="formData.krav_sluta_roka"
                                :options="yesNoUknown"
                            />
                            <div class="colspan c2">&nbsp;</div>
                            <input-wrapper
                                :component="widgets.NumberWidget"
                                label="Vikt (kg)"
                                name="vikt_patient"
                                :colspan="1"
                                v-model="formData.vikt_patient"
                                :min="0.5"
                                :max="350"
                                :step="0.1"
                            />
                            <input-wrapper
                                :component="widgets.NumberWidget"
                                label="Längd (cm)"
                                name="langd_patient"
                                :colspan="1"
                                v-model="formData.langd_patient"
                                :min="30"
                                :max="240"
                                :step="0.1"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label="BMI"
                                name="bmi"
                                :value="bmi"
                                :disabled="true"
                            />
                            <input-wrapper
                                :component="widgets.TextAreaWidget"
                                label="Status, AU"
                                :colspan="4"
                                :maxlength="1000"
                                name="status_AT"
                                v-model="formData.status_AT"
                            />
                            <input-wrapper
                                :component="widgets.TextAreaWidget"
                                label="Cor"
                                :colspan="4"
                                :maxlength="1000"
                                name="cor"
                                v-model="formData.cor"
                            />
                            <input-wrapper
                                :component="widgets.TextAreaWidget"
                                label="Pulm"
                                :colspan="4"
                                :maxlength="1000"
                                name="pulm"
                                v-model="formData.pulm"
                            />
                            <input-wrapper
                                :component="widgets.NumberWidget"
                                label="Puls"
                                name="puls"
                                :colspan="1"
                                v-model="formData.puls"
                                :min="0"
                                :max="350"
                            />
                            <input-wrapper
                                :component="widgets.NumberWidget"
                                label="BT syst."
                                name="blodtryck_systoliskt"
                                :colspan="1"
                                v-model="formData.blodtryck_systoliskt"
                                :min="0"
                                :max="999"
                            />
                            <input-wrapper
                                :component="widgets.NumberWidget"
                                label="BT diast."
                                name="blodtryck_diastoliskt"
                                :colspan="1"
                                v-model="formData.blodtryck_diastoliskt"
                                :min="0"
                                :max="999"
                            />
                            <div class="colspan c5">&nbsp;</div>
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Hjärt-/kärlsjukdom"
                                name="hjart_karlsjukdom"
                                v-model="formData.hjart_karlsjukdom"
                                :options="yesNo"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label=""
                                :disabled="!formData.hjart_karlsjukdom"
                                :maxlength="1000"
                                name="hjart_karlsjukdom_text"
                                v-model="formData.hjart_karlsjukdom_text"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Lungsjukdom"
                                name="lungsjukdom"
                                v-model="formData.lungsjukdom"
                                :options="yesNo"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label=""
                                :disabled="!formData.lungsjukdom"
                                :maxlength="1000"
                                name="lungsjukdom_text"
                                v-model="formData.lungsjukdom_text"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Organkomplikation"
                                name="organkomplikation"
                                v-model="formData.organkomplikation"
                                :options="yesNo"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label=""
                                :disabled="!formData.organkomplikation"
                                :maxlength="1000"
                                name="organkomplikation_text"
                                v-model="formData.organkomplikation_text"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Trombossjukdom/anlag"
                                name="trombossjukdom"
                                v-model="formData.trombossjukdom"
                                :options="yesNo"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label=""
                                :disabled="!formData.trombossjukdom"
                                :maxlength="1000"
                                name="trombossjukdom_text"
                                v-model="formData.trombossjukdom_text"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Annan sjukdom"
                                name="annan_sjukdom"
                                v-model="formData.annan_sjukdom"
                                :options="yesNo"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label=""
                                :disabled="!formData.annan_sjukdom"
                                :maxlength="1000"
                                name="annan_sjukdom_text"
                                v-model="formData.annan_sjukdom_text"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Överkänslighet"
                                name="overkanslighet"
                                v-model="formData.overkanslighet"
                                :options="yesNo"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label=""
                                :disabled="!formData.overkanslighet"
                                :maxlength="1000"
                                name="overkanslighet_text"
                                v-model="formData.overkanslighet_text"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Diabetes"
                                name="diabetes"
                                v-model="formData.diabetes"
                                :options="yesNo"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Blödningsbenägenhet"
                                name="blodningsbenagenhet"
                                v-model="formData.blodningsbenagenhet"
                                :options="yesNo"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label="Mp"
                                :maxlength="1000"
                                name="mp"
                                v-model="formData.mp"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label="Tm avstånd"
                                :maxlength="1000"
                                name="tm_avstand"
                                v-model="formData.tm_avstand"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Intubationssvårigheter"
                                name="intubationssvarigheter"
                                v-model="formData.intubationssvarigheter"
                                :options="yesNo"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Refluxbesvär"
                                name="refluxbesvar"
                                v-model="formData.refluxbesvar"
                                :options="yesNo"
                            />
                            <input-wrapper
                                :component="widgets.TextAreaWidget"
                                label="Aktuell Medicin"
                                :colspan="4"
                                :maxlength="1000"
                                name="aktuell_medicin"
                                v-model="formData.aktuell_medicin"
                            />
                            <input-wrapper
                                :component="widgets.TextAreaWidget"
                                label="Ordinationer till avdelning"
                                :colspan="4"
                                :maxlength="1000"
                                name="ordinationer_avdelning"
                                v-model="formData.ordinationer_avdelning"
                            />
                            <input-wrapper
                                :component="widgets.TextAreaWidget"
                                label="Ordinationer till operation"
                                :colspan="4"
                                :maxlength="1000"
                                name="ordinationer_operation"
                                v-model="formData.ordinationer_operation"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="EKG godkänd"
                                name="ekg_godkand"
                                v-model="formData.ekg_godkand"
                                :options="yesNo"
                            />
                        </div>
                    </collapse-transition>
                </div>
            </div>
            <div class="k3-sub-form-flex-container">
                <div>
                    <div class="circle">
                        <div class="number">4</div>
                    </div>
                    <div class="vr vr-no-min-height"></div>
                </div>
                <div>
                    <div class="left-pad" @click="toggleVisible('godkannande')">
                        <h3>
                            Bedömning<img
                                :class="['k3-dropdown-icon', !hidden.bedomning ? 'k3-img-rotate' : '']"
                                src="@/assets/dropdown-arrow-blue.svg"
                            />
                        </h3>
                    </div>
                    <collapse-transition>
                        <div class="k3-sub-form" v-if="!hidden.bedomning">
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="ASA-klassifikation"
                                name="ASA"
                                v-model="formData.ASA"
                                :options="[
                                    { text: 'ASA1', value: 'ASA1' },
                                    { text: 'ASA2', value: 'ASA2' },
                                    { text: 'ASA3', value: 'ASA3' },
                                    { text: 'ASA4', value: 'ASA4' },
                                ]"
                            />
                            <div class="colspan c6">&nbsp;</div>
                            <input-wrapper
                                :component="widgets.TextAreaWidget"
                                label="Kommentar"
                                :colspan="4"
                                :maxlength="1000"
                                name="kommentar"
                                v-model="formData.kommentar"
                            />
                        </div>
                    </collapse-transition>
                </div>
            </div>
            <div class="hr" />
            <div class="btn-group" role="group">
                <button type="button" role="button" class="btn btn-primary" @click="save">Spara</button>
                <button type="button" class="btn btn-primary" @click="save">
                    <img src="@/assets/sign_white.svg" />Signera
                </button>
                <button type="button" class="btn btn-outline-secondary" @click="cancel">Avbryt</button>
                <div class="left-pad" style="flex-grow: 1; padding-top: 24px">
                    <template v-if="statusSaved"
                        ><img src="@/assets/positive_notification.svg" />Preanestesibedömning sparad
                    </template>
                </div>
                <button type="button" class="btn btn-alert" @click="clear">Rensa alla fält</button>
            </div>
            <div class="k3-sub-form-flex-container">
                <div>
                    <div class="circle">
                        <div class="number">5</div>
                    </div>
                    <div class="vr vr-no-min-height"></div>
                </div>
                <div>
                    <div class="left-pad" @click="toggleVisible('godkannande')">
                        <h3>
                            Godkännande<img
                                :class="['k3-dropdown-icon', !hidden.godkannande ? 'k3-img-rotate' : '']"
                                src="@/assets/dropdown-arrow-blue.svg"
                            />
                        </h3>
                    </div>
                    <collapse-transition>
                        <div class="k3-sub-form" v-if="!hidden.godkannande">
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Godkänd"
                                name="godkand"
                                v-model="formData.godkand"
                                :options="yesNo"
                            />
                            <input-wrapper
                                :component="widgets.TextAreaWidget"
                                label="Krav för godkännande"
                                :colspan="4"
                                :maxlength="1000"
                                name="krav_godkand"
                                v-model="formData.krav_godkand"
                            />

                            <input-wrapper
                                :component="widgets.DateWidget"
                                label="Datum när godkänd"
                                name="datum_godkand"
                                v-model="formData.datum_godkand"
                            />
                            <input-wrapper
                                :component="widgets.DateWidget"
                                label="Giltighetstid"
                                name="giltighetstid"
                                v-model="formData.giltighetstid"
                            />
                        </div>
                    </collapse-transition>
                </div>
            </div>
        </div>
        <div class="hr" />
        <div class="btn-group form-group">
            <button type="button" class="btn btn-primary" @click="save">
                <img src="@/assets/sign_white.svg" />Godkänn och signera
            </button>
            <button type="button" class="btn btn-primary btn-alert" style="margin-top: 0px" @click="save">
                Återkalla godkännande
            </button>
        </div>
    </form>
</template>
<script>
import widgets from "@/components/widgets/inputs";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import BaseTab from "@/tabs/mixins/BaseTab";
import Vue from "vue";
import { mapGetters } from "vuex";
import { CollapseTransition } from "@ivanv/vue-collapse-transition";
import tabIcon from "@/assets/operationsanmalan.svg";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import dialogEvents from "@/dialogEvents";
import PreviewWidget from "@/components/widgets/PreviewWidget.vue";

const noNull = (str) => {
    return str || "";
};

const OP_REGISTRATION_TABLE_COLUMNS = [
    {
        name: "radio",
        title: "",
        template: (item) => {
            return `<div class="k3-mock-radio` + (item.checked ? ` k3-mock-radio-checked` : ``) + `" />`;
        },
    },
    { name: "id", title: "ID (för test)" },
    {
        name: "prio",
        title: "Prioritet",
        template: (item) => {
            return item.prio.displayName;
        },
    },
    {
        name: "documentTime",
        title: "Skapad",
        template: (item) => {
            return item.documentTime ? new Date(item.documentTime).toLocaleDateString("sv-SE") : "";
        },
        sortOrder: 1,
    },
    {
        name: "preliminart_inlaggningsdatum",
        title: "Prel. inl.datum",
        template: (item) => {
            return item.preliminart_inlaggningsdatum
                ? new Date(item.preliminart_inlaggningsdatum).toLocaleDateString("sv-SE")
                : "";
        },
    },
    {
        name: "operator",
        title: "Operatör",
        template: (item) => {
            return item.operator ? noNull(item.operator.name) : "";
        },
    },
    { name: "beskrivning", title: "Beskrivning" },
];

const ja_nej = [
    { text: "Ja", value: true },
    { text: "Nej", value: false },
];

const ja_nej_okant = [
    { text: "Ja", value: "JA" },
    { text: "Nej", value: "NEJ" },
    { text: "Okänt", value: "OKÄNT" },
];

export default {
    extends: BaseTab,
    name: "Preanestesibedomning",
    tabName: "Preanestesibedömning",
    icon: tabIcon,
    patientBound: true,
    props: {
        uid: Number,
        parameters: Object,
        tabId: Number,
    },
    components: {
        InputWrapper,
        CustomActionTable,
        CollapseTransition,
        PreviewWidget,
    },
    data() {
        return {
            widgets: widgets,
            formData: {},
            formDataObject: {},
            id: this.parameters ? this.parameters.id : null,
            currentPreanestesiBedomning: null,
            yesNo: ja_nej,
            yesNoUknown: ja_nej_okant,
            opAnmalanTableColumns: OP_REGISTRATION_TABLE_COLUMNS,
            currentOperation: {},
            selectedOperation: {},
            showOperations: false,
            loadComplete: false,
            hidden: {},
            statusSaved: false,
        };
    },
    computed: {
        ...mapGetters("operation/operationsanmalan", ["opAnmalans"]),
        ...mapGetters("patientData", ["patient"]),
        operations() {
            return (this.currentOperation.id ? [this.currentOperation] : this.opAnmalans).map((item) => {
                let newItem = item;
                newItem.checked = this.selectedOperation.id === item.id;
                return newItem;
            });
        },
        bmi() {
            if (this.formData.langd_patient && this.formData.vikt_patient) {
                return Number.parseFloat(this.formData.vikt_patient / Math.pow(this.formData.langd_patient / 100, 2))
                    .toFixed(1)
                    .toString()
                    .replace(".", ",");
            }
            return "";
        },
        rokare() {
            return this.formData.rokare === "JA" || this.formData.rokare === "OKÄNT";
        },
    },
    methods: {
        toggleVisible(subFormName) {
            let hidden = this.hidden[subFormName] || false;
            Vue.set(this.hidden, subFormName, !hidden);
        },
        validateForm(formName) {
            var form = document.getElementById(formName);
            if (form.checkValidity() === true) return true;
            else {
                var list = form.querySelectorAll(":invalid");
                for (var item of list) {
                    item.focus();
                }
                form.querySelector(":invalid").focus();
            }
            return false;
        },
        rokareChanged(rokare) {
            if (rokare === "NEJ") {
                delete this.formData.erbjuden_rokavvanjning;
                delete this.formData.krav_sluta_roka;
            }
        },
        selectCurrentOperation() {
            dialogEvents.$emit("open", {
                type: "warning",
                description: "Detta val kan inte ångras. Vill du fortsätta?",
                buttons: [
                    {
                        title: "Avbryt",
                        type: "secondary",
                    },
                    {
                        title: "Fortsätt",
                        type: "primary",
                        action: () => {
                            this.currentOperation = this.selectedOperation;
                        },
                    },
                ],
            });
        },
        save() {
            if (!this.validateForm("preane-registration-details")) return;
            let cleanFormData = this.formData;
            cleanFormData.opanmalan = this.currentOperation.id;

            // Backend doesn't accept "" as an empty value, so we need to remove it. It _does_ accept empty arrays, but removing those too for cleaner props.
            // TODO: this should probably be done in store, but this particular component doesn't have a store binding yet.
            for (var key in cleanFormData) {
                if (cleanFormData[key] === null || cleanFormData[key] === undefined || cleanFormData[key] === "")
                    delete cleanFormData[key];
                else if (Array.isArray(cleanFormData[key])) {
                    cleanFormData[key] = cleanFormData[key].filter((el) => {
                        return el !== "";
                    });
                    if (cleanFormData[key] === []) delete cleanFormData[key];
                }
            }
            this.$store.dispatch("operation/operationsanmalan/savePreane", cleanFormData);
            this.statusSaved = true;
        },
        /*
        async loadPreanestesiBedomning(pk) {
            return klinikenApi.get('/operation/preanestesibedomning/'+pk+'/', config)
            .then(response => {
                this.currentPreanestesiBedomning = response.data;
            })
            .catch((e) => {
                openDialog('Kunde inte uppdatera preanestesibedömningar från servern. '+getErrorMessage(e), 'warning');
            })
        },*/
        cancel() {
            this.closeTab(true)
        },
        clear() {
            dialogEvents.$emit("open", {
                type: "warning",
                description: "Du kommer att rensa samtliga fält i formuläret. Vill du fortsätta?",
                buttons: [
                    {
                        title: "Nej",
                        type: "secondary",
                    },
                    {
                        title: "Ja",
                        type: "primary",
                        action: () => {
                            this.formData = {};
                            this.formDataObject = {};
                        },
                    },
                ],
            });
        },
        close() {
            this.$store.dispatch("tabs/closeTab", this.tabId);
        },
        selectOperation(item) {
            this.selectedOperation = item;
            if (!this.formData.langd_patient) Vue.set(this.formData, "langd_patient", this.currentOperation.langd);
            if (!this.formData.vikt_patient) Vue.set(this.formData, "vikt_patient", this.currentOperation.vikt);
            if (!this.formData.rokare) Vue.set(this.formData, "rokare", this.currentOperation.rokare);
            if (!this.formData.erbjuden_rokavvanjning)
                Vue.set(this.formData, "erbjuden_rokavvanjning", this.currentOperation.erbjuden_rokavvanjning);
            if (!this.formData.krav_sluta_roka)
                Vue.set(this.formData, "krav_sluta_roka", this.currentOperation.krav_sluta_roka);
        },
    },
    async created() {
        if (this.id) {
            await this.loadPreanestesiBedomning(this.id);
            this.formData = this.currentPreanestesiBedomning;
        }
        await this.$store.dispatch("operation/operationsanmalan/loadOpAnmalans", this.patient.id);
        this.loadComplete = true;
        this.$on("actionTable::select", this.selectOperation);
    },
};
</script>

<style lang="sass" scoped>
@import "@/style/variables"

#preane-registration-details
  position: relative
  max-width: 1100px

.left-pad
  margin-left: 10px

button, .left-pad
  > img
    margin-right: 8px

.form-group-border
  border-top: 1px solid #6FB8D2
  border-bottom: 1px solid #6FB8D2
  padding-top: 10px

.form-group-first
  border-left: 1px solid #6FB8D2
  border-top-left-radius: 4px
  border-bottom-left-radius: 4px

.form-group-last
  border-right: 1px solid #6FB8D2
  border-top-right-radius: 4px
  border-bottom-right-radius: 4px

.k3-big-font
  font-size: 20px
  font-weight: 500
  letter-spacing: 0
  line-height: 22px

.k3-fake-table
  margin-left: 17px
  width: 1100px
  background-color: #F6F8FB
  padding: 20px

.k3-card
  width: 1100px
  background-color: #F6F8FB
  padding: 22px
  margin: 22px 10px 32px 10px

.btn
  &:disabled
    background-color: #38a7cf
    color: #FFFFFF
    opacity: 0.4

    img
      filter: none

img
  width: 16px
  height: 16px

.circle
  height: 26px
  width: 26px
  background-color: #277692
  border-radius: 13px

  .number
    height: 26px
    width: 100%
    color: #FFFFFF
    font-family: Roboto
    font-size: 18px
    letter-spacing: 0
    line-height: 26px
    text-align: center

.vr
  border-left: 1px solid #277692
  height: calc(100% - 60px)
  min-height: 10px
  margin-left: 13px
  margin-top: 10px
  margin-bottom: 10px

  &.vr-no-min-height
    min-height: 0px

.k3-form
  flex-flow: column nowrap

.k3-sub-form-flex-container
  display: flex
  flex-direction: row
  margin-left: -20px

.k3-sub-form
  display: flex
  flex-direction: row
  flex-wrap: wrap

.k3-dropdown-icon
  margin-left: 15px

.k3-img-rotate
  transform: rotate(180deg)

.hr
  box-sizing: border-box
  height: 1px
  width: 100%
  border-top: 1px solid #979797

.btn-group
  margin-top: 25px !important
  margin-bottom: 55px !important

  > .btn
    flex: 0 0 auto !important

  > .btn-primary
    font-size: 20px
    font-weight: 500
    letter-spacing: 0
    line-height: 22px
</style>
<style lang="sass">
.k3-op-select
  width: 1100px
  margin-left: 17px

  table
    background-color: #F6F8FB

  th
    color: #277692
    font-family: Roboto
    font-size: 14px
    font-weight: 600 !important
    letter-spacing: 0
    line-height: 16px

  td:first-of-type
    padding: 0px 12px 0px 12px

  .k3-mock-radio
    margin-top: 12px
    display: inline-block
    height: 20px !important
    width: 20px !important
    -webkit-appearance: none
    -moz-appearance: none
    border-radius: 50%
    border: 1px solid #95A1B1
    background: radial-gradient(circle, #FFFFFF 0%, #FFFFFF 100%)

  .k3-mock-radio-checked
    background: radial-gradient(circle, #FFFFFF 0%, #FFFFFF 30%, #ca3470 30%, #ca3470 100%)
</style>
