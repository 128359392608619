<template>
    <tbody class="veckooversikt-list">
        <tr v-for="anmalan in group.anmalningar">
            <td>
                {{ getDay(anmalan.bokad_operation_datum) }}<br />
                {{ anmalan.bokad_operation_datum }}
            </td>
            <td>
                {{ anmalan.bokad_operation_tid.substring(0, 5) }}
            </td>
            <td>
                {{ anmalan.bokad_sal_display?.namn }}
            </td>
            <td>
                {{ anmalan.bokad_huvudoperator_display?.user_display?.name }}
            </td>
            <td>
                {{ anmalan.huvudoperationskod_display.code }}
            </td>
            <td>
                <ul>
                    <li v-for="(implantat, index) in anmalan.implantat_display" :key="index">
                        {{ implantat.namn }}
                    </li>
                </ul>
            </td>
            <td>
                <ul>
                    <li v-for="(resurs, index) in anmalan.resurser_display" :key="index">
                        {{ resurs.namn }}
                    </li>
                </ul>
            </td>
            <td>
                {{ anmalan.reop ? "Ja" : "Nej" }}
            </td>
            <td>
                {{ anmalan.operationsinformation }}
            </td>
            <td>
                {{ anmalan.patient_display.personId }}
            </td>
            <td>
                {{ anmalan.patient_display.namn }}
            </td>
        </tr>
        <tr class="footer">
            <td colspan="11">
                Summa optid {{ getDay(group.datum) }} {{ moment(group.datum).format("YYYY-MM-DD") }}<br />
                <strong>{{ sumOptid }} min</strong>
            </td>
        </tr>
    </tbody>
</template>

<script setup>
import { computed } from "vue";
import { sumBy } from "lodash";
import moment from "moment";

const props = defineProps({
    group: { type: Object, default: () => [] },
});

const sumOptid = computed(() => {
    return sumBy(props.group.anmalningar, (anmalan) => anmalan.optid);
});

function getDay(str) {
    let date = new Date(str);
    let day = date.toLocaleDateString("sv", { weekday: "long" });
    return day.charAt(0).toUpperCase() + day.slice(1);
}
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.veckooversikt-list {
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
}
</style>
