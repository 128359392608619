<script>
import InputText from "@/components/InputText.vue";

export default {
    extends: InputText,
    name: "InputNumber",
    props: {
        value: Number(""),
        min: {
            type: Number,
            required: false,
            default: 0,
        },
        max: {
            type: Number,
            required: false,
            default: NaN,
        },
    },
    data() {
        return {
            count: Number(this.value),
        };
    },
    methods: {
        stepUp() {
            this.count = Number(this.count) + 1;
            this.$emit("input", Number(this.count));
        },
        stepDown() {
            if (Number(this.count) !== 0) {
                this.count = Number(this.count) - 1;
                this.$emit("input", Number(this.count));
            }
        },
    },
    watch: {
        value: function (newValue) {
            if (newValue instanceof Event) {
                let value = Number(this.$refs.el.value);
                this.count = value;
            } else {
                this.count = Number(newValue);
            }
        },

        count: function (newValue) {
            this.count = Number(newValue);
            this.$emit("input", this.count);
        },
    },
};
</script>

<template>
    <div class="input-number" :class="klass">
        <input
            type="number"
            v-model="count"
            :class="klass"
            v-bind="$attrs"
            v-on="$listeners"
            ref="el"
            :min="min"
            :max="max"
        />
        <div class="buttons" :class="klass">
            <button type="button" class="step-up" @click="stepUp()" :disabled="$attrs['disabled']">+</button>
            <button type="button" class="step-down" @click="stepDown()" :disabled="$attrs['disabled']">-</button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/style/mixins/input";

.input-number {
    display: inline-flex;

    width: 100%;

    input {
        -moz-appearance: textfield;
        appearance: textfield;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        @include input-mixin;
        padding-right: 0;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;

        &.error {
            border-bottom: $input-border-error;
        }
    }

    .buttons {
        width: 22px;
        display: flex;
        flex-direction: column;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border-top: $input-border;
        border-right: $input-border;
        border-bottom: $input-border;

        &.error {
            border-bottom: $input-border-error;
        }

        &:has(button:disabled) {
            opacity: $disabled-opacity;
        }

        button {
            height: 23px;
            border: 0;
            background-color: transparent;
            cursor: pointer;
            text-align: center;

            &:disabled {
                cursor: default;
            }

            &.step-up {
                border-bottom: $input-border;
            }

            &.step-down {
            }
        }
    }
}
</style>
