<template>
    <div class="journalanteckningsmallar">
        <modal-basic ref="dialog" />
        <div class="header">
            <h1>Journalanteckningsmallar</h1>
            <button type="button" @click="openJournalanteckningsmall">
                <img src="@/assets/plus_circle_blue.svg" />
                <span>Skapa ny journalanteckningsmall</span>
            </button>
        </div>

        <search-filter
            v-model="filterValues"
            @input="handleSearch"
            :fields="filterFields"
            :is-open="true"
            :show-tags="false"
        >
            <template v-slot:quick-filters>
                <a>Denna vecka</a>
                <a>Nästa vecka</a>
                <a>Idag</a>
                <a>Imorgon</a>
                <a>Alla</a>
            </template>
            <template v-slot:fields>
                <FormulateInput type="text" name="mallnamn" label="Mallnamn" />
                <FormulateInput
                    type="vueselect"
                    class="autocomplete"
                    name="journalanteckningstyp"
                    label="Journalanteckningstyp"
                    @search="journalanteckningstyperSearch"
                    :options="journalanteckingstyper"
                />
                <FormulateInput
                    type="vueselect"
                    class="autocomplete"
                    name="skapat_av"
                    label="Skapad av"
                    @search="skapatAvSearch"
                    :options="skapatAv"
                />
            </template>
        </search-filter>

        <div class="subheader">
            <span>
                Visar <strong>{{ getJournalanteckningsmallar.length }}</strong> av
                <strong>{{ getJournalanteckningsmallarCount }}</strong> träffar
            </span>
        </div>

        <kliniken-table
            :items="getJournalanteckningsmallar"
            :columns="columns"
            :count="getJournalanteckningsmallarCount"
            :dropdown-actions="dropdownActions"
            :has-dropdown="true"
            @redigeraJournalanteckningsmall="redigeraJournalanteckningsmall"
            @raderaJournalanteckningsmall="raderaJournalanteckningsmall"
            @dupliceraJournalanteckningsmall="dupliceraJournalanteckningsmall"
        />
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab.js";
import Button from "@/components/Button.vue";
import EditIcon from "@/assets/actionIcons/edit.svg";
import DeleteIcon from "@/assets/actionIcons/delete.svg";
import DuplicateIcon from "@/assets/actionIcons/duplicate.svg";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { flashMessage, openDialog } from "@/utils";
import SearchFilter from "@/components/SearchFilter.vue";
import tabIcon from "@/assets/skapa_journal.svg";
import Journalanteckningsmall from "@/tabs/Journalanteckningmallar/Journalanteckningsmall.vue";
import KlinikenTable from "@/components/Table/KlinikenTable.vue";
import ModalBasic, { BasicModalVariant } from "@/components/generic/ModalBasic.vue";
import { debounce } from "lodash";
import { mapGetters } from "vuex";

export default {
    extends: BaseTab,
    name: "Journalanteckningsmallar",
    tabName: "Journalanteckningsmallar",
    icon: tabIcon,
    components: { Button, SearchFilter, KlinikenTable, ModalBasic },
    data() {
        return {
            filterValues: {},
            sortField: "",
            columns: [
                {
                    title: "Mallnamn",
                    name: "namn",
                },
                {
                    title: "Anteckningstyp",
                    name: "anteckningstyp_display.namn",
                },
                {
                    title: "Skapat av",
                    name: "created_by_display.user_display.name",
                },
            ],
            dropdownActions: [
                {
                    name: "redigeraJournalanteckningsmall",
                    title: "Redigera",
                    icon: EditIcon,
                    show: () => {
                        return true;
                    },
                },
                {
                    name: "dupliceraJournalanteckningsmall",
                    title: "Duplicera",
                    icon: DuplicateIcon,
                    show: () => {
                        return true;
                    },
                },
                {
                    name: "raderaJournalanteckningsmall",
                    title: "Radera",
                    icon: DeleteIcon,
                    show: () => {
                        return true;
                    },
                },
            ],
            journalanteckingstyper: [],
            skapatAv: [],
        };
    },
    props: {
        tabId: Number,
    },
    computed: {
        ...mapGetters("journalanteckningsmall", ["getJournalanteckningsmallar", "getJournalanteckningsmallarCount"]),
        filterFields() {
            return {
                namn: { label: "Sök" },
                anteckningstyp_display__namn: { label: "Anteckningstyp" },
                skapat_av: { label: "Skapad av" },
            };
        },
    },
    watch: {
        getJournalanteckningsmallar(value) {
            this.items = value;
        },
        getJournalanteckningsmallarCount(value) {
            this.journalanteckningsmallarCount = value;
        },
    },
    methods: {
        async fetchJournalanteckningsmallar() {
            let filterValues = {};

            if (Object.keys(this.filterValues).length > 0) {
                filterValues = {
                    anteckningstyp: this.filterValues.journalanteckningstyp ?? "",
                    namn__icontains: this.filterValues.mallnamn ?? "",
                    created_by: this.filterValues.skapat_av ?? "",
                };
            }

            let params = {
                ...filterValues,
                ...{
                    ordering: this.sortField,
                },
            };

            try {
                const response = await klinikenApi.get("/anteckning/journalanteckningsmallar/", getConfig({ params }));
                this.$store.dispatch("journalanteckningsmall/setJournalanteckningsmallar", response.data.results);
                this.$store.dispatch("journalanteckningsmall/setJournalanteckningsmallarCount", response.data.count);
            } catch (error) {
                openDialog(getErrorMessage(error), "warning");
            }
        },
        async handleSearch(filterData) {
            this.filterValues = filterData;
            await this.fetchJournalanteckningsmallar();
        },

        openJournalanteckningsmall() {
            this.$store.dispatch("tabs/openTab", {
                component: Journalanteckningsmall,
            });
        },

        redigeraJournalanteckningsmall(journalanteckningmall) {
            this.$store.dispatch("tabs/openTab", {
                component: Journalanteckningsmall,
                parameters: { ...journalanteckningmall, isEditing: true },
            });
        },

        dupliceraJournalanteckningsmall(journalanteckningmall) {
            const obj = { ...journalanteckningmall };
            delete obj.id;

            this.$store.dispatch("tabs/openTab", {
                component: Journalanteckningsmall,
                parameters: { ...obj, isDuplicating: true },
            });
        },

        async raderaJournalanteckningsmall({ id }) {
            if (id) {
                const confirm = await this.$refs.dialog.show({
                    variant: BasicModalVariant.WARNING,
                    title: "Vill du verkligen radera hela mallen?",
                    okButton: "Ja, radera",
                    cancelButton: "Nej, avbryt",
                });

                if (confirm) {
                    await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
                    try {
                        const response = await klinikenApi.delete(
                            `/anteckning/journalanteckningsmallar/${id}/`,
                            getConfig()
                        );

                        if (response.status === 204) {
                            const items = this.items.filter((item) => item.id !== id);
                            this.$store.dispatch("journalanteckningsmall/setJournalanteckningsmallar", items);
                            flashMessage("Journalanteckningsmall har raderats");
                        }
                    } catch (error) {
                        await this.$refs.dialog.show({
                            variant: BasicModalVariant.ALERT,
                            title: "Gick inte att radera",
                            okButton: "OK",
                        });
                    } finally {
                        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
                    }
                }
            }
        },

        journalanteckningstyperSearch(search, loading) {
            if (search.trim().length >= 3) {
                loading(true);
                this.searchJournalanteckningstyper(loading, search, this);
            }
        },
        searchJournalanteckningstyper: debounce((loading, search, vm) => {
            return klinikenApi
                .get("/anteckning/typer/shallow/", getConfig({ params: { namn__icontains: search } }))
                .then((res) => {
                    vm.options = res.data.map(({ pk, namn }) => ({
                        value: pk,
                        label: namn,
                    }));

                    loading(false);

                    vm.journalanteckingstyper = vm.options;
                });
        }, 350),

        skapatAvSearch(search, loading) {
            if (search.trim().length >= 3) {
                loading(true);
                this.searchSkapatAvSearch(loading, search, this);
            }
        },
        searchSkapatAvSearch: debounce((loading, search, vm) => {
            return klinikenApi.get("/core/profiles/", getConfig({ params: { q: search } })).then((res) => {
                vm.options = res.data.results.map(({ pk, user }) => ({
                    value: pk,
                    label: user.name,
                }));

                loading(false);

                vm.skapatAv = vm.options;
            });
        }, 350),

        async fetchJournalanteckingstyper() {
            try {
                const response = await klinikenApi.get("/anteckning/typer/shallow/", getConfig());

                if (response.data) {
                    this.journalanteckingstyper = response.data.map(({ pk, namn }) => ({
                        value: pk,
                        label: namn,
                    }));
                } else {
                    this.journalanteckingstyper = [];
                }
            } catch (error) {
                openDialog(getErrorMessage(error), "warning");
            }
        },

        async fetchSkapatAv() {
            try {
                const response = await klinikenApi.get("/core/profiles/", getConfig());

                if (response.data?.results) {
                    this.skapatAv = response.data.results.map(({ pk, user }) => ({
                        value: pk,
                        label: user.name,
                    }));
                } else {
                    this.skapatAv = [];
                }
            } catch (error) {
                openDialog(getErrorMessage(error), "warning");
            }
        },
    },
    async created() {
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });

        await this.fetchJournalanteckningsmallar();
        await this.fetchJournalanteckingstyper();
        await this.fetchSkapatAv();

        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
    },
};
</script>
<style lang="scss" scoped>
.journalanteckningsmallar {
    ::v-deep(table) {
        background-color: #f6f8fb;
    }

    ::v-deep .search-filter {
        margin-top: 20px;

        .header {
            display: none;
        }
    }

    margin-bottom: 60px;
    padding: 0 30px;

    .header {
        display: flex;
        align-items: center;
        gap: 40px;

        h1 {
            margin: 0px !important;
            font-family: Roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 34px;
            color: #277692;
        }

        .button-hollow:hover {
            background-color: transparent;
        }

        button {
            background: transparent;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-right: 8px;
            }

            span {
                color: #6c8188;
                font-family: Roboto Medium, serif;
                font-size: 16px;
                line-height: 0px;
                white-space: nowrap;
            }
        }
    }

    .subheader {
        display: flex;
        justify-content: space-between;
        margin: 20px 0px;

        span {
            font-size: 22px;
            line-height: 30px;
            font-weight: normal;
        }

        strong {
            font-size: 22px;
            line-height: 30px;
            font-weight: normal;
            font-family: Roboto Medium, sans-serif;
        }
    }
}
</style>
