<template>
    <div class="vit-bg" v-if="alertInformationSymbol">
        <div class="header-row">
            <h1 class="uppmarksamhetssignal">Uppmärksamhetssignaler</h1>
            <div class="active-copy-add">
                <div class="row newAlert" @click="openAlertInformationFormTab">
                    <img class="newAlertImg" src="@/assets/plus_circle_blue.svg" />
                    <p class="newAlertText">Lägg till uppmärksamhetssignal</p>
                </div>
            </div>
        </div>

        <div class="symbolText">
            <div class="topTextSymbol row">
                <div class="textSymbolLeft">
                    <p
                        :class="{
                            disabledAlert: !alertInformationSymbol.unstructured,
                        }"
                        v-tooltip="{
                            content: 'Ostrukturerade Uppmärksamhetssignaler',
                            placement: 'top',
                        }"
                    >
                        <span v-if="alertInformationSymbol.unstructured"
                            >({{ alertInformationSymbol.unstructured }})</span
                        >
                        Övrigt
                    </p>
                    <p
                        :class="{
                            disabledAlert: !alertInformationSymbol.communicableDisease,
                        }"
                        v-tooltip="{
                            content: 'Smittsamma Sjukdomar',
                            placement: 'bottom',
                        }"
                    >
                        <span v-if="alertInformationSymbol.communicableDisease"
                            >({{ alertInformationSymbol.communicableDisease }})</span
                        >
                        Smitta
                    </p>
                </div>
                <alert-symbol class="midSymbol" :alertInformationSymbol="alertInformationSymbol"></alert-symbol>
                <div class="textSymbolRight">
                    <p
                        :class="{
                            disabledAlert: !alertInformationSymbol.seriousDisease && !alertInformationSymbol.treatment,
                        }"
                        v-tooltip="{
                            content: 'Allvarlig Sjukdomar och Behandlingar',
                            placement: 'top',
                        }"
                    >
                        Allvarlig Sjukdom och Behandling
                        <span v-if="alertInformationSymbol.seriousDisease || alertInformationSymbol.treatment"
                            >({{ alertInformationSymbol.seriousDisease + alertInformationSymbol.treatment }})</span
                        >
                    </p>
                    <p
                        :class="{
                            disabledAlert: !alertInformationSymbol.restrictionOfCare,
                        }"
                        v-tooltip="{
                            content: 'Vårdrutinavvikelser',
                            placement: 'bottom',
                        }"
                    >
                        Avsteg från vårdrutin
                        <span v-if="alertInformationSymbol.restrictionOfCare"
                            >({{ alertInformationSymbol.restrictionOfCare }})</span
                        >
                    </p>
                </div>
            </div>
            <div class="bottomTextSymbol">
                <p
                    :class="{
                        disabledAlert: !alertInformationSymbol.hyperSensitivity,
                    }"
                    v-tooltip="{
                        content: 'Överkänsligheter',
                        placement: 'bottom',
                    }"
                >
                    Överkänslighet
                    <span v-if="alertInformationSymbol.hyperSensitivity"
                        >({{ alertInformationSymbol.hyperSensitivity }})</span
                    >
                </p>
            </div>
        </div>

        <div v-if="alertInformationList.length > 0">
            <label v-if="this.livshotandeSignals.length > 0"
                ><h4>Uppmärksamhetssignaler, livshotande överkänslighet</h4></label
            >
            <div v-if="this.livshotandeSignals.length > 0" class="alert-list">
                <div class="rubriker row container">
                    <p class="datum rubrikText">Datum</p>
                    <p class="alertSign rubrikText"></p>
                    <p class="typ rubrikText">Typ</p>
                    <p class="ansvarig rubrikText">Dokumenterad av</p>
                    <p class="enhet rubrikText">Vårdenhet</p>
                    <p class="aktuell rubrikText">Aktualitet</p>
                    <p class="status rubrikText">Status</p>
                </div>
                <transition name="list-complete">
                    <div>
                        <alert-list-component
                            v-for="livshotande in this.livshotandeSignals"
                            class="alert-list-component"
                            :item="livshotande"
                            :key="livshotande.pk"
                            @signListItem="sign"
                        ></alert-list-component>
                    </div>
                </transition>
            </div>
            <label v-if="this.ovrigaSignals.length > 0"><h4>Uppmärksamhetssignaler, ej livshotande</h4></label>
            <div v-if="this.ovrigaSignals.length > 0" class="alert-list">
                <div class="rubriker row container">
                    <p class="datum rubrikText">Datum</p>
                    <p class="alertSign rubrikText"></p>
                    <p class="typ rubrikText">Typ</p>
                    <p class="ansvarig rubrikText">Dokumenterad av</p>
                    <p class="enhet rubrikText">Vårdenhet</p>
                    <p class="aktuell rubrikText">Aktualitet</p>
                    <p class="status rubrikText">Status</p>
                </div>
                <div>
                    <alert-list-component
                        v-for="ovriga in this.ovrigaSignals"
                        class="alert-list-component"
                        :item="ovriga"
                        :key="ovriga.pk"
                        @signListItem="sign"
                    ></alert-list-component>
                </div>
            </div>
            <label v-if="this.inactualAndMakulerat.length > 0"><h4>Inaktuella uppmärksamhetssignaler</h4></label>
            <div v-if="this.inactualAndMakulerat.length > 0" class="alert-list">
                <div class="rubriker row container">
                    <p class="datum rubrikText">Datum</p>
                    <p class="alertSign rubrikText"></p>
                    <p class="typ rubrikText">Typ</p>
                    <p class="ansvarig rubrikText">Dokumenterad av</p>
                    <p class="enhet rubrikText">Vårdenhet</p>
                    <p class="aktuell rubrikText">Aktualitet</p>
                    <p class="status rubrikText">Status</p>
                </div>
                <transition name="list-complete">
                    <div>
                        <alert-list-component
                            v-for="deprecated in this.inactualAndMakulerat"
                            class="alert-list-component"
                            :item="deprecated"
                            :key="deprecated.pk"
                            @signListItem="sign"
                        ></alert-list-component>
                    </div>
                </transition>
            </div>
        </div>

        <div class="mt-5" v-if="!this.alertListEmpty && alertInformationList.length === 0">
            <skeleton-box height="53px" width="1015px" margin="0 0 5px 0" bgcolor="#F3F5F9" radius="5px"></skeleton-box>
            <skeleton-box height="53px" width="1015px" margin="0 0 5px 0" bgcolor="#F3F5F9" radius="5px"></skeleton-box>
            <skeleton-box height="53px" width="1015px" margin="0 0 5px 0" bgcolor="#F3F5F9" radius="5px"></skeleton-box>
            <skeleton-box height="53px" width="1015px" margin="0 0 5px 0" bgcolor="#F3F5F9" radius="5px"></skeleton-box>
            <skeleton-box height="53px" width="1015px" margin="0 0 5px 0" bgcolor="#F3F5F9" radius="5px"></skeleton-box>
            <skeleton-box height="53px" width="1015px" margin="0 0 5px 0" bgcolor="#F3F5F9" radius="5px"></skeleton-box>
        </div>

        <div v-if="this.alertListEmpty" class="nosignals">
            <h3>Inga uppmärksamhetssignaler finns registrerade på patienten</h3>
        </div>
    </div>
</template>

<script>
import AlertInformationForm from "@/tabs/AlertInformation/AlertInformationForm.vue";
import AlertListComponent from "@/tabs/AlertInformation/AlertListComponent.vue";
import AlertSymbol from "@/tabs/AlertInformation/AlertSymbol.vue";
import Button from "@/components/Button.vue";
import SkeletonBox from "@/components/widgets/Skeleton.vue";
import { getErrorMessage } from "@/api";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "AlertInformationMain",
    props: ["alertInformationSymbol"],
    components: {
        Button,
        alertListComponent: AlertListComponent,
        alertSymbol: AlertSymbol,
        SkeletonBox,
    },
    data() {
        return {
            loading: true,
            toggle: false,
        };
    },
    computed: {
        ...mapGetters("patientData", ["patient"]),
        ...mapGetters("alertInformation", ["alertInformationList", "alertInformationForm"]),
        livshotandeSignals: {
            get() {
                return this.alertInformationList.filter(
                    (livshotande) =>
                        livshotande.degreeOfSeverity === 3 &&
                        livshotande.is_actual &&
                        livshotande.is_nullified === false
                );
            },
            set(data) {
                return data;
            },
        },
        ovrigaSignals: {
            get() {
                return this.alertInformationList.filter(
                    (ovriga) => ovriga.degreeOfSeverity !== 3 && ovriga.is_actual && ovriga.is_nullified === false
                );
            },
            set(data) {
                return data;
            },
        },
        inactualAndMakulerat: {
            get() {
                return this.alertInformationList.filter(
                    (deprecated) => deprecated.is_actual === false || deprecated.is_nullified === true
                );
            },
            set(data) {
                return data;
            },
        },
        alertListEmpty() {
            return (
                this.alertInformationSymbol.communicableDisease === 0 &&
                this.alertInformationSymbol.restrictionOfCare === 0 &&
                this.alertInformationSymbol.hyperSensitivity === 0 &&
                this.alertInformationSymbol.unstructured === 0 &&
                this.alertInformationSymbol.treatment === 0 &&
                this.alertInformationSymbol.seriousDisease === 0
            );
        },
    },
    methods: {
        ...mapActions("alertInformation", ["loadDetailsInformation"]),
        async checkSensitivity(info) {
            if (info.typeOfSensitivity) {
                switch (info.typeOfSensitivity) {
                    case "PharmaceuticalHyperSensitivity":
                        return "phs";
                    case "OtherHyperSensitivity":
                        return "ohs";
                }
            } else {
                return info.typeOfAlertInformation;
            }
        },
        getHyperSensitivityText() {
            switch (this.alertInformationSymbol.degreeOfSeverity) {
                case 1:
                    return "Besvärande Överkänslighet ";
                case 2:
                    return "Allvarlig Överkänslighet ";
                case 3:
                    return "Livshotande Överkänslighet ";
                default:
                    return "Överkänsligheter ";
            }
        },
        async sign(info) {
            let type = await this.checkSensitivity(info);

            try {
                const details = await this.loadDetailsInformation({
                    pk: info.pk,
                    type: type,
                });

                details.typeOfAlertInformation = type;
                await this.$store.dispatch("alertInformation/sign", details);
                await this.$store.dispatch("alertInformation/loadAlertInformationList", { patientId: this.patient.id });
                await this.$store.dispatch("alertInformation/loadAlertInformationSymbol", {
                    patientId: this.patient.id,
                });
            } catch (e) {
                getErrorMessage(e);
            }
        },
        openAlertInformationFormTab() {
            this.$store.dispatch("tabs/openTab", {
                component: AlertInformationForm,
            });
        },
    },
    beforeDestroy() {
        this.$store.commit("alertInformation/resetAlertInformationList");
        this.livshotandeSignals = [];
        this.ovrigaSignals = [];
        this.inactualAndMakulerat = [];
    },
};
</script>

<style scoped lang="scss">
@import "@/style/_variables";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/grid";

@import "@/style/_deprecated_main";

.header-row {
    display: flex;
}

.alert-list-component {
    width: 1015px;
    flex: 1;
}

.alert-list {
    margin-bottom: 50px;
}

.newAlert {
    border-radius: 4px;
    text-align: center;
    padding: 5px;
}

.newAlert:hover {
    background-color: #cee9f3;
    border-radius: 4px;
    text-align: center;
    padding: 5px;
}

.newAlertImg {
    cursor: pointer;
    margin-left: 10px;
}

.newAlertText {
    color: #354052;
    font-family: Roboto;
    font-size: 16px;
    cursor: pointer;
    letter-spacing: 0;
    line-height: 22px;
    margin: 3px 8px;
}

.visaMakulerade {
    text-align: end;
    font-size: 14px;
    margin-right: 100px;
    margin-top: 20px;
    width: 1015px;
}

.nosignals {
    margin-top: 32px;
    margin-left: -15px;
}

.container {
    display: grid !important;
    height: 54px;
    grid-template-columns: 16px 83px 16px 206px 209px 205px 64px 73px 30px;
    grid-template-rows: 54px;
    column-gap: 8px;
}

.datum {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 0;
}

.alertSign {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 0;
    padding: 0;
}

.typ {
    grid-column-start: 4;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 0;
}

.ansvarig {
    grid-column-start: 5;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 0;
}

.enhet {
    grid-column-start: 6;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 0;
}

.aktuell {
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 0;
}

.status {
    grid-column-start: 8;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 0;
}

.rubriker {
    height: 16px;
}

.rubrikText {
    height: 16px;
    color: #277692;
    font-family: Roboto Medium;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
}

.active-copy-add {
    height: 28px;
    width: 269px;
    margin: 5px 0 1px 72px;
}

.vit-bg {
    background-color: #ffffff;
    //padding: 15px 30px;
}

.symbol {
    margin-left: 72px;
    margin-top: 28px;
}

.uppmarksamhetssignal {
    height: 34px;
    width: 343px;
    color: #277692;
    font-family: Roboto;
    font-size: 30px;
    letter-spacing: 0;
    line-height: 34px;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

.symbolText {
    margin: 30px 0 50px -100px;
    width: 500px;
    height: 120px;
}

.disabledAlert {
    opacity: 0.3;
}

.midSymbol {
    margin: 0 20px;
    width: 75px;
    height: 75px;
}

.textSymbolLeft {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
    width: 200px;
    color: #354052;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
}

.textSymbolRight {
    width: 200px;
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #354052;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
}

.bottomTextSymbol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 500px;
    margin-top: 10px;
    color: #354052;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
}

.list-complete-item {
    transition: all 0.8s ease;
    display: inline-block;
    margin-right: 10px;
}

.list-complete-enter-from,
.list-complete-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

.list-complete-leave-active {
    position: absolute;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.6s;
}

.slide-fade-enter,
.slide-fade-leave-active {
    opacity: 0;
}

.slide-fade-enter {
    transform: translateX(31px);
}

.slide-fade-leave-active {
    transform: translateX(-31px);
}
</style>
